import { createTheme } from '@material-ui/core'
import overrides from './overrides'
import palette from './palette'
import spacing from './spacing'

const theme = createTheme({
  overrides,
  spacing,
  palette
})

export default theme
