import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../../colors'
import { hexToRGBA } from '@aims-lib'

const black20 = hexToRGBA(colors.black, 0.2)
const black10 = hexToRGBA(colors.black, 0.1)

const buttonOverride: Overrides['MuiButton'] = {
  root: {
    border: '0.15rem solid',
    borderRadius: '0.625rem',
    fontSize: '1.2rem',
    padding: '0.35rem 1rem',
    textTransform: undefined,
    boxShadow: `0 0.5rem 1rem 0 ${black20}`,
    backgroundColor: undefined,
    '&:hover': {
      backgroundColor: undefined,
      textDecoration: undefined,
      boxShadow: `0 1rem 1.5rem 0 ${black10}`
    }
  },
  contained: {
    border: 'none',
    boxShadow: undefined,
    padding: '0.5rem 1rem'
  },
  containedPrimary: {
    color: colors.primary,
    borderColor: colors.primary,
    backgroundColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.primaryHover
    }
  },
  containedSecondary: {
    backgroundColor: colors.secondary,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.secondaryHover,
      border: undefined
    }
  },
  outlined: {
    border: undefined,
    '&$disabled': {
      border: undefined,
      borderColor: black20
    }
  },
  outlinedSecondary: {
    borderColor: colors.secondary,
    border: undefined,
    color: colors.secondary,
    '&:hover': {
      border: undefined,
      boxShadow: 'none',
      color: undefined,
      backgroundColor: undefined
    },
    '&$disabled': {
      border: undefined
    }
  },
  text: {
    boxShadow: 'none',
    border: 'none',
    padding: undefined,
    '&:hover': {
      textDecoration: 'underline',
      boxShadow: 'none',
      backgroundColor: undefined
    }
  },
  textSecondary: {
    color: colors.secondary,
    '&:hover': {
      backgroundColor: undefined
    }
  },
  label: {
    textTransform: 'none'
  },
  sizeLarge: {
    fontSize: '1.5rem',
    padding: '0.5rem 2rem'
  },
  textSizeSmall: {
    fontSize: 'inherit',
    padding: 0
  }
}

export default buttonOverride
