import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getResultsTracks, getSearchFieldsText, isLoading } from '@aims-store/search'

import { LoaderButtonTextual } from '@aims-controls'
import { useSelector } from 'react-redux'

const SearchButton = (): JSX.Element => {
  const loading = useSelector(isLoading)
  const searchText = useSelector(getSearchFieldsText)
  const results = useSelector(getResultsTracks)
  const [success, setSuccess]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean(false))

  useEffect(() => {
    if (!loading && results.length > 0) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 2000)
      return
    }
    setSuccess(false)
  }, [loading])

  return (
    <div className={'search-button'}>
      <LoaderButtonTextual
        color={'secondary'}
        loading={loading}
        success={success}
        disabled={loading || searchText.length === 0}
        type={'submit'}
        text={{ default: 'Search', loading: 'Searching...', success: 'See results' }}
        fullWidth
      />
    </div>
  )
}

export default SearchButton
