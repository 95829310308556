import { CHANGE_VOLUME, LOAD, PAUSE, PLAY } from '../action-types'
import { ChangeVolumeAction, LoadAction, PlayerState } from '@aims-player/types'

import { Action } from 'redux'
import { SearchType } from '@aims-search/types'

const DEFAULT_VOLUME = 0.75

const initialState: PlayerState = {
  playing: false,
  audio: null,
  track: null,
  volume: DEFAULT_VOLUME,
  trackType: SearchType.Internal
}

const reducer = (state = initialState, action: Action = { type: undefined }): PlayerState => {
  switch (action.type) {
    case LOAD:
      if (state.audio !== null) {
        if (state.trackType !== SearchType.Link) {
          // the listener is actually needed for YT and Vimeo URLs
          state.audio.onpause = null
        }
        state.audio.pause()
      }
      (action as LoadAction).audio.volume = state.volume
      return Object.assign({}, state, {
        playing: false,
        audio: (action as LoadAction).audio,
        track: (action as LoadAction).track,
        trackType: (action as LoadAction).trackType
      })

    case PAUSE:
      if (state.audio === null) {
        return state
      }
      state.audio.pause()
      return Object.assign({}, state, {
        playing: false
      })

    case PLAY:
      if (state.audio === null) return state
      void state.audio.play() // eslint-disable-line no-void
      return Object.assign({}, state, {
        playing: true
      })

    case CHANGE_VOLUME:
      if (state.audio === null) {
        return state
      }
      state.audio.volume = (action as ChangeVolumeAction).volume
      return Object.assign({}, state, {
        volume: (action as ChangeVolumeAction).volume
      })

    default:
      return state
  }
}

export default reducer
