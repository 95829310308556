import styled from 'styled-components'

const StyledMonthlyLimit = styled.div`
  color: ${props => props.theme.colors.grey.medium};
  margin-bottom: -1rem;
  margin-top: 1rem;
  padding-left: 1rem;
  span {
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.font.medium};
    margin-right: 0.5rem;
  }
  big {
    font-size: 130%;
  }
  a {
    margin-left: 0.8rem;
  }
  &.disabled {
    &, span, a {
      color: ${props => props.theme.colors.grey.light};
    }
  }
  .modifiers & {
    margin: 0;
    padding: 0;
    span {
      color: inherit;
      margin-right: 0.3rem;
    }
    big {
      font-size: 100%;
    }
  }
`

export default StyledMonthlyLimit
