import { CHANGE_VOLUME, LOAD, PAUSE, PLAY } from './action-types'
import { ChangeVolumeAction, LoadAction, PauseAction, PlayAction } from '@aims-player/types'

import { DetailedTrack } from '@aims-track/types'
import { SearchType } from '@aims-search/types'

const play = (): PlayAction => ({
  type: PLAY
})

const pause = (): PauseAction => ({
  type: PAUSE
})

const load = (audio: HTMLAudioElement, track: DetailedTrack, trackType: SearchType = SearchType.Internal): LoadAction => ({
  type: LOAD,
  audio,
  track,
  trackType
})

const changeVolume = (volume: number): ChangeVolumeAction => ({
  type: CHANGE_VOLUME,
  volume
})

export {
  play,
  pause,
  load,
  changeVolume
}
