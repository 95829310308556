import {
  AutoTaggingState,
  SetTrackTagsAction,
  TrackAddAction,
  TrackProcessingBeginAction,
  TrackProcessingErrorAction,
  TrackProcessingSuccessAction
} from '../types'

import { Action } from 'redux'
import { Track } from '@aims-auto-tagging/types'

const addTrack = (state: AutoTaggingState, action: TrackAddAction): AutoTaggingState => Object.assign({}, state, {
  tracks: [action.payload.track, ...state.tracks]
})

const setTrackIsProcessing = (state: AutoTaggingState, action: Action): AutoTaggingState => Object.assign({}, state, {
  tracks: state.tracks.map((track: Track) =>
    track.id === (action as TrackProcessingBeginAction).id
      ? { ...track, processing: true }
      : track),
  loading: true
})

const setTrackProcessingSuccess = (state: AutoTaggingState, action: TrackProcessingSuccessAction): AutoTaggingState => Object.assign({}, state, {
  tracks: state.tracks.map((track: Track) =>
    track.id === action.id
      ? {
        ...track,
        id: action.newId,
        processing: false,
        processed: true,
        tags: action.tags
      }
      : track),
  processedCount: state.processedCount + 1,
  loading: false
})

const setTrackProcessingError = (state: AutoTaggingState, action: TrackProcessingErrorAction): AutoTaggingState => Object.assign({}, state, {
  tracks: state.tracks.map((track: Track) =>
    track.id === action.id
      ? { ...track, processing: false, error: true }
      : track),
  processedCount: state.processedCount + 1,
  loading: false
})

const setTrackTags = (state: AutoTaggingState, action: SetTrackTagsAction): AutoTaggingState => {
  const { isHistoryTrack, track, newTags } = action.payload
  const tracks: Track[] = Array.from(state.tracks)
  const history: Track[] = Array.from(state.history)

  const targetTracksArray = isHistoryTrack ? history : tracks
  const trackToChange = targetTracksArray.find((t: Track): boolean => t.id === track.id)
  if (trackToChange === undefined) {
    throw new Error('Cannot change tags for track that is not in store.')
  }

  trackToChange.tags = newTags

  return Object.assign({}, state, {
    ...state,
    tracks,
    history
  })
}

export {
  addTrack,
  setTrackIsProcessing,
  setTrackProcessingSuccess,
  setTrackProcessingError,
  setTrackTags
}
