import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledNav = styled.nav`
  display: none;
  &.opened {
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    top: 6.25rem;
    width: 100%;
    z-index: 11;
  }
  ${media(ScreenSize.Small)} {
    display: flex;
    gap: 2rem;
  }
`

export default StyledNav
