import { Overrides } from '@material-ui/core/styles/overrides'
import colors from 'styled/colors'

const inputBaseOverride: Overrides['MuiInputBase'] = {
  root: {
    fontSize: '1.2rem',
    fontWeight: 700,
    backgroundColor: colors.white
  },
  formControl: {
    backgroundColor: colors.white
  }
}

export default inputBaseOverride
