import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core'

import React from 'react'

const Button = (props: MuiButtonProps): JSX.Element => {
  let disableRipple = props.disableRipple

  if (props.variant === 'text') {
    disableRipple = true
  }

  return (
    <MuiButton {...props} disableRipple={disableRipple}/>
  )
}

export default Button
