import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledBrand = styled.div`
  height: 2.875rem;
  ${media(ScreenSize.Small)} {
    height: 4rem;
  }
`

export default StyledBrand
