import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledHeaderLogo = styled.img`
  display: block;
  height: 2.875rem;
  width: 8rem;
  ${media(ScreenSize.Small)} {
    height: 4rem;
    width: 11rem;
  }
  &.custom {
    width: auto;
    ${media(ScreenSize.Small)} {
      width: auto;
    }
  }
`

export default StyledHeaderLogo
