enum ApiFilterOperator {
  eq = 'eq',
  neq = 'neq',
  gt = 'gt',
  gte = 'gte',
  gten = 'gten',
  lt = 'lt',
  lte = 'lte',
  begins = 'begins',
  contains = 'contains',
  notContains = 'not-contains',
  ends = 'ends',
  null = 'null',
  notNull = 'not-null',
  empty = 'empty',
  notEmpty = 'not-empty',
  in = 'in'
}

export default ApiFilterOperator
