import styled from 'styled-components'

const StyledProjectListing = styled.div`

  & .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .search-bar {
      & .search-icon {
        color: ${props => props.color};
        margin-right: 1rem;
      }

      & .MuiInputBase-root {
        height: 100%;
        font-size: 1.25rem;
        font-weight: 700;

        & .input-field::placeholder {
          font-weight: normal;
        }

        &:before, &:after {
          content: none;
        }
      }
    }
  }
`

export default StyledProjectListing
