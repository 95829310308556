const downloadTrackNext = async (url: string): Promise<void> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })

  if (!response.ok) {
    return await Promise.reject(response.statusText)
  }
  return await Promise.resolve()
}

export {
  downloadTrackNext
}
