import { ApiConfiguration, UserIdentity } from '../types'

import { SessionOptions } from 'next-iron-session'
import base64 from 'base-64'

const api: ApiConfiguration = {
  host: process.env.DEMO_API_URL ?? '',
  clientId: process.env.DEMO_API_CLIENT_ID ?? '',
  clientSecret: process.env.DEMO_API_CLIENT_SECRET ?? ''
}

const getHeaders = (config: ApiConfiguration): HeadersInit => ({
  'X-Client-Id': config.clientId,
  'X-Client-Secret': config.clientSecret
})

const getBasicAuth = (username: string, password: string): string =>
  `Basic ${base64.encode(`${username}:${password}`)}`

export const authenticate = async (username: string, password: string): Promise<UserIdentity> => {
  const headers: HeadersInit = { ...getHeaders(api), authorization: getBasicAuth(username, password) }
  const method = 'POST'
  const response = await fetch(`${api.host}/authenticate/`, { headers, method })
  if (!response.ok) {
    throw new Error('Invalid credentials supplied!')
  }
  return await response.json()
}

export const authenticateWithToken = async (token: string): Promise<UserIdentity> => {
  const headers: HeadersInit = { ...getHeaders(api) }
  const method = 'POST'
  const response = await fetch(`${api.host}/authenticate/${token}`, { headers, method })
  if (!response.ok) {
    throw new Error('Invalid token supplied!')
  }
  return await response.json()
}

export const createSession = async (username: string, password: string): Promise<UserIdentity> => {
  const response = await fetch('/api/sessions', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  })
  if (!response.ok) {
    const errorMessage = await response.text()
    throw new Error(errorMessage)
  }
  return await response.json()
}

export const destroySession = async (): Promise<boolean> => {
  const response = await fetch('/api/logout', {
    method: 'POST'
  })
  if (!response.ok) {
    throw new Error('Can not destroy session!')
  }
  return true
}

export const magicLoginAuthenticate = async (token: string): Promise<UserIdentity> => {
  const response = await fetch('/api/magic-login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token })
  })
  if (!response.ok) {
    const errorMessage = await response.text()
    throw new Error(errorMessage)
  }
  return await response.json()
}

export const getIronSessionOptions = (): SessionOptions => ({
  cookieName: 'AIMS_DEMO_APP_COOKIE',
  cookieOptions: {
    secure: process.env.APP_ENV === 'prod'
  },
  password: process.env.APPLICATION_SECRET ?? ''
})
