import React, { ComponentProps } from 'react'

import { Footer } from '../../footer'
import Head from 'next/head'
import { Header } from '../../header'
import { Player } from '@aims-player/component'
import { StyledLayout } from '../styled'

type LayoutProps = ComponentProps<any> & {
  disableMenu: boolean
}

const Layout = ({ disableMenu = false, children }: LayoutProps): JSX.Element =>
  <StyledLayout>
    <Head>
      <link rel="stylesheet" href="https://use.typekit.net/hol1nen.css" />
      <link rel="icon" href="/favicon.png" />
    </Head>

    <Header disableMenu={disableMenu} />
    {children}
    <Footer/>
    <Player/>
  </StyledLayout>

export default Layout
