import { Collection } from '@aims-collection/types'

const sortProjects = (c1: Collection, c2: Collection): number => {
  if (c1.updatedAt !== null && c2.updatedAt === null) {
    return -1
  } else if (c1.updatedAt === null && c2.updatedAt !== null) {
    return 1
  } else if (c1.updatedAt === null && c2.updatedAt === null) {
    return 0
  }

  const c1Time = new Date(c1.updatedAt ?? '')
  const c2Time = new Date(c2.updatedAt ?? '')

  if (c1Time < c2Time) {
    return 1
  }
  if (c1Time > c2Time) {
    return -1
  }
  return 1
}

export default sortProjects
