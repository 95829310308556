import { DetailedTrack } from '../types'

export const getCachedWaveform = async (track: DetailedTrack): Promise<ArrayBuffer|null> => {
  const identifier = track.idClient
  const response = await fetch(`/api/waveform/${encodeURIComponent(identifier)}`)
  if (!response.ok) {
    return null
  }
  const data = await response.blob()
  return await data.arrayBuffer()
}
