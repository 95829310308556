import { SearchResponse, SearchResults, SearchType, SimilaritySearchOptions, TrackSearchOptions } from '@aims-search/types'

import { UserIdentity } from '@aims-auth/types'
import { convertObjectToFormData } from '@aims-lib'
import { isTextSearch } from './isTextSearch'
import { reformatFilters } from './filtering'
import { reformatResults } from './results'

const shouldUseANN = (user: UserIdentity): null | boolean => {
  if (user.email === 'ann@aimsapi.com') {
    return true
  }
  if (user.email === 'not-ann@aimsapi.com') {
    return false
  }
  return null
}

const constructMultipartSearchBody = (options: SimilaritySearchOptions): FormData => {
  const { user, page = 1, pageSize = 10, offset, limit, file, filters, ignoreVocals, prioritiseBpm, segmentChanged, hash } = options
  const formData = convertObjectToFormData({ filter: typeof filters !== 'undefined' ? reformatFilters(filters) : undefined })

  formData.append('track', file as File)
  formData.append('page', page.toString())
  formData.append('hash', hash ?? '')
  formData.append('page_size', pageSize.toString())
  if (typeof offset !== 'undefined' && offset >= 0 && segmentChanged === true) {
    formData.append('time_offset', offset.toFixed(0))
  }
  if (typeof limit !== 'undefined' && limit > 0 && segmentChanged === true) {
    formData.append('time_limit', limit.toFixed(0))
  }
  if (user.highlights && !isTextSearch(options.type)) {
    formData.append('highlights', 'true')
  }
  if (prioritiseBpm) {
    formData.append('prioritise_bpm', 'true')
  }
  if (ignoreVocals) {
    formData.append('suppress_vocals', 'true')
  }
  const useANN = shouldUseANN(user)
  if (useANN !== null) {
    formData.append('use_approximate_nearest_neighbour_search', useANN ? 'true' : 'false')
  }
  return formData
}

const constructJsonSearchBody = (options: SimilaritySearchOptions): string => {
  const { user, type, page = 1, pageSize = 10, offset, limit, id, text, filters, ignoreVocals, prioritiseBpm, segmentChanged } = options
  const useHighlights = user.highlights && !isTextSearch(options.type)
  return JSON.stringify({
    track_id: type === SearchType.Internal ? id : undefined,
    input_id_type: type === SearchType.Internal ? 'system' : undefined,
    link: type === SearchType.Link ? text : undefined,
    track: type === SearchType.FileLink ? text : undefined,
    page,
    page_size: pageSize,
    time_offset: ((user.highlights || type !== SearchType.Internal) && typeof offset !== 'undefined' && offset >= 0 && segmentChanged === true)
      ? parseInt(offset.toFixed(0))
      : undefined,
    time_limit: ((user.highlights || type !== SearchType.Internal) && typeof limit !== 'undefined' && limit > 0 && segmentChanged === true)
      ? parseInt(limit.toFixed(0))
      : undefined,
    filter: typeof filters !== 'undefined' ? reformatFilters(filters) : undefined,
    highlights: useHighlights || undefined,
    prioritise_bpm: prioritiseBpm || undefined,
    suppress_vocals: ignoreVocals || undefined,
    use_approximate_nearest_neighbour_search: shouldUseANN(user) ?? undefined
  })
}

const constructSearchBody = (options: SimilaritySearchOptions): string|FormData => {
  if (options.type === SearchType.Upload) {
    return constructMultipartSearchBody(options)
  }
  return constructJsonSearchBody(options)
}

export const searchSimilar = async (options: SimilaritySearchOptions): Promise<SearchResults> => {
  const { user, type, hash } = options
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'X-User-Id': user.id
  }
  if (type !== SearchType.Upload) {
    headers['Content-Type'] = 'application/json'
  }
  const method = 'POST'
  const body = constructSearchBody(options)

  const rawResponse = await fetch(`${user.apiHost}/v1/query/by-${type}${hash !== null ? '-hash' : ''}?detailed=true`, { headers, method, body })

  if (!rawResponse.ok) {
    const error = await rawResponse.json()
    throw new Error(error.message)
  }

  let response: SearchResponse = {
    queryId: null,
    tracks: [],
    totalResults: 0,
    hash: null
  }

  if (rawResponse.status !== 204) {
    const responseHash = rawResponse.headers.get('X-Hash')

    response = {
      ...reformatResults<SearchResults>(await rawResponse.json()),
      hash: responseHash
    }
  }

  return response
}

export const searchTracks = async (options: TrackSearchOptions): Promise<SearchResponse> => {
  const { user, text, page = 1, pageSize = 10, filters, prompt, hash } = options
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'Content-Type': 'application/json',
    'X-User-Id': user.id
  }
  const method = 'POST'
  let body: unknown = { filter: typeof filters !== 'undefined' ? reformatFilters(filters) : undefined, hash }
  let uri = ''

  if (prompt === true) {
    body = {
      text: text,
      page,
      page_size: pageSize,
      use_approximate_nearest_neighbour_search: shouldUseANN(user) ?? undefined,
      ...body as Record<string, unknown>
    }
    uri = `${user.apiHost}/v1/query/by-text${hash !== null ? '-hash' : ''}?detailed=true`
  } else {
    uri = `${user.apiHost}/v1/tracks/search?name=${encodeURIComponent(text)}&page=${page}&page_size=${pageSize}&detailed=true`
  }

  body = JSON.stringify(body)

  const rawResponse = await fetch(uri, { headers, method, body: body as BodyInit })

  if (!rawResponse.ok) {
    const error = await rawResponse.json()
    if (error.code !== 6010) {
      throw new Error(error.message)
    }
    return {
      queryId: null,
      tracks: [],
      totalResults: 0,
      hash: null
    }
  }

  let response: SearchResponse = {
    queryId: null,
    tracks: [],
    totalResults: 0,
    hash: null
  }

  if (rawResponse.status !== 204) {
    const responseHash = rawResponse.headers.get('X-Hash')
    response = {
      ...reformatResults<SearchResults>(await rawResponse.json()),
      hash: responseHash
    }
  }

  return response
}

export const downloadAudioFromUrl = async (user: UserIdentity, link: string): Promise<HTMLAudioElement> => {
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'Content-Type': 'application/json'
  }
  const method = 'POST'
  const body = JSON.stringify({ link })
  const response = await fetch(`${user.apiHost}/v1/download/by-url`, { headers, method, body })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  const data = await response.blob()
  return new Audio(URL.createObjectURL(data))
}
