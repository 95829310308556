export const CLEAR_PRELOADED_RESULTS = 'DATA_SEARCH_CLEAR_PRELOADED_RESULTS'
export const CLEAR_SEGMENT = 'DATA_SEARCH_CLEAR_SEGMENT'
export const INCREMENT_EXTRA_FILTER_INDEX = 'DATA_SEARCH_INCREMENT_EXTRA_FILTER_INDEX'
export const LOAD_MORE_BEGIN = 'DATA_SEARCH_LOAD_MORE_BEGIN'
export const LOAD_MORE_ERROR = 'DATA_SEARCH_LOAD_MORE_ERROR'
export const LOAD_MORE_SUCCESS = 'DATA_SEARCH_LOAD_MORE_SUCCESS'
export const REFRESH_FILTERS_KEY = 'DATA_SEARCH_REFRESH_FILTERS_KEY'
export const RESET_FILTERS = 'DATA_SEARCH_RESET_FILTERS'
export const SEARCH_BEGIN = 'DATA_SEARCH_SEARCH_BEGIN'
export const SEARCH_ERROR = 'DATA_SEARCH_SEARCH_ERROR'
export const SEARCH_SUCCESS = 'DATA_SEARCH_SEARCH_SUCCESS'
export const SET_FIELDS_FILE = 'DATA_SEARCH_SET_FIELDS_FILE'
export const SET_FIELDS_ID = 'DATA_SEARCH_SET_FIELDS_ID'
export const SET_FIELDS_TEXT = 'DATA_SEARCH_SET_FIELDS_TEXT'
export const SET_FILTERS = 'DATA_SEARCH_SET_FILTERS'
export const SET_IS_LOADING = 'DATA_SEARCH_SET_IS_LOADING'
export const SET_LAST_SEARCH_STATE = 'DATA_SEARCH_SET_LAST_SEARCH_STATE'
export const SET_MODIFIERS_IGNORE_VOCALS = 'DATA_SEARCH_SET_MODIFIERS_IGNORE_VOCALS'
export const SET_MODIFIERS_PRIORITISE_BPM = 'DATA_SEARCH_SET_MODIFIERS_PRIORITISE_BPM'
export const SET_PAGINATION = 'DATA_SEARCH_SET_PAGINATION'
export const SET_PRELOADED_PROMPT_RESULTS = 'DATA_SEARCH_SET_PRELOADED_PROMPT_RESULTS'
export const SET_PRELOADED_RESULTS = 'DATA_SEARCH_SET_PRELOADED_RESULTS'
export const SET_PROMPT_RESULTS = 'DATA_SEARCH_SET_PROMPT_RESULTS'
export const SET_RESULTS = 'DATA_SEARCH_SET_RESULTS'
export const SET_SEARCH_HASH = 'DATA_SEARCH_SET_SEARCH_HASH'
export const SET_SEGMENT_OFFSET_AND_LIMIT = 'DATA_SEARCH_SET_SEGMENT_OFFSET_AND_LIMIT'
export const SET_SEGMENT_TRACK = 'DATA_SEARCH_SET_SEGMENT_TRACK'
