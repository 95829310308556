import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core'

import React from 'react'

type TextFieldProps = MuiTextFieldProps

const TextField = (props: TextFieldProps): JSX.Element => {
  return (
    <MuiTextField
      {...props}
    />
  )
}

export type { TextFieldProps }
export default TextField
