import styled from 'styled-components'

const StyledSearchFilters = styled.div`
  & .filtering-label {
    color: ${props => props.theme.colors.grey.medium};
    font-size: 0.8rem;
    font-weight: ${props => props.theme.font.medium};
    padding: 0.5rem;
    text-transform: uppercase;
  }

  & .dual-filter {
    & .filter-from {
      padding-right: 0.25rem;
    }
    & .filter-to {
      padding-left: 0.25rem;
    }
  }

  & .filtering-switch {
    height: 3rem;
    margin-left: 0.5rem;

    & div {
      align-items: center;
      display: flex;
      height: 100%;

      & label {
        color: ${props => props.theme.colors.grey.medium};
        margin-left: 0.5rem;
      }
    }
  }

  & .MuiChip-label {
    font-size: 1rem;
  }

  & .filtering-actions {
    justify-content: flex-end;
  }
`

export default StyledSearchFilters
