import React, { MouseEvent as ReactMouseEvent } from 'react'
import { getIsLoading, getProjectTracksPagination, loadMoreSuggestions } from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { LoaderButtonTextual } from '@aims-controls'

const LoadMoreButton = (): JSX.Element => {
  const loading = useSelector(getIsLoading)
  const pagination = useSelector(getProjectTracksPagination)
  const dispatch = useDispatch()

  const handleLoadMore = (event: ReactMouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault()

    dispatch(loadMoreSuggestions())
  }

  return (
    <LoaderButtonTextual
      loading={loading}
      success={false}
      disabled={loading}
      variant={'outlined'}
      color={'secondary'}
      type={'button'}
      size={'medium'}
      text={{ default: `Show ${pagination.pageSize} more suggestions`, loading: 'Loading...', success: 'Success' }}
      onClick={handleLoadMore}
    />
  )
}

export default LoadMoreButton
