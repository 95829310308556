import React, { Dispatch, MouseEvent as ReactMouseEvent, SetStateAction, useEffect, useState } from 'react'
import {
  getPreloadedPromptResults,
  getPreloadedResults,
  getResultsPromptsTracks,
  getResultsTracks,
  getSearchPaginationPage,
  getSearchPaginationSize,
  isLoading,
  loadMore,
  resolveCurrentSearchType
} from '@aims-store/search'
import { useDispatch, useSelector } from 'react-redux'

import { LoaderButtonTextual } from '@aims-controls'
import { isTextSearch } from '@aims-search/lib'

const LoadMoreButton = (): JSX.Element => {
  const dispatch = useDispatch()
  const loading = useSelector(isLoading)
  const page = useSelector(getSearchPaginationPage)
  const pageSize = useSelector(getSearchPaginationSize)
  const results = useSelector(getResultsTracks)
  const promptResults = useSelector(getResultsPromptsTracks)
  const preloadedResults = useSelector(getPreloadedResults)
  const preloadedPromptResults = useSelector(getPreloadedPromptResults)
  const currentSearchType = useSelector(resolveCurrentSearchType)
  const [success, setSuccess]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean(false))

  const handleLoadMore = (event: ReactMouseEvent<HTMLElement, MouseEvent>): void => {
    event.preventDefault()
    dispatch(loadMore())
  }

  useEffect(() => {
    if (!loading && results.length > (page - 1) * pageSize) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 2000)
      return
    }
    setSuccess(false)
  }, [loading])

  const isLoadingWithContext = (): boolean => {
    if (loading) {
      return true
    }
    if (isTextSearch(currentSearchType)) {
      return (preloadedResults.tracks.length === 0 && results.length !== 0) || (preloadedPromptResults.tracks.length === 0 && promptResults.length !== 0)
    }
    return preloadedResults.tracks.length === 0 && results.length !== 0
  }

  const loadingWithContext = isLoadingWithContext()

  return (
    <LoaderButtonTextual
      loading={loadingWithContext}
      success={success}
      disabled={loadingWithContext}
      variant={'outlined'}
      color={'secondary'}
      type={'button'}
      size={'medium'}
      text={{ default: 'Show 10 more tracks', loading: 'Loading...', success: 'Success' }}
      onClick={handleLoadMore}
    />
  )
}

export default LoadMoreButton
