import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledPlayer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-top: 0.1rem solid ${props => props.theme.colors.primary};
  bottom: 0;
  left: 0;
  padding: 0.75rem;
  position: fixed;
  width: 100%;
  ${media(ScreenSize.Small)} {
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }
  p {
    font-weight: ${props => props.theme.font.medium};
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.title {
      font-size: 0.875rem;
      ${media(ScreenSize.Small)} {
        font-size: 1.2rem;
      }
    }
    &.metadata {
      color: ${props => props.theme.colors.grey.medium};
      font-size: 0.8125rem;
      margin-bottom: 0.5rem;
      ${media(ScreenSize.Small)} {
        font-size: 1rem;
      }
    }
  }
  .duration {
    color: ${props => props.theme.colors.primary};
    flex-basis: 5rem;
    flex-grow: 1;
    font-size: 0.8125rem;
    text-align: right;
    width: 5rem;
    ${media(ScreenSize.Small)} {
      flex-grow: 0;
    }
  }
  .volume-control {
    bottom: 4rem;
    position: absolute;
    right: 1rem;
    width: 10rem;
    ${media(ScreenSize.Small)} {
      bottom: 5rem;
      right: 1.5rem;
    }
  }
`

export default StyledPlayer
