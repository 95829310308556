import { Grid } from '@material-ui/core'
import React from 'react'
import { SearchFilterWidgetProps } from '@aims-search/types'
import { TextField } from '@aims-controls'
import { trackFieldKeyMap } from '@aims-search/hooks'

const IntegerWidget = ({ index, field }: SearchFilterWidgetProps): JSX.Element => {
  return (
    <Grid container className={'dual-filter'}>
      <Grid item xs={12} className={'filtering-label'}>
        {trackFieldKeyMap.get(field)}
      </Grid>
      <Grid item xs={6} className={'filter-from'}>
        <TextField
          type={'number'}
          variant={'outlined'}
          color={'secondary'}
          name={`conditions[${index}][value]`}
          id={`conditions-${index}-value`}
          label={'From:'}
          fullWidth
          size={'small'}
        />
        <input type="hidden" name={`conditions[${index}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index}][operator]`} value="gte" />
      </Grid>
      <Grid item xs={6} className={'filter-to'}>
        <TextField
          type={'number'}
          variant={'outlined'}
          color={'secondary'}
          name={`conditions[${index + 1}][value]`}
          id={`conditions-${index + 1}-value`}
          label={'To:'}
          fullWidth
          size={'small'}
        />
        <input type="hidden" name={`conditions[${index + 1}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index + 1}][operator]`} value="lte" />
      </Grid>
    </Grid>
  )
}

export default IntegerWidget
