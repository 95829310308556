import React, { ChangeEvent, useState } from 'react'
import { numericRange, thisYear } from '@aims-lib'

import { Grid } from '@material-ui/core'
import { SearchFilterWidgetProps } from '@aims-search/types'
import { StyledSelect } from '@aims-controls'
import { trackFieldKeyMap } from '@aims-search/hooks'

const YearWidget = ({ index, field }: SearchFilterWidgetProps): JSX.Element => {
  const [from, setFrom] = useState(undefined as number | undefined)
  const [to, setTo] = useState(undefined as number | undefined)
  const handleChangeFrom = (event: ChangeEvent<{ value: unknown }>): void => {
    const value = parseInt(event.target.value as string)
    setFrom(isNaN(value) ? undefined : value)
  }
  const handleChangeTo = (event: ChangeEvent<{ value: unknown }>): void => {
    const value = parseInt(event.target.value as string)
    setTo(isNaN(value) ? undefined : value)
  }
  return (
    <Grid container className={'dual-filter'}>
      <Grid item xs={12} className={'filtering-label'}>
        {trackFieldKeyMap.get(field)}
      </Grid>
      <Grid item xs={6} className={'filter-from'}>
        <StyledSelect
          variant={'outlined'}
          name={`conditions[${index}][value]`}
          id={`conditions-${index}-value`}
          fullWidth
          label={'From:'}
          value={from}
          size={'small'}
          onChange={handleChangeFrom}
        >
          <option key={'-'} value={''} />
          {numericRange(to ?? thisYear(), 1900).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </StyledSelect>
        <input type="hidden" name={`conditions[${index}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index}][operator]`} value="gte" />
      </Grid>
      <Grid item xs={6} className={'filter-to'}>
        <StyledSelect
          variant={'outlined'}
          name={`conditions[${index + 1}][value]`}
          id={`conditions-${index + 1}-value`}
          fullWidth
          label={'To:'}
          value={to}
          size={'small'}
          onChange={handleChangeTo}
        >
          <option key={'-'} value={''} />
          {numericRange(thisYear(), from ?? 1900).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </StyledSelect>
        <input type="hidden" name={`conditions[${index + 1}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index + 1}][operator]`} value="lte" />
      </Grid>
    </Grid>
  )
}

export default YearWidget
