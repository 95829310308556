import { ScreenSize, media } from '@aims-theme'

import { Grid } from '@material-ui/core'
import styled from 'styled-components'

const StyledFindSimilarButton = styled(Grid)`
  flex-basis: 100%;
  flex-grow: 1;
  padding-left: 1rem;
  position: relative;
  text-align: right;
  ${media(ScreenSize.Small)} {
    flex-basis: auto;
    flex-grow: 0;
  }
  button {
    min-width: 7rem;
    ${media(ScreenSize.Small)} {
      min-width: 9rem;
    }
  }
  .hide-artist {
    color: ${props => props.theme.colors.secondary};
    cursor: pointer;
    position: absolute;
    right: 0.5rem;
    top: -1.75rem;

    &:hover {
      color: ${props => props.theme.colors.secondaryHover};
    }
  }
`

export default StyledFindSimilarButton
