export enum LinkType {
  AppleMusic = 'apple',
  SoundCloud = 'soundcloud',
  Spotify = 'spotify',
  TikTok = 'tiktok',
  Vimeo = 'vimeo',
  YouTube = 'youtube'
}

export interface LinkInfo {
  audioUrl: string | null
  description?: string
  duration: number
  embed?: string
  id: string
  thumbnail?: string
  title?: string
  type: LinkType
}
