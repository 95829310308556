import * as Sentry from '@sentry/react'

import { Action, PreloadedState, Store, applyMiddleware, createStore } from 'redux'
import { EnhancerOptions, composeWithDevTools } from 'redux-devtools-extension'

import { LOAD } from '@aims-store/player'
import State from './State'
import reducers from './reducers'
import thunkMiddleware from 'redux-thunk'
import { useMemo } from 'react'

let store: Store|undefined

function initStore (initialState: State): Store {
  const actionSanitizer = <A extends Action>(action: A, id: number): A => (
    action.type === LOAD
      ? { ...action, audio: (('<<AUDIO_DATA>>' as unknown) as HTMLAudioElement) } : action
  )
  const options: EnhancerOptions = {
    actionSanitizer
  }
  const sentryReduxEnhancer = Sentry.createReduxEnhancer()
  const composeEnhancers = composeWithDevTools(options)
  const enhancers = composeEnhancers(applyMiddleware(thunkMiddleware), sentryReduxEnhancer)
  return createStore(
    reducers,
    initialState as PreloadedState<State>,
    enhancers
  )
}

export const initializeStore = (preloadedState: State): Store => {
  let _store = store ?? initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (typeof preloadedState !== 'undefined' && typeof store !== 'undefined') {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (typeof store === 'undefined') store = _store

  return _store
}

export function useStore (initialState: State): Store {
  return useMemo(() => initializeStore(initialState), [initialState])
}
