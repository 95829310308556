import { Button, Dialog, LoaderButtonTextual, StyledSelect } from '@aims-controls'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import React, { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ExportCSVModalHook } from '@aims-project/types'
import { downloadTracksCSV } from '@aims-store/project/actions'
import { getIsLoading } from '@aims-store/project'

const exportCountMap = [
  10, 50, 100, 200, 300
]
const defaultExportCount = exportCountMap[2]

const useExportCSVModal = (): ExportCSVModalHook => {
  const [exportCount, setExportCount] = useState(defaultExportCount)
  const [open, setOpen] = useState(false)
  const loading = useSelector(getIsLoading)
  const dispatch = useDispatch()

  const handleOpenModal = (): void => {
    setOpen(true)
  }

  const handleNumOfTracksChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setExportCount(event.target.value as number)
  }

  const handleCloseDialog = (): void => {
    setOpen(false)
  }

  const render = (): JSX.Element => {
    return (
      <>
        <Button
          color={'secondary'}
          variant={'text'}
          size={'small'}
          onClick={handleOpenModal}
        >
          Export to CSV
        </Button>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
        >
          <DialogTitle>
            <Typography variant={'h4'} component={'p'}>
              Export suggested tracks
            </Typography>
          </DialogTitle>
          <DialogContent>

            <Typography variant={'overline'}>
              Number of suggested tracks
            </Typography>
            <StyledSelect
              variant={'outlined'}
              fullWidth
              size={'small'}
              name={'number-of-track-exports'}
              value={exportCount}
              onChange={handleNumOfTracksChange}
            >
              {exportCountMap.map((exportCountItem: number): JSX.Element => {
                return (
                  <option key={`number-of-track-exports-${exportCountItem}`} value={exportCountItem}>
                    {exportCountItem}
                  </option>
                )
              })}
            </StyledSelect>

          </DialogContent>
          <DialogActions>
            <LoaderButtonTextual
              text={{
                default: 'Download CSV',
                loading: 'Downloading',
                success: ''
              }}
              disabled={loading}
              success={false}
              loading={loading}
              size={'medium'}
              color={'secondary'}
              variant={'contained'}
              onClick={(): void => { dispatch(downloadTracksCSV(exportCount)) } } />
            <Button size={'medium'} variant={'text'} onClick={handleCloseDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return {
    render
  }
}

export default useExportCSVModal
