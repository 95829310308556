import { DetailedTrack } from '@aims-track/types'

const filterTracksForCSVDownload = (tracks: DetailedTrack[], exportCount = 100): DetailedTrack[] => {
  const truncatedTracks = tracks.slice(0, exportCount)

  return truncatedTracks.filter((track: DetailedTrack): boolean => {
    const externalTrack = track.idClient.startsWith('file:') || track.idClient.startsWith('url:')
    return !externalTrack
  })
}

export default filterTracksForCSVDownload
