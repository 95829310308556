import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomYoutubeIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 22 22'}>
    <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M20.5340182,5.34558243 C20.2893803,4.42100049 19.5836942,3.6969965 18.6790719,3.44970861 C17.4639476,2.97024487 5.69444759,2.73532137 2.28027102,3.46344683 C1.37564865,3.71348236 0.667274221,4.43473871 0.4253247,5.35932065 C-0.123094213,7.81846133 -0.164763297,13.135151 0.43876634,15.6492445 C0.683404189,16.5738264 1.38909029,17.2978304 2.29371266,17.5451183 C4.69976623,18.1111328 16.1386019,18.1908145 18.6925135,17.5451183 C19.5971359,17.2950828 20.3055103,16.5738264 20.5474598,15.6492445 C21.1321712,12.9702924 21.1738402,7.98331992 20.5340182,5.34558243 Z" id="Path" fill="#FF0000" fillRule="nonzero"></path>
      <polygon id="Path" fill="#FFFFFF" fillRule="nonzero" points="13.9476146 10.4974135 8.46342543 7.2826709 8.46342543 13.712156"></polygon>
    </g>
  </SvgIcon>

export default CustomYoutubeIcon
