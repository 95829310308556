import { Collection, CollectionTracks } from '@aims-collection/types'
import { DropZone, DropZoneChildrenProps, LoaderButtonTextual, TrackSearchBar, linkIsFileOrUrl } from '@aims-controls'
import React, { useEffect, useState } from 'react'
import { addRemoteTrack, loadProject, loadTracks } from '@aims-project/lib'
import { getIsLoading, getProject, refreshProject, setLoading, setTrack } from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { DetailedTrack } from '@aims-track/types'
import StyledAddRemoteTracksBar from './AddRemoteTracksBar.styled'
import { convertKeysToCamelCase } from '@aims-search/lib'
import { enqueue } from '@aims-layout'
import { getUser } from '@aims-store/auth'

/* eslint-disable no-void */
const AddRemoteTracksBar = (): JSX.Element => {
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined)
  const loading = useSelector(getIsLoading)
  const [searchFieldText, setSearchFieldText] = useState('')
  const user = useSelector(getUser)
  const project = useSelector(getProject)

  useEffect((): void => {
    if (searchFieldText.length === 0 || linkIsFileOrUrl(searchFieldText)) {
      setErrorMessage(undefined)
    } else {
      setErrorMessage('Only supported streaming service links or audio files are supported')
    }
  }, [searchFieldText])

  const handleAddRemoteTrack = async (file?: File): Promise<void> => {
    if (user !== null) {
      dispatch(setLoading(true))
      await addRemoteTrack(user, project.key, file ?? searchFieldText).then(async () => {
        dispatch(enqueue({ message: 'Track has been added successfully', options: { variant: 'success' } }))

        const name = file?.name ?? searchFieldText
        const response = await loadTracks(project.key, user)
        const tracksCollection = convertKeysToCamelCase<CollectionTracks>(response)
        const track = tracksCollection.tracks.find(
          (t: DetailedTrack) => t.trackName === name
        )
        const newRawProject = await loadProject(project.key, user)
        const newProject = convertKeysToCamelCase<Collection>(newRawProject)
        if (track !== undefined) {
          dispatch(setTrack(track, true))
          dispatch(refreshProject(newProject))
        }
      }).catch(() => {
        dispatch(enqueue({ message: 'Adding track has failed - Please provide a valid url', options: { variant: 'error' } }))
      })
      setSearchFieldText('')
      dispatch(setLoading(false))
    }
  }

  const handleOnKeyDown = (): void => {
    if (errorMessage === undefined) {
      void handleAddRemoteTrack()
    }
  }

  return (
    <StyledAddRemoteTracksBar>
      <DropZone className={'track-search-bar'} acceptedCallback={(file: File) => void handleAddRemoteTrack(file)}>
        {({ open, isDragActive }: DropZoneChildrenProps) => (
          <TrackSearchBar
            textFieldValue={searchFieldText}
            disabled={loading}
            placeholder={'Add YouTube, Spotify, TikTok, or SoundCloud track via url.'}
            onChange={(newValue: string) => setSearchFieldText(newValue)}
            isDragActive={isDragActive}
            open={open}
            errorMessage={errorMessage}
            onKeyDown={handleOnKeyDown}
          />
        )}
      </DropZone>
      <LoaderButtonTextual
        className={'add-button'}
        color={'secondary'}
        loading={loading}
        success={false}
        type={'submit'}
        text={{ default: 'Add', loading: 'Adding...', success: 'Added' }}
        fullWidth
        disabled={loading || searchFieldText.length === 0 || errorMessage !== undefined}
        onClick={() => void handleAddRemoteTrack()}
      />
    </StyledAddRemoteTracksBar>
  )
}
/* eslint-enable */

export default AddRemoteTracksBar
