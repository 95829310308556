import { Button } from '@aims-controls'
import { CircularProgress } from '@material-ui/core'
import { LoaderButtonTextualProps } from '../../types'
import React from 'react'
import { StyledLoaderButtonTextual } from '../styled'

const LoaderButtonTextual = ({
  onClick,
  disabled,
  success,
  loading,
  text,
  type = 'button',
  fullWidth = false,
  variant = 'contained',
  color = 'primary',
  size = 'large',
  className = '',
  id
}: LoaderButtonTextualProps): JSX.Element => {
  return (
    <StyledLoaderButtonTextual className={fullWidth ? 'full-width' : ''}>
      <Button
        color={color}
        aria-label={'Save'}
        size={size}
        onClick={onClick}
        disabled={disabled}
        className={`${className} ${success ? 'success' : loading ? 'loading' : ''}`}
        type={type}
        fullWidth={fullWidth}
        variant={variant}
        id={id}
      >
        {success ? text.success : loading ? text.loading : text.default}
      </Button>
      {loading && <CircularProgress size={'2rem'} className={'load-progress'} />}
    </StyledLoaderButtonTextual>
  )
}

export default LoaderButtonTextual
