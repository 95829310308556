import DetailedTrack from '../types/DetailedTrack'
import { getStreamingSecret } from '@aims-store/auth'
import { useSelector } from 'react-redux'

const useAudioApiSecret = (track: DetailedTrack, secret?: string): string => {
  if (secret !== undefined) {
    return secret
  }

  const userSecret = useSelector(getStreamingSecret)
  if (track.idClient.startsWith('file:') || track.idClient.startsWith('url:')) {
    return process.env.NEXT_PUBLIC_REMOTE_TRACK_API_SECRET ?? ''
  }
  return userSecret
}

export default useAudioApiSecret
