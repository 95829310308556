import { ScreenSize, media } from '../../../../styled/mixins'
import styled, { keyframes } from 'styled-components'

import { Grid } from '@material-ui/core'

const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`

const StyledTrackWaveform = styled(Grid)`
  flex-basis: calc(100% - 4.5rem);
  user-select: none;
  ${media(ScreenSize.Small)} {
    flex-basis: auto;
    flex-grow: 1;
  }
  .waveform-container {
    height: 3rem;
    position: relative;
    & > div {
      height: 100%;
    }
  }
  .waveform-status {
    color: ${props => props.theme.colors.secondary};
    height: 100%;
    left: 0;
    line-height: 3rem;
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
    width: 100%;
    
    &.loading {
      animation: ${pulse} ${props => props.theme.transitions.easing.regular} ${props => props.theme.transitions.duration.verySlow} infinite;
    }
    &.failed {
      background-color: ${props => props.theme.colors.grey.lightest};
      border-radius: 0.3rem;
      color: ${props => props.theme.colors.crimson};
    }
  }
`

export default StyledTrackWaveform
