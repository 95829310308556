import { Pause, PlayArrow, Timer } from '@material-ui/icons'
import { getPlayerTrack, isPlaying } from '@aims-store/player'

import { LoaderButtonTextual } from '@aims-controls'
import { PlayButtonProps } from '@aims-track/types'
import React from 'react'
import { StyledPlayButton } from '@aims-track/styled'
import { trackIsFromRemoteSource } from '@aims-track/lib'
import { useSelector } from 'react-redux'

const PlayButton = ({ track, loaded, playable, onClick }: PlayButtonProps): JSX.Element => {
  const playing = useSelector(isPlaying)
  const playedTrack = useSelector(getPlayerTrack)

  const trackIsFromURL = trackIsFromRemoteSource(track)
  const idle = !playing || playedTrack === null || playedTrack.id !== track?.id
  const loading = !loaded && !playable && !idle
  const wavefromMissing = track === null || track.processedAt === null

  return (
    <StyledPlayButton>
      <LoaderButtonTextual
        text={{
          loading: <Timer fontSize={'large'} />,
          success: <Pause fontSize={'large'} />,
          default: <PlayArrow fontSize={'large'} />
        }}
        disabled={trackIsFromURL || (wavefromMissing || loading)}
        success={playing && playedTrack?.id === track?.id}
        loading={loading}
        variant={'outlined'}
        size={'medium'}
        color={playing && playedTrack?.id === track?.id ? 'primary' : 'secondary'}
        onClick={onClick}
      />
    </StyledPlayButton>
  )
}

export default PlayButton
