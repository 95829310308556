import { Checkbox } from '@material-ui/core'
import styled from 'styled-components'

const StyledTrackCheckbox = styled(Checkbox)`
  .MuiCheckbox-root {
    margin-left: auto;
  }
`

export default StyledTrackCheckbox
