import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../../colors'

const paperOverride: Overrides['MuiPaper'] = {
  root: {
    position: 'relative',
    border: `0.1rem solid ${colors.grey.light}`,
    padding: '1rem'
  },
  rounded: {
    borderRadius: '0.5rem'
  }
}

export default paperOverride
