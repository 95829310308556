import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomInfoIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 23.4 23.4'}>
    <g id="Icons_ic_info" data-name="Icons / ic_info" transform="translate(0.7 0.7)">
      <path className="stroke" id="Stroke_1" data-name="Stroke 1" d="M22,11A11,11,0,1,0,11,22,11,11,0,0,0,22,11Z" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path className="fill" id="Combined_Shape" data-name="Combined Shape" d="M.7,11.5a.7.7,0,0,1-.1-1.394L.7,10.1H2.5V5L1.2,5a.7.7,0,0,1-.693-.606L.5,4.3a.7.7,0,0,1,.6-.694L1.2,3.6h2a.7.7,0,0,1,.693.6l.006.1v5.8H5.7a.7.7,0,0,1,.1,1.394l-.1.006ZM2.507,1.595,2.5,1.5V.7A.7.7,0,0,1,3.893.6L3.9.7v.8a.7.7,0,0,1-1.393.094Z" transform="translate(7.8 4.7)" fill="silver"/>
    </g>
  </SvgIcon>

export default CustomInfoIcon
