import React, { useEffect, useRef, useState } from 'react'

import { UseCirculatingPromptsHook } from '../types'

const usePrompts = (promptSwapInterval: number, promptsVisible: boolean, placeholderPrompts?: string[]): UseCirculatingPromptsHook => {
  const getRandomPrompt = (): string => {
    let randomPrompt = ''

    if (promptsVisible && placeholderPrompts !== undefined) {
      const index = Math.round((placeholderPrompts.length - 1) * Math.random())
      randomPrompt = placeholderPrompts[index | 0]
    }

    return randomPrompt
  }

  const [placeholderPrompt, setPlaceholderPrompt] = useState(getRandomPrompt())
  const promptRef = useRef<HTMLSpanElement|null>(null)
  const [lastTimeout, setLastTimeout] = useState(-1)

  useEffect(() => {
    if (!promptsVisible) {
      clearTimeout(lastTimeout)

      return
    }

    const startFading = (): void => {
      if (promptRef.current === null) {
        return
      }

      promptRef.current.classList.add('transparent')

      const newTimeoutId = setTimeout(switchPrompt, 500) as unknown as number

      setLastTimeout(newTimeoutId)
    }

    const switchPrompt = (): void => {
      if (promptRef.current === null) {
        return
      }

      promptRef.current.classList.remove('transparent')

      const randomPrompt = getRandomPrompt()

      setPlaceholderPrompt(randomPrompt)

      const newTimeoutId = setTimeout(startFading, promptSwapInterval - 500) as unknown as number
      setLastTimeout(newTimeoutId)
    }

    const newTimeoutId = setTimeout(startFading, promptSwapInterval - 500) as unknown as number

    setLastTimeout(newTimeoutId)

    return () => {
      clearTimeout(lastTimeout)
    }
  }, [promptsVisible])

  const render = (): JSX.Element => {
    return (
      <span ref={promptRef}>
        {placeholderPrompt?.length !== 0 && <>&nbsp;&quot;{placeholderPrompt}&quot;</>}
      </span>
    )
  }

  return {
    currentPrompt: placeholderPrompt,
    renderPrompts: render
  }
}

export default usePrompts
