import { Grid, IconButton } from '@material-ui/core'
import { getShowThumbButtons, getUser, isDownloadEnabled, isProjectsEnabled } from '@aims-store/auth'
import { refreshProject, setTrack } from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import CustomAddToProjectIcon from '../../../../../icons/component/CustomAddToProjectIcon'
import CustomTrashIcon from '../../../../../icons/component/CustomTrashIcon'
import React from 'react'
import { Tooltip } from '@aims-controls'
import TrackCueSheetInfo from '../../TrackCueSheetInfo'
import TrackDownloadButton from '../../TrackDownloadButton'
import TrackLyrics from '../../TrackLyrics'
import { TrackProps } from '@aims-track/types'
import TrackThumbButtons from '../../TrackThumbButtons'
import { enqueue } from '@aims-layout'
import { toggleTrackInProject } from '@aims-project/lib'
import { useProjectsList } from '@aims-track/hooks'

const TrackActions = ({ track, project }: TrackProps): JSX.Element => {
  const dispatch = useDispatch()
  const downloadEnabled = useSelector(isDownloadEnabled)
  const projectsExist = useSelector(isProjectsEnabled)
  const user = useSelector(getUser)
  const { renderProjectsListDialogue, handleOpenDialogue } = useProjectsList(track)
  const showThumbButtons = useSelector(getShowThumbButtons)
  const showThumbButtonsComponent = project === undefined && showThumbButtons
  const isExternal = track.idClient.startsWith('url:') || track.idClient.startsWith('file:')

  const handleRemoveTrackFromProject = (): void => {
    if (project === undefined) {
      return
    }

    void toggleTrackInProject(user, track.idClient, project.key, false) // eslint-disable-line no-void
      .then((): void => {
        dispatch(setTrack(track, false))
        dispatch(refreshProject({ ...project, updatedAt: new Date().toISOString() }))
        dispatch(enqueue({ message: 'Track removed.', options: { variant: 'success' } }))
      })
  }

  return (
    <Grid container justifyContent={'flex-end'} alignContent={'center'}>
      {!isExternal && showThumbButtonsComponent && <TrackThumbButtons track={track} />}
      {!isExternal && downloadEnabled && <TrackDownloadButton track={track} />}
      {!isExternal && <TrackCueSheetInfo track={track} />}
      {track.lyrics !== null && track.lyrics.length > 0 && <TrackLyrics track={track} />}
      {projectsExist && project === undefined && (
        <Tooltip title={'Add to project'}>
          <IconButton onClick={handleOpenDialogue} className={'action-add-to-project'}>
            <CustomAddToProjectIcon />
          </IconButton>
        </Tooltip>
      )}
      {project !== undefined && (
        <Tooltip title={'Remove from project'}>
          <IconButton
            disabled={user === null}
            onClick={async () => handleRemoveTrackFromProject()}
            className={'action-remove-from-project'}
          >
            <CustomTrashIcon />
          </IconButton>
        </Tooltip>
      )}
      {renderProjectsListDialogue()}
    </Grid>
  )
}

export default TrackActions
