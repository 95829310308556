import styled from 'styled-components'

const StyledHiddenEmbeddedPlayer = styled.div`
  height: 1px;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  pointer-events: none;
  width: 1px;
`

export default StyledHiddenEmbeddedPlayer
