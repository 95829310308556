import { Grid, Typography } from '@material-ui/core'
import { LoaderButtonTextual, Tooltip } from '@aims-controls'
import { PlayButton, TrackWaveform } from '@aims-track/components'
import React, { useEffect, useState } from 'react'
import {
  SegmentSelectionToolProps,
  StyledHiddenEmbeddedPlayer,
  StyledSegmentSelectionTool
} from '@aims-search/styled'
import { getPlayerTrack, isPlaying, load, pause, play } from '@aims-store/player'
import { useDispatch, useSelector } from 'react-redux'

import { Alert } from '@material-ui/lab'
import CustomHelpIcon from '../../../../icons/component/CustomHelpIcon'
import { SearchType } from '@aims-search/types'
import { clearSearchSegment } from '@aims-store/search'
import { isHighlightsEnabled } from '@aims-store/auth'
import md5 from 'md5'
import { setAudioPlayHeadPosition } from '@aims-track/lib'

const defaultAlert = 'The requested media is too long. Only media shorter than 15 minutes can be displayed using the segment selection tool.'

const SegmentSelectionTool = ({
  loading,
  offset,
  limit,
  audio,
  track,
  onSegmentSelect,
  className,
  failed = false,
  failedMessage,
  showApplyButton = false,
  seedType,
  seedText = '',
  renderImmediately = false
}: SegmentSelectionToolProps): JSX.Element => {
  const dispatch = useDispatch()
  const highlights = useSelector(isHighlightsEnabled)
  const playedTrack = useSelector(getPlayerTrack)
  const playing = useSelector(isPlaying)
  const [initial, setInitial] = useState(true)
  const [success, setSuccess] = useState(false)
  const [clicked, setClicked] = useState(false)

  const alertMessage = failedMessage.length > 0 ? failedMessage : defaultAlert
  const tmpAudio = new Audio()

  tmpAudio.preload = 'none'
  setAudioPlayHeadPosition(tmpAudio, highlights, track)

  useEffect(() => {
    return () => {
      dispatch(clearSearchSegment())
      dispatch(pause())
    }
  }, [dispatch])

  useEffect(() => {
    if (!loading && !initial && clicked) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
        setClicked(false)
      }, 2000)
      return
    }

    if (loading) {
      setInitial(false)
    }
  }, [loading])

  const handleButtonClick = (): void => {
    setClicked(true)
  }

  const toggleTrack = (): void => {
    if (audio?.readyState !== 4 || audio === null || track === null) {
      return
    }
    if (playedTrack === null || playedTrack.id !== track.id) {
      // the playback needs to be started synchronously (even though it's done "again" in the store later),
      // since browsers can block playback if done asynchronously
      void audio.play() // eslint-disable-line no-void
      dispatch(load(audio, track, seedType))
      dispatch(play())
      return
    }

    dispatch(playing ? pause() : play())
  }

  return (
    <StyledSegmentSelectionTool container className={className}>
      <Grid item xs={12}>
        <Typography component={'p'} variant={'overline'}>
          Segment recommendation
          <Tooltip title={'Choose segment you want to get similar results to'}>
            <span className={'tooltip-fixer'}>
              <CustomHelpIcon />
            </span>
          </Tooltip>
        </Typography>
      </Grid>
      {failed ? (
        <Grid item container xs={12} alignItems={'center'} wrap={'nowrap'}>
          <Alert severity="error">
            {alertMessage}
          </Alert>
        </Grid>
      ) : (
        <Grid item container xs={12} alignItems={'center'} className={'segment-row'}>
          <PlayButton track={track} loaded={audio !== null} playable item xs onClick={toggleTrack} />
          {seedType === SearchType.Link && <StyledHiddenEmbeddedPlayer><div id={`hidden-embedded-player-${md5(seedText)}`} /></StyledHiddenEmbeddedPlayer> }
          {(!initial || renderImmediately) && track !== null ? (
            <TrackWaveform
              track={Object.assign({}, track, {
                highlights: [
                  {
                    offset,
                    duration: limit,
                    editable: true
                  }
                ]
              })}
              trackType={seedType}
              loaded={audio !== null}
              audio={audio !== null ? audio : tmpAudio}
              onSegmentChange={onSegmentSelect}
              editable
            />
          ) : (
            <div className={'waveform-container placeholder'}>{ initial ? 'Press the search button to use this seed track' : 'Loading...'}</div>
          )}
          {showApplyButton && (
            <LoaderButtonTextual
              loading={clicked && loading}
              success={success}
              disabled={loading}
              type={'submit'}
              text={{ default: 'Apply', loading: 'Searching...', success: 'See results' }}
              variant={'outlined'}
              size={'medium'}
              color={'secondary'}
              className={'apply-button'}
              onClick={handleButtonClick}
            />
          )}
        </Grid>
      )}
    </StyledSegmentSelectionTool>
  )
}

export default SegmentSelectionTool
