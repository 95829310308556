import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../../colors'

const checkboxOverride: Overrides['MuiCheckbox'] = {
  root: {
    '& .MuiIconButton-label': {
      border: '0.1rem solid',
      borderColor: 'inherit',
      backgroundColor: 'inherit',
      borderRadius: '0.3rem'
    }
  },
  colorSecondary: {
    color: 'transparent',
    borderColor: `${colors.grey.light}`,
    '& .MuiIconButton-label': {
      backgroundColor: colors.white
    },
    '&$checked': {
      borderColor: `${colors.secondaryLightest}`,
      '& .MuiIconButton-label': {
        backgroundColor: colors.secondary,
        color: colors.white
      }
    },
    '&:hover': {
      borderColor: `${colors.secondaryLight}`
    }
  }
}

export default checkboxOverride
