import React from 'react'
import StyledSnackbarCloseButton from './SnackbarCloseButton.styled'
import { useSnackbar } from 'notistack'

interface SnackbarCloseButtonProps {
  snackbarKey: string
}

const SnackbarCloseButton = ({ snackbarKey }: SnackbarCloseButtonProps): JSX.Element => {
  const { closeSnackbar } = useSnackbar()

  return <StyledSnackbarCloseButton onClick={() => closeSnackbar(snackbarKey)} />
}

export default SnackbarCloseButton
