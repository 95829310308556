import { Slider, SliderProps } from '@material-ui/core'

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import theme from '../../../../styled/theme'

const useStyles = makeStyles({
  root: {
    color: theme.colors.secondary
  },
  thumb: {
    backgroundColor: theme.colors.secondary,
    '&:hover, &$active': {
      boxShadow: `0px 0px 0px 0.5rem ${theme.colors.secondaryLight}`
    }
  }
})

const StyledSlider = (props: SliderProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Slider
      classes={{
        root: classes.root,
        thumb: classes.thumb
      }}
      {...props}
    />
  )
}

export default StyledSlider
