import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomEditIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 22 22'}>
    <path id="Stroke_1" data-name="Stroke 1" d="M4.418,14.84,0,10.422,10.422,0,14.84,4.418Z" transform="translate(3.623 3.753)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
    <path id="Stroke_2" data-name="Stroke 2" d="M5.026,18.371,18.979,4.418,14.56,0,.608,13.953,0,16.555l2.424,2.424Z" transform="translate(1.987 1.25)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
    <path id="Stroke_4" data-name="Stroke 4" d="M3.161,2.424.737,0,0,3.162Z" transform="translate(1.25 17.805)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
    <path id="Stroke_7" data-name="Stroke 7" d="M10.421,0,0,10.421" transform="translate(6.402 6.532)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
  </SvgIcon>

export default CustomEditIcon
