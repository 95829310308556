import { GlobalStyle, muiTheme, theme } from '../styled'
import React, { useEffect } from 'react'

import { AppProps } from 'next/app'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { Provider } from 'react-redux'
import { SnackbarCloseButton } from '@aims-layout'
import { SnackbarProvider } from 'notistack'
import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components'
import { useStore } from '@aims-app-store'

function App ({ Component, pageProps }: AppProps): JSX.Element {
  const reduxStore = useStore(pageProps.initialReduxState)

  useEffect((): void => {
    TagManager.initialize({ gtmId: 'GTM-P3CM9S4' })
  }, [])

  return (
    <Provider store={reduxStore}>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={muiTheme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top'
            }}
            action={(key: string) => <SnackbarCloseButton snackbarKey={key}/>}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Component {...pageProps} />
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
