import styled from 'styled-components'

const StyledAddRemoteTracksBar = styled.div`
  display: flex;
  gap: 0.8rem;

  & .track-search-bar {
    flex: 1;
  }

  & .add-button {
    padding: 0.65rem 1.15rem;
    margin-left: auto;
    width: 12.5rem;
  }
`

export default StyledAddRemoteTracksBar
