import { Box, Button } from '@aims-controls'
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core'
import { DetailedTrack, ProjectListHook } from '@aims-track/types'
import { NewProjectButton, sortProjects } from '@aims-project/components'
import React, { useState } from 'react'
import { getIsLoading, getProject, getProjects, list, refreshProject, setLoading, setTrack } from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { Collection } from '@aims-collection/types'
import { StyledTrackDialog } from '../styled'
import { enqueue } from '@aims-layout'
import { getUser } from '@aims-store/auth'
import { makeStyles } from '@material-ui/styles'
import { toggleTrackInProject } from '@aims-project/lib'
import { useRouter } from 'next/router'

const useStyles = makeStyles({
  circularProgressBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'radial-gradient(white, transparent 15%)'
  }
})

const useProjectsList = (track: DetailedTrack): ProjectListHook => {
  const loading = useSelector(getIsLoading)
  const dispatch = useDispatch()
  const router = useRouter()
  const project = useSelector(getProject)
  const projects = useSelector(getProjects)
  const user = useSelector(getUser)
  const [dialogueIsOpen, setDialogueIsOpen] = useState(false)
  const styles = useStyles()

  const handleOpenDialogue = (): void => {
    if (user === null) {
      return
    }

    if (router.pathname === '/my-projects/[id]') {
      addTrackToProject(project)
      return
    }

    setDialogueIsOpen(true)

    if (projects.collections.length === 0) {
      dispatch(list())
    }
  }

  const handleCloseDialogue = (): void => {
    setDialogueIsOpen(false)
    dispatch(setLoading(false))
  }

  const addTrackToProject = (project: Collection): void => {
    dispatch(setLoading(true))
    toggleTrackInProject(user, track.idClient, project.key)
      .then((): void => {
        dispatch(setTrack(track, true))
        dispatch(refreshProject({ ...project, updatedAt: new Date().toISOString() }))
        dispatch(enqueue({ message: 'Track added.', options: { variant: 'success' } }))
      })
      .catch((error): void => {
        dispatch(enqueue({ message: (error as Error).message, options: { variant: 'info' } }))
      })
      .finally((): void => { handleCloseDialogue() })
  }

  const renderProjectsListDialogue = (): JSX.Element => {
    return (
      <StyledTrackDialog
        maxWidth={'xs'}
        open={dialogueIsOpen}
        fullWidth
        onClose={handleCloseDialogue}
      >
        <DialogTitle>
          <Typography variant={'h4'} component={'p'}>
            Select a project
          </Typography>
        </DialogTitle>
        <DialogContent className={'full'}>
          <ListItem disabled={loading} button>
            <NewProjectButton size={'small'} variant={'text'} />
          </ListItem>
          <List>
            {projects.collections.sort(sortProjects).map((sortedProject: Collection) => {
              return (
                <ListItem
                  key={sortedProject.id}
                  button
                  disabled={user === null || loading}
                  onClick={() => addTrackToProject(sortedProject)}
                >
                  <ListItemText primary={sortedProject.title} />
                </ListItem>
              )
            })}
          </List>
          {loading && (
            <Box className={styles.circularProgressBox}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button size={'medium'} variant={'text'} onClick={handleCloseDialogue}>
            Done
          </Button>
        </DialogActions>
      </StyledTrackDialog>
    )
  }

  return {
    renderProjectsListDialogue,
    handleOpenDialogue
  }
}

export default useProjectsList
