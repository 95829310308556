import { Collection, CollectionTracks, CollectionType } from '@aims-collection/types'
import { loadCollection, loadCollectionSuggestions, loadCollectionTracks, loadCollections } from '@aims-collection/lib'

import CollectionPagination from '@aims-collection/types/CollectionPagination'
import { DetailedTrack } from '@aims-track/types'
import { UserIdentity } from '@aims-auth/types'
import { convertKeysToCamelCase } from '@aims-search/lib'
import { getPseudoUniqueHash } from 'lib/string'
import slugify from 'slugify'

const getPrefixedLink = (collectionType: CollectionType, apiHost: UserIdentity['apiHost']): string =>
  `${apiHost}/v1/${collectionType}`

const resolveResponse = async (response: Response): Promise<Record<string, unknown>> => {
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  return await response.json()
}

const getUserIdHeaders = (user: UserIdentity, customContentType: string|null = 'application/json'): HeadersInit => {
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'X-User-Id': user.id
  }

  if (customContentType !== null) {
    headers['Content-Type'] = customContentType
  }

  return headers
}

export const loadProject = async (projectKey: string, user: UserIdentity): Promise<Record<string, unknown>> => {
  return await loadCollection(projectKey, CollectionType.Project, user)
}

export const loadTracks = async (projectKey: string, user: UserIdentity): Promise<Record<string, unknown>> => {
  return await loadCollectionTracks(projectKey, CollectionType.Project, user)
}

export const loadProjectSuggestions = async (projectKey: string, user: UserIdentity, pagination: CollectionPagination): Promise<Record<string, unknown>> => {
  const { page, pageSize } = pagination
  return await loadCollectionSuggestions(projectKey, CollectionType.Project, user, page, pageSize)
}

export const loadProjects = async (user: UserIdentity): Promise<Record<string, unknown>> => {
  return await loadCollections(CollectionType.Project, user)
}

export const createProject = async (user: UserIdentity, title: string): Promise<Record<string, unknown>> => {
  const isPrivateProject = true
  const headers = getUserIdHeaders(user)
  const method = 'POST'
  const body = JSON.stringify({
    title,
    key: `${slugify(title)}-${getPseudoUniqueHash()}`,
    is_hidden: isPrivateProject,
    user_id: user.id
  })
  const response = await fetch(`${getPrefixedLink(CollectionType.Project, user.apiHost)}`, { headers, method, body })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  const json = await response.json()
  return json.collection
}

export const updateProject = async (user: UserIdentity, project: Collection, title: string): Promise<Record<string, unknown>> => {
  const headers = getUserIdHeaders(user)
  const method = 'POST'
  const body = JSON.stringify({ title })
  const response = await fetch(`${getPrefixedLink(CollectionType.Project, user.apiHost)}/update/by-key/${project.key}`, { headers, method, body })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  const json = await response.json()
  return json.collection
}

export const removeProject = async (user: UserIdentity, project: Collection): Promise<Record<string, unknown>> => {
  const headers = getUserIdHeaders(user)
  const method = 'DELETE'
  const response = await fetch(`${getPrefixedLink(CollectionType.Project, user.apiHost)}/delete/by-key/${project.key}`, { headers, method })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  return await response.json()
}

export const toggleTrackInProject = async (
  user: UserIdentity | null,
  trackIdClient: DetailedTrack['idClient'],
  projectKey: Collection['key'] | undefined,
  on = true
): Promise<Record<string, unknown>> => {
  if (user === null || projectKey === undefined) {
    return await Promise.resolve({})
  }

  if (on) {
    const tracksResponse = await loadTracks(projectKey, user)
    const projectTrack = convertKeysToCamelCase<CollectionTracks>(tracksResponse)
      .tracks.find((track: DetailedTrack) => track.idClient === trackIdClient
      )
    if (projectTrack !== undefined) {
      return await Promise.reject(new Error(`'${projectTrack.trackName}' already exists in this project.`))
    }
  }

  const headers = getUserIdHeaders(user)
  const body = JSON.stringify({ track_id: trackIdClient, collection_key: projectKey })
  const url = `${getPrefixedLink(CollectionType.Project, user.apiHost)}/${on ? 'add' : 'remove'}-track/by-id`
  const response = await fetch(url, { headers: headers, method: 'POST', body })

  return await resolveResponse(response)
}

export const addRemoteTrack = async (user: UserIdentity, projectKey: Collection['key'], resource: string|File): Promise<Record<string, unknown>> => {
  if (typeof resource === 'string') {
    return await addRemoteUrlTrack(user, resource, projectKey)
  }
  return await addRemoteFileTrack(user, projectKey, resource)
}

const addRemoteUrlTrack = async (user: UserIdentity, remoteTrackUrl: string, projectKey: Collection['key']): Promise<Record<string, unknown>> => {
  const headers = getUserIdHeaders(user)
  const body = JSON.stringify({ link: remoteTrackUrl, collection_key: projectKey })
  const url = `${getPrefixedLink(CollectionType.Project, user.apiHost)}/add-track/by-url`
  const response = await fetch(url, { headers: headers, method: 'POST', body })

  return await resolveResponse(response)
}

// eslint-disable-next-line
const addRemoteFileTrack = async (user: UserIdentity, projectKey: Collection['key'], remoteFile: any): Promise<Record<string, unknown>> => {
  const headers = getUserIdHeaders(user, null)
  const body = new FormData()
  body.append('collection_key', projectKey)
  body.append('track', remoteFile)
  const url = `${getPrefixedLink(CollectionType.Project, user.apiHost)}/add-track/by-file`
  const response = await fetch(url, { headers, method: 'POST', body })

  return await resolveResponse(response)
}
