import { PlaylistBuilderSeedProps, PlaylistType } from '@aims-playlist-builder/types'
import { areAllSeedsValid, determineSeedType, getCountOfValidSeeds, getValidSeedsOnly } from '@aims-playlist-builder/lib'

import { DetailedTrack } from '@aims-track/types'
import { SearchType } from '@aims-search/types'
import { State } from '@aims-app-store'

export const isLoading = (state: State): boolean => state.data.playlistBuilder.loading
export const getResultsTracks = (state: State): DetailedTrack[] => state.data.playlistBuilder.results.tracks
export const getSeeds = (state: State): PlaylistBuilderSeedProps[] => state.data.playlistBuilder.seeds
export const getLength = (state: State): number => state.data.playlistBuilder.length
export const getType = (state: State): PlaylistType => state.data.playlistBuilder.type
export const getSeedType = (id: string): (state: State) => SearchType|null =>
  (state: State): SearchType|null =>
    determineSeedType(state.data.playlistBuilder.seeds.filter((seed: PlaylistBuilderSeedProps) => seed.id === id))
export const isBuildable = (state: State): boolean =>
  areAllSeedsValid(state.data.playlistBuilder.seeds) &&
  (getCountOfValidSeeds(state.data.playlistBuilder.seeds) >= (state.data.playlistBuilder.type === PlaylistType.Regular ? 1 : 2))
export const getValidSeeds = (state: State): PlaylistBuilderSeedProps[] => getValidSeedsOnly(state.data.playlistBuilder.seeds)
