import { Checkbox, Tooltip } from '@aims-controls'
import { FormControlLabel, Grid } from '@material-ui/core'
import React, { ChangeEvent, useEffect } from 'react'
import {
  SetSearchModifiersIgnoreVocalsAction,
  SetSearchModifiersPrioritiseBpmAction,
  isSearchModifierIgnoreVocalsActive,
  isSearchModifierPrioritiseBpmActive,
  resolveCurrentSearchType,
  setSearchModifiersIgnoreVocals,
  setSearchModifiersPrioritiseBpm
} from '@aims-store/search'
import { isIgnoreVocalsEnabled, isPrioritiseBpmEnabled } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import { isTextSearch } from '@aims-search/lib'

const SearchModifiers = (): JSX.Element => {
  const dispatch = useDispatch()
  const prioritiseBpm = useSelector(isSearchModifierPrioritiseBpmActive)
  const ignoreVocals = useSelector(isSearchModifierIgnoreVocalsActive)
  const prioritiseBpmEnabled = useSelector(isPrioritiseBpmEnabled)
  const ignoreVocalsEnabled = useSelector(isIgnoreVocalsEnabled)
  const currentSearchType = useSelector(resolveCurrentSearchType)
  const disableCheckboxes = isTextSearch(currentSearchType)
  const tooltipTitle = 'Upload a file or paste a link to use Ignore vocals and Prioritize BPM'

  useEffect(() => {
    if (disableCheckboxes) {
      dispatch(setSearchModifiersPrioritiseBpm(false))
      dispatch(setSearchModifiersIgnoreVocals(false))
    }
  }, [currentSearchType])

  return (
    <Grid container spacing={2}>
      {prioritiseBpmEnabled && (
        <Grid item>
          <Tooltip title={tooltipTitle} disableHoverListener={!disableCheckboxes}>
            <span>
              <FormControlLabel
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={prioritiseBpm}
                    disabled={disableCheckboxes}
                    onChange={(event: ChangeEvent<HTMLInputElement>): SetSearchModifiersPrioritiseBpmAction =>
                      dispatch(setSearchModifiersPrioritiseBpm(event.target.checked))
                    }
                    name={'prioritise-bpm'}
                  />
                }
                label={'Prioritize BPM'}
              />
            </span>
          </Tooltip>
        </Grid>
      )}
      {ignoreVocalsEnabled && (
        <Grid item>
          <Tooltip title={tooltipTitle} disableHoverListener={!disableCheckboxes}>
            <span>
              <FormControlLabel
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={ignoreVocals}
                    disabled={disableCheckboxes}
                    onChange={(event: ChangeEvent<HTMLInputElement>): SetSearchModifiersIgnoreVocalsAction =>
                      dispatch(setSearchModifiersIgnoreVocals(event.target.checked))
                    }
                    name={'ignore-vocals'}
                  />
                }
                label={'Ignore vocals'}
              />
            </span>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

export default SearchModifiers
