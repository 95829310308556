import { ShareProjectLinkProps } from '@aims-project/types'

const getShareProjectLink = async ({ user, project, downloadable }: ShareProjectLinkProps): Promise<string> => {
  const body = {
    downloadable,
    collection_key: project.key,
    metadata: user.metadata,
    streaming_secret: user.streamingSecret
  }

  const headers = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-User-Id': user.id,
    Authorization: user.apiSecret
  }
  const method = 'POST'
  const rawResponse = await fetch(`${user.apiHost}/v1/project/create-snapshot/by-key`, { headers, method, body: JSON.stringify(body) })
  const response = await rawResponse.json()

  if (!rawResponse.ok) {
    throw new Error(response.message)
  }

  const shareProjectLink = response.id

  return await Promise.resolve(shareProjectLink)
}

export {
  getShareProjectLink
}
