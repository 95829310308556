import { HighlightsProps } from '@aims-track/types'
import React from 'react'
import { StyledHighlight } from '@aims-track/styled'
import { decorateTime } from '@aims-lib'
import { hasOverlap } from '@aims-track/lib'
import { useHighlightedSegment } from '@aims-track/hooks'

const Highlights = ({ highlights, trackDuration, onSegmentChange }: HighlightsProps): JSX.Element|null => {
  const { handleOffsetHandle, handleLimitHandle, handleSegmentHandle } = useHighlightedSegment(trackDuration, onSegmentChange)

  return (
    trackDuration > 0
      ? <>
        {
          highlights.map(({ offset, duration }, index): JSX.Element =>
            <StyledHighlight
              key={`highlight-${index}`}
              className={`highlighted-segment ${hasOverlap(duration, offset, highlights) ? 'overlap' : ''} ${typeof onSegmentChange !== 'undefined' ? 'draggable' : ''}`}
              style={{
                left: `${offset / trackDuration * 100}%`,
                width: `${Math.min(duration, trackDuration - offset) / trackDuration * 100}%`
              }}
            >
              {
                typeof onSegmentChange === 'undefined'
                  ? <div className="label">
                    {decorateTime(Math.round(offset))} - {decorateTime(Math.round(offset + Math.min(duration, trackDuration - offset)))}
                  </div>
                  : <>
                    <div className={'handle start'} onTouchStart={handleOffsetHandle} onMouseDown={handleOffsetHandle}>
                      <div className={'marker start'}>{decorateTime(Math.round(offset))}</div>
                    </div>
                    <div className={'handle segment'} onTouchStart={handleSegmentHandle} onMouseDown={handleSegmentHandle} />
                    <div className={'handle end'} onTouchStart={handleLimitHandle} onMouseDown={handleLimitHandle}>
                      <div className={'marker end'}>{decorateTime(Math.round(offset + Math.min(duration, trackDuration - offset)))}</div>
                    </div>
                  </>
              }
            </StyledHighlight>
          )
        }
      </>
      : null
  )
}

export default Highlights
