import {
  AutoTaggingState,
  LoadMoreSuccessAction,
  SetTrackTagsAction,
  TrackAddAction,
  TrackProcessingBeginAction,
  TrackProcessingErrorAction,
  TrackProcessingSuccessAction
} from '../types'
import {
  LOAD_MORE_BEGIN,
  LOAD_MORE_ERROR,
  LOAD_MORE_SUCCESS,
  SET_TRACK_TAGS,
  TRACK_ADD,
  TRACK_PROCESSING_BEGIN,
  TRACK_PROCESSING_ERROR,
  TRACK_PROCESSING_SUCCESS
} from '../action-types'
import { TagsTitles, Track } from '@aims-auto-tagging/types'
import {
  addTrack,
  setTrackIsProcessing,
  setTrackProcessingError,
  setTrackProcessingSuccess,
  setTrackTags
} from './tracks'

import { Action } from 'redux'

const initialState: AutoTaggingState = {
  loading: false,
  tracks: [],
  history: [],
  historyOffset: 0,
  historyTotal: -1,
  processedCount: 0
}

const loadMoreSuccess = (state: AutoTaggingState, action: LoadMoreSuccessAction): AutoTaggingState => Object.assign({}, state, {
  history: [...state.history, ...action.results.map((result: Track) => {
    if (result.tags === undefined) {
      return result
    }
    result.tags[TagsTitles.bpm] = [result.tags[TagsTitles.bpm] as unknown as string]
    return result
  })],
  historyTotal: action.total,
  historyOffset: state.historyOffset + action.limit,
  loading: false
})

const reducer = (state = initialState, action: Action = { type: undefined }): AutoTaggingState => {
  switch (action.type) {
    case TRACK_ADD:
      return addTrack(state, action as TrackAddAction)
    case TRACK_PROCESSING_BEGIN:
      return setTrackIsProcessing(state, action as TrackProcessingBeginAction)
    case TRACK_PROCESSING_SUCCESS:
      return setTrackProcessingSuccess(state, action as TrackProcessingSuccessAction)
    case TRACK_PROCESSING_ERROR:
      return setTrackProcessingError(state, action as TrackProcessingErrorAction)
    case LOAD_MORE_SUCCESS:
      return loadMoreSuccess(state, action as LoadMoreSuccessAction)
    case SET_TRACK_TAGS:
      return setTrackTags(state, action as SetTrackTagsAction)
    case LOAD_MORE_BEGIN:
    case LOAD_MORE_ERROR:
      return Object.assign({}, state, {
        loading: action.type === LOAD_MORE_BEGIN
      })
    default:
      return state
  }
}

export default reducer
