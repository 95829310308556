import { Overrides } from '@material-ui/core/styles/overrides'

const filledInputOverride: Overrides['MuiFilledInput'] = {
  root: {
    // fontSize: '1.2rem',
    // fontWeight: 700
  }
}

export default filledInputOverride
