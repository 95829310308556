import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../../colors'

const chipOverride: Overrides['MuiChip'] = {
  root: {
    border: `0.0625rem solid ${colors.grey.medium}`,
    backgroundColor: colors.grey.veryLight,
    fontWeight: 500,
    padding: '0 0.25rem 0 0.1875rem'
  },
  label: {
    paddingLeft: '0.375rem',
    paddingRight: '0.375rem'
  },
  deleteIcon: {
    margin: undefined
  }
}

export default chipOverride
