import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomLyricsIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 4 18 18'}>
    <g id="Group_11" transform="translate(1.5 1.5)">
      <path className="stroke" stroke="silver" fill="none" id="Stroke_5" d="M8.83,16.94c0,1.67-1.35,3.02-3.02,3.02s-3.02-1.35-3.02-3.02s1.35-3.02,3.02-3.02 S8.83,15.27,8.83,16.94L8.83,16.94z"/>
      <path className="stroke" stroke="silver" id="Stroke_7" d="M8.5,3v12.5"/>
    </g>
    <path className="stroke" stroke="silver" id="Stroke_7_1_" d="M12.5,4.5h5"/>
    <path className="stroke" stroke="silver" id="Stroke_7_2_" d="M12.5,6.74h4"/>
    <path className="stroke" stroke="silver" id="Stroke_7_3_" d="M12.5,8.74h3"/>
  </SvgIcon>

export default CustomLyricsIcon
