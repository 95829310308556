import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomFileIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 26.4 24.4'}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-116.000000, -65.000000)" fill="#ADADAD" fillRule="nonzero">
        <g transform="translate(116.000000, 65.000000)">
          <path d="M7.00875,18.06 C7.72625,18.06 8.33875,17.810625 8.84625,17.311875 C9.35375,16.813125 9.6075,16.205 9.6075,15.4875 L9.6075,10.185 L12.6525,10.185 L12.6525,8.61 L8.5575,8.61 L8.5575,13.51875 C8.365,13.36125 8.133125,13.23875 7.861875,13.15125 C7.590625,13.06375 7.30625,13.02 7.00875,13.02 C6.32625,13.02 5.74875,13.260625 5.27625,13.741875 C4.80375,14.223125 4.5675,14.805 4.5675,15.4875 C4.5675,16.1875 4.80375,16.79125 5.27625,17.29875 C5.74875,17.80625 6.32625,18.06 7.00875,18.06 L7.00875,18.06 Z M1.575,21 C1.155,21 0.7875,20.8425 0.4725,20.5275 C0.1575,20.2125 0,19.845 0,19.425 L0,1.575 C0,1.155 0.1575,0.7875 0.4725,0.4725 C0.7875,0.1575 1.155,0 1.575,0 L11.05125,0 L16.8,5.74875 L16.8,19.425 C16.8,19.845 16.6425,20.2125 16.3275,20.5275 C16.0125,20.8425 15.645,21 15.225,21 L1.575,21 Z M10.26375,6.4575 L10.26375,1.575 L1.575,1.575 L1.575,19.425 L15.225,19.425 L15.225,6.4575 L10.26375,6.4575 Z M1.575,1.575 L1.575,6.4575 L1.575,1.575 L1.575,19.425 L1.575,1.575 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </SvgIcon>

export default CustomFileIcon
