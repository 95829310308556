import { ExternalTrackProps } from '@aims-track/types'
import { Grid } from '@material-ui/core'
import React from 'react'
import TrackActions from '../TrackActions'
import { TrackChip } from '@aims-controls'

const ExternalTrack = ({ track, project }: ExternalTrackProps): JSX.Element => {
  return (
    <Grid container spacing={2} justifyContent={'space-between'}>
      <Grid wrap={'nowrap'} container item xs={12} sm={8} spacing={2} alignItems={'center'}>
        <Grid item>
          <div className={'external-track-tag'}>external</div>
        </Grid>
        <Grid container zeroMinWidth wrap={'nowrap'} item>
          <TrackChip title={track.trackName} />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={3}>
        <TrackActions track={track} project={project} />
      </Grid>
    </Grid>
  )
}

export default ExternalTrack
