export enum ScreenSize {
  Small = 600,
  Medium = 960,
  Large = 1280,
  ExtraLarge = 1920
}

export const media = (size: ScreenSize): string => {
  return `@media only screen and (min-width: ${size}px)`
}
