import {
  ADD_SEED,
  BUILD_BEGIN,
  BUILD_ERROR,
  BUILD_SUCCESS,
  DISCARD_SEGMENT,
  PICK_SEGMENT,
  REMOVE_SEED,
  SET_FIELD_FILE,
  SET_FIELD_TEXT,
  SET_LENGTH,
  SET_SEGMENT_OFFSET_AND_LIMIT,
  SET_SEGMENT_TRACK,
  SET_TYPE
} from './action-types'
import {
  AddSeedAction,
  BuildBeginAction,
  BuildErrorAction,
  BuildSuccessAction,
  DiscardSegmentAction,
  PickSegmentAction,
  RemoveSeedAction,
  SetFieldFileAction,
  SetFieldTextAction,
  SetLengthAction,
  SetSegmentOffsetAndLimitAction,
  SetSegmentTrackAction,
  SetTypeAction
} from './types'
import {
  PlaylistResults,
  PlaylistType
} from '@aims-playlist-builder/types'
import { getLength, getType, getValidSeeds } from './selectors'

import { DetailedTrack } from '@aims-track/types'
import { Dispatch } from 'redux'
import { State } from '@aims-app-store'
import { UserIdentity } from '@aims-auth/types'
import { buildPlaylist } from '@aims-playlist-builder/lib'
import { enqueue } from '@aims-layout'
import { getUser } from '@aims-store/auth'
import { reformatResults } from '@aims-search/lib'

export const buildBegin = (): BuildBeginAction => ({
  type: BUILD_BEGIN
})

export const buildSuccess = (results: PlaylistResults): BuildSuccessAction => ({
  type: BUILD_SUCCESS,
  results
})

export const buildError = (error: Error): BuildErrorAction => ({
  type: BUILD_ERROR,
  error
})

export const build = (): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(buildBegin())
    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const seeds = getValidSeeds(state)
      const type = getType(state)
      const length = getLength(state)
      const rawResults = await buildPlaylist({ type, length, seeds, user })
      const results = reformatResults<PlaylistResults>(rawResults)
      dispatch(buildSuccess(results))
    } catch (err) {
      const error = err as Error
      dispatch(buildError(error))
      dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
    }
  }

export const addSeed = (): AddSeedAction => ({
  type: ADD_SEED
})

export const removeSeed = (id: string): RemoveSeedAction => ({
  type: REMOVE_SEED,
  id
})

export const setLength = (length: number): SetLengthAction => ({
  type: SET_LENGTH,
  length
})

export const setType = (playlistType: PlaylistType): SetTypeAction => ({
  type: SET_TYPE,
  playlistType
})

export const pickSegment = (id: string): PickSegmentAction => ({
  type: PICK_SEGMENT,
  id
})

export const discardSegment = (id: string): DiscardSegmentAction => ({
  type: DISCARD_SEGMENT,
  id
})

export const setFieldText = (text: string, id: string): SetFieldTextAction => ({
  type: SET_FIELD_TEXT,
  text,
  id
})

export const setFieldFile = (file: File, id: string): SetFieldFileAction => ({
  type: SET_FIELD_FILE,
  file,
  id
})

export const setSegmentOffsetAndLimit = (offset: number, limit: number, id: string): SetSegmentOffsetAndLimitAction => ({
  type: SET_SEGMENT_OFFSET_AND_LIMIT,
  offset,
  limit,
  id
})

export const setSegmentTrack = (track: DetailedTrack, audio: HTMLAudioElement, id: string): SetSegmentTrackAction => ({
  type: SET_SEGMENT_TRACK,
  track,
  audio,
  id
})
