import styled from 'styled-components'

const StyledDropZone = styled.div`
  position: relative;

  &.drag-active {
    &::after {
      color: ${props => props.theme.colors.grey.medium};
      content: 'Drop your file here';
      display: block;
      font-size: 1.5rem;
      left: 3rem;
      position: absolute;
      top: 0.8rem;
    }

    button {
      display: none;
    }

    .MuiInputBase-root {

      input {
        opacity: 0;
      }
      background-color: ${props => props.theme.colors.grey.lightest};

      .MuiOutlinedInput-notchedOutline {
        border: 0.15rem dashed ${props => props.theme.colors.secondary};
      }
    }
  }
`

export default StyledDropZone
