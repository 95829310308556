import { MAX_LIMIT, MIN_LIMIT } from '../lib'
import { MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from 'react'

import { HighlightedSegmentFunctions } from '../types'

export const useHighlightedSegment = (duration: number, onChange?: (offset: number, limit: number) => void): HighlightedSegmentFunctions => {
  let element: HTMLElement|null = null
  let containerWidth: number|null = null
  let cursor: number|null = null
  let left: number|null = null
  let width: number|null = null
  let movingMarker = false

  const minWidth = duration > MIN_LIMIT
    ? MIN_LIMIT / (duration / 100)
    : 100

  const maxWidth = duration > MAX_LIMIT
    ? MAX_LIMIT / (duration / 100)
    : 100

  const segmentChanged = ({ left, width }: {left: string, width: string}): void => {
    if (typeof onChange !== 'undefined') {
      onChange(
        (parseFloat(left) / 100) * duration,
        (parseFloat(width) / 100) * duration
      )
    }
  }

  const closest = (element: HTMLElement|null, selector: string): HTMLElement|null => {
    while (element !== null) {
      if (element.matches(selector)) {
        return element
      }
      element = element.parentElement
    }
    return null
  }

  const getScreenX = (event: MouseEvent | TouchEvent): number => {
    if (typeof (event as MouseEvent).screenX !== 'undefined') {
      return (event as MouseEvent).screenX
    }
    return (event as TouchEvent).touches[0].screenX
  }

  const prepareMouseDown = (event: ReactMouseEvent<HTMLElement, MouseEvent>|ReactTouchEvent<HTMLElement>): void => {
    event.preventDefault()
    event.stopPropagation()
    const target = event.target as HTMLElement
    target.classList.add('dragging')
    element = closest(event.target as HTMLElement, '.highlighted-segment')
    if (element !== null) {
      containerWidth = parseFloat(getComputedStyle(element.parentElement as HTMLElement).width)
      cursor = getScreenX(event.nativeEvent)
      left = parseFloat(element.style.left)
      width = parseFloat(element.style.width)
    }
  }

  const prepareMouseUp = (event: MouseEvent|TouchEvent): void => {
    event.preventDefault()
    event.stopPropagation()
    const target = event.target as HTMLElement
    target.classList.remove('dragging')
    if (element !== null) {
      segmentChanged(element.style)
    }
    element = null
    cursor = null
    left = null
    width = null
    containerWidth = null
  }

  const handleSegmentHandleMove = (event: MouseEvent|TouchEvent): void => {
    if (movingMarker || element === null) {
      return
    }

    let newOffset = (left ?? 0) + ((getScreenX(event) - (cursor ?? 0)) / ((containerWidth ?? 0) / 100))
    if (newOffset < 0) newOffset = 0
    if (newOffset + (width ?? 0) > 100) newOffset = 100 - (width ?? 0)

    element.style.left = `${newOffset}%`

    segmentChanged(element.style)
  }

  const handleOffsetHandleMove = (event: MouseEvent|TouchEvent): void => {
    if (element === null) {
      return
    }

    const rightOffset = (left ?? 0) + (width ?? 0)
    let newOffset = (left ?? 0) + ((getScreenX(event) - (cursor ?? 0)) / ((containerWidth ?? 0) / 100))
    if (newOffset < 0) {
      newOffset = 0
    }

    let newWidth = rightOffset - newOffset

    if (minWidth > newWidth) {
      newOffset = newOffset - (minWidth - newWidth)
      newWidth = rightOffset - newOffset
    }

    if (maxWidth < newWidth) {
      newOffset = newOffset - (maxWidth - newWidth)
      newWidth = rightOffset - newOffset
    }

    element.style.left = `${newOffset}%`
    element.style.width = `${newWidth}%`

    segmentChanged(element.style)
  }

  const handleLimitHandleMove = (event: MouseEvent|TouchEvent): void => {
    if (element === null) {
      return
    }

    let newWidth = (width ?? 0) + ((getScreenX(event) - (cursor ?? 0)) / ((containerWidth ?? 0) / 100))
    if (newWidth + (left ?? 0) > 100) newWidth = 100 - (left ?? 0)

    if (minWidth > newWidth) newWidth = minWidth
    if (maxWidth < newWidth) newWidth = maxWidth

    element.style.width = `${newWidth}%`

    segmentChanged(element.style)
  }

  const handleSegmentHandleUp = (event: MouseEvent|TouchEvent): void => {
    prepareMouseUp(event)
    const target = event.target as HTMLElement
    document.removeEventListener('mousemove', handleSegmentHandleMove)
    target.removeEventListener('touchmove', handleSegmentHandleMove)
    document.removeEventListener('mouseup', handleSegmentHandleUp)
    target.removeEventListener('touchend', handleSegmentHandleUp)
  }

  const handleOffsetHandleUp = (event: MouseEvent|TouchEvent): void => {
    prepareMouseUp(event)
    movingMarker = false
    const target = event.target as HTMLElement
    document.removeEventListener('mousemove', handleOffsetHandleMove)
    target.removeEventListener('touchmove', handleOffsetHandleMove)
    document.removeEventListener('mouseup', handleOffsetHandleUp)
    target.removeEventListener('touchend', handleOffsetHandleUp)
  }

  const handleLimitHandleUp = (event: MouseEvent|TouchEvent): void => {
    prepareMouseUp(event)
    movingMarker = false
    const target = event.target as HTMLElement
    document.removeEventListener('mousemove', handleLimitHandleMove)
    target.removeEventListener('touchmove', handleLimitHandleMove)
    document.removeEventListener('mouseup', handleLimitHandleUp)
    target.removeEventListener('touchend', handleLimitHandleUp)
  }

  const handleSegmentHandle = (event: ReactMouseEvent<HTMLElement, MouseEvent>|ReactTouchEvent<HTMLElement>): void => {
    prepareMouseDown(event)
    const target = event.target as HTMLElement
    document.addEventListener('mousemove', handleSegmentHandleMove)
    target.addEventListener('touchmove', handleSegmentHandleMove)
    document.addEventListener('mouseup', handleSegmentHandleUp)
    target.addEventListener('touchend', handleSegmentHandleUp)
  }

  const handleOffsetHandle = (event: ReactMouseEvent<HTMLElement, MouseEvent>|ReactTouchEvent<HTMLElement>): void => {
    prepareMouseDown(event)
    movingMarker = true
    const target = event.target as HTMLElement
    document.addEventListener('mousemove', handleOffsetHandleMove)
    target.addEventListener('touchmove', handleOffsetHandleMove)
    document.addEventListener('mouseup', handleOffsetHandleUp)
    target.addEventListener('touchend', handleOffsetHandleUp)
  }

  const handleLimitHandle = (event: ReactMouseEvent<HTMLElement, MouseEvent>|ReactTouchEvent<HTMLElement>): void => {
    prepareMouseDown(event)
    movingMarker = true
    const target = event.target as HTMLElement
    document.addEventListener('mousemove', handleLimitHandleMove)
    target.addEventListener('touchmove', handleLimitHandleMove)
    document.addEventListener('mouseup', handleLimitHandleUp)
    target.addEventListener('touchend', handleLimitHandleUp)
  }

  return {
    handleSegmentHandle,
    handleOffsetHandle,
    handleLimitHandle
  }
}
