import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledHighlight = styled.div`
  bottom: 0;
  height: 100%;
  pointer-events: none;
  position: absolute;
  text-align: center;

  &.draggable {
    transition: ${props => props.theme.transitions.duration.regular} ${props => props.theme.transitions.easing.regular} background-color;
    .waveform-container:hover & {
      background-color: ${props => props.theme.colors.primaryLight};
      .handle {
        &.segment {
          opacity: 1;
        }
      }
      .marker {
        opacity: 1;
      }
    }
  }

  .label {
    display: none;
    ${media(ScreenSize.Small)} {
      background-color: ${props => props.theme.colors.white};
      bottom: -2.7rem;
      box-shadow: 0 0 0.2rem 0.2rem ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.primaryDesaturated};
      display: inline-block;
      font-size: 80%;
      line-height: 1;
      pointer-events: auto;
      position: relative;
      white-space: nowrap;
      &:hover {
        z-index: 1;
      }
    }
  }

  .marker {
    background-color: ${props => props.theme.colors.white};
    border: 0.1rem solid ${props => props.theme.colors.primary};
    border-radius: 0.3rem;
    color: ${props => props.theme.colors.primary};
    font-size: 0.8rem;
    left: 50%;
    line-height: 100%;
    margin-left: -1.25rem;
    opacity: 0;
    padding: 0.1rem;
    position: absolute;
    top: -1.25rem;
    transition: ${props => props.theme.transitions.duration.regular} ${props => props.theme.transitions.easing.regular} opacity;
    user-select: none;
    width: 2.5rem;
  }

  .handle {
    height: 100%;
    position: absolute;
    pointer-events: auto;
    top: 0;
    touch-action: none;
    width: 1rem;
    &::after {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
      content: '||';
      display: block;
      font-size: 0.5rem;
      height: 1rem;
      line-height: 180%;
      margin-top: -0.5rem;
      position: absolute;
      top: 50%;
      width: 0.8rem;
    }
    &::before {
      border-left: 0.15rem solid ${props => props.theme.colors.primary};
      content: '';
      display: block;
      height: 100%;
      left: 0.425rem;
      position: absolute;
    }
    &.start {
      cursor: col-resize;
      left: -0.5rem;
      &::after {
        border-radius: 0.2rem 0 0 0.2rem;
        left: -0.3rem;
      }
    }
    &.end {
      cursor: col-resize;
      right: -0.5rem;
      &::after {
        border-radius: 0 0.2rem 0.2rem 0;
        right: -0.3rem;
      }
    }
    &.segment {
      cursor: grab;
      height: 1rem;
      left: 50%;
      margin-left: -1rem;
      opacity: 0;
      pointer-events: auto;
      top: 50%;
      transform: translateY(-0.5rem);
      transition: ${props => props.theme.transitions.duration.regular} ${props => props.theme.transitions.easing.regular} opacity;
      width: 2rem;
      &::after {
        width: 100%;
      }
      &::before {
        display: none;
      }
      &.dragging {
        cursor: grabbing;
        &:hover,
        &:active {
          cursor: grabbing;
        }
      }
    }
  }
`

export default StyledHighlight
