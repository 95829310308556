import styled from 'styled-components'

const ProjectWatcherStickyBarStyled = styled.div`
  z-index: 1;
  position: sticky;
  opacity: 1;
  display: flex;
  justify-content: center;
  height: 0;
  top: 0;
  width: 100%;
  transition: opacity .2s ease-out;
  padding-top: 1rem;

  &.hidden {
    pointer-events: none;
    opacity: 0;
    padding: 0;
  }

  .load-progress {
    margin-top: 0.7rem;
  }
`

export default ProjectWatcherStickyBarStyled
