import { Grid } from '@material-ui/core'
import styled from 'styled-components'

const StyledSearchFiltersExpander = styled(Grid)`
  padding-top: 1rem;
  a {
    display: flex;
    margin-top: 0.2rem;
    text-decoration: none;
  }
  span {
    border-top: 0.1rem solid ${props => props.theme.colors.grey.light};
    display: flex;
    flex: 1;
    margin-left: 1rem;
    margin-top: 1rem;
  }
`

export default StyledSearchFiltersExpander
