import { Button, LoaderButtonTextual, Tooltip } from '@aims-controls'
import { DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getIsLoading, getIsNewProjectCreated, remove, setNewProjectCreated } from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { Collection } from '@aims-collection/types'
import CustomTrashIcon from '../../../../icons/component/CustomTrashIcon'
import { StyledTrackDialog } from '@aims-track/styled'
import { useRouter } from 'next/router'

const DeleteProjectButton = ({ project }: { project: Collection }): JSX.Element => {
  const dispatch = useDispatch()
  const loading = useSelector(getIsLoading)
  const created = useSelector(getIsNewProjectCreated)
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (created) {
      setSuccess(true)
      setTimeout(() => {
        handleClose()
        dispatch(setNewProjectCreated(false))
      }, 1000)
    }
  }, [created])

  const handleDeleteProject = (): void => {
    dispatch(remove(project))
    if (router.pathname !== '/my-projects') {
      void router.push('/my-projects') // eslint-disable-line no-void
    }
  }

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
    setSuccess(false)
  }

  return (
    <>
      <Tooltip title={'Delete project'}>
        <IconButton disabled={loading} onClick={handleOpen} className={'action-delete-project'}>
          <CustomTrashIcon />
        </IconButton>
      </Tooltip>
      <StyledTrackDialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant={'h4'} component={'p'}>
            Delete project {project.title}
          </Typography>
        </DialogTitle>
        <DialogContent className={'full'}>
          <Typography>Do you really want to permanently delete your project?</Typography>
        </DialogContent>
        <DialogActions>
          <LoaderButtonTextual
            text={{
              default: 'Delete',
              loading: 'Deleting...',
              success: 'Deleted'
            }}
            disabled={loading}
            success={success}
            loading={loading}
            variant={'contained'}
            onClick={handleDeleteProject}
            size={'medium'}
            color={'secondary'}
          />
          <Button size={'medium'} variant={'text'} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </StyledTrackDialog>
    </>
  )
}

export default DeleteProjectButton
