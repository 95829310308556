import { CollectionType } from '../types'
import { State } from '@aims-app-store'
import { UserIdentity } from '@aims-auth/types'
import { initialState } from '@aims-store/project'
import { shouldAllowGrouping } from '@aims-store/auth'

export const loadCollection = async (key: string, type: CollectionType, user: UserIdentity): Promise<Record<string, unknown>> => {
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'X-User-Id': user.id
  }
  const allowGrouping = shouldAllowGrouping({ data: { auth: { user } } } as unknown as State)
  const response = await fetch(`${user.apiHost}/v1/${type}/get/by-key/${key}${allowGrouping ? '?groups=true' : ''}`, { headers })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  const json = await response.json()
  return json.collection
}

export const loadCollections =
async (type: CollectionType, user: UserIdentity, filterByUserId = false): Promise<Record<string, unknown>> => {
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'X-User-Id': user.id
  }
  const pageSize = 'page_size=999'
  const page = 'page=1'

  const response = await fetch(`${user.apiHost}/v1/${type}?user_id=${user.id}&show_hidden=1&${pageSize}&${page}`, { headers })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  if (response.status === 204) {
    return initialState.projects as unknown as Record<string, unknown>
  }
  return await response.json()
}

export const loadCollectionTracks = async (key: string, type: CollectionType, user: UserIdentity): Promise<Record<string, unknown>> => {
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'X-User-Id': user.id
  }
  const allowGrouping = shouldAllowGrouping({ data: { auth: { user } } } as unknown as State)
  const response = await fetch(`${user.apiHost}/v1/${type}/get-tracks/by-key/${key}?detailed=true${allowGrouping ? '&auto_tagging_output=true' : ''}`, { headers })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }

  if (response.status === 204) {
    return initialState.tracks as unknown as Record<string, unknown>
  }

  return await response.json()
}

export const loadSimilarCollections = async (key: string, type: CollectionType, user: UserIdentity): Promise<Record<string, unknown>> => {
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'X-User-Id': user.id,
    'Content-Type': 'application/json'
  }
  const method = 'POST'
  const body = JSON.stringify({
    key: key
  })
  const response = await fetch(`${user.apiHost}/v1/${type}/similar/by-key`, { headers, method, body })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  return await response.json()
}

export const loadCollectionSuggestions = async (key: string, type: CollectionType, user: UserIdentity, page: number, pageSize: number, groupId?: string): Promise<Record<string, unknown>> => {
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'X-User-Id': user.id,
    'Content-Type': 'application/json'
  }
  const method = 'POST'
  const body = JSON.stringify({
    key,
    page,
    page_size: pageSize,
    group_id: groupId
  })
  const response = await fetch(`${user.apiHost}/v1/${type}/suggest/by-key?detailed=true`, { headers, method, body })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  return await response.json()
}
