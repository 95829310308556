import { ScreenSize, media } from '../../../../styled/mixins'

import { StyledTrackProps } from '../types'
import styled from 'styled-components'
import { theme } from '@aims-theme'

const ultraLight = 'rgba(0, 183, 190, 0.01)'

const { secondaryLight, grey } = theme.colors

const StyledTrack = styled.div<StyledTrackProps>`
  border: 0.1rem solid ${({ nested }) => nested !== undefined && nested ? secondaryLight : grey.light};
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  background-color: ${({ nested }) => nested !== undefined && nested ? ultraLight : undefined};

  .track-headline {
    justify-content: space-between;
    gap: 0.5rem;

    ${media(ScreenSize.Small)} {
      justify-content: normal;
    }
  }

  .track-metadata {
    display: none;
    ${media(ScreenSize.Small)} {
      display: flex;
    }
  }

  .track-play-area {
    gap: 1rem;
    ${media(ScreenSize.Small)} {
      flex-wrap: nowrap;
    }
  }

  & .external-track-tag {
    border-radius: 0.3rem;
    text-transform: uppercase;
    background-color: ${theme.colors.grey.light};
    padding: 0.4rem;
    display: inline-block;
    line-height: 1;
    color: ${theme.colors.grey.medium};
  }
`

export default StyledTrack
