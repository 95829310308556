import styled from 'styled-components'

const StyledSnackbarCloseButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export default StyledSnackbarCloseButton
