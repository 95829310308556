import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledNavItem = styled.div`
  background-color: ${props => props.theme.colors.white};
  font-size: 1.2rem;
  padding: 1.125rem 1rem;

  &:last-of-type {
    text-decoration: underline;
  }

  ${media(ScreenSize.Small)} {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
  }
  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: ${props => props.theme.colors.secondary};
    }
  }
  &.active {
    text-decoration: underline;
    a {
      color: ${props => props.theme.colors.primary};
      &:hover {
        color: ${props => props.theme.colors.primaryHover};
      }
    }
  }
  &.current {
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.font.medium};
    padding: 0;
    &:last-child {
      padding-bottom: 0;
      padding-top: 1.35rem
    }
    ${media(ScreenSize.Small)} {
      display: none;
    }
  }
  &:last-child {
    margin-right: 0;
    padding-bottom: 4rem;
    ${media(ScreenSize.Small)} {
      padding-bottom: 0;
    }
    a {
      color: ${props => props.theme.colors.grey.medium};
      &:hover {
        color: ${props => props.theme.colors.grey.mediumDark};
      }
    }
  }
`

export default StyledNavItem
