import { ConfirmEmailResponse } from '@aims-auth/types'

const registerNewUser = async (email: string, password: string, captcha: string): Promise<{status: string}> => {
  const response = await fetch('/api/registration/register', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password, captchaCode: captcha })
  })

  const responseJson = await response.json()

  if (!response.ok) {
    /* eslint-disable */
    return await Promise.reject({ status: responseJson.status })
    /* eslint-enable */
  }

  return await Promise.resolve({ status: 'ok' })
}

const confirmEmail = async (token: string): Promise<ConfirmEmailResponse> => {
  if (token === undefined) {
    return await Promise.reject(new Error('Token is missing'))
  }
  const response = await fetch('/api/registration/confirm-email', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token })
  })

  const responseJson: ConfirmEmailResponse = await response.json()
  if (!response.ok) {
    return await Promise.reject(responseJson)
  }
  return await Promise.resolve(responseJson)
}

export {
  registerNewUser,
  confirmEmail
}
