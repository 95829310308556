import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../../colors'
import theme from '../../theme'

const typographyOverride: Overrides['MuiTypography'] = {
  colorPrimary: {
    color: colors.primary
  },
  colorSecondary: {
    color: colors.secondary
  },
  overline: {
    fontFamity: undefined,
    fontWeight: theme.font.medium,
    color: colors.black,
    letterSpacing: undefined,
    lineHeight: undefined,
    display: 'inline-block',
    fontSize: '0.8rem'
  }
}

export default typographyOverride
