import { Grid, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import { getIsLoading, getProjects, getSearchTerm, setIsProjectOutdated, setSearchTerm } from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { Collection } from '@aims-collection/types'
import CustomSearchIcon from 'components/icons/component/CustomSearchIcon'
import NewProjectButton from '../NewProjectButton'
import Project from '../Project'
import StyledProjectListing from './ProjectListing.styled'
import { StyledSearchResults } from '@aims-search/styled'
import sortProjects from './sortProjects'

const ProjectsListing = (): JSX.Element => {
  const projects = useSelector(getProjects)
  const loading = useSelector(getIsLoading)
  const searchTerm = useSelector(getSearchTerm)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsProjectOutdated(false))
    dispatch(setSearchTerm(''))
  }, [])

  const renderRelatedProjects = (): JSX.Element[] => {
    const applyFilter = (project: Collection): boolean => {
      if (searchTerm.length !== 0) {
        return project.title.toLowerCase().includes(searchTerm.toLowerCase())
      }

      return true
    }

    const mapProjects = (project: Collection): JSX.Element => {
      return <Project project={project} key={project.key} />
    }

    const projectsList: JSX.Element[] = projects.collections
      .filter(applyFilter)
      .sort(sortProjects)
      .map(mapProjects)

    return projectsList
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSearchTerm(e.target.value))
  }

  return (
    <StyledProjectListing>
      {
        projects.collections.length > 0
          ? <div>
            <div className={'top-bar'}>
              <NewProjectButton size={'medium'} />
              <TextField
                variant={'outlined'}
                color={'secondary'}
                className={'search-bar'}
                value={searchTerm}
                disabled={loading}
                placeholder={'Search projects'}
                onChange={handleOnChange}
                InputProps={{
                  startAdornment: <CustomSearchIcon className={'search-icon'}/>
                }}
                inputProps={{
                  className: 'input-field'
                }}
              />
            </div>
            {renderRelatedProjects()}
          </div>
          : <>
            <StyledSearchResults className={`projects ${loading ? 'loading' : ''}`} />
            {
              !loading &&
            <Grid container justifyContent={'center'} spacing={4}>
              <Grid item>
                <NewProjectButton />
              </Grid>
            </Grid>
            }
          </>
      }
    </StyledProjectListing>
  )
}

export default ProjectsListing
