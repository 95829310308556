import {
  clearSearchSegment,
  getSearchSegmentTrack,
  setSearchSegmentOffsetAndLimit,
  setSearchSegmentTrack
} from '@aims-store/search'
import { computeDefaultSegment, setAudioPlayHeadPosition } from '@aims-track/lib'
import { setSegmentOffsetAndLimit, setSegmentTrack } from '@aims-store/playlist-builder'
import { useDispatch, useSelector } from 'react-redux'

import { DetailedTrack } from '@aims-track/types'
import { LinkInfo } from '../../../../lib/linkInfo/types'
import { createHiddenPlayer } from '../../../../lib/embedPlayer'
import { createSilentAudio } from 'create-silent-audio'
import { pause } from '@aims-store/player'

interface SegmentFunctions {
  clear: () => void
  seekAudio: (audio: HTMLAudioElement, track: DetailedTrack) => void
  seekFile: (file: File) => void
  seekLink: (link: string) => Promise<void>
  seekFileLink: (link: string) => void
  seekPlaylistFile: (file: File, id: string) => void
  seekPlaylistLink: (link: string, id: string) => Promise<void>
}

export const useSegment = (): SegmentFunctions => {
  const dispatch = useDispatch()
  const currentTrack = useSelector(getSearchSegmentTrack)

  const didTrackChange = (track: DetailedTrack): boolean =>
    currentTrack === null || track.id !== currentTrack.id || track.idClient !== currentTrack.idClient

  const clear = (): void => {
    dispatch(clearSearchSegment())
    dispatch(pause())
  }

  const seekLoadedAudio = (audio: HTMLAudioElement, track: DetailedTrack): void => {
    const { offset, duration } = computeDefaultSegment(audio.duration)
    setAudioPlayHeadPosition(audio, false, track)
    dispatch(setSearchSegmentTrack(track, audio))
    dispatch(setSearchSegmentOffsetAndLimit(offset, duration, false))
  }

  const seekPlaylistLoadedAudio = (audio: HTMLAudioElement, track: DetailedTrack, id: string): void => {
    const { offset, duration } = computeDefaultSegment(audio.duration)
    dispatch(setSegmentTrack(track, audio, id))
    dispatch(setSegmentOffsetAndLimit(offset, duration, id))
  }

  const seekAudio = (audio: HTMLAudioElement, track: DetailedTrack): void => {
    if (didTrackChange(track)) {
      clear()
    }
    if (isNaN(audio.duration)) {
      audio.addEventListener('loadeddata', () => seekLoadedAudio(audio, track))
      audio.load()
    } else {
      seekLoadedAudio(audio, track)
    }
  }

  const seekFile = (
    file: File,
    onLoadedData: (audio: HTMLAudioElement, track: DetailedTrack) => void = (audio, track) => seekLoadedAudio(audio, track)
  ): void => {
    const audio = new Audio(URL.createObjectURL(file))
    const audioDuration = isNaN(audio.duration) ? undefined : audio.duration
    const track: DetailedTrack = {
      id: -1 * file.size,
      idClient: file.name,
      duration: audioDuration,
      trackName: file.name
    } as unknown as DetailedTrack
    if (didTrackChange(track)) {
      clear()
    }
    audio.onloadeddata = (): void => onLoadedData(audio, track)

    if (audioDuration !== undefined && audioDuration > 900) {
      throw new Error('Media too long.')
    }
  }

  const seekLink = async (
    link: string,
    onLoadedData: (audio: HTMLAudioElement, track: DetailedTrack) => void = (audio, track) => seekLoadedAudio(audio, track)
  ): Promise<void> => {
    const track: DetailedTrack = {
      id: generateRandomIdForLinkTrack(link),
      idClient: link,
      trackName: link
    } as unknown as DetailedTrack

    if (!didTrackChange(track)) {
      return
    }

    clear()

    // get track duration
    const infoResponse = await fetch('/api/links/info', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url: link })
    })
    if (!infoResponse.ok) {
      const text = await infoResponse.text()
      throw new Error(text)
    }
    const info: LinkInfo = await infoResponse.json()

    if (info.duration > 900) {
      throw new Error('Media too long.')
    }

    let hiddenPlayer = false
    if (info.audioUrl === null) {
      // create fake audio
      info.audioUrl = createSilentAudio(info.duration)
      hiddenPlayer = true
    }
    const audio = new Audio(info.audioUrl)
    audio.onloadeddata = (): void => onLoadedData(audio, track)
    if (hiddenPlayer) {
      // create hidden iframe player and tie it to the fake audio
      createHiddenPlayer(link, info, audio)
    }
  }

  const seekFileLink = async (link: string): Promise<void> => {
    const track: DetailedTrack = {
      id: -1 * link.length,
      idClient: link,
      trackName: link
    } as unknown as DetailedTrack
    if (didTrackChange(track)) {
      clear()
    }
    const response = await fetch(link)
    const data = await response.blob()
    const audio = new Audio(URL.createObjectURL(data))
    audio.onloadeddata = (): void => seekLoadedAudio(audio, track)
  }

  const seekPlaylistFile = (file: File, id: string): void =>
    seekFile(file, (audio: HTMLAudioElement, track: DetailedTrack): void => seekPlaylistLoadedAudio(audio, track, id))

  const seekPlaylistLink = async (link: string, id: string): Promise<void> =>
    await seekLink(link, (audio: HTMLAudioElement, track: DetailedTrack): void => seekPlaylistLoadedAudio(audio, track, id))

  const generateRandomIdForLinkTrack = (trackIdClient: DetailedTrack['idClient']): number => {
    let val = 1
    for (let i = 0; i < trackIdClient.length; i++) {
      const charVal = trackIdClient.charCodeAt(i)
      const multiply = 100 * (i + 1) / trackIdClient.length
      val += multiply * charVal
    }

    return -1 * Math.round(val)
  }

  return {
    clear,
    seekFile,
    seekLink,
    seekAudio,
    seekFileLink,
    seekPlaylistFile,
    seekPlaylistLink
  }
}
