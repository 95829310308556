import { ScreenSize, media } from '@aims-theme'

import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledHeadline = styled(Typography)`&& {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 1.8rem;
  margin-left: 1rem;
  text-transform: uppercase;
  ${media(ScreenSize.Small)} {
    font-size: 2.125rem;
  }
}`

export default StyledHeadline
