export const BUILD_BEGIN = 'DATA_PLAYLIST_BUILDER_BUILD_BEGIN'
export const BUILD_SUCCESS = 'DATA_PLAYLIST_BUILDER_BUILD_SUCCESS'
export const BUILD_ERROR = 'DATA_PLAYLIST_BUILDER_BUILD_ERROR'
export const ADD_SEED = 'DATA_PLAYLIST_BUILDER_ADD_SEED'
export const REMOVE_SEED = 'DATA_PLAYLIST_BUILDER_REMOVE_SEED'
export const SET_TYPE = 'DATA_PLAYLIST_BUILDER_SET_TYPE'
export const SET_LENGTH = 'DATA_PLAYLIST_BUILDER_SET_LENGTH'
export const PICK_SEGMENT = 'DATA_PLAYLIST_BUILDER_PICK_SEGMENT'
export const DISCARD_SEGMENT = 'DATA_PLAYLIST_BUILDER_DISCARD_SEGMENT'
export const SET_FIELD_TEXT = 'DATA_PLAYLIST_BUILDER_SET_FIELD_TEXT'
export const SET_FIELD_FILE = 'DATA_PLAYLIST_BUILDER_SET_FIELD_FILE'
export const SET_SEGMENT_OFFSET_AND_LIMIT = 'DATA_PLAYLIST_BUILDER_SET_SEGMENT_OFFSET_AND_LIMIT'
export const SET_SEGMENT_TRACK = 'DATA_PLAYLIST_BUILDER_SET_SEGMENT_TRACK'
