import {
  ADD_SEED,
  BUILD_BEGIN,
  BUILD_ERROR,
  BUILD_SUCCESS,
  DISCARD_SEGMENT,
  PICK_SEGMENT,
  REMOVE_SEED,
  SET_FIELD_FILE,
  SET_FIELD_TEXT,
  SET_LENGTH,
  SET_SEGMENT_OFFSET_AND_LIMIT,
  SET_SEGMENT_TRACK,
  SET_TYPE
} from '../action-types'
import {
  BuildSuccessAction,
  PickSegmentAction,
  PlaylistBuilderState,
  RemoveSeedAction,
  SetFieldFileAction,
  SetFieldTextAction,
  SetLengthAction,
  SetSegmentOffsetAndLimitAction,
  SetSegmentTrackAction,
  SetTypeAction
} from '../types'
import {
  PlaylistBuilderSeedProps,
  PlaylistType
} from '@aims-playlist-builder/types'

import { Action } from 'redux'
import { computeDefaultSegment } from '@aims-track/lib'
import { v4 as uuid } from 'uuid'

const createSeed = (): PlaylistBuilderSeedProps => ({
  id: uuid(),
  fields: {
    text: '',
    id: null,
    file: null
  },
  segment: {
    limit: -1,
    offset: -1,
    audio: null,
    track: null,
    changed: false
  },
  showSegment: false
})

const initialState: PlaylistBuilderState = {
  loading: false,
  results: {
    queryId: null,
    tracks: [],
    playlist: {
      length: 0,
      duration: 0
    }
  },
  seeds: [createSeed()],
  length: 10,
  type: PlaylistType.Regular
}

const reducer = (state = initialState, action: Action = { type: undefined }): PlaylistBuilderState => {
  switch (action.type) {
    case BUILD_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        results: initialState.results
      })
    case BUILD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        results: (action as BuildSuccessAction).results
      })
    case BUILD_ERROR:
      return Object.assign({}, state, {
        loading: false
      })
    case ADD_SEED:
      return Object.assign({}, state, {
        seeds: [...state.seeds, createSeed()]
      })
    case REMOVE_SEED:
      return Object.assign({}, state, {
        seeds: state.seeds.filter(seed => seed.id !== (action as RemoveSeedAction).id)
      })
    case SET_LENGTH:
      return Object.assign({}, state, {
        length: (action as SetLengthAction).length
      })
    case SET_TYPE:
      return Object.assign({}, state, {
        type: (action as SetTypeAction).playlistType,
        seeds: (action as SetTypeAction).playlistType === PlaylistType.Transition
          ? [...state.seeds, createSeed()].slice(0, 2) // always show two seeds (add one if only one, remove if more)
          : state.seeds
      })
    case PICK_SEGMENT:
      return Object.assign({}, state, {
        seeds: state.seeds.map(seed =>
          seed.id === (action as PickSegmentAction).id
            ? { ...seed, showSegment: true }
            : seed
        )
      })
    case DISCARD_SEGMENT:
      return Object.assign({}, state, {
        seeds: state.seeds.map(seed =>
          seed.id === (action as PickSegmentAction).id
            ? {
              ...seed,
              showSegment: false,
              segment: { ...seed.segment, ...computeDefaultSegment(seed.segment.audio?.duration ?? 0) }
            }
            : seed
        )
      })
    case SET_FIELD_TEXT:
      return Object.assign({}, state, {
        seeds: state.seeds.map(seed =>
          seed.id === (action as SetFieldTextAction).id
            ? { ...seed, fields: { ...seed.fields, id: null, file: null, text: (action as SetFieldTextAction).text } }
            : seed
        )
      })
    case SET_FIELD_FILE:
      return Object.assign({}, state, {
        seeds: state.seeds.map(seed =>
          seed.id === (action as SetFieldFileAction).id
            ? { ...seed, fields: { ...seed.fields, id: null, file: (action as SetFieldFileAction).file } }
            : seed
        )
      })
    case SET_SEGMENT_OFFSET_AND_LIMIT:
      return Object.assign({}, state, {
        seeds: state.seeds.map(seed =>
          seed.id === (action as SetFieldFileAction).id
            ? {
              ...seed,
              segment: {
                ...seed.segment,
                offset: (action as SetSegmentOffsetAndLimitAction).offset,
                limit: (action as SetSegmentOffsetAndLimitAction).limit,
                changed: true
              }
            }
            : seed
        )
      })
    case SET_SEGMENT_TRACK:
      return Object.assign({}, state, {
        seeds: state.seeds.map(seed =>
          seed.id === (action as SetFieldFileAction).id
            ? {
              ...seed,
              segment: {
                ...seed.segment,
                track: (action as SetSegmentTrackAction).track,
                audio: (action as SetSegmentTrackAction).audio
              }
            }
            : seed
        )
      })
    default:
      return state
  }
}

export default reducer
