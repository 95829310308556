import { PlayButton, TrackWaveform } from '@aims-track/components'
import React, {
  FormEventHandler,
  ChangeEvent as ReactChangeEvent,
  MouseEvent as ReactMouseEvent,
  useState
} from 'react'
import {
  VolumeDownOutlined,
  VolumeMuteOutlined,
  VolumeOffOutlined,
  VolumeUp,
  VolumeUpOutlined
} from '@material-ui/icons'
import {
  changeVolume,
  getPlayerAudio,
  getPlayerTrack,
  getPlayerTrackType,
  getPlayerVolume,
  isPlaying,
  pause,
  play
} from '@aims-store/player'
import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@material-ui/core'
import { StyledPlayer } from '../styled'
import { StyledSlider } from '@aims-controls'
import { decorateTime } from '@aims-lib'
import { useHotkeys } from 'react-hotkeys-hook'

const Player = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const playedTrack = useSelector(getPlayerTrack)
  const playedTrackType = useSelector(getPlayerTrackType)
  const playerAudio = useSelector(getPlayerAudio)
  const playerVolume = useSelector(getPlayerVolume)
  const playing = useSelector(isPlaying)
  const loaded = playerAudio !== null && playerAudio.duration > 0
  const [currentTime, setCurrentTime] = useState(0)

  const toggleTrack = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent): void => {
    event.preventDefault()
    dispatch(playing ? pause() : play())
  }

  const handleChangeVolume = (event: ReactChangeEvent<Record<string, unknown>>, value: number | number[]): void => {
    dispatch(changeVolume(value as number))
  }

  const filterOutInputElements = (event: KeyboardEvent): boolean => {
    const target = event.target as HTMLElement | null
    if (target === null) {
      return true
    }
    return !['input', 'textarea'].includes(target.tagName)
  }

  const getVolumeIcon = (): JSX.Element =>
    playerVolume > 0 ? (
      playerVolume > 0.9 ? (
        <VolumeUp fontSize={'small'} color={'action'} />
      ) : playerVolume > 0.66 ? (
        <VolumeUpOutlined fontSize={'small'} color={'action'} />
      ) : playerVolume > 0.33 ? (
        <VolumeDownOutlined fontSize={'small'} color={'action'} />
      ) : (
        <VolumeMuteOutlined fontSize={'small'} color={'action'} />
      )
    ) : (
      <VolumeOffOutlined fontSize={'small'} color={'action'} />
    )

  useHotkeys('space', toggleTrack, { filter: filterOutInputElements })

  if (playerAudio !== null) {
    playerAudio.ontimeupdate = (event: Event): void => {
      setCurrentTime((event.target as HTMLAudioElement).currentTime)
    }
  }

  const albumCodeText = playedTrack === null ? 'Missing album code' : playedTrack.albumCode

  return playedTrack !== null && playerAudio !== null ? (
    <StyledPlayer>
      <p className={'title'}>{playedTrack.trackName}</p>
      <p className={'metadata'}>{albumCodeText}</p>
      <div className={'volume-control'}>
        <Grid container wrap={'nowrap'} spacing={2} alignItems={'center'}>
          <Grid item xs>
            <StyledSlider
              value={playerVolume}
              onChange={
                handleChangeVolume as ((
                  event: ReactChangeEvent<Record<string, unknown>>,
                  value: number | number[]
                ) => void) &
                FormEventHandler<HTMLSpanElement>
              }
              min={0}
              max={1}
              step={0.05}
            />
          </Grid>
          <Grid item>{getVolumeIcon()}</Grid>
        </Grid>
      </div>
      <Grid container alignItems={'center'} wrap={'nowrap'}>
        <PlayButton track={playedTrack} loaded={loaded} playable item xs onClick={toggleTrack} />
        <TrackWaveform track={playedTrack} loaded={loaded} audio={playerAudio} trackType={playedTrackType} />
        <div className={'duration'}>
          {decorateTime(currentTime)}/
          {decorateTime(isNaN(playerAudio.duration) ? playedTrack.duration : playerAudio.duration)}
        </div>
      </Grid>
    </StyledPlayer>
  ) : null
}

export default Player
