import { Button, LoaderButtonTextual, TextField } from '@aims-controls'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  createNewProject,
  getIsLoading,
  getIsNewProjectCreated,
  getNewProjectTitle,
  setNewProjectCreated,
  setNewProjectTitle
} from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { NewProjectButtonProps } from '@aims-project/types'
import { StyledTrackDialog } from '@aims-track/styled'

const NewProjectButton = ({ size = 'large', variant = 'outlined' }: NewProjectButtonProps): JSX.Element => {
  const dispatch = useDispatch()
  const loading = useSelector(getIsLoading)
  const created = useSelector(getIsNewProjectCreated)
  const title = useSelector(getNewProjectTitle)
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (created) {
      setSuccess(true)
      setTimeout(() => {
        handleClose()
        dispatch(setNewProjectCreated(false))
      }, 1000)
    }
  }, [created])

  const handleNewProject = (): void => {
    dispatch(createNewProject())
  }

  const handleProjectTitle = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setNewProjectTitle(event.target.value))
  }

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
    setSuccess(false)
    dispatch(setNewProjectTitle(''))
  }

  return (
    <>
      <LoaderButtonTextual
        text={{
          default: '+ New Project',
          loading: 'Creating...',
          success: 'Created'
        }}
        disabled={loading}
        success={false}
        loading={loading && title.length > 0}
        variant={variant}
        onClick={handleOpen}
        size={size}
        color={'secondary'}
      />
      <StyledTrackDialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant={'h4'} component={'p'}>
            New project
          </Typography>
        </DialogTitle>
        <DialogContent className={'full'}>
          <TextField
            label="Enter new project name"
            placeholder="Project name"
            value={title}
            variant={'outlined'}
            color={'secondary'}
            onChange={handleProjectTitle}
            autoFocus
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <LoaderButtonTextual
            text={{
              default: 'Create',
              loading: 'Creating...',
              success: 'Created'
            }}
            disabled={loading}
            success={success}
            loading={loading}
            variant={'contained'}
            onClick={handleNewProject}
            size={'medium'}
            color={'secondary'}
          />
          <Button size={'medium'} variant={'text'} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </StyledTrackDialog>
    </>
  )
}

export default NewProjectButton
