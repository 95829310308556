import { DropZone, DropZoneChildrenProps, TrackSearchBar } from '@aims-controls'
import React, { FormEvent, useEffect, useRef, useState } from 'react'
import {
  clearSearchSegment,
  didSeedChange,
  getFiltersKey,
  getSearchError,
  getSearchFieldsText,
  getSearchSegmentAudio,
  getSearchSegmentLimit,
  getSearchSegmentOffset,
  getSearchSegmentTrack,
  isLoading,
  refreshFiltersKey,
  resolveCurrentSearchType,
  search,
  setPagination,
  setSearchFieldsFile,
  setSearchFieldsText,
  setSearchModifiersIgnoreVocals,
  setSearchModifiersPrioritiseBpm,
  setSearchSegmentOffsetAndLimit
} from '@aims-store/search'
import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@material-ui/core'
import SearchButton from './SearchButton'
import SearchFilters from './SearchFilters'
import SearchModifiers from './SearchModifiers'
import SearchResults from './SearchResults'
import { SearchType } from '@aims-search/types'
import SegmentSelectionTool from '../SegmentSelectionTool'
import { StyledMonthlyLimit } from '@aims-auto-tagging/styled'
import StyledSearchPane from './SearchPane.styled'
import { UserIdentity } from '@aims-auth/types'
import { getRemainingMonthlyRequests } from '@aims-playlist-plugging/api'
import { getUser } from '@aims-store/auth'
import md5 from 'md5'
import usePlaceholder from './usePlaceholder'
import { useSegment } from '@aims-search/hooks'

const SearchPane = (): JSX.Element => {
  const dispatch = useDispatch()
  const loading = useSelector(isLoading)
  const offset = useSelector(getSearchSegmentOffset)
  const limit = useSelector(getSearchSegmentLimit)
  const audio = useSelector(getSearchSegmentAudio)
  const track = useSelector(getSearchSegmentTrack)
  const currentSearchType = useSelector(resolveCurrentSearchType)
  const searchText = useSelector(getSearchFieldsText)
  const searchError = useSelector(getSearchError) ?? ''
  const seedChanged = useSelector(didSeedChange)
  const user = useSelector(getUser) as UserIdentity
  const filtersKey = useSelector(getFiltersKey)
  const formRef = useRef<HTMLFormElement>(null)
  const { seekFile, seekLink, seekFileLink } = useSegment()
  const [failedSegment, setFailedSegment] = useState(false)
  const [filtersActive, setFiltersActive] = useState(false)
  const [remainingRequestsChecked, setRemainingRequestsChecked] = useState(false)
  const [remainingMonthlyRequests, setRemainingMonthlyRequests] = useState(0)
  const [totalRequests, setTotalRequests] = useState(0)
  const { placeholder, prompts } = usePlaceholder(user)

  const updateRemainingRequests = async (): Promise<void> => {
    const { remainingRequests, total } = await getRemainingMonthlyRequests(user)
    setRemainingRequestsChecked(true)
    setRemainingMonthlyRequests(remainingRequests)
    setTotalRequests(total)
  }

  useEffect((): void => {
    if (user.textSearch && (!remainingRequestsChecked || (!loading && currentSearchType === SearchType.TextPrompt))) {
      void updateRemainingRequests() // eslint-disable-line no-void
    }
  }, [loading, currentSearchType, user.textSearch])

  const handleOnSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    if (!loading) {
      await searchForTracks(event.target as HTMLFormElement)
    }
  }

  const searchForTracks = async (form: HTMLFormElement): Promise<void> => {
    let filterData = new FormData(form)
    setFailedSegment(false)
    if (seedChanged) {
      // clear segment to avoid getting out of bound for the new seed
      resetSearch()
      filterData = new FormData()
    }

    dispatch(search(currentSearchType, filterData))

    try {
      if (currentSearchType === SearchType.Link) {
        await seekLink(searchText)
      } else if (currentSearchType === SearchType.FileLink) {
        seekFileLink(searchText)
      }
    } catch (err) {
      setFailedSegment(true)
    }
  }

  const handleOnChange = (newValue: string): void => {
    setFailedSegment(false)
    dispatch(setSearchFieldsText(newValue))
  }

  const handleOnReset = (): void => {
    dispatch(refreshFiltersKey())
  }

  const acceptedCallback = (file: File): void => {
    setFailedSegment(false)
    // clear segment to avoid getting out of bound for the new seed
    resetSearch()
    dispatch(setSearchFieldsText(file.name))
    dispatch(setSearchFieldsFile(file))
    dispatch(search(SearchType.Upload, new FormData(formRef.current ?? undefined)))
    seekFile(file)
  }

  const resetSearch = (): void => {
    dispatch(clearSearchSegment())
    dispatch(setSearchModifiersPrioritiseBpm(false))
    dispatch(setSearchModifiersIgnoreVocals(false))
    dispatch(setPagination({ page: 1, pageSize: 10 }))
    handleOnReset()
  }

  const onSegmentSelect = (offset: number, limit: number): void => {
    dispatch(setSearchSegmentOffsetAndLimit(offset, limit, true))
  }

  const handleOnKeyDown = async (): Promise<void> => {
    if (formRef.current === null || loading) {
      return
    }

    await searchForTracks(formRef.current)
  }

  return (
    <StyledSearchPane onSubmit={handleOnSubmit} onReset={handleOnReset} ref={formRef}>
      <div className={'top-panel'}>
        <div className={'search-area'}>
          <DropZone className={'track-search-bar'} acceptedCallback={acceptedCallback} disabled={user.public}>
            {({ open, isDragActive }: DropZoneChildrenProps) => (
              <TrackSearchBar
                textFieldValue={searchText}
                disabled={loading}
                onChange={handleOnChange}
                placeholder={placeholder}
                placeholderPrompts={prompts}
                isDragActive={isDragActive}
                open={open}
                textSearchEnabled={user.textSearch}
                onKeyDown={handleOnKeyDown}
                isPublic={user.public}
              />
            )}
          </DropZone>
          <SearchButton />
        </div>
        <div className={'modifiers'}>
          <Grid item>
            <SearchModifiers />
          </Grid>
          {
            user.textSearch &&
            <Grid item>
              <StyledMonthlyLimit className={'active'}>
                <span>
                  <big>{remainingRequestsChecked ? remainingMonthlyRequests : '-'}</big>/{totalRequests}
                </span>
                prompt searches remaining this month. <a href="mailto:info@aimsapi.com">Get more credits</a>
              </StyledMonthlyLimit>
            </Grid>
          }
        </div>
        {
          [SearchType.Internal, SearchType.Upload, SearchType.Link, SearchType.FileLink].includes(
            currentSearchType
          ) && (
            <SegmentSelectionTool
              key={`segment-${md5(searchText)}`}
              loading={loading}
              track={track}
              audio={audio}
              offset={offset}
              limit={limit}
              failed={failedSegment}
              failedMessage={searchError}
              onSegmentSelect={onSegmentSelect}
              showApplyButton
              seedType={currentSearchType}
              seedText={searchText}
            />
          )}
        <SearchFilters
          active={filtersActive}
          setActive={setFiltersActive}
          key={filtersKey}
        />
      </div>
      <SearchResults />
    </StyledSearchPane>
  )
}

export default SearchPane
