import { hexToRGBA } from '@aims-lib'
import styled from 'styled-components'

const StyledProjectDetailPage = styled.div`
  &.loading-project {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  & .tracks-container {
    margin-top: 2.5rem;

    & .results-label {
      font-size: 1.2rem;
      font-weight: 600;
      color: ${props => props.theme.colors.black};
      text-transform: uppercase;
    }

    & .tracks {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  & .unprocessed {
    user-select: none;
    display: flex;
    flex-direction: column;
    margin: 6rem 0 2rem;
    gap: 2rem;
    align-items: center;

    & .MuiTypography-root {
      text-align: center;
      max-width: 52rem;
      color: ${props => hexToRGBA(props.theme.colors.primary, 0.3)}
    }
  }
`

export default StyledProjectDetailPage
