import { APPLY_FILTER_BUTTON_ID, SEARCH_FILTERS_ID } from '@aims-search/components'
import {
  clearSearchSegment,
  getExtraFilterIndex,
  getSearchFieldsId,
  incrementExtraFilterIndex,
  isLoading,
  refreshFiltersKey,
  search,
  setPagination,
  setSearchFieldsId,
  setSearchFieldsText,
  setSearchModifiersIgnoreVocals,
  setSearchModifiersPrioritiseBpm
} from '@aims-store/search'
import { feedback, feedbackSimilar } from '@aims-store/feedback'
import { useDispatch, useSelector } from 'react-redux'

import { LoaderButtonTextual } from '@aims-controls'
import React from 'react'
import { SearchType } from '@aims-search/types'
import { StyledFindSimilarButton } from '@aims-track/styled'
import { TrackProps } from '@aims-track/types'
import { getSearchFilters } from '@aims-store/auth'
import { useRouter } from 'next/router'
import { useSegment } from '@aims-search/hooks'

const FindSimilarButton = ({ track, audio }: TrackProps & { audio: HTMLAudioElement }): JSX.Element => {
  const dispatch = useDispatch()
  const loading = useSelector(isLoading)
  const id = useSelector(getSearchFieldsId)
  const filters = useSelector(getSearchFilters)
  const extraFilterIndex = useSelector(getExtraFilterIndex)
  const { seekAudio } = useSegment()
  const router = useRouter()

  const handleFindSimilar = (): void => {
    void router.push('/') // eslint-disable-line no-void
    dispatch(setSearchFieldsText(`track: ${track.trackName}`))
    dispatch(setSearchFieldsId(track.id as number))
    dispatch(clearSearchSegment())
    dispatch(setSearchModifiersPrioritiseBpm(false))
    dispatch(setSearchModifiersIgnoreVocals(false))
    dispatch(setPagination({ page: 1, pageSize: 10 }))
    dispatch(refreshFiltersKey())
    dispatch(search(SearchType.Internal, new FormData()))
    if (typeof (track.queryId) === 'string') {
      dispatch(feedback(feedbackSimilar(track.idClient), track.queryId))
    }
    seekAudio(audio, track)
  }

  const handleHideArtist = (): void => {
    const hideArtistIndex = 2 + filters.length * 2 + extraFilterIndex
    const formFilter = document.getElementById(SEARCH_FILTERS_ID) as HTMLDivElement
    const applyButton = document.getElementById(APPLY_FILTER_BUTTON_ID) as HTMLButtonElement

    const hideArtistField = document.createElement<'input'>('input')
    hideArtistField.type = 'hidden'
    hideArtistField.name = `conditions[${hideArtistIndex}][field]`
    hideArtistField.value = 'artists'

    const hideArtistOperator = document.createElement<'input'>('input')
    hideArtistOperator.type = 'hidden'
    hideArtistOperator.name = `conditions[${hideArtistIndex}][operator]`
    hideArtistOperator.value = 'not-contains'

    const hideArtistValue = document.createElement<'input'>('input')
    hideArtistValue.type = 'hidden'
    hideArtistValue.name = `conditions[${hideArtistIndex}][value]`
    hideArtistValue.value = track.artists.join(',')

    formFilter.appendChild(hideArtistField)
    formFilter.appendChild(hideArtistOperator)
    formFilter.appendChild(hideArtistValue)

    dispatch(incrementExtraFilterIndex())
    applyButton.click()
  }

  const trackIsRemote = (): boolean => {
    return track.idClient.startsWith('file:') || track.idClient.startsWith('url:')
  }

  return (
    <StyledFindSimilarButton>
      {typeof (track.queryId) === 'string' && router.pathname === '/' && track.artists.length > 0 && (
        <span className={'hide-artist'} onClick={handleHideArtist}>
          hide artist
        </span>
      )}
      <LoaderButtonTextual
        text={{
          default: 'Find similar',
          loading: 'Searching...',
          success: 'See results'
        }}
        disabled={loading || trackIsRemote()}
        success={!loading && track.id === id}
        loading={loading && track.id === id}
        variant={'outlined'}
        onClick={handleFindSimilar}
        size={'medium'}
        color={'secondary'}
      />
    </StyledFindSimilarButton>
  )
}

export default FindSimilarButton
