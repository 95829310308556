import { getIsLoading, getIsProjectOutdated, refreshSuggestions } from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { LoaderButtonTextual } from '@aims-controls'
import ProjectWatcherStickyBarStyled from './ProjectWatcherStickyBar.styled'
import React from 'react'

const ProjectWatcherStickyBar = (): JSX.Element => {
  const dispatch = useDispatch()
  const projectOutdated = useSelector(getIsProjectOutdated)
  const loading = useSelector(getIsLoading)

  const handleRefreshSuggestions = (): void => {
    dispatch(refreshSuggestions())
  }

  return (
    <ProjectWatcherStickyBarStyled className={projectOutdated ? '' : 'hidden'}>
      <LoaderButtonTextual
        color={'secondary'}
        loading={loading}
        success={false}
        disabled={loading}
        size={'medium'}
        type={'button'}
        text={{ default: 'Refresh Suggestions', loading: 'Refreshing', success: '' }}
        onClick={handleRefreshSuggestions}
      />
    </ProjectWatcherStickyBarStyled>
  )
}

export default ProjectWatcherStickyBar
