import { CUE_SHEET_DISPLAY, CUE_SHEET_EXPORT, DOWNLOAD, PLAY, SIMILAR, THUMBS_DOWN, THUMBS_UP } from './action-types'
import {
  CueSheetDisplayAction,
  CueSheetExportAction,
  DownloadAction,
  FeedbackAction,
  PlayAction,
  SimilarAction,
  ThumbsDownAction,
  ThumbsUpAction
} from './types'

import { Dispatch } from 'redux'
import { State } from '@aims-app-store'
import { UserIdentity } from '@aims-auth/types'
import { enqueue } from '@aims-layout'
import { getUser } from '@aims-store/auth'
import { submitFeedback } from '@aims-feedback/lib'

const FEEDBACK_DOWNLOAD = 'track_download'
const FEEDBACK_SIMILAR = 'track_similar'
const FEEDBACK_LIKE = 'track_like'
const FEEDBACK_DISLIKE = 'track_dislike'
const FEEDBACK_PLAY = 'track_play'
const FEEDBACK_CUE_SHEET_DISPLAY = 'track_display_cue_sheet_info'
const FEEDBACK_CUE_SHEET_EXPORT = 'track_export_cue_sheet_info'

const FEEDBACK_TYPE_MAPPING: { [key: string]: string } = {
  [THUMBS_UP]: FEEDBACK_LIKE,
  [THUMBS_DOWN]: FEEDBACK_DISLIKE,
  [DOWNLOAD]: FEEDBACK_DOWNLOAD,
  [SIMILAR]: FEEDBACK_SIMILAR,
  [PLAY]: FEEDBACK_PLAY,
  [CUE_SHEET_DISPLAY]: FEEDBACK_CUE_SHEET_DISPLAY,
  [CUE_SHEET_EXPORT]: FEEDBACK_CUE_SHEET_EXPORT
}

export const feedbackThumbsUp = (idClient: string): ThumbsUpAction => ({
  type: THUMBS_UP,
  idClient
})

export const feedbackThumbsDown = (idClient: string): ThumbsDownAction => ({
  type: THUMBS_DOWN,
  idClient
})

export const feedbackDownload = (idClient: string): DownloadAction => ({
  type: DOWNLOAD,
  idClient
})

export const feedbackSimilar = (idClient: string): SimilarAction => ({
  type: SIMILAR,
  idClient
})

export const feedbackPlay = (idClient: string): PlayAction => ({
  type: PLAY,
  idClient
})

export const feedbackCueSheetDisplay = (idClient: string): CueSheetDisplayAction => ({
  type: CUE_SHEET_DISPLAY,
  idClient
})

export const feedbackCueSheetExport = (idClient: string): CueSheetExportAction => ({
  type: CUE_SHEET_EXPORT,
  idClient
})

export const feedback = (action: FeedbackAction, queryId: string): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const type = FEEDBACK_TYPE_MAPPING[action.type]
      const idClient = action.idClient
      dispatch(action)
      await submitFeedback({ user, queryId, idClient, type })
    } catch (err) {
      const error = err as Error
      dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
    }
  }
