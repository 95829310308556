import { ApiFilter, ApiFilterCondition } from '@aims-search/types'
import { CollectionPagination, CollectionResults } from '@aims-collection/types'
import { GetPlaylistsProps, GetPlaylistsResponse, PlaylistItem } from '@aims-playlist-plugging/types'
import nodeFetch, { HeadersInit as NodeHeadersInit } from 'node-fetch'

import NodeFormData from 'form-data'
import { ReadStream } from 'fs'
import { UserIdentity } from '@aims-auth/types'
import { convertKeysToCamelCase } from '@aims-search/lib'
import { convertObjectToFormData } from '@aims-lib'
import { reformatResults } from '@aims-collection/lib'

const getHeadersNext = (userId: string|undefined, apiSecret: string|undefined, contentType: string|null = null): HeadersInit => {
  const headers: HeadersInit = {
    Authorization: apiSecret ?? '',
    'X-Requested-With': 'XMLHttpRequest',
    'X-User-Id': userId ?? ''
  }

  if (contentType !== null) {
    headers['Content-Type'] = contentType
  }

  return headers
}

const getPlaylistsByFile = async (user: UserIdentity, file: File, filters: ApiFilter|null, pagination: CollectionPagination, hash: string|null): Promise<GetPlaylistsResponse> => {
  const { apiSecret, apiHost, id } = user
  const { page, pageSize } = pagination
  const method = 'POST'
  const headers: HeadersInit = {
    Authorization: user.apiSecret,
    'X-Requested-With': 'XMLHttpRequest',
    'X-User-Id': user.id
  }

  const body = convertObjectToFormData({
    filter: filters,
    id: id,
    apiSecret: apiSecret,
    apiHost: apiHost,
    page,
    pageSize,
    hash: hash,
    type: 'by-file',
    trackName: file.name
  })

  body.append('track', file as Blob)

  const rawResponse = await fetch('/api/playlist-plugging/playlists/get-playlists?detailed=true', { headers, method, body })

  if (!rawResponse.ok) {
    try {
      const errorResponse = await rawResponse.json()
      throw new Error(errorResponse.message)
    } catch (error) {
      throw new Error('preload error')
    }
  }

  return await rawResponse.json()
}

const getPlaylistsByFileNext = async (props: GetPlaylistsProps): Promise<GetPlaylistsResponse> => {
  const { user, payload, filters, page, pageSize, hash, trackName = '' } = props
  const tfilters = filters as Record<string, string>
  const { id, apiSecret, apiHost = '' } = user
  const method = 'POST'
  const uriHasHash = hash !== null
  const uri = `${apiHost}/v1/playlist/plug/by-file${uriHasHash ? '-hash' : ''}?detailed=true`
  const headers: NodeHeadersInit = { ...getHeadersNext(id, apiSecret) } as unknown as NodeHeadersInit
  const body = new NodeFormData()
  const track = payload as ReadStream

  body.append('page', page)
  body.append('page_size', pageSize)

  if (uriHasHash) {
    body.append('hash', hash)
  } else {
    body.append('track', track)
  }

  if (typeof tfilters !== 'undefined') {
    Object.keys(tfilters).forEach(key => {
      body.append(key, tfilters[key])
    })
  }

  const response = await nodeFetch(uri, { headers, method, body })

  let newHash: string|null = null

  if (!uriHasHash) {
    const responseHash = response.headers.get('X-Hash')

    if (responseHash !== null && responseHash.length > 0) {
      newHash = responseHash
    }
  }

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }

  const json = await response.json()
  const playlistsRaw = reformatResults<CollectionResults>(json).collections
  const playlists: PlaylistItem[] = Object.values(
    convertKeysToCamelCase<PlaylistItem[]>(playlistsRaw as unknown as Record<string, unknown>)
  )

  return {
    playlistItems: playlists,
    hashData: {
      value: newHash,
      trackName: trackName
    }
  }
}

const getPlaylistsByUrl = async (user: UserIdentity, url: string, filters: ApiFilter|null, pagination: CollectionPagination, hash: string|null): Promise<GetPlaylistsResponse> => {
  const method = 'POST'
  const headers: HeadersInit = {
    Authorization: user.apiSecret,
    'X-Requested-With': 'XMLHttpRequest',
    'X-User-Id': user.id,
    'Content-Type': 'application/json'
  }
  const modifiedFilters = filters
  const { page, pageSize } = pagination
  const { apiSecret, apiHost, id } = user

  if (modifiedFilters !== null && filters !== null) {
    modifiedFilters.conditions = filters.conditions.map(condition => {
      const tempCondition = condition as ApiFilterCondition
      if (tempCondition.field !== 'contact') return condition
      tempCondition.value = null
      return tempCondition
    }) as ApiFilterCondition[]
  }

  const body = JSON.stringify({
    link: encodeURI(url),
    detailed: true,
    filter: modifiedFilters,
    id: id,
    apiSecret: apiSecret,
    apiHost: apiHost,
    page,
    pageSize,
    hash,
    type: 'by-url'
  })

  const rawResponse = await fetch('/api/playlist-plugging/playlists/get-playlists?detailed=true', { headers, method, body })

  if (!rawResponse.ok) {
    try {
      const errorResponse = await rawResponse.json()
      throw new Error(errorResponse.message)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  }

  return await rawResponse.json()
}

const getPlaylistsByUrlNext = async (props: GetPlaylistsProps): Promise<GetPlaylistsResponse> => {
  const { user, payload, filters, page, pageSize } = props
  const { apiSecret, apiHost, id } = user
  const method = 'POST'
  const uriHasHash = props.hash !== null
  const headers = getHeadersNext(id, apiSecret, 'application/json')
  const body = { link: payload, page: page, page_size: pageSize, hash: props.hash }

  let newHash: string|null = null

  if (filters !== null) {
    Object.assign(body, { filter: filters })
  }

  const response = await fetch(`${apiHost ?? ''}/v1/playlist/plug/by-url${uriHasHash ? '-hash' : ''}?detailed=true`, { headers, method, body: JSON.stringify(body) })

  if (!uriHasHash) {
    const responseHash = response.headers.get('X-Hash')

    if (responseHash !== null && responseHash.length > 0) {
      newHash = responseHash
    }
  }

  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }

  const json = await response.json()
  const reformatedResponse = reformatResults<CollectionResults>(json)

  const playlistsRaw = reformatedResponse.collections
  const playlists: PlaylistItem[] = Object.values(
    convertKeysToCamelCase<PlaylistItem[]>(playlistsRaw as unknown as Record<string, unknown>)
  )

  return {
    playlistItems: playlists,
    hashData: {
      value: newHash,
      trackName: payload as string
    }
  }
}

export {
  getPlaylistsByFile,
  getPlaylistsByFileNext,
  getPlaylistsByUrl,
  getPlaylistsByUrlNext
}
