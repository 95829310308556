import { DetailedTrack, TrackField, TrackHighlights } from '@aims-track/types'

import { UserIdentity } from '@aims-auth/types'

const createDetailedTracksCSV = (tracks: DetailedTrack[], metadata: UserIdentity['metadata']): string => {
  const csvTrackValues = metadata.map((m: string): string => `"${m}"`).join(',')

  let csv = csvTrackValues + '\n'

  tracks.forEach((item: DetailedTrack): void => {
    metadata.forEach((trackValue: TrackField, index: number): void => {
      const key = trackValue as keyof DetailedTrack
      const trackValueItem = item[key]

      let csvAddition = ''

      if (trackValueItem !== null && typeof (trackValueItem) === 'object') {
        if (Array.isArray(trackValueItem)) {
          csvAddition = trackValueItem.map((val: string|TrackHighlights): string => {
            if (typeof (val) === 'string') {
              return `${val}`
            }
            return `duration: ${val.duration}, offset: ${val.offset}${val.editable !== undefined ? `, editable: ${val.editable.toString()}` : ''}`
          }).join(',')
        }
      } else if (trackValueItem !== undefined && trackValueItem !== null) {
        csvAddition = trackValueItem.toString()
      }

      csv = csv + `"${csvAddition}"` + (index === metadata.length - 1 ? '' : ',')
    })

    csv = csv + '\n'
  })

  return csv
}

export default createDetailedTracksCSV
