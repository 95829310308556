import { fire, getMessages } from '../store'
import { useDispatch, useSelector } from 'react-redux'

import { SnackbarMessageData } from '../types'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'

const SnackbarContainer = (): null => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const messages: SnackbarMessageData[] = useSelector(getMessages)

  useEffect(() => {
    const lastMessage = messages.slice(-1)[0]
    if (typeof lastMessage !== 'undefined' && !lastMessage.fired) {
      const { message, options } = lastMessage
      enqueueSnackbar(message, options)
      dispatch(fire(lastMessage.id))
    }
  }, [messages])

  return null
}

export default SnackbarContainer
