import { Collection } from '@aims-collection/types'
import DeleteProjectButton from '../DeleteProjectButton'
import EditProjectButton from '../EditProjectButton'
import { Grid } from '@material-ui/core'
import React from 'react'
import ShareProjectButton from './ShareProjectButton'

const ProjectActions = ({ project }: { project: Collection }): JSX.Element => {
  return (
    <Grid container justifyContent={'flex-end'}>
      <EditProjectButton project={project} />
      <DeleteProjectButton project={project} />
      <ShareProjectButton project={project} />
      {/* {project.processedAt !== null &&
        downloadEnabled && ( // todo: download needs to download per track (add some non-obtrusive UI with progress per track)
        <Tooltip title={'Download'}>
          <IconButton onClick={() => alert('todo: download')} className={'action-download-project'}>
            <CustomDownloadIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Grid>
  )
}

export default ProjectActions
