import { ScreenSize, media, theme } from '@aims-theme'

import { hexToRGBA } from '@aims-lib'
import styled from 'styled-components'

const searchIconErrorColor = hexToRGBA(theme.colors.crimson, 0.7)

const StyledTrackSearchBar = styled.div<{error: string, promptSwapInterval: number}>`
  position: relative;
  display: flex;
  gap: 0.875rem;
  border: 0.125rem solid ${props => props.theme.colors.grey.light};
  border-radius: 0.625rem;
  background-color: #fff;
  font-size: 1.25rem;
  padding: 0.4375rem 0.9375rem;
  align-items: center;
  min-height: 3.625rem;

  &.focused, &:focus {
    border-color: ${props => props.theme.colors.secondary};
    background: ${props => `linear-gradient(45deg, ${props.theme.colors.white} 0%, ${hexToRGBA(props.theme.colors.secondary, 0.02)} 100%)`};

    & .adornment {
      color: ${props => props.error.length > 0 ? searchIconErrorColor : props.theme.colors.secondary};
    }
  }

  &.error {
    border-color: ${props => props.theme.colors.crimson};
    margin-bottom: 1.8rem;

    &:after {
      content: "${(props) => props.error}";
      position: absolute;
      bottom: -1.5rem;
      font-size: 0.9rem;
      color: ${props => props.theme.colors.crimson};
    }
  }

  .adornment {
    display: flex;
    color: ${props => props.error.length > 0 ? searchIconErrorColor : props.theme.colors.grey.medium};
  }

  & .search-bar {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    & .track-search-input {
      width: 100%;
      background-color: none;

      & .MuiInputBase-input {
        padding: 0;
        font-weight: 700;
        font-size: 1.25rem;
        user-select: none;
      }

      & input::placeholder {
        color: transparent;
      }

      & input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    & .custom-placeholder {
      position: absolute;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      left: 0;
      color: ${props => props.theme.colors.grey.medium};
      font-size: 1.25rem;
      user-select: none;
      pointer-events: none;

      & span {
        opacity: 1;
        transition: opacity 0.5s;
      }

      & span.transparent {
        opacity: 0;
        transition: opacity 0.5s;
      }
    }
  }

  .upload-button {
    display: none;
    background-color: transparent;

    ${media(ScreenSize.Small)} {
      display: block;
      margin-left: auto;
      box-sizing: content-box;
      color: ${props => props.theme.colors.secondary};
      font-size: 1.2rem;
      font-weight: ${props => props.theme.font.medium};
      text-decoration: underline;
      text-transform: none;

      :hover, :focus {
        background-color: white;
        color: ${props => props.theme.colors.secondaryHover};
      }
    }
  }
`

export default StyledTrackSearchBar
