import { AlbumState as ArtistState, LoadSuccessAction } from '../types'
import {
  LOAD_BEGIN,
  LOAD_ERROR,
  LOAD_SUCCESS
} from '../action-types'

import { Action } from 'redux'

const initialState: ArtistState = {
  loading: false,
  artist: {
    id: '',
    key: '',
    title: '',
    processedAt: null,
    updatedAt: null,
    queryId: null,
    spotifyData: null
  },
  tracks: {
    pagination: {
      itemCount: 0,
      page: 0,
      pageSize: 0,
      pageCount: 0
    },
    tracks: []
  },
  results: {
    queryId: null,
    collections: []
  }
}

const reducer = (state = initialState, action: Action = { type: undefined }): ArtistState => {
  switch (action.type) {
    case LOAD_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        artist: initialState.artist,
        tracks: initialState.tracks,
        results: initialState.results
      })
    case LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        artist: (action as LoadSuccessAction).artist,
        tracks: (action as LoadSuccessAction).tracks,
        results: (action as LoadSuccessAction).results
      })
    case LOAD_ERROR:
      return Object.assign({}, state, {
        loading: false
      })
    default:
      return state
  }
}

export default reducer
