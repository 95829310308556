import styled from 'styled-components'

const StyledMetadataItem = styled.span`
  color: ${props => props.theme.colors.grey.medium};
  padding-right: 2rem;

  .description {
    color: ${props => props.theme.colors.black};
    font-weight: ${props => props.theme.font.medium};
    margin-left: 0.6rem;
  }

  &.explicit {
    border: 0.1rem solid ${props => props.theme.colors.grey.dark};
    border-radius: 0.125rem;
    color: ${props => props.theme.colors.grey.dark};
    font-size: 0.8rem;
    font-weight: ${props => props.theme.font.medium};
    height: 1.3rem;
    padding: 0 0.25rem;
  }
`

export default StyledMetadataItem
