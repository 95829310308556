import colors from '../colors'
import createPalette from '@material-ui/core/styles/createPalette'

const palette = createPalette({
  primary: { main: colors.primary },
  secondary: { main: colors.secondary },
  error: { main: colors.warning.red },
  warning: { main: colors.warning.desaturated },
  info: { main: colors.yellow },
  success: { main: colors.green }
})

export default palette
