import { UsePlaceholderHook } from '@aims-search/types'
import { UserIdentity } from '@aims-auth/types'

const placeholderPrompts = [
  'Echoes in the halls of a medieval castle.',
  "Bustling Elizabethan tavern, backdrop to Shakespeare's writing.",
  'Daring escape from a pirate ship.',
  'High-stakes, dark action shooting scene.',
  'Soothing a dragon to sleep.',
  'Alien dance-off in a sci-fi club.',
  'Suspenseful vampire hunt on a moonlit night.',
  'Crowning of an ancient king.',
  "Gladiator's triumphant victory in a Roman arena.",
  'Roaring Twenties speakeasy mood.',
  'High-speed chase through a neon cityscape.',
  'Peaceful walk through a Zen garden.',
  'Climactic mountain summit at dawn.',
  'Solemn knightly vigil in a gothic cathedral.',
  'Lively Oktoberfest celebration.'
]

const usePlaceholder = (user: UserIdentity): UsePlaceholderHook => {
  const prompts = user.textSearch ? placeholderPrompts : undefined
  const placeholder = user.public ? (
    user.textSearch ? 'Try a prompt like' : 'Search track name, artist or ISRC.'
  ) : (
    user.textSearch ? 'Search, paste a link, or try a prompt like' : 'Add YouTube, Spotify, TikTok, or SoundCloud link. Or search track name, artist or ISRC.'
  )

  return {
    placeholder,
    prompts
  }
}

export default usePlaceholder
