import { Button, Tooltip } from '@aims-controls'
import { DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import React, { Dispatch, SetStateAction, useState } from 'react'

import { ApiFilterCondition } from '@aims-search/types'
import CustomLyricsIcon from '../../../../icons/component/CustomLyricsIcon'
import { StyledTrackDialog } from '@aims-track/styled'
import { TrackProps } from '@aims-track/types'
import { getLastSearchState } from '@aims-store/search'
import { useSelector } from 'react-redux'

const TrackLyrics = ({ track }: TrackProps): JSX.Element => {
  const lastSearchState = useSelector(getLastSearchState)
  const searchTermArray =
    (lastSearchState?.filters?.conditions as ApiFilterCondition[])
      ?.filter((condition) => condition.field === 'lyrics')
      ?.map((condition) => condition.value) ?? []
  const searchTerm = searchTermArray.length > 0 ? searchTermArray[0] : null
  const [modalOpen, setModalOpen]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean(false))

  const toggleModal = (): void => {
    setModalOpen(!modalOpen)
  }

  return (
    <>
      <Tooltip title={'Lyrics'}>
        <IconButton onClick={toggleModal} className={'action-lyrics'}>
          <CustomLyricsIcon />
        </IconButton>
      </Tooltip>
      <StyledTrackDialog
        open={modalOpen}
        maxWidth={'sm'}
        fullWidth
        onClose={toggleModal}
      >
        <DialogTitle>
          <Typography variant={'h4'} component={'p'}>
            <CustomLyricsIcon fontSize={'large'} />
            {track.trackName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {track.lyrics?.split(/\r?\n/i).map((line, index) => (
            <span key={`line-${index}`}>
              {searchTerm !== null
                ? line.split(new RegExp(searchTerm, 'i')).map((segment, index) => (
                  <>
                    {index > 0 ? <em className={'lyric-highlight'}>{searchTerm}</em> : ''}
                    {segment}
                  </>
                ))
                : line}
              <br />
            </span>
          ))}
        </DialogContent>
        <DialogActions>
          <Button size={'medium'} variant={'text'} onClick={toggleModal}>
            Close
          </Button>
        </DialogActions>
      </StyledTrackDialog>
    </>
  )
}

export default TrackLyrics
