import React from 'react'
import ReactMarkdown from 'react-markdown'

const extractEmails = (text: string | null): string[] | null => {
  if (text === null) {
    return []
  }
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
}

const HypertextLinkEmail = ({ text }: {text: string | null}): JSX.Element => {
  let output = ''
  const emails = extractEmails(text)
  emails?.forEach((email: string) => {
    output = text?.replace(email, `[${email}](mailto:${email})`) ?? ''
  })

  return <ReactMarkdown>{output}</ReactMarkdown>
}

export default HypertextLinkEmail
