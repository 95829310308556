import { DetailedTrack, GenericTrack, NestedMasterTrack } from '@aims-track/types'

import { v4 } from 'uuid'

const isFromURLSourceRegExp = new RegExp('^url:')

const getTracksAsNested = (tracks: DetailedTrack[]): GenericTrack[] => {
  const applyNestingToTrack = (track: DetailedTrack, versions: DetailedTrack[]): GenericTrack => {
    const nestedMasterTrack: NestedMasterTrack = {
      masterTrack: track,
      tracksList: versions
    }

    const singular = nestedMasterTrack.tracksList.length === 0 ? track : undefined
    const nested = nestedMasterTrack.tracksList.length > 0 ? nestedMasterTrack : undefined

    return {
      singular,
      nested
    }
  }

  const nestedTracks: GenericTrack[] = []

  const groupsById: {[key: string]: DetailedTrack[]} = {}
  const indices: string[] = []

  // group by ID (masterTrackId -> idClient)
  tracks.forEach((track: DetailedTrack, index: number): void => {
    const { albumCode, masterTrackId, masterTrackNumber, idClient, id } = track
    const key = `${albumCode ?? v4()}:${masterTrackId ?? masterTrackNumber ?? idClient}`
    indices[index] = id.toString()
    if (!Array.isArray(groupsById[key])) {
      groupsById[key] = []
    }
    groupsById[key].push(track)
  })

  // remove groups with a single track
  const groupKeysToKeep = Object.keys(groupsById).filter(key => groupsById[key].length > 1)
  const groups: {[key: string]: DetailedTrack[]} = {}
  groupKeysToKeep.forEach(key => {
    groups[key] = groupsById[key]
  })

  // remove tracks that are already grouped
  tracks = tracks.filter(
    track => Object.keys(groups).filter(
      key => groups[key].filter(groupTrack => groupTrack.id === track.id).length === 0
    ).length === Object.keys(groups).length
  )

  // group by track number (masterTrackNumber -> trackNumber)
  tracks.forEach((track: DetailedTrack): void => {
    const { albumCode, masterTrackNumber, trackNumber } = track
    const key = `${albumCode ?? v4()}:${masterTrackNumber ?? trackNumber ?? v4()}`
    if (!Array.isArray(groups[key])) {
      groups[key] = []
    }
    groups[key].push(track)
  })

  // sort and output tracks (sorting needs to be performed since two independent grouping attempts were made)
  indices.forEach((id: string): void => {
    const groupKeys = Object.keys(groups).filter(key => groups[key].filter(track => track.id.toString() === id).length > 0)
    if (groupKeys.length === 0) {
      return
    }
    const groupKey = groupKeys[0]
    const tracks = groups[groupKey].sort((trackA, trackB): number => {
      return indices.indexOf(trackA.id.toString()) > indices.indexOf(trackB.id.toString()) ? 1 : -1
    })
    const main = tracks.splice(0, 1)[0]
    const trackWithNestingApplied = applyNestingToTrack(main, tracks)
    nestedTracks.push(trackWithNestingApplied)
    delete groups[groupKey] // eslint-disable-line @typescript-eslint/no-dynamic-delete
  })

  return nestedTracks
}

const trackIsFromRemoteSource = (track: DetailedTrack|null): boolean => {
  let isFromRemoteSource = false

  if (track?.idClient !== undefined) {
    isFromRemoteSource = isFromURLSourceRegExp.test(track.idClient)
  }

  return isFromRemoteSource
}

export {
  getTracksAsNested,
  trackIsFromRemoteSource
}
