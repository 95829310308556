import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledFooter = styled.footer`
  align-items: center;
  bottom: 0;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  display: flex;
  height: ${props => props.theme.layout.footer.heightMobile};
  width: 100%;
  
  ${media(ScreenSize.Small)} {
    height: ${props => props.theme.layout.footer.height};
  }
  p {
    font-size: 0.7rem;
    max-width: 7rem;
    ${media(ScreenSize.Small)} {
      font-size: 100%;
      max-width: none;
    }
  }
  .social {
    color: ${props => props.theme.colors.white};
    margin-right: 1rem;
    ${media(ScreenSize.Small)} {
      margin-right: 1.5rem;
    }
    &:last-child {
      margin-right: 0;
    }
    svg {
      font-size: 1.85rem;
      ${media(ScreenSize.Small)} {
        font-size: 2.5rem;
      }
    }
  }
  .suppliers {
    display: flex;
    a {
      background: ${props => props.theme.colors.white};
      border-radius: 0.3rem;
      display: flex;
      margin-right: 1rem;
    }
    img {
      height: 2rem;
      padding: 0.25rem;
      width: auto;
      ${media(ScreenSize.Small)} {
        height: 4rem;
      }
    }
  }
`

export default StyledFooter
