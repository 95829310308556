export const LOGIN_BEGIN = 'DATA_AUTH_LOGIN_BEGIN'
export const LOGIN_SUCCESS = 'DATA_AUTH_LOGIN_SUCCESS'
export const LOGIN_ERROR = 'DATA_AUTH_LOGIN_ERROR'
export const LOGOUT = 'DATA_AUTH_LOGOUT'
export const SET_API_CONFIGURATION = 'DATA_AUTH_SET_API_CONFIGURATION'
export const SET_AUTO_TAGGING_TOKEN = 'DATA_AUTH_SET_AUTO_TAGGING_TOKEN'
export const DECREMENT_AUTO_TAGGING_REQUESTS_REMAINING = 'DATA_AUTH_DECREMENT_AUTO_TAGGING_REQUESTS_REMAINING'
export const REQUEST_RESET_BEGIN = 'DATA_AUTH_REQUEST_RESET_BEGIN'
export const REQUEST_RESET_SUCCESS = 'DATA_AUTH_REQUEST_RESET_SUCCESS'
export const REQUEST_RESET_ERROR = 'DATA_AUTH_REQUEST_RESET_ERROR'
export const RESET_BEGIN = 'DATA_AUTH_RESET_BEGIN'
export const RESET_SUCCESS = 'DATA_AUTH_RESET_SUCCESS'
export const RESET_ERROR = 'DATA_AUTH_RESET_ERROR'
export const MAGIC_LOGIN_BEGIN = 'DATA_AUTH_MAGIC_LOGIN_BEGIN'
export const MAGIC_LOGIN_ERROR = 'DATA_AUTH_MAGIC_LOGIN_ERROR'
export const SET_IS_LOADING = 'DATA_AUTH_SET_IS_LOADING'
export const SET_PUBLIC_USER = 'DATA_AUTH_SET_PUBLIC_USER'
export const SET_SEARCH_FILTER_FIELDS = 'DATA_AUTH_SET_SEARCH_FILTER_FIELDS'
