import {
  CLEAR_PRELOADED_RESULTS,
  CLEAR_SEGMENT,
  INCREMENT_EXTRA_FILTER_INDEX,
  LOAD_MORE_BEGIN,
  LOAD_MORE_ERROR,
  LOAD_MORE_SUCCESS,
  REFRESH_FILTERS_KEY,
  RESET_FILTERS,
  SEARCH_BEGIN,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
  SET_FIELDS_FILE,
  SET_FIELDS_ID,
  SET_FIELDS_TEXT,
  SET_FILTERS,
  SET_IS_LOADING,
  SET_LAST_SEARCH_STATE,
  SET_MODIFIERS_IGNORE_VOCALS,
  SET_MODIFIERS_PRIORITISE_BPM,
  SET_PAGINATION,
  SET_PRELOADED_PROMPT_RESULTS,
  SET_PRELOADED_RESULTS,
  SET_PROMPT_RESULTS,
  SET_RESULTS,
  SET_SEARCH_HASH,
  SET_SEGMENT_OFFSET_AND_LIMIT,
  SET_SEGMENT_TRACK
} from './action-types'
import {
  LoadMoreSuccessAction,
  SearchErrorAction,
  SearchState,
  SearchSuccessAction,
  SetLastSearchStateAction,
  SetLoadingAction,
  SetPaginationAction,
  SetPreloadedPromptsResultsAction,
  SetPreloadedResultsAction,
  SetPromptResultsAction,
  SetResultsAction,
  SetSearchFieldsFileAction,
  SetSearchFieldsIdAction,
  SetSearchFieldsTextAction,
  SetSearchFiltersAction,
  SetSearchHashAction,
  SetSearchModifiersIgnoreVocalsAction,
  SetSearchModifiersPrioritiseBpmAction,
  SetSearchSegmentOffsetAndLimitAction,
  SetSearchSegmentTrackAction
} from './types'
import initialState, { DEFAULT_FILTERS_KEY } from './initialState'

import { Action } from 'redux'
import { v4 } from 'uuid'

const reducer = (state = initialState, action: Action = { type: undefined }): SearchState => {
  switch (action.type) {
    case SEARCH_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        results: initialState.results,
        promptResults: initialState.promptResults
      })
    case SEARCH_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        results: (action as SearchSuccessAction).payload.results,
        preloadedResults: (action as SearchSuccessAction).payload.preloadedResults ?? initialState.preloadedResults,
        error: null
      })
    case SEARCH_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: (action as SearchErrorAction).payload.message
      })
    case SET_SEARCH_HASH:
      return Object.assign({}, state, {
        searchHash: (action as SetSearchHashAction).payload.hash
      })
    case SET_FIELDS_TEXT:
      return Object.assign({}, state, {
        fields: Object.assign({}, state.fields, {
          text: (action as SetSearchFieldsTextAction).text,
          file: null,
          id: null
        })
      })
    case SET_FIELDS_FILE:
      return Object.assign({}, state, {
        fields: Object.assign({}, state.fields, {
          file: (action as SetSearchFieldsFileAction).file,
          id: null
        })
      })
    case SET_FIELDS_ID:
      return Object.assign({}, state, {
        fields: Object.assign({}, state.fields, {
          id: (action as SetSearchFieldsIdAction).id,
          file: null
        })
      })
    case SET_FILTERS:
      return Object.assign({}, state, {
        filters: (action as SetSearchFiltersAction).payload.filters
      })
    case RESET_FILTERS:
      return Object.assign({}, state, {
        fields: initialState.fields,
        filters: initialState.filters,
        results: initialState.results,
        promptResults: initialState.promptResults
      })
    case SET_MODIFIERS_PRIORITISE_BPM:
      return Object.assign({}, state, {
        modifiers: Object.assign({}, state.modifiers, {
          prioritiseBpm: (action as SetSearchModifiersPrioritiseBpmAction).checked
        })
      })
    case SET_MODIFIERS_IGNORE_VOCALS:
      return Object.assign({}, state, {
        modifiers: Object.assign({}, state.modifiers, {
          ignoreVocals: (action as SetSearchModifiersIgnoreVocalsAction).checked
        })
      })
    case SET_LAST_SEARCH_STATE:
      return Object.assign({}, state, {
        lastSearchState: (action as SetLastSearchStateAction).state
      })
    case SET_PRELOADED_RESULTS:
      return Object.assign({}, state, {
        preloadedResults: (action as SetPreloadedResultsAction).payload.preloadedResults
      })
    case CLEAR_PRELOADED_RESULTS:
      return Object.assign({}, state, {
        preloadedResults: initialState.preloadedResults,
        preloadedPromptResults: initialState.preloadedPromptResults
      })
    case SET_SEGMENT_OFFSET_AND_LIMIT:
      return Object.assign({}, state, {
        segment: Object.assign({}, state.segment, {
          offset: (action as SetSearchSegmentOffsetAndLimitAction).offset,
          limit: (action as SetSearchSegmentOffsetAndLimitAction).limit,
          changed: (action as SetSearchSegmentOffsetAndLimitAction).changed
        })
      })
    case SET_SEGMENT_TRACK:
      return Object.assign({}, state, {
        segment: Object.assign({}, state.segment, {
          track: (action as SetSearchSegmentTrackAction).track,
          audio: (action as SetSearchSegmentTrackAction).audio
        })
      })
    case CLEAR_SEGMENT:
      return Object.assign({}, state, {
        segment: initialState.segment
      })
    case LOAD_MORE_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        pagination: Object.assign({}, state.pagination, {
          page: state.pagination.page + 1
        })
      })
    case LOAD_MORE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        results: Object.assign({}, state.results, {
          tracks: [...state.results.tracks, ...(action as LoadMoreSuccessAction).results.tracks]
        })
      })
    case LOAD_MORE_ERROR:
      return Object.assign({}, state, {
        loading: false
      })
    case REFRESH_FILTERS_KEY:
      return Object.assign({}, state, {
        filtersKey: v4(),
        extraFilterIndex: initialState.extraFilterIndex
      })
    case INCREMENT_EXTRA_FILTER_INDEX:
      return Object.assign({}, state, {
        extraFilterIndex: state.extraFilterIndex + 1
      })
    case SET_PAGINATION:
      return Object.assign({}, state, {
        pagination: (action as SetPaginationAction).payload.pagination
      })
    case SET_IS_LOADING:
      return Object.assign({}, state, {
        loading: (action as SetLoadingAction).payload.loading
      })
    case SET_RESULTS:
      return Object.assign({}, state, {
        results: Object.assign({}, state.results, {
          tracks: [...state.results.tracks, ...(action as SetResultsAction).payload.results.tracks]
        })
      })
    case SET_PROMPT_RESULTS:
      return Object.assign({}, state, {
        promptResults: Object.assign({}, state.promptResults, {
          tracks: [...state.promptResults.tracks, ...(action as SetPromptResultsAction).payload.promptResults.tracks]
        })
      })
    case SET_PRELOADED_PROMPT_RESULTS:
      return Object.assign({}, state, {
        preloadedPromptResults: (action as SetPreloadedPromptsResultsAction).payload.preloadedPromptResults
      })
    default:
      return state
  }
}

export { initialState, DEFAULT_FILTERS_KEY }
export default reducer
