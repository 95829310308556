import { DetailedTrack, TrackField } from '@aims-track/types'
import React, { useEffect, useState } from 'react'
import { UserScope, UserToken } from '@aims-auth/types'

import { ApiFilter } from '@aims-search/types'
import { Cancel as CancelIcon } from '@material-ui/icons'
import { ExternalTrack } from '@aims-track/components/Track/ExternalTrack'
import ProjectHead from './ProjectHead'
import { ProjectSnapshot } from '@aims-project/types'
import ProjectSnapshotTrack from './ProjectSnapshotTrack'
import StyledProjectSnapshotPage from './ProjectSnapshotPage.styled'
import { StyledTrack } from '@aims-track/styled'
import { Typography } from '@material-ui/core'
import { convertKeysToCamelCase } from '@aims-search/lib'
import { setLoading } from '@aims-store/project'
import { setPublicUser } from '@aims-store/auth'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'

const ProjectSnapshotPage = (): JSX.Element => {
  const router = useRouter()
  const [snapshotLoaded, setSnapshotLoaded] = useState<boolean>(false)
  const [snapshot, setSnapshot] = useState<ProjectSnapshot|null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const id = router.query.uuid

    if (id === undefined) {
      return
    }

    const dataFetchAsync = async (): Promise<void> => {
      dispatch(setLoading(true))

      /* eslint-disable */
      const responseRaw = await fetch(`https://api.aimsapi.com/v1/snapshot/${id}`)
      let response = await responseRaw.json()

      if (response.code !== undefined) {
        setSnapshot(null)
        setSnapshotLoaded(true)
        return
      }

      /* eslint-enable */

      const snapshotResponse = convertKeysToCamelCase<{snapshot: ProjectSnapshot}>(response).snapshot

      dispatch(setPublicUser({
        albums: false,
        apiHost: '',
        apiSecret: '',
        autoTagging: false,
        autoTaggingSecret: '',
        autoTaggingToken: {} as unknown as UserToken,
        branding: {
          header: {},
          footer: {}
        },
        cdn: true,
        download: snapshotResponse.downloadable,
        downloadFilenameTemplate: null,
        email: '',
        filters: [],
        textSearch: false,
        highlights: false,
        id: '',
        ignoreVocals: false,
        licensingInfo: '',
        metadata: snapshotResponse.metadata as unknown as TrackField[],
        multipleDownloadVersions: false,
        nestVersions: false,
        playlists: false,
        predefinedFilter: {} as unknown as ApiFilter,
        prioritiseBpm: false,
        projects: false,
        showThumbButtons: false,
        showPluggingContact: false,
        streamingHash: '',
        streamingSecret: snapshotResponse.streamingSecret,
        token: {} as unknown as UserToken,
        tutorialData: [],
        userScope: {} as unknown as UserScope,
        public: false
      }))

      setSnapshot(snapshotResponse)
      setSnapshotLoaded(true)
      dispatch(setLoading(false))
    }

    void dataFetchAsync() // eslint-disable-line no-void
  }, [router])

  const renderSnapshot = (): JSX.Element => {
    if (!snapshotLoaded) {
      return <></>
    }

    if (snapshot === null) {
      return (
        <>
          <CancelIcon className={'cancel-icon'}/>
          <Typography className={'header'} variant={'h5'}>
            Sorry, this link is not valid or the project has been deleted.
          </Typography>
          <Typography variant={'h5'}>
            Make sure you copied the link correctly. If you believe something is broken, please contact us at <a href={'mailto:support@aimsapi.com'}>support@aimsapi.com</a>.
          </Typography>
        </>
      )
    }

    return (
      <>
        <ProjectHead snapshot={snapshot} />
        {snapshot.tracks.map((track: DetailedTrack): JSX.Element => {
          const externalTrack = track.idClient.startsWith('file:') || track.idClient.startsWith('url:')
          let trackItem = <></>

          if (externalTrack) {
            trackItem = <ExternalTrack track={track} key={`snapshot-track-${track.id}`} />
          } else {
            trackItem = <ProjectSnapshotTrack track={{ ...track, queryId: null }} />
          }

          return (
            <StyledTrack key={`snapshot-track-${track.id}`} >
              {trackItem}
            </StyledTrack>
          )
        })}
      </>
    )
  }

  return (
    <StyledProjectSnapshotPage className={snapshot !== null ? '' : 'missing-snapshot'}>
      {renderSnapshot()}
    </StyledProjectSnapshotPage>
  )
}

export default ProjectSnapshotPage
