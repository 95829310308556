import { Grid } from '@material-ui/core'
import styled from 'styled-components'

const StyledPlayButton = styled(Grid)`
  flex-grow: 0;
  padding-right: 1rem;
  button {
    &.MuiButton-outlined {
      padding: 0.35rem 0;
      min-width: 3.2rem;
    }
  }
  .success {
    pointer-events: auto;
  }
`

export default StyledPlayButton
