import { AuthState } from './types'

const initialState: AuthState = {
  loading: false,
  filterFields: [],
  user: null,
  apiConfiguration: null,
  autoTaggingConfiguration: null,
  logout: false,
  resetRequested: false,
  resetCompleted: false,
  error: false
}

export default initialState
