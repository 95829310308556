import { ScreenSize, media } from '@aims-theme'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`

const StyledSearchResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &:empty {
    height: 14rem;
    opacity: 0.25;
    position: relative;
    text-align: center;
    ${media(ScreenSize.Small)} {
      height: 40rem;
    }
    &.loading {
      opacity: 1;
      &::after {
        animation: ${pulse} ${props => props.theme.transitions.duration.verySlow} ${props => props.theme.transitions.easing.regular} infinite;
        color: ${props => props.theme.colors.secondary};
        content: 'Searching...';
      }
      &::before {
        display: none;
      }
    }
    &.no-results {
      opacity: 1;
      &::after {
        color: ${props => props.theme.colors.black};
        content: 'There are no results for this search. Your filtering criteria is probably too strict';
      }
      &::before {
        display: none;
      }
    }
    &::after {
      content: 'Search or upload a track to find similar ones or simply filter a category to browse through';
      display: block;
    }
    &::before {
      content: '';
      display: block;
    }
  }

  &::after {
    color: ${props => props.theme.colors.primary};
    display: none;
    font-size: 1.25rem;
    font-weight: ${props => props.theme.font.medium};
    height: 7rem;
    left: 0;
    margin-top: -5rem;
    padding: 0 2rem;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    ${media(ScreenSize.Small)} {
      height: 8rem;
      font-size: 2.2rem;
      margin-top: -8rem;
      padding: 0 12rem;
    }
  }
  &::before {
    background-image: url('/img/header/logo.svg');
    background-size: 17rem 6rem;
    display: none;
    height: 6rem;
    left: 50%;
    margin-left: -3rem;
    opacity: 0.5;
    position: absolute;
    width: 6rem;
    top: 60%;
    ${media(ScreenSize.Small)} {
      background-size: 28rem 10rem;
      height: 10rem;
      margin-left: -5rem;
      top: 50%;
      width: 10rem;
    }
  }

  &.no-keyword-results {
    height: 14rem;
    position: relative;
    text-align: center;
    ${media(ScreenSize.Small)} {
      height: 40rem;
    }
    &::after {
      content: 'No results for your keyword search';
      display: block;
      opacity: 0.25;
    }
    &::before {
      display: none;
    }
  }
  &.no-prompt-results {
    height: 14rem;
    position: relative;
    text-align: center;
    ${media(ScreenSize.Small)} {
      height: 40rem;
    }
    &::after {
      content: 'No results for your prompt search';
      display: block;
      opacity: 0.25;
    }
    &::before {
      display: none;
    }
  }

  .collection-container {
    margin-top: 0.5rem;
    ${media(ScreenSize.Small)} {
      margin-bottom: 5rem;
    }
  }

  .cta-text {
    color: ${props => props.theme.colors.primary};
    font-size: 1.5rem;
    font-weight: ${props => props.theme.font.medium};
    ${media(ScreenSize.Small)} {
      font-size: 2rem;
      padding: 0 12rem;
    }
  }

  & .results-label {
    color: ${props => props.theme.colors.black};
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
  }
`

export default StyledSearchResults
