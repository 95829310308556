import { ScreenSize, media } from '../../../../styled/mixins'

import { Grid } from '@material-ui/core'
import styled from 'styled-components'

const StyledSegmentSelectionTool = styled(Grid)`
  border-top: 0.1rem solid ${props => props.theme.colors.grey.light};
  margin-top: 1rem;
  padding: 1rem 0;
  &.no-border {
    border-top: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
  .waveform-container {
    height: 3rem;
    position: relative;
    background-color: ${props => props.theme.colors.white};
    border: 0.1rem solid ${props => props.theme.colors.grey.light};
    border-radius: 0.3rem;
    margin-right: 1rem;
    &.placeholder {
      color: ${props => props.theme.colors.secondary};
      flex-grow: 1;
      line-height: 3rem;
      text-align: center;
    }
  }
  .MuiTypography-overline {
    color: ${props => props.theme.colors.grey.medium};
    font-size: 0.8rem;
    font-weight: ${props => props.theme.font.medium};
    padding: 0.5rem 0;
    text-transform: uppercase;
    svg {
      margin-bottom: -0.4rem;
      margin-left: 0.5rem;
      .fill {
        fill: ${props => props.theme.colors.secondary};
      }
      .stroke {
        stroke: ${props => props.theme.colors.secondary};
      }
    }
  }
  .apply-button {
    width: 8rem;
  }
  .MuiAlert-standardError {
    width: 100%;
  }
  .segment-row {
    ${media(ScreenSize.Small)} {
      flex-wrap: nowrap;
    }
    & > div:nth-child(3) {
      flex-basis: 100%;
      margin-top: 1rem;
      text-align: center;
      ${media(ScreenSize.Small)} {
        flex-basis: auto;
        margin-top: 0;
      }
    }
  }
`

export default StyledSegmentSelectionTool
