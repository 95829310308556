import React, { useEffect } from 'react'
import { getIsProjectOutdated, getIsRefreshingProject, getProjects, refreshProject, setIsProjectOutdated, setIsRefreshingProject } from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { Collection } from '@aims-collection/types'
import { UserIdentity } from '@aims-auth/types'
import { convertKeysToCamelCase } from '@aims-search/lib'
import { getUser } from '@aims-store/auth'
import { loadProject } from '@aims-project/lib'
import { useRouter } from 'next/router'

const ProjectWatcher = ({ children }: JSX.ElementChildrenAttribute): JSX.Element => {
  const user = useSelector(getUser) as UserIdentity
  const projects = useSelector(getProjects)
  const dispatch = useDispatch()
  const isRefreshingSuggestions = useSelector(getIsRefreshingProject)
  const isOutdated = useSelector(getIsProjectOutdated)
  const interval = 15000
  const router = useRouter()
  const projectKey = router.query.id as string

  useEffect(() => {
    const intervalFunc = (): void => {
      if (isOutdated) {
        return
      }

      const intervalAsync = async (): Promise<void> => {
        if (isRefreshingSuggestions) {
          return
        }

        dispatch(setIsRefreshingProject(true))

        try {
          const projectRaw = await loadProject(projectKey, user)
          const remoteProject = convertKeysToCamelCase<Collection>(projectRaw)
          const project = projects.collections.find((project: Collection) => project.key === projectKey) as Collection
          const projectIsOutdated = remoteProject.processedAt !== project.processedAt && remoteProject.processedAt !== null

          if (projectIsOutdated) {
            dispatch(setIsProjectOutdated(true))
            dispatch(refreshProject(remoteProject))
          }
        } catch (err) {}

        dispatch(setIsRefreshingProject(false))
      }

      void intervalAsync() // eslint-disable-line no-void
    }

    const intervalID = setInterval(intervalFunc, interval) as unknown as number

    return () => {
      clearInterval(intervalID)
    }
  }, [isOutdated])

  return <div>{children}</div>
}

export default ProjectWatcher
