import { ProjectHeadProps } from '@aims-project/types'
import React from 'react'
import StyledProjectHead from './ProjectHead.styled'
import { Typography } from '@material-ui/core'

const ProjectHead = ({ snapshot }: ProjectHeadProps): JSX.Element => {
  const createdAt = new Date(snapshot.createdAt).toLocaleString()

  return (
    <StyledProjectHead>
      <div className={'project-description'}>
        <Typography className={'title'}>{snapshot.title}</Typography>
        <Typography className={'number-of-tracks'} component={'span'}>({`${snapshot.tracks.length} tracks`})</Typography>
      </div>
      <div className={'snapshot'}>
        <Typography className={'from'}>Snapshot from</Typography>
        <Typography className={'date'} component={'span'}>{`${createdAt}`}</Typography>
      </div>
    </StyledProjectHead>
  )
}

export default ProjectHead
