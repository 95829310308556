import { IconButton, Typography } from '@material-ui/core'
import { LinkInfo, LinkType } from '@aims-lib'
import React, { useEffect, useState } from 'react'
import { Tooltip, TrackChipProps } from '@aims-controls'

import { Close } from '@material-ui/icons'
import CustomAppleMusicIcon from 'components/icons/component/CustomAppleMusicIcon'
import CustomFileIcon from 'components/icons/component/CustomFileIcon'
import CustomSoundCloudIcon from 'components/icons/component/CustomSoundCloudIcon'
import CustomSpotifyIcon from 'components/icons/component/CustomSpotifyIcon'
import CustomTikTokIcon from 'components/icons/component/CustomTikTokIcon'
import CustomVimeoIcon from 'components/icons/component/CustomVimeoIcon'
import CustomYoutubeIcon from 'components/icons/component/CustomYoutubeIcon'
import StyledTrackChip from './TrackChip.styled'
import { isSupportedStreamingServiceLink } from 'lib/linkValidator'

const TrackChip = ({ title, onClose, onFailedToLoad, onLoaded }: TrackChipProps): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [linkInfo, setLinkInfo] = useState<LinkInfo|null>(null)
  const [loadFailed, setLoadFailed] = useState(false)

  const linkIsFile = title.endsWith('.mp3') || title.endsWith('.wav')

  const asyncGetLinkInfo = async (): Promise<void> => {
    setLoading(true)

    try {
      const infoResponse = await fetch('/api/links/info', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: title })
      })

      const info: LinkInfo = await infoResponse.json()

      setLinkInfo(info)

      if (onLoaded !== undefined) {
        onLoaded()
      }
    } catch (err) {
      setLinkInfo(null)
      setLoadFailed(true)

      if (onFailedToLoad !== undefined) {
        onFailedToLoad()
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLinkInfo(null)

    const linkIsUrl = isSupportedStreamingServiceLink(title)

    if (!linkIsUrl && !linkIsFile) {
      if (onFailedToLoad != null) {
        setLoadFailed(true)
        onFailedToLoad()
      }

      return
    }

    if (linkIsUrl) {
      void asyncGetLinkInfo() // eslint-disable-line no-void
    }
  }, [title])

  const handleOnClose = (): void => {
    if (onClose != null) {
      onClose()
    }
  }

  const getIcon = (type: LinkInfo['type']|undefined): JSX.Element => {
    if (type === undefined) {
      return <CustomFileIcon />
    }

    switch (type) {
      case LinkType.YouTube:
        return <CustomYoutubeIcon />
      case LinkType.AppleMusic:
        return <CustomAppleMusicIcon />
      case LinkType.SoundCloud:
        return <CustomSoundCloudIcon />
      case LinkType.Spotify:
        return <CustomSpotifyIcon />
      case LinkType.TikTok:
        return <CustomTikTokIcon />
      case LinkType.Vimeo:
        return <CustomVimeoIcon />
      default:
        throw new Error('Icon type not recognized')
    }
  }

  if (loadFailed) {
    return <Typography>Invalid URL</Typography>
  }

  let textComponent = (
    <Typography
      component={'a'}
      target={'_blank'}
      href={title}
      rel={'nofollow noopener noreferrer'}
    >
      {linkInfo?.title}
    </Typography>
  )

  if (loading) {
    textComponent = <Typography>Loading...</Typography>
  } else if (linkIsFile) {
    textComponent = <Typography>{title}</Typography>
  }

  return (
    <StyledTrackChip>
      {getIcon(linkInfo?.type)}
      <Tooltip
        interactive={true}
        className={'title'}
        title={title}
        disableHoverListener={linkIsFile}
      >
        {textComponent}
      </Tooltip>
      {(onClose != null) && (
        <IconButton
          size={'small'}
          onClick={handleOnClose}
          aria-label={'remove track'}
          component={'span'}
        >
          <Close />
        </IconButton>
      )}
    </StyledTrackChip>
  )
}

export default TrackChip
