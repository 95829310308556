import { ScreenSize, media } from '../../../../styled/mixins'

import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledHeaderHeadline = styled(Typography)`&& {
  font-size: 0.9rem;
  margin-left: 8.25rem;
  margin-top: -2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  ${media(ScreenSize.Small)} {
    font-size: 1.25rem;
    margin-left: 11.5rem;
    margin-top: -3.4rem;
  }
}`

export default StyledHeaderHeadline
