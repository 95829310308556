import {
  PlaylistPluggingState,
  SetCurrentLoadAttemptAction,
  SetCurrentPreloadAttemptAction,
  SetFiltersAction,
  SetLastSearchTargetAction,
  SetLoadInProgressAction,
  SetLoadingMoreInProgressAction,
  SetPaginationAction,
  SetPlaylistsAction,
  SetPlaylistsHashAction,
  SetPreloadInProgressAction,
  SetPreloadedPlaylistsAction
} from './types'
import {
  SET_CURRENT_LOAD_ATTEMPT,
  SET_CURRENT_PRELOAD_ATTEMPT,
  SET_FILTERS,
  SET_LAST_SEARCH_TARGET,
  SET_LOAD_IN_PROGRESS,
  SET_LOAD_MORE_IN_PROGRESS,
  SET_PAGINATION,
  SET_PLAYLISTS,
  SET_PLAYLISTS_HASH,
  SET_PRELOADED_PLAYLISTS,
  SET_PRELOAD_IN_PROGRESS
} from './action-types'

import { Action } from 'redux'
import initialState from './initialState'

const reducer = (state = initialState, action: Action = { type: undefined }): PlaylistPluggingState => {
  switch (action.type) {
    case SET_FILTERS:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          filters: (action as SetFiltersAction).payload.filters
        }
      })
    case SET_PAGINATION:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          pagination: (action as SetPaginationAction).payload.pagination
        }
      })
    case SET_PLAYLISTS:
      return Object.assign({}, state, {
        playlists: (action as SetPlaylistsAction).payload.playlists
      })
    case SET_PRELOADED_PLAYLISTS:
      return Object.assign({}, state, {
        preloadedPlaylists: (action as SetPreloadedPlaylistsAction).payload.preloadedPlaylists
      })
    case SET_PLAYLISTS_HASH:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          hash: (action as SetPlaylistsHashAction).payload.hash
        }
      })
    case SET_LOAD_IN_PROGRESS:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          loadInProgress: (action as SetLoadInProgressAction).payload.loadInProgress
        }
      })
    case SET_LOAD_MORE_IN_PROGRESS:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          loadMoreInProgress: (action as SetLoadingMoreInProgressAction).payload.loadMoreInProgress
        }
      })
    case SET_LAST_SEARCH_TARGET:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          lastTarget: (action as SetLastSearchTargetAction).payload.lastTarget
        }
      })
    case SET_PRELOAD_IN_PROGRESS:
      return Object.assign({}, state, {
        search: {
          ...state.search,
          preloadInProgress: (action as SetPreloadInProgressAction).payload.preloadInProgress
        }
      })
    case SET_CURRENT_PRELOAD_ATTEMPT: {
      return Object.assign({}, state, {
        search: {
          ...state.search,
          currentPreloadAttempt: (action as SetCurrentPreloadAttemptAction).payload.currentPreloadAttempt
        }
      })
    }
    case SET_CURRENT_LOAD_ATTEMPT: {
      return Object.assign({}, state, {
        search: {
          ...state.search,
          currentLoadAttempt: (action as SetCurrentLoadAttemptAction).payload.currentLoadAttempt
        }
      })
    }
    default:
      return state
  }
}

export default reducer
