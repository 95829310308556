import React, { ComponentProps } from 'react'

import { AuthHeader } from '../../header'
import { Footer } from '../../footer'
import Head from 'next/head'
import { StyledAuthLayout } from '../styled'

const AuthLayout = ({ children }: ComponentProps<any>): JSX.Element =>
  <StyledAuthLayout>
    <Head>
      <link rel="stylesheet" href="https://use.typekit.net/hol1nen.css" />
      <link rel="icon" href="/favicon.png" />
    </Head>

    <AuthHeader/>
    {children}
    <Footer/>
  </StyledAuthLayout>

export default AuthLayout
