import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledHeader = styled.header`
  border-bottom: 0.1rem solid ${props => props.theme.colors.secondaryLight};
  padding: 1rem 1.5rem 1rem 0;
  
  ${media(ScreenSize.Small)} {
    border-bottom: 0;
    padding-bottom: 2rem;
  }

  &.opened {
    &::before {
      background-color: ${props => props.theme.colors.blackTransparent};
      content: '';
      left: 0;
      position: absolute;
      height: 10000vh;
      top: 6.5rem;
      width: 100vw;
      z-index: 10;
    }
    .current {
      opacity: 0;
    }
  }

  .MuiIconButton-label {
    color: ${props => props.theme.colors.secondary};
    ${media(ScreenSize.Small)} {
      display: none;
    }
  }

  .redirect-to-website {
    display: contents;
    color: inherit;

    &__icon {
      width: 1rem;
      height: 1rem;
      margin: auto 0 auto 0.625rem;
      color: ${props => props.theme.colors.secondary};
    }
  }
`

export default StyledHeader
