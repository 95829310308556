import {
  Collection,
  CollectionPagination,
  CollectionTrackResults,
  CollectionTracks,
  PaginatedCollections
} from '@aims-collection/types'

import { State } from '@aims-app-store'

export const getIsLoading = (state: State): boolean => state.data.project.loading
export const getIsNewProjectCreated = (state: State): boolean => state.data.project.newProject.created
export const getIsProjectOutdated = (state: State): boolean => state.data.project.isProjectOutdated
export const getIsRefreshingProject = (state: State): boolean => state.data.project.isRefreshingProject
export const getNewProjectTitle = (state: State): string => state.data.project.newProject.title
export const getPreloadedSuggestions = (state: State): CollectionTrackResults => state.data.project.preloadedSuggestions
export const getProject = (state: State): Collection => state.data.project.project
export const getProjectTracksPagination = (state: State): CollectionPagination => state.data.project.suggestionsPagination
export const getProjects = (state: State): PaginatedCollections => state.data.project.projects
export const getSearchTerm = (state: State): string => state.data.project.searchTerm
export const getSuggestions = (state: State): CollectionTrackResults => state.data.project.suggestions
export const getTracks = (state: State): CollectionTracks => state.data.project.tracks
