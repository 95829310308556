import React, { useCallback } from 'react'

import { DropZoneProps } from '../../types'
import { StyledDropZone } from '../styled'
import { enqueue } from '@aims-layout'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'

const DropZone = ({ maxFileFize, children, acceptedCallback, className = '', disabled = false }: DropZoneProps): JSX.Element => {
  const dispatch = useDispatch()

  const maxSupportedFileSize = maxFileFize ?? 120_000_000

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach(acceptedCallback)
    }
    if (rejectedFiles.length > 0) {
      dispatch(
        enqueue({
          message: `The specified track is not in a supported format. Use internal tracks specified by ID, links for YouTube, SoundCloud and Vimeo, or MP3 or WAV files under ${maxSupportedFileSize / 1_000_000}MB.`,
          options: { variant: 'error', autoHideDuration: 10000 }
        })
      )
    }
  }, [])

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept: 'audio/mpeg, audio/x-wav, audio/wav',
    maxSize: maxSupportedFileSize,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    disabled
  })

  return (
    <StyledDropZone {...getRootProps()} className={`${className} ${isDragActive ? 'drag-active' : ''}`}>
      <input {...getInputProps()} />
      {children({ open, isDragActive })}
    </StyledDropZone>
  )
}

export default DropZone
