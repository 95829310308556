import styled from 'styled-components'
import { theme } from '@aims-theme'

const InfoMessageStyled = styled.div`
  border-radius: 0.625rem;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  margin: 0 0 1rem 0;

  & .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  &.warning {
    border: 0.1rem solid ${theme.colors.warning.red};
    background-color: ${theme.colors.warning.desaturated};

    & .icon {
      color: ${theme.colors.warning.red};
    }
  }

  &.info {
    border: 0.1rem solid ${theme.colors.secondary};
    background-color: ${theme.colors.secondaryLightest};

    & .icon {
      color: ${theme.colors.secondary};
    }
  }
`
export default InfoMessageStyled
