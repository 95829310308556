import { DetailedTrack } from '@aims-track/types'
import { UserIdentity } from '@aims-auth/types'
import createDetailedTracksCSV from './createDetailedTracksCSV'

const downloadTracksCSV = (name: string, tracks: DetailedTrack[], metadata: UserIdentity['metadata']): void => {
  const csv = createDetailedTracksCSV(tracks, metadata)
  const hiddenElement = document.createElement('a')

  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
  hiddenElement.target = '_blank'
  hiddenElement.download = `${name}.csv`
  hiddenElement.click()
}

export default downloadTracksCSV
