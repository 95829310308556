import styled from 'styled-components'
import { theme } from '@aims-theme'

const StyledProjectSnapshotPage = styled.div`

  display: flex;
  flex-direction: column;
  flex: 1;
  
  .tracks {
    padding-left: 1rem;
    color: ${props => props.theme.colors.grey.medium};
  }

  &.missing-snapshot {
    align-self: center;
    justify-content: center;
    align-items: center;
    max-width: 45rem;
    text-align: center;

    .cancel-icon {
      width: 6rem;
      height: 6rem;
      color: ${theme.colors.warning.red};
      margin-bottom: 1rem;
    }

    .header {
      font-weight: bold;
      margin-bottom: 1.25rem;
    }
  }
`

export default StyledProjectSnapshotPage
