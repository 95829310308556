import { Grid } from '@material-ui/core'
import React from 'react'
import { SearchFilterWidgetProps } from '@aims-search/types'
import { TextField } from '@aims-controls'
import { trackFieldKeyMap } from '@aims-search/hooks'

const TextWidget = ({ index, field }: SearchFilterWidgetProps): JSX.Element => {
  const label = trackFieldKeyMap.get(field)

  return (
    <Grid container>
      <Grid item xs={12} className={'filtering-label empty'}>
        {label ?? <>&nbsp;</>}
      </Grid>
      <Grid item xs={12}>
        <input type="hidden" name={`conditions[${index}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index}][operator]`} value={'contains'} />
        <TextField
          type={'text'}
          variant={'outlined'}
          color={'secondary'}
          name={`conditions[${index}][value]`}
          id={`conditions-${index}-value`}
          label={trackFieldKeyMap.get(field)}
          fullWidth
          size={'small'}
        />
      </Grid>
    </Grid>
  )
}

export default TextWidget
