import styled from 'styled-components'

const StyledLoaderButtonTextual = styled.div`
  display: inline-block;
  position: relative;

  button {
    background-color: ${props => props.theme.colors.secondary};
    &:hover {
      background-color: ${props => props.theme.colors.secondaryHover};
    }
    &.MuiButton-outlined {
      background-color: ${props => props.theme.colors.white};
      border-color: ${props => props.theme.colors.secondary};
      border-width: 0.15rem;
      color: ${props => props.theme.colors.secondary};
      &:hover {
        background-color: ${props => props.theme.colors.white};
      }
      &.success {
        &:hover {
          background-color: ${props => props.theme.colors.white};
        }
      }
      &.Mui-disabled {
        background-color: ${props => props.theme.colors.grey.light};
        border-color: ${props => props.theme.colors.grey.light};
        box-shadow: none;
        color: ${props => props.theme.colors.grey.medium};
      }
    }
    &.MuiButton-outlinedPrimary {
      border-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.primary};
    }
    &.Mui-disabled {
      background-color: ${props => props.theme.colors.grey.light};
      color: ${props => props.theme.colors.grey.medium};
    }
    &.MuiButton-text {
      background-color: transparent;
    }
  }

  &.full-width {
    display: block;
  }

  .load-progress {
    height: 2rem;
    left: 50%;
    margin-left: -1rem;
    margin-top: -1rem;
    position: absolute;
    top: 50%;
    width: 2rem;
    svg {
      circle {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  .success {
    background-color: ${props => props.theme.colors.primary};
    pointer-events: none;
    &:hover {
      background-color: ${props => props.theme.colors.primary};
    }
  }

  .loading {
    background-color: ${props => props.theme.colors.primaryHover};
    pointer-events: none;
    &:hover {
      background-color: ${props => props.theme.colors.primaryHover};
    }
  }
`

export default StyledLoaderButtonTextual
