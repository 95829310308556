import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledLogo = styled.img`
  height: 2rem;
  margin-left: 1rem;
  width: 5rem;
  ${media(ScreenSize.Small)} {
    height: 5rem;
    margin-left: 3rem;
    width: 14rem;
  }
  &.custom {
    width: auto;
    ${media(ScreenSize.Small)} {
      width: auto;
    }
  }
`

export default StyledLogo
