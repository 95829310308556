import { Button, LoaderButtonTextual, TextField, Tooltip } from '@aims-controls'
import { DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  getIsLoading,
  getIsNewProjectCreated,
  getNewProjectTitle,
  setNewProjectCreated,
  setNewProjectTitle,
  update
} from '@aims-store/project'
import { useDispatch, useSelector } from 'react-redux'

import { Collection } from '@aims-collection/types'
import CustomEditIcon from '../../../../icons/component/CustomEditIcon'
import { StyledTrackDialog } from '@aims-track/styled'

const EditProjectButton = ({ project }: { project: Collection }): JSX.Element => {
  const dispatch = useDispatch()
  const loading = useSelector(getIsLoading)
  const created = useSelector(getIsNewProjectCreated)
  const title = useSelector(getNewProjectTitle)
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (created) {
      setSuccess(true)
      setTimeout(() => {
        handleClose()
        dispatch(setNewProjectCreated(false))
      }, 1000)
    }
  }, [created])

  const handleEditProject = (): void => {
    dispatch(update(project))
  }

  const handleProjectTitle = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setNewProjectTitle(event.target.value))
  }

  const handleOpen = (): void => {
    setOpen(true)
    dispatch(setNewProjectTitle(project.title))
  }

  const handleClose = (): void => {
    setOpen(false)
    setSuccess(false)
    dispatch(setNewProjectTitle(''))
  }

  return (
    <>
      <Tooltip title={'Edit project'}>
        <IconButton disabled={loading} onClick={handleOpen} className={'action-edit-project'}>
          <CustomEditIcon />
        </IconButton>
      </Tooltip>
      <StyledTrackDialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant={'h4'} component={'p'}>
            Edit project
          </Typography>
        </DialogTitle>
        <DialogContent className={'full'}>
          <TextField
            label="Project name"
            placeholder="Project name"
            value={title}
            variant={'outlined'}
            color={'secondary'}
            onChange={handleProjectTitle}
            autoFocus
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <LoaderButtonTextual
            text={{
              default: 'Update',
              loading: 'Updating...',
              success: 'Updated'
            }}
            disabled={loading}
            success={success}
            loading={loading}
            variant={'contained'}
            onClick={handleEditProject}
            size={'medium'}
            color={'secondary'}
          />
          <Button size={'medium'} variant={'text'} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </StyledTrackDialog>
    </>
  )
}

export default EditProjectButton
