import { ApiFilter, ApiFilterCondition, SearchType } from '@aims-search/types'
import { PlaylistBuilderEndpointType, PlaylistBuilderOptions, PlaylistType } from '@aims-playlist-builder/types'
import { consolidateFilterConfiguration, reformatFilters } from '@aims-search/lib'

import { convertObjectToFormData } from '@aims-lib'

const getPlaylistBuilderEndpointType = (type: PlaylistType, seeds: number): PlaylistBuilderEndpointType => {
  if (type === PlaylistType.Transition) {
    return PlaylistBuilderEndpointType.Transition
  }
  if (seeds > 1) {
    return PlaylistBuilderEndpointType.Multiple
  }
  return PlaylistBuilderEndpointType.Single
}

const constructMultipartBody = (options: PlaylistBuilderOptions): FormData => {
  const { type, seeds, length, user } = options
  const endpoint = getPlaylistBuilderEndpointType(type, seeds.length)
  const trackKey = endpoint === PlaylistBuilderEndpointType.Transition
    ? ['from_track', 'to_track']
    : (endpoint === PlaylistBuilderEndpointType.Multiple ? 'tracks' : 'track')
  const conditions = user.predefinedFilter !== null && user.predefinedFilter.conditions?.length > 0
    ? user.predefinedFilter.conditions as Array<ApiFilter & ApiFilterCondition>
    : []
  let filters
  if (conditions.length > 0) {
    filters = consolidateFilterConfiguration({ conditions })
  }
  const formData = convertObjectToFormData({ filter: typeof filters !== 'undefined' ? reformatFilters(filters) : undefined })

  formData.append('playlist_length', length.toString())
  seeds
    .forEach((seed, index): void => {
      const { offset, limit, changed } = seed.segment
      const { file, text } = seed.fields
      const name = Array.isArray(trackKey) ? trackKey[index] : trackKey
      const key = `${name}${endpoint === PlaylistBuilderEndpointType.Multiple ? `[${index}]` : ''}`

      formData.append(`${key}[type]`, file !== null ? SearchType.Upload : SearchType.Link)
      formData.append(`${key}[value]`, file ?? text)
      if (typeof offset !== 'undefined' && offset >= 0 && changed) {
        formData.append(`${key}[time_offset]`, offset.toFixed(0))
      }
      if (typeof limit !== 'undefined' && limit > 0 && changed) {
        formData.append(`${key}[time_limit]`, limit.toFixed(0))
      }
    })
  return formData
}

export const buildPlaylist = async (options: PlaylistBuilderOptions): Promise<Record<string, unknown>> => {
  const { user, type, seeds } = options
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'X-User-Id': user.id
  }
  const method = 'POST'
  const endpoint = getPlaylistBuilderEndpointType(type, seeds.length)
  const body = constructMultipartBody(options)
  const response = await fetch(`${user.apiHost}/v1/playlist/${endpoint}?detailed=true`, { headers, method, body })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
  return await response.json()
}
