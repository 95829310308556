import { getMetadata, isAlbumsEnabled } from '@aims-store/auth'

import { Grid } from '@material-ui/core'
import Link from 'next/link'
import React from 'react'
import { StyledMetadataItem } from '@aims-track/styled'
import { TrackProps } from '@aims-track/types'
import { useCueSheetInfo } from '@aims-search/hooks'
import { useSelector } from 'react-redux'

const TrackMetadata = ({ track }: TrackProps): JSX.Element|null => {
  const metadataStructure = useSelector(getMetadata)
  const albums = useSelector(isAlbumsEnabled)
  const { getItem, exposedMetadata } = useCueSheetInfo(track, metadataStructure)

  return exposedMetadata.length > 0 ? (
    <Grid container className={'track-metadata'}>
      {
        exposedMetadata.map(field => {
          const item = getItem(field)

          return (item != null) && (
            <Grid item key={field}>
              <StyledMetadataItem>
                {item.label}:
                <span className={'description'}>
                  {
                    field === 'albumName' && albums && track.albumCode !== null
                      ? <Link href={`/albums/${encodeURIComponent(track.albumCode)}`}>
                        {item.value}
                      </Link>
                      : (
                        field === 'artists' && albums && track.artistCanonical !== null
                          ? <Link href={`/artists/${encodeURIComponent(track.artistCanonical)}`}>
                            {item.value}
                          </Link>
                          : item.value
                      )
                  }
                </span>
              </StyledMetadataItem>
            </Grid>
          )
        }
        )
      }
    </Grid>
  ) : null
}

export default TrackMetadata
