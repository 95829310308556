import styled from 'styled-components'

const StyledTrackChip = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  border: 0.1rem solid ${props => props.theme.colors.grey.light};
  border-radius: 0.375rem;
  background-color: ${props => props.theme.colors.white};
  padding: 0.5rem 0.875rem;
  min-width: 0;

  .title {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${props => props.theme.colors.black};
  }

  & .MuiIconButton-sizeSmall {
    width: 1rem;
    height: 1rem;
    border: none;
    color: ${props => props.theme.colors.grey.dark};
    padding: 0;

    & .MuiSvgIcon-root {
      width: 1rem;
      height: 1rem;
    }
  }
`

export default StyledTrackChip
