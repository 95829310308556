import React, { ChangeEvent, useState } from 'react'

import { Grid } from '@material-ui/core'
import { SearchFilterWidgetProps } from '@aims-search/types'
import { StyledSwitch } from '@aims-controls'

const SwitchWidget = ({ index, field, label, filterValue }: SearchFilterWidgetProps): JSX.Element => {
  const [value, setValue] = useState('')
  const handleChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setValue(value === '' ? filterValue ?? 'true' : '')
  }
  return (
    <Grid container>
      <Grid item xs={12} className={'filtering-label'}>
        &nbsp;
      </Grid>
      <Grid item xs={12} className={'filtering-switch'}>
        <input type="hidden" name={`conditions[${index}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index}][operator]`} value="eq" />
        <input type="hidden" name={`conditions[${index}][value]`} value={value} />
        <div>
          <StyledSwitch checked={value === filterValue} onChange={handleChange} id={`switch-${index}`} />
          <label htmlFor={`switch-${index}`}>{label}</label>
        </div>
      </Grid>
    </Grid>
  )
}

export default SwitchWidget
