import styled from 'styled-components'
import theme from '../../../../styled/theme'

const { secondaryLight, secondaryLightest } = theme.colors

const StyledNestedTrackGroup = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.expanded ? '1rem' : '0'};
  margin-bottom: 2rem;
  position: relative;

  .expand-button {
    position: absolute;
    bottom: -2rem;
    height: 2rem;
    right: 3rem;
    cursor: pointer;
    border: none;
    padding: 0.2rem 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    background: linear-gradient(104deg, ${secondaryLightest}, rgba(0, 183, 190, 0.01) 90%);
    transition: background-color 0.1s ease-in;
    text-transform: uppercase;
    font-size: 0.9rem;
    text-align: center;

    &:hover {
      background-color: ${secondaryLightest};
    }

    &:active {
      background-color: ${secondaryLight};
    }
  }

  .tracks-list {
    display: flex;
    flex-direction: column;
    width: calc(100% - 4rem);
    margin: 0 auto;
    gap: 1rem;
    max-height: 0;
    transition: max-height 0.2s ease-in;
    max-height: ${props => props.expanded ? '100%' : ''};

    & > *:not(:last-child):before {
      position: absolute;
      content: '';
      left: -1rem;
      top: 10%;
      height: 80%;
      width: calc(100% + 2rem);
      border: 0 solid ${secondaryLight};
      border-left-width: 0.1rem;
      border-right-width: 0.1rem;
      border-radius: 1rem;
    }
  }
`

export default StyledNestedTrackGroup
