import { Button, LoaderButtonTextual } from '@aims-controls'
import { Collapse, Grid } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import React, { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'
import { getAllFieldFilters, getSearchFilters } from '@aims-store/auth'
import { getSearchFieldsText, isLoading } from '@aims-store/search'

import SearchFilterWidget from './SearchFilterWidget'
import { SearchFiltersProps } from '@aims-search/types'
import StyledSearchFilters from './SearchFilters.styled'
import { StyledSearchFiltersExpander } from '@aims-search/styled'
import { useSelector } from 'react-redux'

export const SEARCH_FILTERS_ID = 'similarity-search-form-filters'
export const APPLY_FILTER_BUTTON_ID = 'similarity-search-form-apply-filter-button'

const SearchFilters = ({ active, setActive }: SearchFiltersProps): JSX.Element => {
  const filters = useSelector(getSearchFilters)
  const loading = useSelector(isLoading)
  const [initial, setInitial] = useState(true)
  const [success, setSuccess] = useState(false)
  const [clicked, setClicked] = useState(false)
  const allFieldFilters = useSelector(getAllFieldFilters)
  const searchText = useSelector(getSearchFieldsText)

  useEffect(() => {
    if (!loading && !initial && clicked) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
        setClicked(false)
      }, 2000)
      return
    }
    if (loading) {
      setInitial(false)
    }
  }, [loading])

  const handleButtonClick = (): void => {
    setClicked(true)
  }

  const toggle = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault()
    setActive(!active)
  }

  let index = 0

  if (filters.length === 0) {
    return (
      <></>
    )
  }

  return (
    <StyledSearchFilters>
      <StyledSearchFiltersExpander item container xs={12}>
        <a href="#" onClick={toggle}>
          {active ? <ExpandLess fontSize={'small'} /> : <ExpandMore fontSize={'small'} />}
          {active ? 'Hide' : 'Show'} filters
        </a>
        <span />
      </StyledSearchFiltersExpander>
      <Collapse in={active}>
        <input type="hidden" name="logic" value="and" />
        <Grid container spacing={2} id={SEARCH_FILTERS_ID}>
          {filters.map((filterField: string): JSX.Element => {
            index = index + 2
            const fieldData = allFieldFilters.find(fieldFilters => filterField === fieldFilters.key)
            return (
              <SearchFilterWidget
                fieldData={fieldData}
                index={index}
                field={filterField}
                key={`filter-${index}`}
              />
            )
          })}
          <Grid container item xs>
            <Grid item>
              &nbsp;
            </Grid>
            <Grid item container className={'filtering-actions'}>
              <Button
                className={'filter-reset'}
                type={'reset'}
                variant={'text'}
                disabled={loading}
                size={'medium'}
              >
                Reset
              </Button>
              <LoaderButtonTextual
                loading={clicked && loading}
                success={success}
                type={'submit'}
                disabled={loading || searchText.length === 0}
                text={{ default: 'Apply filters', loading: 'Filtering...', success: 'See results' }}
                variant={'outlined'}
                size={'medium'}
                color={'secondary'}
                onClick={handleButtonClick}
                id={APPLY_FILTER_BUTTON_ID}
              />
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
    </StyledSearchFilters>
  )
}

export default SearchFilters
