import { IconButton } from '@material-ui/core'
import { Launch } from '@material-ui/icons'
import { PresentationTextFieldProps } from '../../types'
import React from 'react'
import StyledPresentationTextField from './PresentationTextField.styled'
import TextField from '../TextField'

const PresentationTextField = (props: PresentationTextFieldProps): JSX.Element => {
  return (
    <StyledPresentationTextField>
      <TextField
        {...props}
        className={'presentation-text-field'}
        inputProps={{
          className: 'presentation-text-field__input',
          readOnly: true
        }}
      />
      {props.followLink === true && (
        <IconButton
          color={'secondary'}
          aria-label={'Open link in a new tab'}
          target={'_blank'}
          component={'a'}
          disabled={props.disabled}
          href={props.value as string}
        >
          <Launch />
        </IconButton>
      )}
    </StyledPresentationTextField>
  )
}

export default PresentationTextField
