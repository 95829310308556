import styled from 'styled-components'

const StyledProject = styled.div`
  border: 0.1rem solid ${props => props.theme.colors.grey.light};
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 1rem;
  position: relative;
  margin-bottom: 1rem;

  &.full-width {
    cursor: default;
    margin: 1rem 0;
  }

  &.featured {
    background-color: ${props => props.theme.colors.grey.lightest};
    border: 0;
    cursor: default;
    padding: 1.3rem;
    flex-grow: 1;
  }

  .link {
    cursor: pointer;
    font-size: 1.625rem;
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
    &.disabled {
      cursor: not-allowed;
      text-decoration: none;
      &:hover {
        color: inherit;
      }
    }
  }

  .metadata {

    font-weight: ${props => props.theme.font.medium};

    .last-updated-title {
      color: ${props => props.theme.colors.grey.mediumLight};
      font-weight: ${props => props.theme.font.regular};
    }

    p {
      margin: 0;
    }

    .info-icons-list {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      margin-left: 1rem;
    }
  }
`

export default StyledProject
