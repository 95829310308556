import { StyledNav, StyledNavItem } from '../styled'
import { isPlaylistsEnabled, isProjectsEnabled, isPublic as isPublicSelector } from '@aims-store/auth'

import Link from 'next/link'
import { Logout } from '../../header'
import React from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

const Nav = ({ opened }: { opened: boolean }): JSX.Element => {
  const router = useRouter()
  const projects = useSelector(isProjectsEnabled)
  const playlists = useSelector(isPlaylistsEnabled)
  const isPublic = useSelector(isPublicSelector)

  return (
    <StyledNav className={opened ? 'opened' : ''}>
      { !isPublic &&
        <>
          <StyledNavItem className={router.pathname === '/' ? 'active' : ''}>
            <Link href={'/'}>Similarity Search</Link>
          </StyledNavItem>
          <StyledNavItem className={router.pathname.indexOf('/playlist-generator') === 0 ? 'active' : ''}>
            <Link href={'/playlist-generator'}>Playlist Generator</Link>
          </StyledNavItem>
          {
            projects &&
            <StyledNavItem className={router.pathname.indexOf('/my-projects') === 0 ? 'active' : ''}>
              <Link href={'/my-projects'}>My Projects</Link>
            </StyledNavItem>
          }
          <StyledNavItem className={router.pathname.indexOf('/auto-tagging') === 0 ? 'active' : ''}>
            <Link href={'/auto-tagging'}>Auto-Tagging</Link>
          </StyledNavItem>
          {
            playlists &&
            <StyledNavItem className={router.pathname.indexOf('/playlist-plugging') === 0 ? 'active' : ''}>
              <Link href={'/playlist-plugging'}>Playlist Plugging</Link>
            </StyledNavItem>
          }
          <StyledNavItem className={router.pathname.indexOf('/tutorial') === 0 ? 'active' : ''}>
            <Link href={'/tutorial'}>Tutorial</Link>
          </StyledNavItem>
        </>
      }
      <StyledNavItem>
        <Logout/>
      </StyledNavItem>
    </StyledNav>
  )
}

export default Nav
