import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core'

import React from 'react'

const Tooltip = (props: TooltipProps): JSX.Element => {
  const { children } = props
  return (
    <MuiTooltip
      {...props}
      placement={'top'}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
