import { ScreenSize, media } from '@aims-theme'

import styled from 'styled-components'

const StyledSearchPane = styled.form`
  background-color: ${props => props.theme.colors.grey.lightest};
  border-radius: 0.3rem;
  padding: 2rem 1rem;

  .filtering-label {
    color: ${props => props.theme.colors.grey.medium};
    font-size: 0.8rem;
    font-weight: ${props => props.theme.font.medium};
    padding: 0.5rem;
    text-transform: uppercase;
    &.empty {
      padding: 0;
      ${media(ScreenSize.Small)} {
        padding: 0.5rem;
      }
    }
    &.sm {
      display: none;
      ${media(ScreenSize.Small)} {
        display: block;
      }
    }
  }

  .filtering-actions {
    justify-content: flex-end;
    ${media(ScreenSize.Small)} {
      justify-content: flex-end;
    }
  }

  .filtering-switch {
    align-items: center;
    height: 3rem;
    margin-left: 0.5rem;
    div {
      align-items: center;
      display: flex;
      height: 100%;
      label {
        color: ${props => props.theme.colors.grey.medium};
        margin-left: 0.5rem;
      }
    }
  }

  .dual-filter {
    .filter-from {
      padding-right: 0.25rem;
    }
    .filter-to {
      padding-left: 0.25rem;
    }
  }

  .with-modifier {
    .modifier {
      .MuiInputBase-root {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      fieldset {
        border-right: none;
      }
      select {
        font-size: 70%;
        line-height: 1;
        padding-bottom: 1.025rem;
        padding-top: 1.025rem;
      }
    }
    .filter-input {
      .MuiInputBase-root {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  .filter-reset {
    color: ${props => props.theme.colors.grey.mediumLight};
    margin-right: 2rem;
    text-decoration: underline;
  }

  .search-button {
    display: none;
    ${media(ScreenSize.Small)} {
      display: block;
    }
  }
  .search-button-mobile {
    button {
      background-color: ${props => props.theme.colors.white};
      border: 0.1rem solid ${props => props.theme.colors.grey.light};
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      box-shadow: none;
      min-width: 0;
      padding: 1.05rem 0;
    }
    ${media(ScreenSize.Small)} {
      display: none;
    }
  }

  .add-new-seed-row {
    padding-bottom: 1rem;
    ${media(ScreenSize.Small)} {
      padding-bottom: 0;
    }
  }
`

export default StyledSearchPane
