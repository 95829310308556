import {
  ADD_TRACK,
  CREATE_BEGIN,
  CREATE_ERROR,
  CREATE_SUCCESS,
  LIST_BEGIN,
  LIST_ERROR,
  LOAD_BEGIN,
  LOAD_ERROR,
  LOAD_SUCCESS,
  REFRESH_PROJECT,
  REMOVE_BEGIN,
  REMOVE_ERROR,
  REMOVE_SUCCESS,
  REMOVE_TRACK,
  SET_IS_PROJECT_OUTDATED,
  SET_IS_REFRESHING_PROJECT,
  SET_LOADING,
  SET_NEW_PROJECT_CREATED,
  SET_NEW_PROJECT_TITLE,
  SET_PRELOADED_SUGGESTIONS,
  SET_PROJECTS,
  SET_PROJECT_SUGGESTIONS_PAGINATION,
  SET_SEARCH_TERM,
  SET_SUGGESTIONS,
  UPDATE_BEGIN,
  UPDATE_ERROR,
  UPDATE_SUCCESS
} from './action-types'
import { AnyAction, Dispatch } from 'redux'
import {
  Collection,
  CollectionPagination,
  CollectionTrackResults,
  CollectionTracks,
  PaginatedCollections
} from '@aims-collection/types'
import {
  CreateBeginAction,
  CreateErrorAction,
  CreateSuccessAction,
  ListBeginAction,
  ListErrorAction,
  LoadBeginAction,
  LoadErrorAction,
  LoadSuccessAction,
  RefreshProjectAction,
  RemoveBeginAction,
  RemoveErrorAction,
  RemoveSuccessAction,
  SetIsProjectOutdatedAction,
  SetIsRefreshingProjectAction,
  SetLoadingAction,
  SetNewProjectCreatedAction,
  SetNewProjectTitleAction,
  SetPreloadedSuggestionsAction,
  SetProjectSuggestionsPaginationAction,
  SetProjectsAction,
  SetSearchTermAction,
  SetSuggestionsListAction,
  SetTrackAction,
  UpdateBeginAction,
  UpdateErrorAction,
  UpdateSuccessAction
} from './types'
import { convertKeysToCamelCase, reformatResults } from '@aims-search/lib'
import {
  createProject,
  downloadTracksCSV as downloadTracksCSVLib,
  loadProject,
  loadProjectSuggestions,
  loadProjects,
  loadTracks,
  removeProject,
  updateProject
} from '@aims-project/lib'
import { getNewProjectTitle, getPreloadedSuggestions, getProject, getProjectTracksPagination, getSuggestions } from './selectors'

import { DetailedTrack } from '@aims-track/types'
import { State } from '@aims-app-store'
import { UserIdentity } from '@aims-auth/types'
import { enqueue } from '@aims-layout'
import { getUser } from '@aims-store/auth'

const handleError = (dispatch: Dispatch<AnyAction>, error: Error, onError: (error: Error) => AnyAction): void => {
  dispatch(loadError(error))
  dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
  dispatch(onError(error))
}

const loadBegin = (): LoadBeginAction => ({
  type: LOAD_BEGIN
})

const setLoading = (on: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: {
    on
  }
})

const loadSuccess = (project: Collection, tracks: CollectionTracks, suggestions: CollectionTrackResults): LoadSuccessAction => ({
  type: LOAD_SUCCESS,
  project,
  tracks,
  suggestions
})

const loadError = (error: Error): LoadErrorAction => ({
  type: LOAD_ERROR,
  error
})

const loadMoreSuggestions = (): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(setLoading(true))

    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const pagination = getProjectTracksPagination(state)
      const project = getProject(state)
      const suggestions = getSuggestions(state)
      const preloadedSuggestions = getPreloadedSuggestions(state)

      pagination.page = pagination.page + 1
      preloadedSuggestions.tracks = [...suggestions.tracks, ...preloadedSuggestions.tracks]

      dispatch(setSuggestions(preloadedSuggestions))

      const preloadedResult = await loadProjectSuggestions(project.key, user, pagination)
      const newPreloadedSuggestions = reformatResults<CollectionTrackResults>(preloadedResult)

      dispatch(setProjectSuggestionsPagination(pagination))
      dispatch(setPreloadedSuggestions(newPreloadedSuggestions))
    } catch (error) {
      handleError(dispatch, error as Error, loadError)
    }

    dispatch(setLoading(false))
  }

const load = (projectKey: string, page?: number, pageSize?: number): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(loadBegin())
    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const result = Promise.all([
        loadProject(projectKey, user),
        loadTracks(projectKey, user)
      ])
      const [rawResults, rawTracks] = await result
      const project = convertKeysToCamelCase<Collection>(rawResults)
      const tracks = convertKeysToCamelCase<CollectionTracks>(rawTracks)
      let results: CollectionTrackResults = { queryId: null, tracks: [] }

      if (tracks.tracks.length !== 0 && project.processedAt !== null) {
        const pagination = { page: 1, pageSize: 10 }
        const suggestionsResult = await loadProjectSuggestions(projectKey, user, pagination)

        results = reformatResults<CollectionTrackResults>(suggestionsResult)
        pagination.page = 2

        const preloadedSuggestionsResult = await loadProjectSuggestions(projectKey, user, pagination)
        const preloadedResults = reformatResults<CollectionTrackResults>(preloadedSuggestionsResult)

        dispatch(setProjectSuggestionsPagination(pagination))
        dispatch(setPreloadedSuggestions(preloadedResults))
      }

      dispatch(loadSuccess(project, tracks, results))
    } catch (error) {
      handleError(dispatch, error as Error, loadError)
    }
  }

const setSuggestions = (suggestions: CollectionTrackResults): SetSuggestionsListAction => ({
  type: SET_SUGGESTIONS,
  payload: {
    suggestions
  }
})

const setPreloadedSuggestions = (preloadedSuggestions: CollectionTrackResults): SetPreloadedSuggestionsAction => ({
  type: SET_PRELOADED_SUGGESTIONS,
  payload: {
    preloadedSuggestions
  }
})

const listBegin = (): ListBeginAction => ({
  type: LIST_BEGIN
})

const setProjects = (projects: PaginatedCollections): SetProjectsAction => ({
  type: SET_PROJECTS,
  projects
})

const listError = (error: Error): ListErrorAction => ({
  type: LIST_ERROR,
  error
})

const list = (): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(listBegin())
    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const results = await loadProjects(user)
      const projects = convertKeysToCamelCase<PaginatedCollections>(results)
      dispatch(setProjects(projects))
    } catch (error) {
      handleError(dispatch, error as Error, listError)
    }
  }

const setNewProjectCreated = (created: boolean): SetNewProjectCreatedAction => ({
  type: SET_NEW_PROJECT_CREATED,
  created
})

const setNewProjectTitle = (title: string): SetNewProjectTitleAction => ({
  type: SET_NEW_PROJECT_TITLE,
  title
})

const setSearchTerm = (searchTerm: string): SetSearchTermAction => ({
  type: SET_SEARCH_TERM,
  searchTerm
})

const setProjectSuggestionsPagination = (pagination: CollectionPagination): SetProjectSuggestionsPaginationAction => ({
  type: SET_PROJECT_SUGGESTIONS_PAGINATION,
  payload: {
    pagination
  }
})

const createBegin = (): CreateBeginAction => ({
  type: CREATE_BEGIN
})

const createSuccess = (project: Collection): CreateSuccessAction => ({
  type: CREATE_SUCCESS,
  project
})

const createError = (error: Error): CreateErrorAction => ({
  type: CREATE_ERROR,
  error
})

const createNewProject = (): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(createBegin())
    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const title = getNewProjectTitle(state)
      const result = await createProject(user, title)
      const project = convertKeysToCamelCase<Collection>(result)
      dispatch(createSuccess(project))
      dispatch(setNewProjectCreated(true))
    } catch (error) {
      handleError(dispatch, error as Error, createError)
    }
  }

const updateBegin = (): UpdateBeginAction => ({
  type: UPDATE_BEGIN
})

const updateSuccess = (project: Collection): UpdateSuccessAction => ({
  type: UPDATE_SUCCESS,
  project
})

const updateError = (error: Error): UpdateErrorAction => ({
  type: UPDATE_ERROR,
  error
})

const update = (project: Collection): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(updateBegin())
    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const title = getNewProjectTitle(state)
      const result = await updateProject(user, project, title)
      const updatedProject = convertKeysToCamelCase<Collection>(result)
      dispatch(updateSuccess(updatedProject))
      dispatch(setNewProjectCreated(true))
    } catch (error) {
      handleError(dispatch, error as Error, updateError)
    }
  }

const downloadTracksCSV = (exportCount: number): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(setLoading(true))
    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const project = getProject(state)
      const response = await loadProjectSuggestions(project.key, user, { page: 1, pageSize: exportCount })
      const tracksToExport = reformatResults<CollectionTrackResults>(response)

      downloadTracksCSVLib(`${project.key}-suggestions-${exportCount}-tracks`, tracksToExport.tracks, user.metadata)
    } catch (error) {
      handleError(dispatch, error as Error, removeError)
    }
    dispatch(setLoading(false))
  }

const removeBegin = (): RemoveBeginAction => ({
  type: REMOVE_BEGIN
})

const removeSuccess = (project: Collection): RemoveSuccessAction => ({
  type: REMOVE_SUCCESS,
  project
})

const removeError = (error: Error): RemoveErrorAction => ({
  type: REMOVE_ERROR,
  error
})

const remove = (project: Collection): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(removeBegin())
    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      await removeProject(user, project)
      dispatch(setNewProjectCreated(true))
      dispatch(enqueue({ message: `Project ${project.title} has been deleted`, options: { variant: 'success' } }))
      setTimeout((): void => {
        dispatch(removeSuccess(project))
      }, 1000)
    } catch (error) {
      handleError(dispatch, error as Error, removeError)
    }
  }

const refreshProject = (project: Collection): RefreshProjectAction => ({
  type: REFRESH_PROJECT,
  payload: {
    project
  }
})

const refreshSuggestions = (): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(setLoading(true))

    try {
      const state = getState()
      const project = getProject(state)
      const user = getUser(state) as UserIdentity
      const suggestionsResult = await loadProjectSuggestions(project.key, user, { page: 1, pageSize: 10 })
      const suggestions = reformatResults<CollectionTrackResults>(suggestionsResult)

      dispatch(setProjectSuggestionsPagination({ page: 2, pageSize: 10 }))
      dispatch(setSuggestions(suggestions))
      dispatch(setIsProjectOutdated(false))

      const preloadedPagination = { page: 2, pageSize: 10 }
      const preloadedSuggestionsResult = await loadProjectSuggestions(project.key, user, preloadedPagination)
      const preloadedResults = reformatResults<CollectionTrackResults>(preloadedSuggestionsResult)

      dispatch(setProjectSuggestionsPagination(preloadedPagination))
      dispatch(setPreloadedSuggestions(preloadedResults))
    } catch (error) {
      handleError(dispatch, error as Error, removeError)
    }

    dispatch(setLoading(false))
  }

const setIsRefreshingProject = (on: boolean): SetIsRefreshingProjectAction => ({
  type: SET_IS_REFRESHING_PROJECT,
  payload: {
    on
  }
})

const setIsProjectOutdated = (on: boolean): SetIsProjectOutdatedAction => ({
  type: SET_IS_PROJECT_OUTDATED,
  payload: {
    on
  }
})

const setTrack = (track: DetailedTrack, add: boolean): SetTrackAction => ({
  type: add ? ADD_TRACK : REMOVE_TRACK,
  payload: {
    track
  }
})

export {
  createBegin,
  createError,
  createNewProject,
  createSuccess,
  downloadTracksCSV,
  list,
  listBegin,
  listError,
  setProjects,
  load,
  loadBegin,
  loadError,
  loadMoreSuggestions,
  loadSuccess,
  refreshProject,
  refreshSuggestions,
  remove,
  removeBegin,
  removeError,
  removeSuccess,
  setIsProjectOutdated,
  setIsRefreshingProject,
  setLoading,
  setNewProjectCreated,
  setNewProjectTitle,
  setPreloadedSuggestions,
  setProjectSuggestionsPagination,
  setSearchTerm,
  setSuggestions,
  setTrack,
  update,
  updateBegin,
  updateError,
  updateSuccess
}
