import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomSpotifyIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 22 22'}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path d="M10.5,0 C4.70383065,0 0,4.70383065 0,10.5 C0,16.2961694 4.70383065,21 10.5,21 C16.2961694,21 21,16.2961694 21,10.5 C21,4.70383065 16.2961694,0 10.5,0 Z" id="Path" fill="#1ED760"></path>
          <path d="M17.2149194,9.44576613 C16.9947581,9.44576613 16.8592742,9.39072581 16.66875,9.28064516 C13.6542339,7.48125 8.26451613,7.04939516 4.77580645,8.02318548 C4.6233871,8.06552419 4.4328629,8.13326613 4.2296371,8.13326613 C3.67076613,8.13326613 3.24314516,7.69717742 3.24314516,7.13407258 C3.24314516,6.55826613 3.59879032,6.23225806 3.97983871,6.12217742 C5.47016129,5.68608871 7.13830645,5.47862903 8.9546371,5.47862903 C12.0453629,5.47862903 15.2842742,6.12217742 17.6510081,7.50241935 C17.98125,7.69294355 18.1971774,7.95544355 18.1971774,8.45927419 C18.1971774,9.03508065 17.7314516,9.44576613 17.2149194,9.44576613 L17.2149194,9.44576613 Z M15.9024194,12.6719758 C15.6822581,12.6719758 15.5340726,12.5745968 15.3816532,12.4941532 C12.7354839,10.927621 8.78951613,10.2967742 5.2796371,11.2493952 C5.07641129,11.3044355 4.96633065,11.3594758 4.77580645,11.3594758 C4.32278226,11.3594758 3.95443548,10.991129 3.95443548,10.5381048 C3.95443548,10.0850806 4.17459677,9.78447581 4.61068548,9.66169355 C5.78770161,9.33145161 6.99012097,9.0858871 8.75141129,9.0858871 C11.4991935,9.0858871 14.1538306,9.76754032 16.2453629,11.0122984 C16.5883065,11.2155242 16.7237903,11.4780242 16.7237903,11.846371 C16.7195565,12.303629 16.3639113,12.6719758 15.9024194,12.6719758 L15.9024194,12.6719758 Z M14.7635081,15.4493952 C14.5856855,15.4493952 14.4756048,15.3943548 14.3104839,15.2969758 C11.6685484,13.7050403 8.59475806,13.6372984 5.55907258,14.2596774 C5.39395161,14.3020161 5.17802419,14.3697581 5.05524194,14.3697581 C4.64455645,14.3697581 4.38629032,14.04375 4.38629032,13.7008065 C4.38629032,13.2647177 4.64455645,13.0572581 4.96209677,12.9895161 C8.4296371,12.2231855 11.9733871,12.2909274 14.996371,14.0987903 C15.2546371,14.2639113 15.4070565,14.4120968 15.4070565,14.797379 C15.4070565,15.1826613 15.1064516,15.4493952 14.7635081,15.4493952 L14.7635081,15.4493952 Z" id="Shape" fill="#000000"></path>
        </g>
      </g>
    </g>
  </SvgIcon>

export default CustomSpotifyIcon
