import React from 'react'
import StyledUnprocessedProjectPane from './UnprocessedProjectPane.styled'
import { Typography } from '@material-ui/core'

const UnprocessedProjectPane = (): JSX.Element => {
  return (
    <StyledUnprocessedProjectPane>
      <Typography color={'primary'} variant={'h4'}>
        No tracks added yet. Add music by pasting a reference link/ audio file here or by clicking the folder icon next to any tracks you like across search results.
      </Typography>
      <img
        src={'/img/project/unprocessed-project.jpg'}
        alt={'Highlighted button for adding tracks'}
        width={400}
        height={200}
      />
    </StyledUnprocessedProjectPane>
  )
}

export default UnprocessedProjectPane
