import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledAuthHeaderIllustration = styled.img`
  outline: none;
  position: absolute;
  top: -8rem;
  z-index: -1;
  width: 30rem;
  ${media(ScreenSize.Small)} {
    top: -12rem;
    width: auto;
  }
  &.left {
    left: -90%;
    top: -12rem;
    ${media(ScreenSize.Small)} {
      left: -10%;
      top: -12rem;
    }
  }
  &.right {
    right: -90%;
    ${media(ScreenSize.Small)} {
      right: -10%;
    }
  }
`

export default StyledAuthHeaderIllustration
