import { PlaylistBuilderSeedProps } from '@aims-playlist-builder/types'
import { SearchType } from '@aims-search/types'
import { isSupportedStreamingServiceLink } from 'lib/linkValidator'

const getSeedType = (seed: PlaylistBuilderSeedProps): SearchType|null => {
  const { fields } = seed
  if (fields.file !== null) {
    return SearchType.Upload
  }
  if (fields.id !== null) {
    return SearchType.Internal
  }
  if (fields.text.length === 0) {
    return null
  }
  if (fields.text.match(/^https?:\/\/(.+)\.(.+)/i) === null) {
    return SearchType.Text
  }
  if (isSupportedStreamingServiceLink(fields.text)) {
    return SearchType.Link
  }
  return SearchType.FileLink
}

export const determineSeedType = (seeds: PlaylistBuilderSeedProps[]): SearchType|null => {
  if (seeds.length === 0) {
    return null
  }
  return getSeedType(seeds[0])
}

export const areAllSeedsValid = (seeds: PlaylistBuilderSeedProps[]): boolean => {
  return seeds.filter(seed => {
    const type = getSeedType(seed)
    return type !== null && ![SearchType.Link, SearchType.Upload].includes(type)
  }).length === 0
}

export const getValidSeedsOnly = (seeds: PlaylistBuilderSeedProps[]): PlaylistBuilderSeedProps[] => {
  return seeds.filter(seed => {
    const type = getSeedType(seed)
    return type !== null && [SearchType.Link, SearchType.Upload].includes(type)
  })
}

export const getCountOfValidSeeds = (seeds: PlaylistBuilderSeedProps[]): number =>
  getValidSeedsOnly(seeds).length
