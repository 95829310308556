import { ProjectState } from './types'

const initialState: ProjectState = {
  loading: false,
  isProjectOutdated: false,
  isRefreshingProject: false,
  newProject: {
    title: '',
    created: false
  },
  project: {
    id: '',
    key: '',
    title: '',
    processedAt: null,
    updatedAt: null,
    queryId: null
  },
  tracks: {
    tracks: [],
    pagination: {
      page: 1,
      pageSize: 10,
      itemCount: 10,
      pageCount: 1
    }
  },
  suggestions: {
    queryId: null,
    tracks: []
  },
  preloadedSuggestions: {
    queryId: null,
    tracks: []
  },
  suggestionsPagination: {
    page: 1,
    pageSize: 10
  },
  projects: {
    collections: [],
    pagination: {
      page: 1,
      pageSize: 10,
      itemCount: 0,
      pageCount: 0
    }
  },
  searchTerm: ''
}

export default initialState
