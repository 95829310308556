import { DetailedTrack, Segment } from '../types'

export const DEFAULT_OFFSET = 30
export const DEFAULT_LIMIT = 60
export const MAX_LIMIT = 60
export const MIN_LIMIT = 5

export const computeDefaultSegment = (duration: number): Segment => {
  if (duration <= DEFAULT_LIMIT) {
    return { offset: 0, duration }
  }

  if (duration <= (DEFAULT_OFFSET + DEFAULT_LIMIT)) {
    return { offset: duration - DEFAULT_LIMIT, duration: DEFAULT_LIMIT }
  }

  return { offset: DEFAULT_OFFSET, duration: DEFAULT_LIMIT }
}

export const setAudioPlayHeadPosition = (audio: HTMLAudioElement, highlights: boolean, track: DetailedTrack|null): void => {
  audio.currentTime = highlights
    ? (typeof track?.highlights !== 'undefined' && track.highlights.length > 0 ? track.highlights[0].offset : 0)
    : computeDefaultSegment(track?.duration ?? audio.duration).offset
}
