import { isDateString } from './object'
import qs from 'qs'

export function convertObjectToFormData (
  object: Record<string, unknown>,
  path?: string,
  formData: FormData = new FormData(),
  keepNullValues = false
): FormData {
  for (const property of Object.keys(object)) {
    if (object.hasOwnProperty(property)) { // eslint-disable-line no-prototype-builtins
      const key = typeof path !== 'undefined' ? `${path}[${property}]` : property
      const value = object[property]
      if (value !== null || keepNullValues) {
        if (value !== null && typeof value === 'object' && !(value instanceof Date)) {
          convertObjectToFormData(value as Record<string, unknown>, key, formData)
        } else if (typeof value !== 'undefined') {
          formData.append(key, value as string)
        }
      }
    }
  }
  return formData
}

export function convertFormDataToQueryString (formData: FormData): string {
  return Array.from(formData.entries()).reduce((carry, entry) => {
    const key = encodeURIComponent(entry[0])
    const value = entry[1] !== null && typeof entry[1] !== 'undefined' ? encodeURIComponent((entry[1] as string).toString()) : ''
    const separator = carry.length > 0 ? '&' : ''
    return `${carry}${separator}${key}=${value}`
  }, '')
}

export const convertQueryStringToObject = (queryString: string): Record<string, unknown> =>
  qs.parse(queryString, {
    decoder (str: string, decoder: any, charset: string) {
      const strWithoutPlus = str.replace(/\+/g, ' ')
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str)
      }

      const keywords: Record<string, unknown> = {
        true: true,
        false: false,
        null: null,
        undefined
      }
      if (str in keywords) {
        return keywords[str]
      }

      if (isDateString(str)) {
        return new Date(str)
      }

      // utf-8
      try {
        return decodeURIComponent(strWithoutPlus)
      } catch (e) {
        return strWithoutPlus
      }
    }
  })

export const convertFormDataToObject = (formData: FormData): Record<string, unknown> =>
  convertQueryStringToObject(convertFormDataToQueryString(formData))

export const convertObjectToQueryString = (object: Record<string, unknown>): string =>
  convertFormDataToQueryString(convertObjectToFormData(object))
