import styled from 'styled-components'

const StyledProjectHead = styled.div`
  border-radius: 0.5rem;
  padding: 1.3rem;
  background-color: ${props => props.theme.colors.grey.lightest};

  .project-description {
    .title {
      display: inline-block;
      margin-right: 0.3125rem;
      font-weight: 500;
      font-size: 1.625rem;
    }

    .number-of-tracks {
      color: ${props => props.theme.colors.grey.medium};
      font-size: 1.625rem;
    }
  }

  .snapshot {
    .from {
      color: ${props => props.theme.colors.grey.medium};
    }
    .date {
      font-weight: 500;
    }
  }
`

export default StyledProjectHead
