export enum TagsTitles {
  bpm = 'bpm',
  decades = 'decades',
  genres = 'genres',
  instruments = 'instruments',
  key = 'key',
  moods = 'moods',
  usages = 'usages',
  vocals = 'vocals',
  subgenres = 'subgenres',
  tempo = 'tempo'
}
