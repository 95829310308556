import styled from 'styled-components'

const StyledTrackTagCategory = styled.p`
  color: ${props => props.theme.colors.grey.medium};
  font-size: 0.875rem;
  font-weight: ${props => props.theme.font.medium};
  margin-bottom: 0.2rem;
  margin-top: 0.6rem;
`

export default StyledTrackTagCategory
