import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomTikTokIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 22 22'}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M15.83603,21 L5.16396986,21 C2.31217147,21 0,18.6879079 0,15.8354861 L0,5.16379251 C0,2.31209206 2.31217147,0 5.16396986,0 L15.83603,0 C18.6878284,0 21,2.31209206 21,5.16379251 L21,15.8354861 C21.0007213,18.6879079 18.6885498,21 15.83603,21 Z" id="Path" fill="#000000" fillRule="nonzero"></path>
      <path d="M8.72637288,8.93744465 L8.72637288,8.30261126 C8.50605706,8.26776074 8.28353026,8.24871522 8.06049635,8.24560054 C5.90126726,8.24129212 3.99020884,9.64008979 3.34164655,11.6989699 C2.69308425,13.75785 3.45851762,15.9999661 5.23034075,17.2328414 C3.37122503,15.2432159 3.4772747,12.123154 5.4669686,10.2641022 C6.35355927,9.43649791 7.51381339,8.96424601 8.72637288,8.93744465 L8.72637288,8.93744465 Z" id="Path" fill="#25F4EE" fillRule="nonzero"></path>
      <path d="M8.84612965,16.12549 C10.053076,16.1240472 11.0450372,15.1732399 11.0984227,13.9670565 L11.0984227,3.20590909 L13.0643096,3.20590909 C13.0240995,2.98118415 13.0050187,2.75318818 13.0073169,2.524906 L10.3185609,2.524906 L10.3185609,13.2752323 C10.2738324,14.487187 9.27898547,15.4473725 8.06626776,15.4495367 C7.70411173,15.4466511 7.34700568,15.3571973 7.02597095,15.1898321 C7.44821686,15.7747552 8.12471357,16.1225103 8.84612965,16.12549 Z M16.7356483,6.85692249 L16.7356483,6.25888058 C16.0120577,6.25888058 15.3043384,6.04534571 14.7019474,5.64496783 C15.2299988,6.25936679 15.943979,6.68485299 16.7356483,6.85692249 L16.7356483,6.85692249 Z" id="Shape" fill="#25F4EE" fillRule="nonzero"></path>
      <path d="M14.7026692,5.64568877 C14.1088235,4.96973595 13.7815732,4.10060343 13.7821291,3.20085881 L13.0643099,3.20085881 C13.2511142,4.20636453 13.8436745,5.0906085 14.7026692,5.64568877 L14.7026692,5.64568877 Z M8.06049669,10.9350052 C6.81387194,10.9414978 5.8082036,11.9572312 5.81463422,13.2038131 C5.81889427,14.0376443 6.28240506,14.8012597 7.02019988,15.1898317 C6.29083783,14.1827551 6.51592286,12.7745792 7.52303405,12.0445208 C7.9078388,11.7658166 8.37099252,11.616067 8.84612999,11.6167296 C9.07193644,11.6196153 9.29630004,11.6549639 9.51200652,11.7213329 L9.51200652,8.98505668 C9.29197148,8.95187221 9.06905074,8.93455857 8.84612999,8.93311577 L8.72637322,8.93311577 L8.72637322,11.0136379 C8.50922388,10.954483 8.28486029,10.9285125 8.06049669,10.9350052 L8.06049669,10.9350052 Z" id="Shape" fill="#FE2C55" fillRule="nonzero"></path>
      <path d="M16.7356486,6.85692202 L16.7356486,8.93744418 C15.396229,8.93487334 14.0915855,8.51085981 13.0065958,7.72548952 L13.0065958,13.1915493 C13.0008244,15.9191687 10.7882097,18.1273789 8.06049669,18.1273789 C7.04748312,18.1293088 6.05884854,17.8167477 5.23106252,17.2328409 C7.08729253,19.2289591 10.2103472,19.3429406 12.2072554,17.4867743 C13.2114458,16.5533661 13.7819979,15.2442515 13.7821291,13.8732738 L13.7821291,8.42236345 C14.8708823,9.20219801 16.1769924,9.62056279 17.516232,9.61844727 L17.516232,6.9406046 C17.2536328,6.9391618 16.9924764,6.91174854 16.7356486,6.85692202 Z" id="Path" fill="#FE2C55" fillRule="nonzero"></path>
      <path d="M13.0065958,13.1922707 L13.0065958,7.72621092 C14.0950364,8.50666699 15.4013523,8.92509768 16.7406986,8.92229474 L16.7406986,6.84177259 C15.9494643,6.67450382 15.2339781,6.25474607 14.7019477,5.64568877 C13.8433806,5.09017392 13.2509573,4.20613435 13.0635885,3.20085881 L11.0977017,3.20085881 L11.0977017,13.967056 C11.0464804,15.2129165 9.99608361,16.1810374 8.75018029,16.1305393 C8.05960456,16.1025219 7.41995541,15.7595796 7.01442847,15.1999313 C5.91136757,14.619203 5.4878903,13.2543112 6.06863852,12.1512882 C6.45699404,11.4133025 7.22077976,10.9497202 8.05472528,10.9458262 C8.28053173,10.9479904 8.50489532,10.9826177 8.72060181,11.049708 L8.72060181,8.93816558 C5.99793873,8.98433528 3.82788825,11.2603573 3.87405954,13.9829268 C3.89494549,15.2158649 4.37704431,16.3962329 5.22529111,17.2912745 C6.06121009,17.8559158 7.0512034,18.1484438 8.05977526,18.1288217 C10.7882097,18.1281003 13.0008244,15.9198901 13.0065958,13.1922707 L13.0065958,13.1922707 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"></path>
    </g>
  </SvgIcon>

export default CustomTikTokIcon
