import colors from './colors'

export default {
  colors,
  transitions: {
    duration: {
      verySlow: '2s',
      slow: '800ms',
      regular: '400ms',
      fast: '200ms'
    },
    easing: {
      regular: 'ease-in-out',
      sharp: 'ease-out'
    }
  },
  layout: {
    footer: {
      height: '7rem',
      heightMobile: '4rem'
    }
  },
  font: {
    regular: 400,
    medium: 500,
    bold: 700
  },
  waveform: {
    height: 40
  }
}
