import React, { useState } from 'react'
import { feedback, feedbackThumbsDown, feedbackThumbsUp } from '@aims-store/feedback'

import { Check } from '@material-ui/icons'
import CustomThumbsDownIcon from '../../../../icons/component/CustomThumbsDownIcon'
import CustomThumbsUpIcon from '../../../../icons/component/CustomThumbsUpIcon'
import { IconButton } from '@material-ui/core'
import { Tooltip } from '@aims-controls'
import { TrackProps } from '@aims-track/types'
import { useDispatch } from 'react-redux'

const TrackThumbButtons = ({ track }: TrackProps): JSX.Element => {
  const dispatch = useDispatch()
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false)
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const handleThumbsUp = (): void => {
    if (thumbsUpClicked || disabled) {
      return
    }
    if (typeof (track.queryId) === 'string') {
      dispatch(feedback(feedbackThumbsUp(track.idClient), track.queryId))
    }
    setThumbsUpClicked(true)
    setDisabled(true)
    setTimeout((): void => {
      setThumbsUpClicked(false)
    }, 2000)
  }

  const handleThumbsDown = (): void => {
    if (thumbsDownClicked || disabled) {
      return
    }
    if (typeof (track.queryId) === 'string') {
      dispatch(feedback(feedbackThumbsDown(track.idClient), track.queryId))
    }
    setThumbsDownClicked(true)
    setDisabled(true)
    setTimeout((): void => {
      setThumbsDownClicked(false)
    }, 2000)
  }

  if (typeof (track.queryId) !== 'string') {
    return <></>
  }

  return (
    <>
      <Tooltip title={disabled ? 'You already gave feedback on this result' : 'Good result'}>
        <IconButton onClick={handleThumbsUp} className={`action-like ${disabled ? 'disabled' : ''}`}>
          {thumbsUpClicked ? <Check /> : <CustomThumbsUpIcon />}
        </IconButton>
      </Tooltip>
      <Tooltip title={disabled ? 'You already gave feedback on this result' : 'Bad result'}>
        <IconButton onClick={handleThumbsDown} className={`action-dislike ${disabled ? 'disabled' : ''}`}>
          {thumbsDownClicked ? <Check /> : <CustomThumbsDownIcon />}
        </IconButton>
      </Tooltip>
    </>
  )
}

export default TrackThumbButtons
