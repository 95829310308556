import { Button, Dialog, InfoMessage, PresentationTextField, StyledSwitch, Tooltip } from '@aims-controls'
import { DialogActions, DialogContent, DialogTitle, IconButton, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getUser, isDownloadEnabled } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import CustomShareIcon from 'components/icons/component/CustomShareIcon'
import { ShareButtonProps } from '@aims-project/types'
import { enqueue } from '@aims-layout'
import { getShareProjectLink } from '@aims-project/lib'

const useStyles = makeStyles(createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem'
  }
}))

const ShareProjectButton = ({ project }: ShareButtonProps): JSX.Element => {
  const contentClasses = useStyles()
  const user = useSelector(getUser)
  const downloadEnabled = useSelector(isDownloadEnabled)
  const [open, setOpen] = useState(false)
  const [allowSongDownload, setAllowSongDownload] = useState(downloadEnabled)
  const [copyToClipboardText, setCopyToClipboardText] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) {
      return
    }
    // eslint-disable-next-line
    void refreshShareProjectLink()
  }, [allowSongDownload])

  useEffect(() => {
    if (!open || copyToClipboardText !== '') {
      return
    }

    // eslint-disable-next-line
    void refreshShareProjectLink()
  }, [open])

  const refreshShareProjectLink = async (): Promise<void> => {
    if (user === null) {
      throw new Error('User cannot be null')
    }
    await getShareProjectLink({ user, project, downloadable: allowSongDownload })
      .then((response: string) => {
        const newCopyLink = `https://${window.location.hostname}/share/${response}`
        setCopyToClipboardText(newCopyLink)
      })
      .catch((error: Error) => {
        dispatch(enqueue({
          message: error.message, options: { variant: 'error' }
        }))
      })

    return await Promise.resolve()
  }

  const handleAllowSongDownloadChange = (): void => {
    setAllowSongDownload(!allowSongDownload)
    setCopyToClipboardText('')
  }

  const handleCloseDialog = (): void => setOpen(false)

  const handleCopyToClipboard = (): void => {
    void navigator.clipboard.writeText(copyToClipboardText) // eslint-disable-line no-void
    dispatch(enqueue({
      message: 'Copied to clipboard!', options: { variant: 'success' }
    }))
  }

  if (project.processedAt === null) {
    return <></>
  }

  return (
    <>
      <Tooltip title={'Share project'}>
        <IconButton onClick={() => setOpen(!open)} className={'action-share-project'}>
          <CustomShareIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          <Typography variant={'h4'}>
            Share &quot;{project.title}&quot;
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: contentClasses.root }}>
          <InfoMessage type={'info'}>
            This link contains a <strong>current snapshot</strong> of your project.
          </InfoMessage>
          <PresentationTextField
            variant={'outlined'}
            value={copyToClipboardText === '' ? 'Generating link...' : copyToClipboardText}
            label={'Link to share project'}
            disabled={copyToClipboardText === ''}
            fullWidth
            followLink={true}
          />
          {downloadEnabled && (
            <div>
              <StyledSwitch
                checked={allowSongDownload}
                onChange={handleAllowSongDownloadChange}
                id={'allow-download-switch'}
              />
              <Typography style={{ marginLeft: '1rem' }} variant={'button'} component={'label'} htmlFor={'allow-download-switch'}>Allow song download</Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button size={'medium'} color={'secondary'} variant={'contained'} onClick={handleCopyToClipboard}>
            Copy link to clipboard
          </Button>
          <Button size={'medium'} variant={'text'} onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ShareProjectButton
