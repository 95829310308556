import { Overrides } from '@material-ui/core/styles/overrides'

const linkOverride: Overrides['MuiLink'] = {
  root: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}

export default linkOverride
