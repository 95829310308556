import { hexToRGBA } from '@aims-lib'
import { muiTheme } from '@aims-theme'
import styled from 'styled-components'

const StyledUnprocessedProjectPane = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  margin: 6rem 0 2rem;
  gap: 2rem;
  align-items: center;

  & .MuiTypography-root {
    text-align: center;
    max-width: 52rem;
    color: ${hexToRGBA(muiTheme.palette.primary.main, 0.3)}
  }
`

export default StyledUnprocessedProjectPane
