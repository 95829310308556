import styled from 'styled-components'

const StyledAspectRatioBox = styled.div<{ ratio: number }>`
  padding-top: ${props => props.ratio * 100}%;
  position: relative;
  width: 100%;
  .aspect-ratio-box {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export default StyledAspectRatioBox
