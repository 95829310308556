import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomUploadIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 29.4 25.4'}>
    <g id="Icons_ic_upload" data-name="Icons / ic_upload" transform="translate(0.7 0.7)">
      <path id="Stroke_1" data-name="Stroke 1" d="M0,5,5,0l5,5" transform="translate(9 8)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_2" data-name="Stroke 2" d="M.5,0V15" transform="translate(13.5 9)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_4" data-name="Stroke 4" d="M19.007,17h4.558a4.4,4.4,0,1,0-.931-8.709A8.565,8.565,0,0,0,5.887,6.045c-.12-.008-.242-.014-.364-.014A5.484,5.484,0,1,0,5.522,17H8.941" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
    </g>
  </SvgIcon>

export default CustomUploadIcon
