import { IGNORE_HOURS, timeToSeconds, today } from '@aims-lib'
import React, { useState } from 'react'

import { Grid } from '@material-ui/core'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { Schedule } from '@material-ui/icons'
import { SearchFilterWidgetProps } from '@aims-search/types'
import { TextField } from '@aims-controls'
import { trackFieldKeyMap } from '@aims-search/hooks'

const DurationWidget = ({ index, field }: SearchFilterWidgetProps): JSX.Element => {
  const [from, setFrom] = useState(null as Date | null)
  const [to, setTo] = useState(null as Date | null)
  return (
    <Grid container className={'dual-filter'}>
      <Grid item xs={12} className={'filtering-label'}>
        {trackFieldKeyMap.get(field)}
      </Grid>
      <Grid item xs={6} className={'filter-from'}>
        <KeyboardTimePicker
          ampm={false}
          openTo={'minutes'}
          color={'secondary'}
          views={['minutes', 'seconds']}
          format={'mm:ss'}
          label={'From:'}
          value={from}
          onChange={setFrom}
          initialFocusedDate={today()}
          TextFieldComponent={TextField}
          inputVariant={'outlined'}
          size={'small'}
          keyboardIcon={<Schedule />}
          clearable={true}
          variant={'inline'}
        />
        <input type="hidden" name={`conditions[${index}][value]`} value={timeToSeconds(from, IGNORE_HOURS) ?? ''} />
        <input type="hidden" name={`conditions[${index}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index}][operator]`} value="gte" />
      </Grid>
      <Grid item xs={6} className={'filter-to'}>
        <KeyboardTimePicker
          ampm={false}
          color={'secondary'}
          openTo={'minutes'}
          views={['minutes', 'seconds']}
          format={'mm:ss'}
          label={'To:'}
          value={to}
          onChange={setTo}
          initialFocusedDate={today()}
          TextFieldComponent={TextField}
          inputVariant={'outlined'}
          size={'small'}
          keyboardIcon={<Schedule />}
          clearable={true}
          variant={'inline'}
        />
        <input type="hidden" name={`conditions[${index + 1}][value]`} value={timeToSeconds(to, IGNORE_HOURS) ?? ''} />
        <input type="hidden" name={`conditions[${index + 1}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index + 1}][operator]`} value="lte" />
      </Grid>
    </Grid>
  )
}

export default DurationWidget
