import { LinkInfo, LinkType } from './linkInfo/types'

import Player from '@vimeo/player'
import PlayerStates from 'youtube-player/dist/constants/PlayerStates'
import YouTubePlayer from 'youtube-player'
import md5 from 'md5'

type PlayerBuilder = (link: string, info: LinkInfo, audio: HTMLAudioElement) => void

const getPlayerElementId = (link: string): string => {
  return `hidden-embedded-player-${md5(link)}`
}

const youTubeBuilder = (link: string, info: LinkInfo, audio: HTMLAudioElement): void => {
  const hiddenEmbeddedPlayer = YouTubePlayer(getPlayerElementId(link), {
    videoId: info.id,
    playerVars: { autoplay: 0 }
  })
  // FIXME: there will need to be some synchronisation between the player and the audio as the player might be buffering
  audio.onplay = async () => {
    await hiddenEmbeddedPlayer.playVideo()
  }
  audio.onpause = async () => {
    await hiddenEmbeddedPlayer.pauseVideo()
  }
  audio.onseeked = async () => {
    const state = await hiddenEmbeddedPlayer.getPlayerState()
    await hiddenEmbeddedPlayer.seekTo(audio.currentTime, true)
    if (state === PlayerStates.VIDEO_CUED) {
      // seekTo causes the playback to start unless the state is paused, we don't want this since the controls are external
      return await hiddenEmbeddedPlayer.pauseVideo()
    }
  }
}

const vimeoBuilder = (link: string, info: LinkInfo, audio: HTMLAudioElement): void => {
  const hiddenEmbeddedPlayer = new Player(getPlayerElementId(link), {
    id: parseInt(info.id),
    dnt: true,
    keyboard: false,
    controls: false,
    autoplay: false,
    title: false
  })
  // FIXME: there will need to be some synchronisation between the player and the audio as the player might be buffering
  audio.onplay = async () => {
    await hiddenEmbeddedPlayer.play()
  }
  audio.onpause = async () => {
    await hiddenEmbeddedPlayer.pause()
  }
  audio.onseeked = async () => {
    await hiddenEmbeddedPlayer.setCurrentTime(audio.currentTime)
  }
}

const playerBuilders: { [key in LinkType]?: PlayerBuilder } = {
  // [LinkType.AppleMusic]: undefined, // not needed (preview URL available from the API)
  // [LinkType.SoundCloud]: undefined, // not needed (preview URL available from the API)
  // [LinkType.Spotify]: undefined, // not needed (preview URL available from the API)
  // [LinkType.TikTok]: undefined, // not needed (preview URL available from the API)
  [LinkType.Vimeo]: vimeoBuilder,
  [LinkType.YouTube]: youTubeBuilder
}

const createHiddenPlayer = (link: string, info: LinkInfo, audio: HTMLAudioElement): void => {
  const playerBuilder = playerBuilders[info.type]
  if (typeof playerBuilder === 'undefined') {
    throw new Error('Playback is not available for this service')
  }
  // TODO: pass player through here to destroy it on segment clear
  return playerBuilder(link, info, audio)
}

export { createHiddenPlayer }
