import { Branding, BrandingData } from '@aims-auth/types'
import { Container, Grid, Typography } from '@material-ui/core'
import { LinkedIn, MailOutline } from '@material-ui/icons'
import { StyledFooter, StyledLogo } from '../styled'

import React from 'react'
import { getBranding } from '@aims-store/auth'
import { useSelector } from 'react-redux'

const Footer = (): JSX.Element => {
  const { suppliers, footer }: Branding = useSelector(getBranding)

  const renderLogo = (): JSX.Element => {
    const { logo, link }: BrandingData = footer

    if (logo === null || link === null) {
      return <></>
    }

    const jsxLogo = <StyledLogo alt="AIMS API" src={'/img/footer/logo.svg'}/>

    return link !== undefined ? (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <StyledLogo alt="AIMS API" src={logo} className={'custom'}/>
      </a>
    ) : jsxLogo
  }

  return (
    <StyledFooter>
      <Container>
        <Grid container>
          <Grid container item xs={3} alignItems={'center'}>
            {
              typeof suppliers !== 'undefined' && suppliers.length > 0
                ? <div className={'suppliers'}>{
                  suppliers.map((supplier, key) =>
                    <a href={supplier.link} target="_blank" rel="noopener noreferrer" key={key}>
                      <img src={supplier.image} alt="Music supplier" />
                    </a>
                  )
                }</div>
                : <>
                  <a className={'social'} href="https://www.linkedin.com/company/aims-api/" target="_blank"
                    rel="noopener noreferrer">
                    <LinkedIn/>
                  </a>
                  <a className={'social'} href="mailto:info@aimsapi.com" rel="noopener noreferrer">
                    <MailOutline/>
                  </a>
                </>
            }
          </Grid>
          <Grid container item xs={9} justifyContent={'flex-end'} alignItems={'center'}>
            <Typography>&copy;&nbsp;All rights reserved. AIMS API s. r. o. {new Date().getFullYear()}</Typography>
            {footer !== undefined && renderLogo()}
          </Grid>
        </Grid>
      </Container>
    </StyledFooter>
  )
}

export default Footer
