import { ScreenSize, media } from '../../../../styled/mixins'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`

const StyledSearchResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  gap: 1.8rem;

  ${media(ScreenSize.Small)} {
    padding-left: 0;
    padding-right: 0;
  }

  &:empty {
    height: 14rem;
    opacity: 0.25;
    position: relative;
    text-align: center;
    ${media(ScreenSize.Small)} {
      height: 40rem;
    }
    &.loading {
      opacity: 1;
      &::after {
        animation: ${pulse} ${props => props.theme.transitions.duration.verySlow} ${props => props.theme.transitions.easing.regular} infinite;
        color: ${props => props.theme.colors.secondary};
        content: 'Searching...';
      }
      &::before {
        display: none;
      }
    }
    &.no-results {
      opacity: 1;
      &::after {
        color: ${props => props.theme.colors.black};
        content: 'There are no results for this search. Your filtering criteria is probably too strict';
      }
      &::before {
        display: none;
      }
    }
    &::after {
      color: ${props => props.theme.colors.primary};
      content: 'Search or upload a track to find similar ones or simply filter a category to browse through';
      display: block;
      font-size: 1.25rem;
      font-weight: ${props => props.theme.font.medium};
      height: 7rem;
      left: 0;
      margin-top: -5rem;
      padding: 0 2rem;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
      ${media(ScreenSize.Small)} {
        height: 8rem;
        font-size: 2.2rem;
        margin-top: -8rem;
        padding: 0 12rem;
      }
    }
    &::before {
      background-image: url('/img/header/logo.svg');
      background-size: 17rem 6rem;
      content: '';
      display: block;
      height: 6rem;
      left: 50%;
      margin-left: -3rem;
      opacity: 0.5;
      position: absolute;
      width: 6rem;
      top: 60%;
      ${media(ScreenSize.Small)} {
        background-size: 28rem 10rem;
        height: 10rem;
        margin-left: -5rem;
        top: 50%;
        width: 10rem;
      }
    }
    &.playlist-builder {
      &::after {
        content: 'Build a playlist based on single or even multiple tracks and they will appear here';
      }
      &.loading {
        &::after {
          content: 'Building playlist...';
        }
      }
    }
    &.collection {
      &::after {
        content: 'There are no tracks here yet';
      }
      &.loading {
        &::after {
          content: 'Loading...';
        }
      }
    }
    &.auto-tagging {
      &::after {
        content: 'Upload your tracks to analyze them and get tags';
      }
      &.loading {
        &::after {
          content: 'Loading...';
        }
      }
    }
    &.projects {
      ${media(ScreenSize.Small)} {
        height: 30rem;
      }
      &::after {
        content: 'Create your projects by adding tracks from search or playlist and get your personalised recommendations';
      }
      &.loading {
        &::after {
          content: 'Loading...';
        }
      }
    }
  }

  .collection-container {
    margin-top: 0.5rem;
    ${media(ScreenSize.Small)} {
      margin-bottom: 5rem;
    }
  }

  .cta-text {
    color: ${props => props.theme.colors.primary};
    font-size: 1.5rem;
    font-weight: ${props => props.theme.font.medium};
    ${media(ScreenSize.Small)} {
      font-size: 2rem;
      padding: 0 12rem;
    }
  }

  &.project {
    position: relative;
  }

  & .results-label {
    color: ${props => props.theme.colors.black};
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  & > :first-child {
    margin-bottom: -1.6rem;
  }
`

export default StyledSearchResults
