import { getEnvConfig } from '@aims-lib'

const requestApi = async (email: string): Promise<boolean> => {
  const config = getEnvConfig('demo-api')
  const headers: HeadersInit = {
    'X-Client-Id': config.clientId,
    'X-Client-Secret': config.clientSecret
  }
  const method = 'POST'
  const body = JSON.stringify({ email })
  const response = await fetch(`${config.host}/resetting/request/`, { headers, method, body })
  if (!response.ok) {
    const errorMessage = await response.json()
    throw new Error(errorMessage.message)
  }
  return true
}

const resetPasswordNext = async (password: string, token: string): Promise<void> => {
  const response = await fetch('/api/reset', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ password, token })
  })

  if (!response.ok) {
    const errorMessage = await response.text()
    throw new Error(errorMessage)
  }
}

const resetPassword = async (password: string, token: string): Promise<void> => {
  const config = getEnvConfig('demo-api')
  const headers: HeadersInit = {
    'X-Client-Id': config.clientId,
    'X-Client-Secret': config.clientSecret
  }
  const method = 'POST'
  const body = JSON.stringify({ password })
  const response = await fetch(`${config.host}/resetting/reset/${token}`, { headers, method, body })
  if (!response.ok) {
    const errorMessage = await response.json()
    throw new Error(errorMessage.message.replace(/^[^:]+:(.*)$/im, '$1'))
  }
}

const requestResetNext = async (email: string): Promise<void> => {
  const response: Response = await fetch('/api/request-reset', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  })
  if (!response.ok) {
    const error = await response.text()
    throw new Error(error)
  }
}

export {
  requestApi,
  resetPassword,
  resetPasswordNext,
  requestResetNext
}
