import { DetailedTrack, NestedTrackGroupProps } from '@aims-track/types'

import React from 'react'
import { StyledNestedTrackGroup } from '@aims-track/styled'
import Track from '../Track'

const NestedTrackGroup = ({ master, tracks, expanded, handleChange, project }: NestedTrackGroupProps): JSX.Element => {
  const renderTracksList = (): JSX.Element => {
    const versions = `version${tracks.length > 1 ? 's' : ''}`
    const expandButtonText = `${expanded ? 'collapse' : `+${tracks.length} ${versions}`}`
    const expandButton: React.ReactElement = (
      <button
        onClick={() => handleChange(expanded ? null : master.id)}
        className={'expand-button'}
      >
        {expandButtonText}
      </button>
    )

    return (
      <div className={'tracks-list'}>
        {!expanded && expandButton}
        {expanded && tracks.map((track: DetailedTrack) => (
          <Track track={track} key={track.id} nested={true} project={project}/>
        ))}
        {expanded && expandButton}
      </div>
    )
  }

  return (
    <StyledNestedTrackGroup expanded={expanded}>
      <Track track={master} nested={true} project={project}/>
      {renderTracksList()}
    </StyledNestedTrackGroup>
  )
}

export default NestedTrackGroup
