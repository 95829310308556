import { ScreenSize, media } from '@aims-theme'

import styled from 'styled-components'

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & .main {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 4rem;
    flex: 1;

    ${media(ScreenSize.Small)} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    &.textual {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      ${media(ScreenSize.Small)} {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
`

export default StyledLayout
