import { Action, Dispatch } from 'redux'
import {
  ApiFilter,
  ApiFilterCondition,
  SearchResponse,
  SearchResults,
  SearchType
} from '@aims-search/types'
import {
  CLEAR_PRELOADED_RESULTS,
  CLEAR_SEGMENT,
  INCREMENT_EXTRA_FILTER_INDEX,
  LOAD_MORE_BEGIN,
  LOAD_MORE_ERROR,
  LOAD_MORE_SUCCESS,
  REFRESH_FILTERS_KEY,
  RESET_FILTERS,
  SEARCH_BEGIN,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
  SET_FIELDS_FILE,
  SET_FIELDS_ID,
  SET_FIELDS_TEXT,
  SET_FILTERS,
  SET_LAST_SEARCH_STATE,
  SET_MODIFIERS_IGNORE_VOCALS,
  SET_MODIFIERS_PRIORITISE_BPM,
  SET_PAGINATION,
  SET_PRELOADED_PROMPT_RESULTS,
  SET_PRELOADED_RESULTS,
  SET_PROMPT_RESULTS,
  SET_RESULTS,
  SET_SEARCH_HASH,
  SET_SEGMENT_OFFSET_AND_LIMIT,
  SET_SEGMENT_TRACK
} from './action-types'
import {
  ClearPreloadedResultsAction,
  ClearSearchSegmentAction,
  IncrementExtraFilterIndexAction,
  LastSearchState,
  LoadMoreBeginAction,
  LoadMoreErrorAction,
  LoadMoreSuccessAction,
  RefreshFiltersKeyAction,
  SearchBeginAction,
  SearchErrorAction,
  SearchStateSearchOnly,
  SearchSuccessAction,
  SetLastSearchStateAction,
  SetPaginationAction,
  SetPreloadedPromptsResultsAction,
  SetPreloadedResultsAction,
  SetPromptResultsAction,
  SetResultsAction,
  SetSearchFieldsFileAction,
  SetSearchFieldsIdAction,
  SetSearchFieldsTextAction,
  SetSearchFiltersAction,
  SetSearchHashAction,
  SetSearchModifiersIgnoreVocalsAction,
  SetSearchModifiersPrioritiseBpmAction,
  SetSearchSegmentOffsetAndLimitAction,
  SetSearchSegmentTrackAction
} from './types'
import {
  consolidateFilterConfiguration,
  isTextSearch,
  searchSimilar,
  searchTracks
} from '@aims-search/lib'
import {
  getLastSearchState,
  getPreloadedPromptResults,
  getPreloadedResults,
  getSearchFieldsFile,
  getSearchFieldsId,
  getSearchFieldsText,
  getSearchHash,
  getSearchPaginationPage,
  getSearchPaginationSize,
  getSearchSegmentChanged,
  getSearchSegmentLimit,
  getSearchSegmentOffset,
  isSearchModifierIgnoreVocalsActive,
  isSearchModifierPrioritiseBpmActive
} from './selectors'
import { getUser, setIsLoading } from '@aims-store/auth'

import { CollectionPagination } from '@aims-collection/types'
import { DetailedTrack } from '@aims-track/types'
import { State } from '@aims-app-store'
import { UserIdentity } from '@aims-auth/types'
import { convertFormDataToObject } from '@aims-lib'
import { enqueue } from '@aims-layout'

const updateSearchFilters = (predefinedConditions: Array<ApiFilter & ApiFilterCondition>, filterData: FormData): ApiFilter => {
  const searchFilters = convertFormDataToObject(filterData) as unknown as ApiFilter

  searchFilters.conditions = searchFilters.conditions ?? []

  if (!Object.hasOwn(searchFilters.conditions, 'push')) {
    const newConditions: ApiFilterCondition[] = []
    Object.values(searchFilters.conditions).forEach((value: ApiFilter | ApiFilterCondition): void => {
      newConditions.push(value as ApiFilterCondition)
    })
    searchFilters.conditions = newConditions
  }

  if (predefinedConditions.length !== 0) {
    searchFilters.conditions.push(...predefinedConditions)
  }

  return searchFilters
}

export const setLastSearchState = (state: LastSearchState): SetLastSearchStateAction => ({
  type: SET_LAST_SEARCH_STATE,
  state
})

export const searchBegin = (): SearchBeginAction => ({
  type: SEARCH_BEGIN
})

export const setSearchHash = (hash: string|null): SetSearchHashAction => ({
  type: SET_SEARCH_HASH,
  payload: {
    hash
  }
})

export const searchSuccess = (results: SearchResults, preloadedResults?: SearchResults): SearchSuccessAction => ({
  type: SEARCH_SUCCESS,
  payload: {
    results,
    preloadedResults
  }
})

export const searchError = (message: string): SearchErrorAction => ({
  type: SEARCH_ERROR,
  payload: {
    message
  }
})

export const setResults = (results: SearchResults): SetResultsAction => ({
  type: SET_RESULTS,
  payload: {
    results
  }
})

export const setPromptResults = (promptResults: SearchResults): SetPromptResultsAction => ({
  type: SET_PROMPT_RESULTS,
  payload: {
    promptResults
  }
})

export const setPreloadedPromptResults = (preloadedPromptResults: SearchResults): SetPreloadedPromptsResultsAction => ({
  type: SET_PRELOADED_PROMPT_RESULTS,
  payload: {
    preloadedPromptResults
  }
})

export const search = (type: SearchType, filterData: FormData): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(searchBegin())

    try {
      const state = getState()
      const text = getSearchFieldsText(state)
      const file = getSearchFieldsFile(state)
      const hash = getSearchHash(state)
      const id = getSearchFieldsId(state)
      const ignoreVocals = isSearchModifierIgnoreVocalsActive(state)
      const prioritiseBpm = isSearchModifierPrioritiseBpmActive(state)
      const lastSearchState = getLastSearchState(state)
      const user = getUser(state) as UserIdentity
      const offset = getSearchSegmentOffset(state)
      const limit = getSearchSegmentLimit(state)
      const segmentChanged = getSearchSegmentChanged(state)
      const pageSize = getSearchPaginationSize(state)
      const predefinedConditions = user.predefinedFilter !== null && user.predefinedFilter.conditions?.length > 0
        ? user.predefinedFilter.conditions as Array<ApiFilter & ApiFilterCondition>
        : []
      const searchFilters = updateSearchFilters(predefinedConditions, filterData)
      const filters = consolidateFilterConfiguration(searchFilters as unknown as Record<string, unknown>)
      const fileName = file !== null ? file.name : text
      const usingSearchText = isTextSearch(type)
      const loadPrompts = usingSearchText && user.textSearch

      let newHash = null
      let promptResults: SearchResponse|undefined
      let preloadedPromptResults: SearchResponse|undefined

      if (lastSearchState !== null && fileName === lastSearchState.fileName) {
        newHash = hash
      }

      dispatch(setSearchFilters(filters))

      const results = usingSearchText
        ? await searchTracks({ text, user, pageSize, filters, hash: newHash })
        : await searchSimilar({ type, id, text, file, filters, ignoreVocals, prioritiseBpm, user, offset, limit, pageSize, segmentChanged, hash: newHash })

      if (loadPrompts) {
        promptResults = await searchTracks({ text, user, pageSize, filters, prompt: true, hash: newHash })
        if (promptResults !== undefined) {
          dispatch(setPromptResults(promptResults))
        }
      }

      if (newHash === null) {
        newHash = usingSearchText && promptResults !== undefined ? promptResults.hash : results.hash
      }

      const { fields, segment, modifiers }: SearchStateSearchOnly = state.data.search
      dispatch(setLastSearchState({ fields, segment, filters, modifiers, type, fileName }))
      dispatch(searchSuccess(results))
      dispatch(setSearchHash(newHash))

      const preloadedResults = isTextSearch(type)
        ? await searchTracks({ text, user, page: 2, pageSize, filters, hash: newHash })
        : await searchSimilar({ type, id, text, file, filters, ignoreVocals, prioritiseBpm, user, offset, limit, page: 2, pageSize, segmentChanged, hash: newHash })

      if (loadPrompts) {
        preloadedPromptResults = await searchTracks({ text, user, page: 2, pageSize, filters, prompt: true, hash: newHash })
        if (preloadedPromptResults !== undefined) {
          dispatch(setPreloadedPromptResults(preloadedPromptResults))
        }
      }

      dispatch(setPagination({ page: 2, pageSize: 10 }))
      dispatch(searchSuccess(results, preloadedResults))
    } catch (err) {
      const error = err as Error
      dispatch(searchError(error.message))
      dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
    }
  }

export const setSearchFieldsText = (text: string): SetSearchFieldsTextAction => ({
  type: SET_FIELDS_TEXT,
  text
})

export const setSearchFieldsFile = (file: File): SetSearchFieldsFileAction => ({
  type: SET_FIELDS_FILE,
  file
})

export const setSearchFieldsId = (id: number): SetSearchFieldsIdAction => ({
  type: SET_FIELDS_ID,
  id
})

export const setSearchFilters = (filters?: ApiFilter): SetSearchFiltersAction => ({
  type: SET_FILTERS,
  payload: {
    filters
  }
})

export const setSearchModifiersPrioritiseBpm = (checked: boolean): SetSearchModifiersPrioritiseBpmAction => ({
  type: SET_MODIFIERS_PRIORITISE_BPM,
  checked
})

export const setSearchModifiersIgnoreVocals = (checked: boolean): SetSearchModifiersIgnoreVocalsAction => ({
  type: SET_MODIFIERS_IGNORE_VOCALS,
  checked
})

export const setSearchSegmentOffsetAndLimit = (offset: number, limit: number, changed: boolean): SetSearchSegmentOffsetAndLimitAction => ({
  type: SET_SEGMENT_OFFSET_AND_LIMIT,
  offset,
  limit,
  changed
})

export const clearSearchSegment = (): ClearSearchSegmentAction => ({
  type: CLEAR_SEGMENT
})

export const setSearchSegmentTrack = (track: DetailedTrack, audio: HTMLAudioElement): SetSearchSegmentTrackAction => ({
  type: SET_SEGMENT_TRACK,
  track,
  audio
})

export const loadMoreBegin = (): LoadMoreBeginAction => ({
  type: LOAD_MORE_BEGIN
})

export const loadMoreSuccess = (results: SearchResults): LoadMoreSuccessAction => ({
  type: LOAD_MORE_SUCCESS,
  results
})

export const loadMoreError = (error: Error): LoadMoreErrorAction => ({
  type: LOAD_MORE_ERROR,
  error
})

export const loadMore = (): (dispatch: Dispatch, getState: () => State) => void =>
  async (dispatch: Dispatch, getState: () => State): Promise<void> => {
    dispatch(setIsLoading(true))

    try {
      const state = getState()
      const user = getUser(state) as UserIdentity
      const page = getSearchPaginationPage(state)
      const hash = getSearchHash(state)
      const pageSize = getSearchPaginationSize(state)
      const preloadedResults = getPreloadedResults(state)
      const preloadedPromptResults = getPreloadedPromptResults(state)
      const { type, fields, modifiers, filters, segment } = getLastSearchState(state) as LastSearchState
      const { id, file, text } = fields
      const { ignoreVocals, prioritiseBpm } = modifiers
      const { offset, limit, changed } = segment

      dispatch(setResults(preloadedResults))
      if (isTextSearch(type)) {
        dispatch(setPromptResults(preloadedPromptResults))
      }
      dispatch(clearPreloadedResults())

      let promptResponse: SearchResponse|undefined

      const response = isTextSearch(type)
        ? await searchTracks({ text, user, page: page + 1, pageSize, filters, hash })
        : await searchSimilar({ type, id, text, file, filters, ignoreVocals, prioritiseBpm, user, offset, limit, page: page + 1, pageSize, segmentChanged: changed, hash })

      if (user.textSearch && isTextSearch(type)) {
        promptResponse = await searchTracks({ text, user, page: page + 1, pageSize, filters, prompt: true, hash })
      }

      dispatch(setPagination({ page: page + 1, pageSize }))
      dispatch(setPreloadedResults(response))

      if (promptResponse !== undefined) {
        dispatch(setPreloadedPromptResults(promptResponse))
      }
    } catch (err) {
      const error = err as Error
      dispatch(loadMoreError(error))
      dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
    }
  }

export const setPreloadedResults = (preloadedResults: SearchResults): SetPreloadedResultsAction => ({
  type: SET_PRELOADED_RESULTS,
  payload: {
    preloadedResults
  }
})

export const clearPreloadedResults = (): ClearPreloadedResultsAction => ({
  type: CLEAR_PRELOADED_RESULTS
})

export const refreshFiltersKey = (): RefreshFiltersKeyAction => ({
  type: REFRESH_FILTERS_KEY
})

export const incrementExtraFilterIndex = (): IncrementExtraFilterIndexAction => ({
  type: INCREMENT_EXTRA_FILTER_INDEX
})

export const setPagination = (pagination: CollectionPagination): SetPaginationAction => ({
  type: SET_PAGINATION,
  payload: {
    pagination: pagination
  }
})

export const resetSearchFilters = (): Action => ({
  type: RESET_FILTERS
})
