import styled from 'styled-components'

const StyledTrackTabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .category-tabs {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 1rem;
    margin-top: 1rem;

    button {
      border-radius: 0.3125rem;
      border-width: 0.0625rem;
      box-shadow: none;
      font-size: 1.1rem;
      padding: 0.125rem 0.75rem;
    }

    & .inactive {
      color: ${props => props.theme.colors.grey.medium};
    }
  }
`

export default StyledTrackTabs
