import {
  ADD_TRACK,
  CREATE_BEGIN,
  CREATE_ERROR,
  CREATE_SUCCESS,
  LIST_BEGIN,
  LIST_ERROR,
  LOAD_BEGIN,
  LOAD_ERROR,
  LOAD_SUCCESS,
  REFRESH_PROJECT,
  REMOVE_BEGIN,
  REMOVE_ERROR,
  REMOVE_SUCCESS,
  REMOVE_TRACK,
  SET_IS_PROJECT_OUTDATED,
  SET_IS_REFRESHING_PROJECT,
  SET_LOADING,
  SET_NEW_PROJECT_CREATED,
  SET_NEW_PROJECT_TITLE,
  SET_PRELOADED_SUGGESTIONS,
  SET_PROJECTS,
  SET_PROJECT_SUGGESTIONS_PAGINATION,
  SET_SEARCH_TERM,
  SET_SUGGESTIONS,
  UPDATE_BEGIN,
  UPDATE_ERROR,
  UPDATE_SUCCESS
} from './action-types'
import {
  CreateSuccessAction,
  LoadSuccessAction,
  ProjectState,
  RefreshProjectAction,
  RemoveSuccessAction,
  SetIsProjectOutdatedAction,
  SetIsRefreshingProjectAction,
  SetLoadingAction,
  SetNewProjectCreatedAction,
  SetNewProjectTitleAction,
  SetPreloadedSuggestionsAction,
  SetProjectSuggestionsPaginationAction,
  SetProjectsAction,
  SetSearchTermAction,
  SetSuggestionsListAction,
  SetTrackAction,
  UpdateSuccessAction
} from './types'

import { Action } from 'redux'
import { DetailedTrack } from '@aims-track/types'
import initialState from './initialState'

const reducer = (state = initialState, action: Action = { type: undefined }): ProjectState => {
  switch (action.type) {
    case ADD_TRACK:
      return Object.assign({}, state, {
        tracks: {
          ...state.tracks,
          tracks: state.tracks.tracks.concat((action as SetTrackAction).payload.track)
        },
        suggestions: {
          ...state.suggestions,
          tracks: state.suggestions.tracks?.filter((track: DetailedTrack) =>
            track.idClient !== (action as SetTrackAction).payload.track.idClient)
        }
      })
    case CREATE_BEGIN:
      return Object.assign({}, state, {
        loading: true
      })
    case CREATE_ERROR:
      return Object.assign({}, state, {
        loading: false
      })
    case CREATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        projects: Object.assign({}, state.projects, {
          collections: [(action as CreateSuccessAction).project, ...state.projects.collections],
          pagination: Object.assign({}, state.projects.pagination, {
            itemCount: state.projects.pagination.itemCount + 1
          })
        })
      })
    case LIST_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        projects: initialState.projects
      })
    case LIST_ERROR:
      return Object.assign({}, state, {
        loading: false
      })
    case SET_PROJECTS:
      return Object.assign({}, state, {
        projects: (action as SetProjectsAction).projects
      })
    case LOAD_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        project: initialState.project,
        tracks: initialState.tracks,
        suggestions: initialState.suggestions,
        suggestionsPagination: initialState.suggestionsPagination
      })
    case LOAD_ERROR:
      return Object.assign({}, state, {
        loading: false
      })
    case LOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        project: (action as LoadSuccessAction).project,
        tracks: (action as LoadSuccessAction).tracks,
        suggestions: (action as LoadSuccessAction).suggestions
      })
    case REFRESH_PROJECT:
      return Object.assign({}, state, {
        project: (action as RefreshProjectAction).payload.project,
        projects: Object.assign({}, state.projects, {
          collections: state.projects.collections.map(project => {
            if (project.key === (action as RefreshProjectAction).payload.project.key) {
              return (action as RefreshProjectAction).payload.project
            }
            return project
          })
        })
      })
    case REMOVE_BEGIN:
      return Object.assign({}, state, {
        loading: true
      })
    case REMOVE_ERROR:
      return Object.assign({}, state, {
        loading: false
      })
    case REMOVE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        project: initialState.project,
        projects: Object.assign({}, state.projects, {
          collections: state.projects.collections.filter(
            project => (action as RemoveSuccessAction).project.key !== project.key
          )
        })
      })
    case REMOVE_TRACK:
      return Object.assign({}, state, {
        tracks: {
          ...state.tracks,
          tracks: state.tracks.tracks.filter((track: DetailedTrack) =>
            track.idClient !== (action as SetTrackAction).payload.track.idClient)
        }
      })
    case SET_IS_PROJECT_OUTDATED:
      return Object.assign({}, state, {
        isProjectOutdated: (action as SetIsProjectOutdatedAction).payload.on
      })
    case SET_IS_REFRESHING_PROJECT:
      return Object.assign({}, state, {
        isRefreshingProject: (action as SetIsRefreshingProjectAction).payload.on
      })
    case SET_LOADING:
      return Object.assign({}, state, {
        loading: (action as SetLoadingAction).payload.on
      })
    case SET_NEW_PROJECT_CREATED:
      return Object.assign({}, state, {
        newProject: Object.assign({}, state.newProject, {
          created: (action as SetNewProjectCreatedAction).created
        })
      })
    case SET_NEW_PROJECT_TITLE:
      return Object.assign({}, state, {
        newProject: Object.assign({}, state.newProject, {
          title: (action as SetNewProjectTitleAction).title
        })
      })
    case SET_SEARCH_TERM:
      return Object.assign({}, state, {
        searchTerm: (action as SetSearchTermAction).searchTerm
      })
    case SET_PRELOADED_SUGGESTIONS:
      return Object.assign({}, state, {
        preloadedSuggestions: (action as SetPreloadedSuggestionsAction).payload.preloadedSuggestions
      })
    case SET_PROJECT_SUGGESTIONS_PAGINATION:
      return Object.assign({}, state, {
        suggestionsPagination: (action as SetProjectSuggestionsPaginationAction).payload.pagination
      })
    case UPDATE_BEGIN:
      return Object.assign({}, state, {
        loading: true
      })
    case UPDATE_ERROR:
      return Object.assign({}, state, {
        loading: false
      })
    case UPDATE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        title: state.project.key === (action as UpdateSuccessAction).project.key
          ? (action as UpdateSuccessAction).project.title
          : state.project.title,
        projects: Object.assign({}, state.projects, {
          collections: state.projects.collections.map(
            project => (action as UpdateSuccessAction).project.key === project.key
              ? (action as UpdateSuccessAction).project
              : project
          )
        })
      })
    case SET_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: (action as SetSuggestionsListAction).payload.suggestions
      })
    default:
      return state
  }
}

export { initialState }
export default reducer
