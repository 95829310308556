import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledTrackName = styled.span`
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 1.125rem;
  font-weight: ${props => props.theme.font.medium};
  max-width: 60vw;
  overflow: hidden;
  gap: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${media(ScreenSize.Small)} {
    flex-grow: 0;
    max-width: none;
  }
  &:first-child {
    padding-left: 0;
  }
`

export default StyledTrackName
