import styled from 'styled-components'
import { theme } from '@aims-theme'

const StyledPresentationTextField = styled.div`
  position: relative;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  
  .presentation-text-field {
    background-color: ${props => props.theme.colors.grey.lightest};

    &__input {
      height: 1.5rem;
      padding: 0.5rem 1rem;
      font-weight: bold;
      border: 1px solid ${theme.colors.grey.light};
      border-radius: 0.25rem;
    }
  }

  & .MuiInputLabel-root {
    position: absolute;
    text-transform: uppercase;
    color: ${theme.colors.grey.mediumDark};
    transform: none !important;
    font-size: 0.8rem;
    top: -1rem;
    left: 0.5rem;
  }

  & .MuiOutlinedInput-notchedOutline {
    display: none;
  }
`

export default StyledPresentationTextField
