import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomSearchIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 26.264 26.385'}>
    <g id="Icons_ic_search_input" data-name="Icons / ic_search_input" transform="translate(0.78 0.78)">
      <path id="Stroke_3" data-name="Stroke 3" d="M2.517,0,7.1,4.585a1.217,1.217,0,0,1,0,1.721l-.849.849a1.217,1.217,0,0,1-1.721,0L0,2.624" transform="translate(17.246 17.192)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.56"/>
      <path id="Stroke_7" data-name="Stroke 7" d="M3.2,18.642A10.92,10.92,0,1,0,3.2,3.2,10.92,10.92,0,0,0,3.2,18.642Z" transform="translate(0 0)" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.56"/>
    </g>
  </SvgIcon>

export default CustomSearchIcon
