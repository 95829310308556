import { Button, Tooltip } from '@aims-controls'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import ProjectWatcher, { ProjectWatcherStickyBar } from './ProjectWatcher'
import React, { useEffect } from 'react'
import { downloadTracksCSV, filterTracksForCSVDownload } from '@aims-project/lib'
import { getProject, getProjectTracksPagination, getSuggestions, getTracks, load } from '@aims-store/project'
import { getUser, shouldAllowGrouping } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import AddRemoteTracksBar from '../AddRemoteTracksBar'
import { CollectionType } from '@aims-collection/types'
import Link from 'next/link'
import LoadMoreButton from './LoadMoreButton'
import Project from '../Project'
import StyledProjectDetailPage from './ProjectDetailPage.styled'
import UnprocessedProjectPane from './UnprocessedProjectPane'
import { UserIdentity } from '@aims-auth/types'
import { useExportCSVModal } from '@aims-project/hooks'
import { useNestingTracks } from '@aims-track/hooks'
import { useRouter } from 'next/router'

const CSVExportTooltipText = 'There are no internal songs from your library that can be exported.'

const ProjectDetailPage = (): JSX.Element => {
  const dispatch = useDispatch()
  const router = useRouter()
  const project = useSelector(getProject)
  const user = useSelector(getUser) as UserIdentity
  const tracks = useSelector(getTracks)
  const suggestions = useSelector(getSuggestions)
  const pagination = useSelector(getProjectTracksPagination)
  const projectKey = router.query.id as string
  const allowGrouping = useSelector(shouldAllowGrouping)
  const suggestionsExport = useExportCSVModal()
  const nestingTracks = useNestingTracks(tracks.tracks, project)
  const nestingSuggestions = useNestingTracks(suggestions.tracks)

  const projectLoaded = project.key === projectKey
  const projectTracksForCSVExport = filterTracksForCSVDownload(tracks.tracks)
  const canExportProjectTracks = projectTracksForCSVExport.length > 0

  useEffect(() => {
    if (project.key !== projectKey) {
      dispatch(load(projectKey, pagination.page, pagination.pageSize))
    }
  }, [projectKey])

  const renderCollectionGroupingTest = (): JSX.Element => {
    const renderGrouping = allowGrouping && projectLoaded

    if (!renderGrouping) {
      return <></>
    }

    return (
      <Link href={`/grouping-test/${CollectionType.Project}/${encodeURIComponent(project.key)}`}>
        <Button variant={'outlined'} color={'primary'}>Show Grouping Test for this Collection</Button>
      </Link>
    )
  }

  const renderProjectBody = (): JSX.Element => {
    const projectHasTracks = tracks.tracks.length > 0

    if (projectHasTracks) {
      const projectIsProcessed = project.processedAt !== null

      const renderSuggestions = (): JSX.Element => (
        <Grid container className={'tracks-container'}>
          <Grid container item justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <Typography className={'results-label'}>Suggested tracks</Typography>
            </Grid>
            <Grid item>
              {suggestionsExport.render()}
            </Grid>
          </Grid>
          <Grid item xs={12} className={'tracks'}>
            {nestingSuggestions.render()}
          </Grid>
          <Grid container item justifyContent={'center'}>
            <Grid item>
              <LoadMoreButton />
            </Grid>
          </Grid>
        </Grid>
      )

      const renderUnfinishedProcessing = (): JSX.Element => (
        <Grid container justifyContent={'center'}>
          <Grid item xs={8}>
            <Typography color={'primary'} variant={'h6'} align={'center'}>
              The project hasn&apos;t finish processing yet.
            </Typography>
            <Typography variant={'body1'} align={'center'}>
              Projects containing only external tracks (from links or audio files) take a bit longer to process.<br />
              The page will refresh automatically once there are suggestions to show.
            </Typography>
          </Grid>
        </Grid>
      )

      return (
        <Grid container>
          <Grid container className={'tracks-container'}>
            <Grid container item justifyContent={'space-between'} alignItems={'center'}>
              <Grid item>
                <Typography className={'results-label'}>Project tracks</Typography>
              </Grid>
              <Grid item>
                <Tooltip title={!canExportProjectTracks ? <Typography>{CSVExportTooltipText}</Typography> : ''}>
                  <span>
                    <Button
                      color={'secondary'}
                      variant={'text'}
                      size={'small'}
                      disabled={!canExportProjectTracks}
                      onClick={(): void => downloadTracksCSV(`${project.key}-project`, projectTracksForCSVExport, user.metadata)}
                    >Export to CSV</Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <div className={'tracks'}>
              {nestingTracks.render()}
            </div>
          </Grid>
          {projectIsProcessed ? renderSuggestions() : renderUnfinishedProcessing()}
        </Grid>
      )
    }

    return (
      <UnprocessedProjectPane />
    )
  }

  return (
    <StyledProjectDetailPage className={projectLoaded ? '' : 'loading-project'}>
      {projectLoaded ? (
        <>
          <Project project={project} featured />
          <AddRemoteTracksBar />
          {renderCollectionGroupingTest()}
          <ProjectWatcher>
            <ProjectWatcherStickyBar />
            {renderProjectBody()}
          </ProjectWatcher>
        </>
      ) : <CircularProgress />}
    </StyledProjectDetailPage>
  )
}

export default ProjectDetailPage
