import { DetailedTrack, GenericTrack, NestingTracksHook } from '@aims-track/types'
import { NestedTrackGroup, Track } from '@aims-track/components'
import React, { useState } from 'react'

import { Collection } from '@aims-collection/types'
import { getTracksAsNested } from '../lib'
import { getUser } from '@aims-store/auth'
import { useSelector } from 'react-redux'

const useNestingTracks = (tracks: DetailedTrack[], project?: Collection): NestingTracksHook => {
  const user = useSelector(getUser)
  const [expandedTrack, setExpandedTrack] = useState<DetailedTrack['id']|null>(null)

  const createTrackContainers = (): JSX.Element[] => {
    if (user?.nestVersions === true) {
      const nestedTracks = getTracksAsNested(tracks)

      return nestedTracks.map((track: GenericTrack): JSX.Element => {
        if (track.singular !== undefined) {
          return <Track track={track.singular} project={project} key={track.singular.id}/>
        }
        if (track.nested !== undefined) {
          const { masterTrack, tracksList } = track.nested
          return (
            <NestedTrackGroup
              key={`nested-track-group-${track.nested.masterTrack.id}`}
              project={project}
              master={masterTrack}
              tracks={tracksList}
              expanded={expandedTrack === masterTrack.id}
              handleChange={setExpandedTrack}
            />
          )
        }
        return <></>
      })
    }

    return tracks.map((track, index) => (
      <Track track={track} key={`${track.key ?? track.id}-${index}`} project={project} />
    ))
  }

  const render = (): JSX.Element => (
    <>
      {tracks.length > 0 && createTrackContainers()}
    </>
  )

  return {
    render
  }
}

export default useNestingTracks
