import { ApiConfiguration, Branding, UserIdentity } from '@aims-auth/types'

import { SearchFilterField } from '@aims-search/types'
import { State } from '@aims-app-store'
import { TrackField } from '@aims-track/types'

export const getAllFieldFilters = (state: State): SearchFilterField[] => state.data.auth.filterFields ?? []
export const getApiConfiguration = (state: State): ApiConfiguration|null => state.data.auth.apiConfiguration
export const getApiSecret = (state: State): string => state.data.auth.user?.apiSecret ?? ''
export const getAutoTaggingConfiguration = (state: State): ApiConfiguration|null => state.data.auth.autoTaggingConfiguration
export const getAutoTaggingMonthlyRequestLimit = (state: State): number => state.data.auth.user?.monthlyRequestLimit ?? 0
export const getAutoTaggingRemainingMonthlyRequests = (state: State): number => state.data.auth.user?.remainingMonthlyRequests ?? 0
export const getBranding = (state: State): Branding => state.data.auth.user?.branding ?? { footer: {}, header: {} }
export const getDownloadFilenameTemplate = (state: State): string|null => state.data.auth.user?.downloadFilenameTemplate ?? ''
export const getLicensingInfo = (state: State): string => state.data.auth.user?.licensingInfo ?? ''
export const getMetadata = (state: State): TrackField[] => state.data.auth.user?.metadata ?? []
export const getMultipleDownloadVersions = (state: State): boolean => state.data.auth.user?.multipleDownloadVersions ?? false
export const getSearchFilters = (state: State): string[] => state.data.auth.user?.filters ?? []
export const getShowThumbButtons = (state: State): boolean => state.data.auth.user?.showThumbButtons ?? false
export const getStreamingSecret = (state: State): string => state.data.auth.user?.streamingSecret ?? ''
export const getUser = (state: State): UserIdentity|null => state.data.auth.user
export const hasError = (state: State): boolean => state.data.auth.error
export const isAlbumsEnabled = (state: State): boolean => state.data.auth.user?.albums ?? false
export const isAutoTaggingEnabled = (state: State): boolean => state.data.auth.user?.autoTagging ?? false
export const isCdnEnabled = (state: State): boolean => state.data.auth.user?.cdn ?? false
export const isDownloadEnabled = (state: State): boolean => state.data.auth.user?.download ?? false
export const isHighlightsEnabled = (state: State): boolean => state.data.auth.user?.highlights ?? false
export const isIgnoreVocalsEnabled = (state: State): boolean => state.data.auth.user?.ignoreVocals ?? false
export const isLoading = (state: State): boolean => state.data.auth.loading
export const isLoggedOut = (state: State): boolean => state.data.auth.logout
export const isPlaylistsEnabled = (state: State): boolean => state.data.auth.user?.playlists ?? false
export const isPrioritiseBpmEnabled = (state: State): boolean => state.data.auth.user?.prioritiseBpm ?? false
export const isProjectsEnabled = (state: State): boolean => state.data.auth.user?.projects ?? false
export const isPublic = (state: State): boolean => state.data.auth.user?.public ?? false
export const shouldAllowGrouping = (state: State): boolean => state.data.auth.user?.email === 'jan.novotny@aimsapi.com' ?? false
export const wasResetCompleted = (state: State): boolean => state.data.auth.resetCompleted
export const wasResetRequested = (state: State): boolean => state.data.auth.resetRequested
