export const ADD_TRACK = 'DATA_PROJECT_ADD_TRACK'
export const CREATE_BEGIN = 'DATA_PROJECT_CREATE_BEGIN'
export const CREATE_ERROR = 'DATA_PROJECT_CREATE_ERROR'
export const CREATE_SUCCESS = 'DATA_PROJECT_CREATE_SUCCESS'
export const LIST_BEGIN = 'DATA_PROJECT_LIST_BEGIN'
export const LIST_ERROR = 'DATA_PROJECT_LIST_ERROR'
export const LOAD_BEGIN = 'DATA_PROJECT_LOAD_BEGIN'
export const LOAD_ERROR = 'DATA_PROJECT_LOAD_ERROR'
export const LOAD_SUCCESS = 'DATA_PROJECT_LOAD_SUCCESS'
export const REFRESH_PROJECT = 'DATA_PROJECT_REFRESH_PROJECT'
export const REMOVE_BEGIN = 'DATA_PROJECT_REMOVE_BEGIN'
export const REMOVE_ERROR = 'DATA_PROJECT_REMOVE_ERROR'
export const REMOVE_SUCCESS = 'DATA_PROJECT_REMOVE_SUCCESS'
export const REMOVE_TRACK = 'DATA_PROJECT_REMOVE_TRACK'
export const SET_IS_PROJECT_OUTDATED = 'DATA_PROJECT_SET_IS_PROJECT_OUTDATED'
export const SET_IS_REFRESHING_PROJECT = 'DATA_PROJECT_SET_IS_REFRESHING_PROJECT'
export const SET_LOADING = 'DATA_PROJECT_SET_LOADING'
export const SET_NEW_PROJECT_CREATED = 'DATA_PROJECT_SET_NEW_PROJECT_CREATED'
export const SET_NEW_PROJECT_TITLE = 'DATA_PROJECT_SET_NEW_PROJECT_TITLE'
export const SET_PRELOADED_SUGGESTIONS = 'DATA_PROJECT_SET_PRELOADED_SUGGESTIONS'
export const SET_PROJECTS = 'DATA_PROJECT_SET_PROJECTS'
export const SET_PROJECT_SUGGESTIONS_PAGINATION = 'DATA_PROJECT_SET_PROJECT_SUGGESTIONS_PAGINATION'
export const SET_SEARCH_TERM = 'DATA_PROJECT_SET_SEARCH_TERM'
export const SET_SUGGESTIONS = 'DATA_PROJECT_SET_SUGGESTIONS'
export const UPDATE_BEGIN = 'DATA_PROJECT_UPDATE_BEGIN'
export const UPDATE_ERROR = 'DATA_PROJECT_UPDATE_ERROR'
export const UPDATE_SUCCESS = 'DATA_PROJECT_UPDATE_SUCCESS'
