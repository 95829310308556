import { Grid, Typography } from '@material-ui/core'
import { PlayButton, TrackCueSheetInfo, TrackWaveform } from '@aims-track/components'
import React, { useEffect, useState } from 'react'
import { StyledMetadataItem, StyledTrackCode, StyledTrackDuration, StyledTrackName } from '@aims-track/styled'
import { decorateTime, isFirefox } from '@aims-lib'
import { getMetadata, getUser } from '@aims-store/auth'
import { getPlayerTrack, isPlaying, load, pause, play } from '@aims-store/player'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@aims-controls'
import { ProjectSnapshotTrackProps } from '@aims-project/types'
import { TrackFileType } from '@aims-track/types'
import { UserIdentity } from '@aims-auth/types'
import { downloadTrackNext } from '@aims-track/api'
import { enqueue } from '@aims-layout'
import { getIsLoading } from '@aims-store/project'
import { useCueSheetInfo } from '@aims-search/hooks'
import { useStreaming } from '@aims-player/hooks'

const ProjectSnapshotTrack = ({ track }: ProjectSnapshotTrackProps): JSX.Element => {
  const dispatch = useDispatch()
  const metadataStructure = useSelector(getMetadata)
  const user = useSelector(getUser) as UserIdentity
  const loading = useSelector(getIsLoading)

  const playing = useSelector(isPlaying)
  const playedTrack = useSelector(getPlayerTrack)

  const [loaded, setLoaded] = useState(false)
  const [playable, setPlayable] = useState(false)
  const [audio, setAudio] = useState(null as HTMLAudioElement | null)
  const { getItem, exposedMetadata } = useCueSheetInfo(track, metadataStructure)

  const { trackStreamUrl, trackDownloadUrl } = useStreaming(user.streamingSecret, user.cdn)

  const tmpAudio = new Audio()
  tmpAudio.preload = 'none'

  const onAudioLoaded = (): void => {
    setLoaded(true)
  }

  const onPlaying = (): void => {
    setPlayable(true)
  }

  const onEnded = (): void => {
    dispatch(pause())
  }

  const handleDownload = (): void => {
    const download = (fileName: string, text: string): void => {
      const tempAnchor = document.createElement('a')

      tempAnchor.setAttribute('href', fileName)
      tempAnchor.setAttribute('download', text)
      tempAnchor.setAttribute('target', isFirefox() ? '_blank' : '_self')
      tempAnchor.setAttribute('rel', 'noopener noreferrer')
      tempAnchor.click()
    }

    const fileType = TrackFileType.mp3
    const url = trackDownloadUrl(track, null, fileType)
    dispatch(enqueue({ message: `"${track.trackName}.${fileType}" is being downloaded, please wait...` }))

    downloadTrackNext(url)
      .then(() => {
        download(url, `${track.trackName}.${fileType}`)
        dispatch(enqueue({ message: `"${track.trackName}.${fileType}" was downloaded successfully.` }))
      })
      .catch((err) => {
        dispatch(enqueue({ message: (err as Error).message }))
      })
  }

  useEffect(() => {
    if (audio === null) {
      return
    }
    audio.addEventListener('loadeddata', onAudioLoaded)
    audio.addEventListener('playing', onPlaying)
    audio.addEventListener('ended', onEnded)
    audio.src = trackStreamUrl(track)

    return () => {
      audio.removeEventListener('loadeddata', onAudioLoaded)
    }
  }, [audio])

  const toggleTrack = (): void => {
    if ((playedTrack === null || playedTrack.id !== track.id) && audio !== null) {
      // the playback needs to be started synchronously (even though it's done "again" in the store later),
      // since browsers can block playback if done asynchronously
      void audio.play() // eslint-disable-line no-void
      dispatch(load(audio, track))
      dispatch(play())
      return
    }

    dispatch(playing ? pause() : play())
  }

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        <Grid container item xs={12} sm={9} alignItems={'center'}>
          <Grid
            xs={12}
            container
            item
            alignItems={'center'}
            wrap={'nowrap'}
            className={'track-headline'}
            spacing={1}
            alignContent={'center'}
          >
            <Grid item>
              {track.trackCode !== null && <StyledTrackCode>{track.trackCode}</StyledTrackCode>}
            </Grid>
            <Grid item>
              <StyledTrackName>
                {typeof track.explicit !== 'undefined' && track.explicit && (
                  <StyledMetadataItem className={'explicit'}>Explicit</StyledMetadataItem>
                )}
                <Typography component={'header'} variant={'h6'}>{track.trackName ?? 'N/A'}</Typography>
              </StyledTrackName>
            </Grid>
            <Grid item>
              <StyledTrackDuration>{loaded && audio !== null && audio.duration > 0 ? decorateTime(audio.duration) : '--:--'}</StyledTrackDuration>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={'track-metadata'}>
              {exposedMetadata.map(field => {
                const item = getItem(field)

                return (item != null) && (
                  <Grid item key={field}>
                    <StyledMetadataItem>
                      {item.label}:
                      <span className={'description'}>
                        {item.value}
                      </span>
                    </StyledMetadataItem>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid container justifyContent={'flex-end'} alignContent={'center'}>
            <TrackCueSheetInfo track={track} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} alignItems={'center'} className={'track-play-area'}>
        <Grid item container xs={12} md={10} alignItems={'center'} className={'track-play-area'}>
          <PlayButton
            track={track}
            loaded={loaded}
            playable={playable}
            item
            xs
            onClick={toggleTrack}
          />
          <TrackWaveform
            audioSecret={user.streamingSecret}
            track={track}
            loaded={loaded}
            audio={loaded && audio !== null ? audio : tmpAudio}
          />
        </Grid>
        {user.download && (
          <Grid container item xs={12} md={2} alignItems={'center'} justifyContent={'flex-end'}>
            <Grid item>
              <Button
                disabled={loading}
                variant={'outlined'}
                onClick={handleDownload}
                size={'medium'}
                color={'secondary'}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        )}
        <audio ref={setAudio} preload={'auto'} />
      </Grid>
    </Grid>
  )
}

export default ProjectSnapshotTrack
