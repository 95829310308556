import styled from 'styled-components'

const StyledSearchPane = styled.form`
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .top-panel {
    background-color: ${props => props.theme.colors.grey.lightest};
    padding: 2rem 1rem;

    & .search-area {
      display: flex;
      gap: 0.8rem;
      align-items: center;

      & .track-search-bar {
        flex: 1;
      }

      & .search-button {
        padding: 0.65rem 1.15rem;
        margin-left: auto;
        width: 12.5rem;
      }
    }
  }

  & .modifiers {
    align-items: center;
    display: flex;
    gap: 1rem;
    margin: 0.5rem 0 0 0.5rem;

    & .MuiTypography-body1 {
      color: ${props => props.theme.colors.grey.medium};
    }
  }
`

export default StyledSearchPane
