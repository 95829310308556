import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomThumbsUpIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 23.4 23.404'}>
    <g id="Icons_ic_like_normal" data-name="Icons / ic_like_normal" transform="translate(0.7 0.704)">
      <path id="Stroke_1" data-name="Stroke 1" d="M16.041,14.817a2.123,2.123,0,0,0-.243-3.688,2.2,2.2,0,0,0,.213-.944A2.163,2.163,0,0,0,13.869,8H7.551L9.736,2.746A2,2,0,0,0,9.08.37a1.914,1.914,0,0,0-2.7.456L.589,9.912a3.415,3.415,0,0,0-.339.616A3.254,3.254,0,0,0,0,11.778v5.1A5.075,5.075,0,0,0,5.027,22h8.9a2.163,2.163,0,0,0,2.142-2.184,2.2,2.2,0,0,0-.45-1.335,2.2,2.2,0,0,0,.425-3.664Z" transform="translate(5)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_3" data-name="Stroke 3" d="M3.69,11H1.31A1.258,1.258,0,0,1,0,9.8V1.2A1.258,1.258,0,0,1,1.31,0H3.69A1.258,1.258,0,0,1,5,1.2V9.8A1.258,1.258,0,0,1,3.69,11Z" transform="translate(0 10)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
    </g>
  </SvgIcon>

export default CustomThumbsUpIcon
