import React, { MouseEvent } from 'react'

import { logout } from '@aims-store/auth'
import { useDispatch } from 'react-redux'

const Logout = (): JSX.Element => {
  const dispatch = useDispatch()
  const onClick = (event: MouseEvent): void => {
    event.preventDefault()
    dispatch(logout())
  }
  return (
    <a href={'#'} onClick={onClick}>
      Log out
    </a>
  )
}

export default Logout
