import { PlaylistPluggingState } from './types'

const initialState: PlaylistPluggingState = {
  search: {
    filters: null,
    pagination: {
      page: 1,
      pageSize: 40
    },
    loadInProgress: false,
    loadMoreInProgress: false,
    preloadInProgress: false,
    hash: null,
    lastTarget: '',
    currentLoadAttempt: -1,
    currentPreloadAttempt: -1
  },
  playlists: [],
  preloadedPlaylists: []
}

export default initialState
