import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomVimeoIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 22 22'}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M21,3.955814 L21,17.044186 C21,18.1186047 20.608392,19.0465116 19.825175,19.827907 C19.041958,20.6093023 18.111888,21 17.034965,21 L3.965035,21 C2.888112,21 1.958042,20.6093023 1.174825,19.827907 C0.391608,19.0465116 0,18.1186047 0,17.044186 L0,3.955814 C0,2.8813953 0.391608,1.9534884 1.174825,1.172093 C1.958042,0.3906977 2.888112,0 3.965035,0 L17.034965,0 C18.111888,0 19.041958,0.3906977 19.825175,1.172093 C20.608392,1.9534884 21,2.8813953 21,3.955814 Z" id="Shape-path" fill="#13B8EB" fillRule="nonzero"></path>
      <path d="M18.158066,6.94801169 C18.255545,5.04698829 17.524449,4.04895099 15.964776,4.00142539 C13.868967,3.95389979 12.455514,5.09451379 11.724417,7.47079309 C12.114335,7.28069079 12.504253,7.23316519 12.845432,7.23316519 C13.625268,7.23316519 13.966446,7.66089539 13.868967,8.51635599 C13.820227,9.03913739 13.479049,9.75202119 12.845432,10.7500585 C12.211815,11.7005702 11.724417,12.2233516 11.431979,12.2233516 C11.04206,12.2233516 10.652142,11.4629422 10.310964,9.98964909 C10.213485,9.51439319 9.969786,8.37377919 9.677347,6.61533259 C9.384909,4.95193709 8.702552,4.14400209 7.484058,4.28657889 C6.947921,4.33410449 6.216824,4.80936029 5.242029,5.61729529 C4.99833,5.80739759 4.608412,6.14007669 4.121014,6.56780699 C3.633617,6.99553719 3.243699,7.32821629 3,7.51831869 L3.731096,8.42130479 C4.413453,7.94604889 4.803371,7.70842099 4.900851,7.70842099 C5.436988,7.70842099 5.924386,8.51635599 6.363043,10.0847003 C6.509263,10.5599561 6.704222,11.3203655 6.99666,12.2708772 C7.289099,13.2213889 7.484058,13.9817982 7.630277,14.4570541 C8.263894,16.0253984 8.994991,16.8333333 9.872306,16.8333333 C11.285759,16.8333333 13.040391,15.5501425 15.087461,12.9362354 C17.085791,10.3223282 18.109326,8.37377919 18.158066,6.94801169 Z" id="Shape-path" fill="#FFFFFF" fillRule="nonzero"></path>
    </g>
  </SvgIcon>

export default CustomVimeoIcon
