import { Info, Warning } from '@material-ui/icons'

import { InfoMessageProps } from '../types'
import InfoMessageStyled from './InfoMessage.styled'
import React from 'react'
import { Typography } from '@material-ui/core'

const InfoMessage = ({ type, children }: InfoMessageProps): JSX.Element => {
  const messageTextClasses = `message-text ${type}`
  let icon = <></>

  switch (type) {
    case 'warning': {
      icon = <Warning className={'icon'}/>
      break
    }
    case 'info': {
      icon = <Info className={'icon'}/>
      break
    }
    default: break
  }

  return (
    <InfoMessageStyled className={type}>
      {icon}
      <Typography className={messageTextClasses}>
        {children}
      </Typography>
    </InfoMessageStyled>
  )
}

export default InfoMessage
