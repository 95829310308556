import MuiButton from './button'
import MuiCheckbox from './checkbox'
import MuiChip from './chip'
import MuiFilledInput from './filledInput'
import MuiFormControl from './formControl'
import MuiInputBase from './inputBase'
import MuiInputLabel from './inputLabel'
import MuiLink from './link'
import MuiOutlinedInput from './outlinedInput'
import MuiPaper from './paper'
import MuiRadio from './radio'
import MuiSwitch from './switch'
import MuiTooltip from './tooltip'
import MuiTypography from './typography'
import { ThemeOptions } from '@material-ui/core'

const overrides: ThemeOptions['overrides'] = {
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiFilledInput,
  MuiFormControl,
  MuiInputBase,
  MuiInputLabel,
  MuiLink,
  MuiOutlinedInput,
  MuiPaper,
  MuiRadio,
  MuiSwitch,
  MuiTooltip,
  MuiTypography
}

export default overrides
