import React, { ChangeEvent, useState } from 'react'

import { Grid } from '@material-ui/core'
import { SearchFilterWidgetProps } from '@aims-search/types'
import { StyledSelect } from '@aims-controls'
import { trackFieldKeyMap } from '@aims-search/hooks'

const BooleanWidget = ({ index, field }: SearchFilterWidgetProps): JSX.Element => {
  const [value, setValue] = useState('')
  const handleChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setValue(event.target.value as string)
  }
  const label = trackFieldKeyMap.get(field) ?? ''

  return (
    <Grid container>
      <Grid item xs={12} className={'filtering-label'}>
        {label}
      </Grid>
      <Grid item xs={12}>
        <input type="hidden" name={`conditions[${index}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index}][operator]`} value="eq" />
        <StyledSelect
          variant={'outlined'}
          name={`conditions[${index}][value]`}
          id={`conditions-${index}-value`}
          fullWidth
          label={label}
          value={value}
          size={'small'}
          onChange={handleChange}
        >
          <option key={'-'} value="" />
          <option key={'yes'} value={'true'}>
            yes
          </option>
          <option key={'no'} value={'false'}>
            no
          </option>
        </StyledSelect>
      </Grid>
    </Grid>
  )
}

export default BooleanWidget
