import { ScreenSize, media } from '../../../../styled/mixins'

import { Dialog } from '@material-ui/core'
import styled from 'styled-components'

const StyledTrackDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: ${props => props.theme.colors.whiteTransparent};
  }
  .MuiDialog-paper {
    max-height: 35rem;
    border-radius: 0.5rem;
  }
  .MuiDialogContent-root {
    border-bottom: 0.1rem solid ${props => props.theme.colors.grey.light};
    border-top: 0.1rem solid ${props => props.theme.colors.grey.light};
    padding: 1rem 0.75rem;
    ${media(ScreenSize.Small)} {
      border-bottom: 0;
      border-top: 0;
      padding: 1rem 5rem;
      &.full {
        padding: 1rem 2rem;
      }
    }
    .MuiGrid-container {
      font-size: 1.1rem;
      font-weight: ${props => props.theme.font.medium};
      padding: 0.5rem 0;

      div:first-child, .not-available {
        color: ${props => props.theme.colors.grey.mediumLight};
      }
    }
    .lyric-highlight {
      font-style: normal;
      background-color: ${props => props.theme.colors.secondaryLight};
    }
  }
  .MuiDialogTitle-root {
    padding: 1rem 0.75rem 0.75rem 0.75rem;
    ${media(ScreenSize.Small)} {
      padding: 2rem;
    }
    p {
      display: flex;
      font-size: 1.25rem;
      font-weight: ${props => props.theme.font.medium};
      ${media(ScreenSize.Small)} {
        font-size: 2.125rem;
      }
    }
    svg {
      font-size: 1.75rem;
      margin-right: 1rem;
      ${media(ScreenSize.Small)} {
        font-size: 2.5rem;
      }
      .fill {
        fill: ${props => props.theme.colors.primary};
      }
      .stroke {
        stroke: ${props => props.theme.colors.primary};
      }
    }
  }
  .MuiDialogActions-root {
    padding: 0.75rem 0.75rem 1.5rem 0.75rem;
    ${media(ScreenSize.Small)} {
      padding: 2rem 1rem;
    }
    button {
      font-weight: ${props => props.theme.font.regular};
      &.MuiButton-text {
        color: ${props => props.theme.colors.secondary};
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`

export default StyledTrackDialog
