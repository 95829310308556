import React, { ChangeEvent, useState } from 'react'
import { StyledSelect, TextField } from '@aims-controls'

import { Grid } from '@material-ui/core'
import { SearchFilterWidgetProps } from '@aims-search/types'
import { trackFieldKeyMap } from '@aims-search/hooks'

const TextWithModifiersWidget = ({ index, field }: SearchFilterWidgetProps): JSX.Element => {
  const [value, setValue] = useState('')
  const label = trackFieldKeyMap.get(field) ?? ''

  const handleChange = (event: ChangeEvent<{ value: unknown }>): void => {
    setValue(event.target.value as string)
  }

  return (
    <Grid container className={'with-modifier dual-filter'}>
      <Grid item xs={12} className={'filtering-label empty'}>
        {label ?? <>&nbsp;</>}
      </Grid>
      <Grid item xs={5} className={'modifier filter-from'}>
        <input type="hidden" name={`conditions[${index}][field]`} value={field} />
        <StyledSelect
          variant={'outlined'}
          name={`conditions[${index}][operator]`}
          id={`conditions-${index}-operator`}
          fullWidth
          value={value}
          size={'small'}
          onChange={handleChange}
        >
          <option key={'contains'} value={'contains'}>
            contains
          </option>
          <option key={'begins'} value={'begins'}>
            begins with
          </option>
          <option key={'ends'} value={'ends'}>
            ends with
          </option>
          <option key={'not-contains'} value={'not-contains'}>
            doesn&apos;t contain
          </option>
          <option key={'eq'} value={'eq'}>
            is same as
          </option>
          <option key={'neq'} value={'neq'}>
            is not same as
          </option>
        </StyledSelect>
      </Grid>
      <Grid item xs={7} className={'filter-input filter-to'}>
        <TextField
          type={'text'}
          variant={'outlined'}
          color={'secondary'}
          name={`conditions[${index}][value]`}
          id={`conditions-${index}-value`}
          label={label}
          fullWidth
          size={'small'}
        />
      </Grid>
    </Grid>
  )
}

export default TextWithModifiersWidget
