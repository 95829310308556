import { ScreenSize, media } from '../../../../styled/mixins'

import styled from 'styled-components'

const StyledLogoHeader = styled.img`
  height: 3rem;
  margin-left: -3rem;
  width: 8rem;
  ${media(ScreenSize.Small)} {
    height: 8rem;
    width: 20rem;
  }
`

export default StyledLogoHeader
