import { FormControl, InputLabel, Select, SelectProps } from '@material-ui/core'

import React from 'react'

const StyledSelect = (props: SelectProps & { size: 'small' | 'medium' }): JSX.Element => {
  const { children, fullWidth, size = 'medium' } = props
  return (
    <FormControl
      variant={props.variant}
      color={'secondary'}
      fullWidth={fullWidth}
      size={size}
    >
      <InputLabel>{ props.label }</InputLabel>
      <Select native
        {...props}
      >
        { children }
      </Select>
    </FormControl>
  )
}

export default StyledSelect
