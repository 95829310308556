import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../../colors'

const radioOverride: Overrides['MuiRadio'] = {
  root: {
    color: colors.grey.light
  },
  colorPrimary: {
    '&:hover': {
      backgroundColor: colors.primaryLightest
    }
  }
}

export default radioOverride
