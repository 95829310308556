import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomAddToProjectIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 26.4 24.4'}>
    <g id="Icons_ic_add_to_project" data-name="Icons / ic_add_to_project" transform="translate(0.7 0.7)">
      <g id="Group_9" data-name="Group 9">
        <path id="Stroke_1" data-name="Stroke 1" d="M24,12.653V1.062A1.07,1.07,0,0,0,22.922,0H16.517a1.325,1.325,0,0,0-1.244.853L14.6,2.661a1.324,1.324,0,0,1-1.243.853H1.078A1.07,1.07,0,0,0,0,4.576V18.939A1.07,1.07,0,0,0,1.078,20H13" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
        <path id="Stroke_3" data-name="Stroke 3" d="M13,6.5A6.5,6.5,0,1,0,6.5,13,6.5,6.5,0,0,0,13,6.5Z" transform="translate(12 10)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
        <path id="Stroke_5" data-name="Stroke 5" d="M.5,0V8" transform="translate(18 12.5)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
        <path id="Stroke_7" data-name="Stroke 7" d="M8,.5H0" transform="translate(14.5 16)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      </g>
    </g>
  </SvgIcon>

export default CustomAddToProjectIcon
