import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomShareIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 22 22'}>
    <g id="Group_11" data-name="Group 11" transform="translate(1.5 1.5)">
      <path id="Stroke_1" data-name="Stroke 1" d="M6.044,3.022A3.022,3.022,0,1,1,3.022,0,3.022,3.022,0,0,1,6.044,3.022Z" transform="translate(11.703 0.25)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_3" data-name="Stroke 3" d="M6.044,3.022A3.022,3.022,0,1,1,3.022,0,3.022,3.022,0,0,1,6.044,3.022Z" transform="translate(0.25 6.609)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_5" data-name="Stroke 5" d="M6.044,3.022A3.022,3.022,0,1,1,3.022,0,3.022,3.022,0,0,1,6.044,3.022Z" transform="translate(11.703 12.967)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_7" data-name="Stroke 7" d="M0,0,6.169,3.425" transform="translate(5.914 11.097)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_9" data-name="Stroke 9" d="M6.169,0,0,3.425" transform="translate(5.914 4.738)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
    </g>
  </SvgIcon>

export default CustomShareIcon
