import { ENQUEUE, FIRE } from './action-types'
import { EnqueueAction, FireAction } from '../types'

import { Action } from 'redux'
import { LayoutState } from '../../store'
import { v4 } from 'uuid'

const initialState: LayoutState = {
  snackbarContainer: {
    messages: []
  }
}

const reducer = (state = initialState, action: Action = { type: undefined }): LayoutState => {
  switch (action.type) {
    case ENQUEUE:
      return Object.assign({}, state, {
        snackbarContainer: {
          messages: [...state.snackbarContainer.messages, { ...(action as EnqueueAction).message, fired: false, id: v4() }]
        }
      })
    case FIRE:
      return Object.assign({}, state, {
        snackbarContainer: {
          messages: state.snackbarContainer.messages.map(message => message.id === (action as FireAction).id ? { ...message, fired: true } : message)
        }
      })
    default:
      return state
  }
}

export default reducer
