import Highlights from '../Highlights'
import React from 'react'
import { SearchType } from '@aims-search/types'
import { StyledTrackWaveform } from '@aims-track/styled'
import { TrackWaveformProps } from '@aims-track/types'
import { computeDefaultSegment } from '@aims-track/lib'
import dynamic from 'next/dynamic'
import { getLastSearchState } from '@aims-store/search'
import { isHighlightsEnabled } from '@aims-store/auth'
import { isTextSearch } from '@aims-search/lib'
import { useAudioApiSecret } from '@aims-track/hooks'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

const StoredWaveform = dynamic(async () => await import('../StoredWaveform/StoredWaveform'), { ssr: false })
const TransientWaveform = dynamic(async () => await import('../TransientWaveform/TransientWaveform'), { ssr: false })

const TrackWaveform = ({ track, trackType = SearchType.Internal, audio, loaded, onSegmentChange, editable, audioSecret }: TrackWaveformProps): JSX.Element => {
  const router = useRouter()
  const apiSecret = useAudioApiSecret(track, audioSecret)
  const isPlaylistBuilder = router.pathname.indexOf('/playlist-generator') === 0
  const highlights = useSelector(isHighlightsEnabled)
  const lastSearchState = useSelector(getLastSearchState)
  const isProject = router.pathname.startsWith('/my-projects')
  const isSearch = router.pathname === '/'
  const searchingByText = lastSearchState !== null && isTextSearch(lastSearchState.type)

  const trackHighlights =
    (isSearch && searchingByText) || isProject ? []
      : typeof track.highlights !== 'undefined'
        ? track.highlights
        : typeof (track.queryId) === 'string' && !isPlaylistBuilder
          ? [computeDefaultSegment(track.duration ?? audio.duration)]
          : []
  const highlightsElement =
    highlights || editable === true ? (
      <Highlights
        highlights={trackHighlights}
        trackDuration={track.duration ?? audio.duration}
        onSegmentChange={onSegmentChange}
      />
    ) : null

  const createWaveform = (): JSX.Element => {
    if (!loaded && track.duration === 0) {
      return <></>
    }

    if (track.id > 0) {
      return (
        <StoredWaveform audio={audio} track={track} highlights={trackHighlights} apiSecret={apiSecret} >
          {trackHighlights.length > 0 && highlightsElement}
        </StoredWaveform>
      )
    }

    return (
      <TransientWaveform audio={audio} track={track} highlights={trackHighlights} apiSecret={apiSecret} trackType={trackType} >
        {highlightsElement}
      </TransientWaveform>
    )
  }

  return <StyledTrackWaveform>{createWaveform()}</StyledTrackWaveform>
}

export default TrackWaveform
