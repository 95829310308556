import { Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import {
  getLastSearchState,
  getResultsPromptsTracks,
  getResultsTotalPromptResults,
  getResultsTotalResults,
  getResultsTracks,
  isLoading,
  resolveCurrentSearchType
} from '@aims-store/search'

import { Box } from '@aims-controls'
import LoadMoreButton from './LoadMoreButton'
import StyledSearchResults from './SearchResults.styled'
import TrackTabs from './TrackTabs'
import { UserIdentity } from '@aims-auth/types'
import { getUser } from '@aims-store/auth'
import { isTextSearch } from '@aims-search/lib'
import { useSelector } from 'react-redux'

const SearchResults = (): JSX.Element => {
  const tracks = useSelector(getResultsTracks)
  const promptTracks = useSelector(getResultsPromptsTracks)
  const totalResults = useSelector(getResultsTotalResults)
  const totalPromptResults = useSelector(getResultsTotalPromptResults)
  const lastSearchState = useSelector(getLastSearchState)
  const loading = useSelector(isLoading)
  const currentSearchType = useSelector(resolveCurrentSearchType)
  const user = useSelector(getUser) as UserIdentity
  const [currentTab, setCurrentTab] = useState(0)

  const hasTracks = tracks.length > 0 || promptTracks.length > 0
  const extraClass = lastSearchState !== null && isTextSearch(lastSearchState.type) && isTextSearch(currentSearchType)
    ? (currentTab === 0 && user.textSearch)
      ? promptTracks.length > 0
        ? ''
        : 'no-prompt-results'
      : tracks.length > 0
        ? ''
        : 'no-keyword-results'
    : ''
  const className = !hasTracks
    ? (
      loading
        ? 'loading'
        : (lastSearchState !== null ? 'no-results' : '')
    ) : ''

  const shouldShowLoadMore = (currentTab: number): boolean => {
    if (isTextSearch(currentSearchType) && currentTab === 0 && user.textSearch) {
      return typeof totalPromptResults === 'undefined' ? promptTracks.length > 0 : totalPromptResults > promptTracks.length
    }
    return typeof totalResults === 'undefined' || totalResults > tracks.length
  }

  const handleOnTabChanged = (newTab: number): void => {
    setCurrentTab(newTab)
  }

  return (
    <StyledSearchResults className={`${className} ${currentSearchType} ${extraClass}`}>
      {
        hasTracks &&
        <>
          <Typography className={'results-label'}>Results</Typography>
          <TrackTabs onTabChanged={handleOnTabChanged} activeTab={currentTab} />
          {shouldShowLoadMore(currentTab) && (
            <Grid container justifyContent={'center'}>
              <Box paddingY={4}>
                <LoadMoreButton />
              </Box>
            </Grid>
          )}
        </>
      }
    </StyledSearchResults>
  )
}

export default SearchResults
