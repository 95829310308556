import album from './album/reducers'
import artist from './artist/reducers'
import auth from './auth/reducers'
import autoTagging from './auto-tagging/reducers'
import { combineReducers } from 'redux'
import feedback from './feedback/reducers'
import player from './player/reducers'
import playlistBuilder from './playlist-builder/reducers'
import playlistPlugging from './playlist-plugging/reducers'
import project from './project/reducers'
import search from './search/reducers'

export default combineReducers({
  album,
  artist,
  auth,
  autoTagging,
  feedback,
  player,
  playlistBuilder,
  playlistPlugging,
  project,
  search
})
