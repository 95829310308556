import { ThemeColors } from './styled-theme'
import { hexToRGBA } from '@aims-lib'

const baseColors = {
  white: '#fff',
  black: '#000',
  green: '#0b6',
  purple: '#c3f',
  mauve: '#d473d4',
  crimson: '#dc143c',
  warningRed: '#ca0000',
  warningRedDesaturated: '#ffebec',
  teal: '#028428',
  turquoise: '#5af',
  yellow: '#ffc200',

  white50: '#f5f5f5',

  grey10: '#333',
  grey20: '#666',
  grey30: '#aaa',
  grey40: '#c0c0c0',
  grey50: '#e2e2e2',
  grey55: '#F2F2F2',
  grey60: '#f9fcfc',

  primary: '#8137cf',
  primaryHover: '#8f49d9',
  primaryDesaturated: '#b296d3',
  secondary: '#00b8be'
}

const colors: ThemeColors = {
  primary: baseColors.primary,
  primaryHover: baseColors.primaryHover,
  primaryDesaturated: baseColors.primaryDesaturated,
  primaryLight: hexToRGBA(baseColors.primary, 0.25),
  primaryLightest: hexToRGBA(baseColors.primary, 0.05),
  secondary: baseColors.secondary,
  secondaryHover: hexToRGBA(baseColors.secondary, 0.75),
  secondaryLight: hexToRGBA(baseColors.secondary, 0.25),
  secondaryLightest: hexToRGBA(baseColors.secondary, 0.05),
  warning: {
    red: baseColors.warningRed,
    desaturated: baseColors.warningRedDesaturated
  },
  grey: {
    dark: baseColors.grey10,
    mediumDark: baseColors.grey20,
    medium: baseColors.grey30,
    mediumLight: baseColors.grey40,
    light: baseColors.grey50,
    veryLight: baseColors.grey55,
    lightest: baseColors.grey60
  },
  white: baseColors.white,
  whiteTransparent: hexToRGBA(baseColors.white, 0.5),
  whitish: baseColors.white50,
  green: baseColors.green,
  purple: baseColors.purple,
  mauve: baseColors.mauve,
  crimson: baseColors.crimson,
  teal: baseColors.teal,
  turquoise: baseColors.turquoise,
  yellow: baseColors.yellow,
  black: baseColors.black,
  blackTransparent: hexToRGBA(baseColors.black, 0.6)
}

export default colors
