import {
  ApiConfiguration,
  UserIdentity,
  UserToken
} from '@aims-auth/types'
import {
  DECREMENT_AUTO_TAGGING_REQUESTS_REMAINING,
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  MAGIC_LOGIN_BEGIN,
  MAGIC_LOGIN_ERROR,
  REQUEST_RESET_BEGIN,
  REQUEST_RESET_ERROR,
  REQUEST_RESET_SUCCESS,
  RESET_BEGIN,
  RESET_ERROR,
  RESET_SUCCESS,
  SET_API_CONFIGURATION,
  SET_AUTO_TAGGING_TOKEN,
  SET_IS_LOADING,
  SET_PUBLIC_USER,
  SET_SEARCH_FILTER_FIELDS
} from './action-types'
import {
  DecrementAutoTaggingRequestsRemainingAction,
  LoginBeginAction,
  LoginErrorAction,
  LoginSuccessAction,
  LogoutStoreAction,
  MagicLoginBeginAction,
  MagicLoginErrorAction,
  RequestResetBeginAction,
  RequestResetErrorAction,
  RequestResetSuccessAction,
  ResetBeginAction,
  ResetErrorAction,
  ResetSuccessAction,
  SetApiConfigurationAction,
  SetAutoTaggingTokenAction,
  SetIsLoadingAction,
  SetPublicUserAction
} from './types'
import { SetSearchFilterFieldsAction, resetSearchFilters } from '@aims-store/search'
import {
  createSession,
  destroySession,
  magicLoginAuthenticate
} from '@aims-auth/lib'
import {
  requestResetNext,
  resetPasswordNext
} from '@aims-auth/api'
import { setLoading, setProjects } from '@aims-store/project'

import { Dispatch } from 'redux'
import { PaginatedCollections } from '@aims-collection/types'
import { SearchFilterField } from '@aims-search/types'
import { convertKeysToCamelCase } from '@aims-search/lib'
import { enqueue } from '@aims-layout'
import { getSearchFilterFields } from './lib'
import { loadProjects } from '@aims-project/lib'

const loginBegin = (): LoginBeginAction => ({
  type: LOGIN_BEGIN
})

const loginSuccess = (user: UserIdentity): LoginSuccessAction => ({
  type: LOGIN_SUCCESS,
  payload: {
    user
  }
})

const loginError = (error: Error): LoginErrorAction => ({
  type: LOGIN_ERROR,
  error
})

const login = (username: string, password: string): (dispatch: Dispatch) => void =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(loginBegin())
    try {
      const user = await createSession(username, password)
      const filterFields = await getSearchFilterFields(user)
      dispatch(loginSuccess(user))
      dispatch(setSearchFilterFields(filterFields))

      if (user.projects) {
        const results = await loadProjects(user)
        const projects = convertKeysToCamelCase<PaginatedCollections>(results)

        dispatch(setProjects(projects))
      }

      dispatch(setLoading(false))
    } catch (err) {
      const error = err as Error
      dispatch(loginError(error))
      dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
    }
  }

const logoutStore = (): LogoutStoreAction => ({
  type: LOGOUT
})

const logout = (): (dispatch: Dispatch) => void =>
  async (dispatch: Dispatch): Promise<void> => {
    await destroySession()
    dispatch(logoutStore())
    dispatch(resetSearchFilters())
  }

const setApiConfiguration = (apiConfiguration: ApiConfiguration, autoTaggingConfiguration: ApiConfiguration): SetApiConfigurationAction => ({
  type: SET_API_CONFIGURATION,
  apiConfiguration,
  autoTaggingConfiguration
})

const setAutoTaggingToken = (token: UserToken, remainingMonthlyRequests?: number, monthlyRequestLimit?: number): SetAutoTaggingTokenAction => ({
  type: SET_AUTO_TAGGING_TOKEN,
  token,
  remainingMonthlyRequests,
  monthlyRequestLimit
})

const decrementAutoTaggingRequestsRemaining = (remainingMonthlyRequests: number): DecrementAutoTaggingRequestsRemainingAction => ({
  type: DECREMENT_AUTO_TAGGING_REQUESTS_REMAINING,
  remainingMonthlyRequests
})

const requestResetBegin = (): RequestResetBeginAction => ({
  type: REQUEST_RESET_BEGIN
})

const requestResetSuccess = (): RequestResetSuccessAction => ({
  type: REQUEST_RESET_SUCCESS
})

const requestResetError = (error: Error): RequestResetErrorAction => ({
  type: REQUEST_RESET_ERROR,
  error
})

const requestReset = (email: string): (dispatch: Dispatch) => void =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(requestResetBegin())
    try {
      await requestResetNext(email)
      dispatch(requestResetSuccess())
    } catch (err) {
      const error = err as Error
      dispatch(requestResetError(error))
      dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
    }
  }

const resetBegin = (): ResetBeginAction => ({
  type: RESET_BEGIN
})

const resetSuccess = (): ResetSuccessAction => ({
  type: RESET_SUCCESS
})

const resetError = (error: Error): ResetErrorAction => ({
  type: RESET_ERROR,
  error
})

const resetPassword = (password: string, token: string): (dispatch: Dispatch) => void =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(resetBegin())
    try {
      await resetPasswordNext(password, token)
      dispatch(resetSuccess())
    } catch (err) {
      const error = err as Error
      dispatch(resetError(error))
      dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
    }
  }

const magicLoginBegin = (): MagicLoginBeginAction => ({
  type: MAGIC_LOGIN_BEGIN
})

const magicLoginError = (error: Error): MagicLoginErrorAction => ({
  type: MAGIC_LOGIN_ERROR,
  error
})

const magicLogin = (token: string): (dispatch: Dispatch) => void =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(magicLoginBegin())
    try {
      const user = await magicLoginAuthenticate(token)
      const filterFields = await getSearchFilterFields(user)

      dispatch(loginSuccess(user))
      dispatch(setSearchFilterFields(filterFields))

      if (user.projects) {
        const results = await loadProjects(user)
        const projects = convertKeysToCamelCase<PaginatedCollections>(results)

        dispatch(setProjects(projects))
      }

      dispatch(setLoading(false))
    } catch (err) {
      const error = err as Error
      dispatch(magicLoginError(error))
      dispatch(enqueue({ message: error.message, options: { variant: 'error' } }))
    }
  }

const setIsLoading = (on: boolean): SetIsLoadingAction => ({
  type: SET_IS_LOADING,
  payload: {
    on
  }
})

const setSearchFilterFields = (searchFilterFields: SearchFilterField[]): SetSearchFilterFieldsAction => ({
  type: SET_SEARCH_FILTER_FIELDS,
  payload: {
    searchFilterFields
  }
})

const setPublicUser = (user: UserIdentity): SetPublicUserAction => ({
  type: SET_PUBLIC_USER,
  payload: {
    user
  }
})

export {
  decrementAutoTaggingRequestsRemaining,
  login,
  loginBegin,
  loginError,
  loginSuccess,
  logout,
  logoutStore,
  magicLogin,
  magicLoginBegin,
  magicLoginError,
  requestReset,
  requestResetBegin,
  requestResetError,
  requestResetSuccess,
  resetBegin,
  resetError,
  resetPassword,
  resetSuccess,
  setApiConfiguration,
  setAutoTaggingToken,
  setIsLoading,
  setPublicUser,
  setSearchFilterFields
}
