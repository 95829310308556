import styled from 'styled-components'

const StyledAuthLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;

  & main {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 4rem;
    flex: 1;
  }
`

export default StyledAuthLayout
