import { isSupportedStreamingServiceLink } from 'lib/linkValidator'

const linkIsFileOrUrl = (value: string): boolean => {
  const linkIsFile = value.endsWith('.mp3') || value.endsWith('.wav')
  const linkIsUrl = isSupportedStreamingServiceLink(value)

  const linkIsFileOrUrl = linkIsFile || linkIsUrl

  return linkIsFileOrUrl
}

export default linkIsFileOrUrl
