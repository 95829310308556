import { getLastSearchState, getResultsPromptsTracks, getResultsTracks, isLoading } from '@aims-store/search'

import { Button } from '@aims-controls'
import React from 'react'
import StyledTrackTabs from './TrackTabs.styled'
import { TrackTabsProps } from '@aims-search/types'
import { UserIdentity } from '@aims-auth/types'
import { getUser } from '@aims-store/auth'
import { isTextSearch } from '@aims-search/lib'
import { useNestingTracks } from '@aims-track/hooks'
import { useSelector } from 'react-redux'

const TrackTabs = ({ onTabChanged, activeTab }: TrackTabsProps): JSX.Element => {
  const keywordResults = useSelector(getResultsTracks)
  const promptResults = useSelector(getResultsPromptsTracks)
  const loading = useSelector(isLoading)
  const lastSearchState = useSelector(getLastSearchState)
  const keywordNestingTracks = useNestingTracks(keywordResults, undefined)
  const promptNestingTracks = useNestingTracks(promptResults, undefined)
  const user = useSelector(getUser) as UserIdentity
  const results = {
    Prompt: promptResults,
    Keywords: keywordResults
  }

  let showTrackTabs = !loading && user.textSearch

  if (showTrackTabs) {
    showTrackTabs = lastSearchState !== null && isTextSearch(lastSearchState.type)
  }

  const handleOnClick = (newTabIndex: number): void => {
    onTabChanged(newTabIndex)
  }

  return (
    <StyledTrackTabs>
      {showTrackTabs ? (
        <>
          <div className={'category-tabs'}>
            {
              Object.keys(results).map((promptLabel: string, index: number) => (
                <Button
                  color={'default'}
                  onClick={() => handleOnClick(index)}
                  variant={'outlined'}
                  key={`${promptLabel}-key`}
                  className={activeTab !== index ? 'inactive' : ''}
                >{promptLabel}</Button>
              ))
            }
          </div>
          {activeTab === 0 && promptNestingTracks.render()}
          {activeTab === 1 && keywordNestingTracks.render()}
        </>
      ) : (
        keywordNestingTracks.render()
      )}
    </StyledTrackTabs>
  )
}

export default TrackTabs
