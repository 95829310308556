import { Button, TrackChip, TrackSearchBarProps } from '@aims-controls'
import { InputBase, Typography } from '@material-ui/core'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import CustomSearchIcon from 'components/icons/component/CustomSearchIcon'
import CustomUploadIcon from 'components/icons/component/CustomUploadIcon'
import StyledTrackSearchBar from './TrackSearchBar.styled'
import { isSupportedStreamingServiceLink } from 'lib/linkValidator'
import linkIsFileOrUrl from './linkCheck'
import usePrompts from './usePrompts'

const promptSwapInterval = 5000

const TrackSearchBar = ({
  errorMessage,
  placeholder = '',
  textFieldValue,
  open,
  onChange,
  isDragActive,
  uploadDisabled,
  onKeyDown,
  placeholderPrompts,
  textSearchEnabled = false,
  isPublic = false
}: TrackSearchBarProps): JSX.Element => {
  const [chipVisible, setChipVisible] = useState(false)
  const [placeholderVisible, setPlaceholderVisible] = useState(true)
  const { renderPrompts, currentPrompt } = usePrompts(
    promptSwapInterval,
    textSearchEnabled && placeholderVisible && textFieldValue.length === 0,
    placeholderPrompts
  )

  const styledRef = useRef<HTMLDivElement>(null)
  const tabIndex = chipVisible ? -1 : 0
  const uploadButtonDisabled = uploadDisabled ?? isDragActive ?? open !== null

  useEffect(() => {
    let newChipVisible = textFieldValue.length !== 0

    if (newChipVisible) {
      newChipVisible = !isPublic && linkIsFileOrUrl(textFieldValue)
    }

    setChipVisible(newChipVisible)
  }, [textFieldValue])

  useEffect(() => {
    const searchInputElement = document.getElementById('track-search-bar-input')

    if (searchInputElement === null) {
      return
    }

    searchInputElement.addEventListener('animationstart', function (event) {
      if (event.animationName === 'mui-auto-fill') {
        setPlaceholderVisible(false)
      }
    })

    searchInputElement.addEventListener('animationstart', function (event) {
      if (event.animationName === 'mui-auto-fill-cancel') {
        setPlaceholderVisible(true)
      }
    })
  }, [])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let newValue = event.target.value

    const linkIsUrl = isSupportedStreamingServiceLink(newValue)

    if (linkIsUrl) {
      newValue = newValue.substring(textFieldValue.length)
    }

    onChange(newValue)
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLDivElement>): void => {
    e.currentTarget.classList.add('focused')
  }

  const handleOnBlur = (e: React.FocusEvent<HTMLDivElement>): void => {
    e.currentTarget.classList.remove('focused')
  }

  const handleOnCloseTrackChip = (): void => {
    onChange('')
  }

  const handleOnKeyDown = (event: React.KeyboardEvent): void => {
    if (event.code !== 'Enter' || textFieldValue === '' || onKeyDown === undefined) {
      return
    }

    onKeyDown(event)
  }

  const handleOnLoaded = (): void => {
    styledRef.current?.focus()
  }

  const getIcon = (): JSX.Element => {
    if (isDragActive !== undefined && isDragActive) {
      return <CustomUploadIcon />
    }

    return <CustomSearchIcon />
  }

  return (
    <StyledTrackSearchBar
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      ref={styledRef}
      error={errorMessage ?? ''}
      className={errorMessage !== undefined ? 'error' : ''}
      tabIndex={0}
      onKeyDown={handleOnKeyDown}
      promptSwapInterval={promptSwapInterval}
    >
      <span className={'adornment'}>{getIcon()}</span>
      {!chipVisible ? (
        <div className={'search-bar'}>
          <InputBase
            name={'search-bar'}
            className={'track-search-input'}
            color={'secondary'}
            onChange={handleOnChange}
            value={chipVisible ? ' ' : textFieldValue}
            inputProps={{ 'aria-label': 'search bar link', tabIndex, id: 'track-search-bar-input' }}
            placeholder={`${placeholder}${textSearchEnabled ? ` "${currentPrompt}"` : ''}`}
            tabIndex={tabIndex}
            onKeyDown={handleOnKeyDown}
          />
          {placeholderVisible && textFieldValue.length === 0 && (
            <Typography
              className={'custom-placeholder'}
              component={'span'}>
              {placeholder}
              {renderPrompts()}
            </Typography>
          )}
        </div>
      ) : (
        <TrackChip
          title={textFieldValue}
          onClose={handleOnCloseTrackChip}
          onFailedToLoad={() => setChipVisible(false)}
          onLoaded={handleOnLoaded}
        />
      )}
      {(open !== null) && (
        <Button disabled={uploadButtonDisabled} className={'upload-button'} variant={'text'} color={'secondary'} onClick={open}>
          Upload
        </Button>
      )}
    </StyledTrackSearchBar>
  )
}

export default TrackSearchBar
