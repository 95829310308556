import { createGlobalStyle } from 'styled-components'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'futura-pt', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important;
    &::after, &::before {
      box-sizing: border-box;
    }
  }
  html {
    font-family: 'futura-pt', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 16px;
    height: 100%;
    overflow-x: hidden;
  }
  body {
    background-color: ${theme.colors.white};
    box-sizing: border-box;
    margin: 0;
    min-height: 100%;
    padding: 0;
    position: relative;
    width: 100vw;

    & > div:first-child {
      height: 100%;
    }
  }
  a {
    color: ${theme.colors.secondary};
    font-weight: ${theme.font.medium};
    &:hover {
      color: ${theme.colors.secondaryHover};
    }
  }

  .MuiToolbar-root {
    &.MuiPickersToolbar-toolbar {
      background-color: ${theme.colors.secondary};
    }
  }
  .MuiPickersBasePicker-container {
    .MuiPickersClock-pin,
    .MuiPickersClockPointer-pointer,
    .MuiPickersClockPointer-thumb {
      background-color: ${theme.colors.secondary};
      border-color: ${theme.colors.secondary};
    }
  }
  .MuiPopover-root {
    .MuiPaper-rounded {
      border-radius: 0.5rem;
    }
  }
`

export default GlobalStyle
