import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
  StyledMetadataItem,
  StyledTrack,
  StyledTrackCode,
  StyledTrackDuration,
  StyledTrackName
} from '@aims-track/styled'
import { feedback, feedbackPlay } from '@aims-store/feedback'
import { getPlayerTrack, isPlaying, load, pause, play } from '@aims-store/player'
import { isCdnEnabled, isHighlightsEnabled } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import { ExternalTrack } from './ExternalTrack'
import FindSimilarButton from '../FindSimiliarButton'
import PlayButton from '../PlayButton'
import TrackActions from './TrackActions'
import TrackMetadata from '../TrackMetadata'
import { TrackProps } from '@aims-track/types'
import TrackWaveform from '../TrackWaveform'
import { decorateTime } from '@aims-lib'
import { setAudioPlayHeadPosition } from '@aims-track/lib'
import { useAudioApiSecret } from '@aims-track/hooks'
import { useStreaming } from '@aims-player/hooks'

const Track = ({ track, project, nested }: TrackProps): JSX.Element => {
  const dispatch = useDispatch()

  const highlights = useSelector(isHighlightsEnabled)
  const playing = useSelector(isPlaying)
  const playedTrack = useSelector(getPlayerTrack)
  const apiSecret = useAudioApiSecret(track)
  const useCdn = useSelector(isCdnEnabled)

  const [loaded, setLoaded] = useState(false)
  const [playable, setPlayable] = useState(false)
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)

  const { trackStreamUrl } = useStreaming(apiSecret, useCdn)

  const external = track.idClient.startsWith('file:') || track.idClient.startsWith('url:')
  const tmpAudio = new Audio()
  tmpAudio.preload = 'none'
  setAudioPlayHeadPosition(tmpAudio, highlights, track)

  const onAudioLoaded = (): void => {
    seekAudio()
    setLoaded(true)
  }

  const onPlaying = (): void => {
    setPlayable(true)
  }

  const onEnded = (): void => {
    dispatch(pause())
  }

  useEffect(() => {
    if (audio === null) {
      return
    }
    audio.addEventListener('loadeddata', onAudioLoaded)
    audio.addEventListener('playing', onPlaying)
    audio.addEventListener('ended', onEnded)
    audio.src = trackStreamUrl(track)

    return () => {
      audio.removeEventListener('loadeddata', onAudioLoaded)
    }
  }, [audio])

  const seekAudio = (): void => {
    if (audio !== null) {
      setAudioPlayHeadPosition(audio, highlights, track)
    }
  }

  const toggleTrack = (): void => {
    if ((playedTrack === null || playedTrack.id !== track.id) && audio !== null) {
      // the playback needs to be started synchronously (even though it's done "again" in the store later),
      // since browsers can block playback if done asynchronously
      void audio.play() // eslint-disable-line no-void
      dispatch(load(audio, track))
      dispatch(play())
      if (typeof (track.queryId) === 'string') {
        dispatch(feedback(feedbackPlay(track.idClient), track.queryId))
      }
      return
    }

    dispatch(playing ? pause() : play())
  }

  return (
    <StyledTrack nested={nested}>
      {external ? <ExternalTrack track={track} project={project}/> : (
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Grid container item xs={12} sm={9} alignItems={'center'}>
              <Grid
                xs={12}
                container
                item
                alignItems={'center'}
                wrap={'nowrap'}
                className={'track-headline'}
                spacing={1}
                alignContent={'center'}
              >
                <Grid item>
                  {track.trackCode !== null && <StyledTrackCode>{track.trackCode}</StyledTrackCode>}
                </Grid>
                <Grid item>
                  <StyledTrackName>
                    {typeof track.explicit !== 'undefined' && track.explicit && (
                      <StyledMetadataItem className={'explicit'}>Explicit</StyledMetadataItem>
                    )}
                    <Typography component={'header'} variant={'h6'}>{track.trackName ?? 'N/A'}</Typography>
                  </StyledTrackName>
                </Grid>
                <Grid item>
                  <StyledTrackDuration>{track.duration > 0 ? decorateTime(track.duration) : 'N/A'}</StyledTrackDuration>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TrackMetadata track={track} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TrackActions track={track} project={project} />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems={'center'} className={'track-play-area'}>
            <PlayButton track={track} loaded={loaded} playable={playable} item xs onClick={toggleTrack} />
            <TrackWaveform track={track} loaded={loaded} audio={loaded && audio !== null ? audio : tmpAudio}/>
            <FindSimilarButton track={track} audio={audio !== null ? audio : tmpAudio} />
            <audio ref={setAudio} preload={track.duration > 0 ? 'none' : 'auto'} />
          </Grid>
        </Grid>
      )}
    </StyledTrack>
  )
}

export default Track
