import { SearchState } from './types'
import { v4 } from 'uuid'

const DEFAULT_FILTERS_KEY = v4()
const initialState: SearchState = {
  loading: false,
  error: null,
  results: {
    queryId: null,
    tracks: [],
    totalResults: undefined,
    hash: null
  },
  promptResults: {
    queryId: null,
    tracks: [],
    totalResults: undefined,
    hash: null
  },
  preloadedResults: {
    queryId: null,
    tracks: [],
    totalResults: undefined,
    hash: null
  },
  preloadedPromptResults: {
    queryId: null,
    tracks: [],
    totalResults: undefined,
    hash: null
  },
  fields: {
    text: '',
    file: null,
    id: null
  },
  segment: {
    offset: -1,
    limit: -1,
    audio: null,
    track: null,
    changed: false
  },
  modifiers: {
    prioritiseBpm: false,
    ignoreVocals: false
  },
  filters: undefined,
  pagination: {
    page: 1,
    pageSize: 10
  },
  searchHash: null,
  lastSearchState: null,
  filtersKey: DEFAULT_FILTERS_KEY,
  extraFilterIndex: 1
}

export { DEFAULT_FILTERS_KEY }
export default initialState
