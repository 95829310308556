import { ENQUEUE, FIRE } from './action-types'
import { EnqueueAction, FireAction, PartialSnackbarMessageData } from '../types'

export const enqueue = (message: PartialSnackbarMessageData): EnqueueAction => ({
  type: ENQUEUE,
  message
})

export const fire = (id: string): FireAction => ({
  type: FIRE,
  id
})
