import { ArrowForwardIos, Close, Menu } from '@material-ui/icons'
import { Branding, BrandingData } from '@aims-auth/types'
import { Container, Grid, IconButton, Typography } from '@material-ui/core'
import { Nav, StyledNavItem } from '../../nav'
import React, { ComponentProps, useState } from 'react'
import { StyledBrand, StyledHeader, StyledHeaderHeadline, StyledHeaderLogo } from '../styled'

import { getBranding } from '@aims-store/auth'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

interface HeaderProps extends ComponentProps<'header'> {
  disableMenu?: boolean
}

const Header = ({ disableMenu }: HeaderProps): JSX.Element => {
  const router = useRouter()
  const { header }: Branding = useSelector(getBranding)
  const [opened, setOpened] = useState(false)

  const toggle = (): void => {
    setOpened(!opened)
  }

  const currentRouteName = (): string => {
    if (router.pathname === '/') {
      return 'Similarity Search'
    }
    if (router.pathname.indexOf('/playlist-generator') === 0) {
      return 'Playlist Generator'
    }
    if (router.pathname.indexOf('/my-projects') === 0) {
      return 'My Projects'
    }
    if (router.pathname.indexOf('/auto-tagging') === 0) {
      return 'Auto-Tagging'
    }
    if (router.pathname.indexOf('/playlist-plugging') === 0) {
      return 'Playlist Plugging'
    }
    if (router.pathname.indexOf('/tutorial') === 0) {
      return 'Tutorial'
    }
    if (router.pathname.indexOf('/artists') === 0) {
      return 'Similar Artists'
    }
    return ''
  }

  const renderLogo = (): JSX.Element => {
    const { logo, link }: BrandingData = header

    if (logo === null || link === null) {
      return <></>
    }

    const jsxLogo = <StyledHeaderLogo alt="AIMS API" src={'/img/header/logo.svg'}/>

    return link !== undefined ? (
      <a href={link} target="_blank" rel="noopener noreferrer">
        <StyledHeaderLogo alt="AIMS API" src={logo} className={'custom'}/>
      </a>
    ) : (
      <>
        {jsxLogo}
        <StyledHeaderHeadline variant={'h4'}>App</StyledHeaderHeadline>
      </>
    )
  }

  const renderMenu = (): JSX.Element => (
    <>
      <IconButton onClick={toggle} size={'small'}>
        {opened ? <Close fontSize={'large'}/> : <Menu fontSize={'large'}/>}
      </IconButton>
      <Nav opened={opened}/>
    </>
  )

  const renderLinkToWebpage = (): JSX.Element => {
    return (
      <a href={'https://aimsapi.com'} target={'_blank'} className={'redirect-to-website'} rel="noreferrer">
        <Typography component={'span'}>AI-powered music search & discovery</Typography>
        <ArrowForwardIos className={'redirect-to-website__icon'} />
      </a>
    )
  }

  return (
    <StyledHeader className={opened ? 'opened' : ''}>
      <Container>
        <Grid container alignItems={'center'}>
          <Grid item xs={9} sm={3}>
            <StyledBrand>
              {renderLogo()}
            </StyledBrand>
          </Grid>
          <Grid container item xs={3} sm={9} justifyContent={'flex-end'}>
            {disableMenu === false ? renderMenu() : renderLinkToWebpage()}
          </Grid>
        </Grid>
        <StyledNavItem className={'active current'}>
          { currentRouteName() }
        </StyledNavItem>
      </Container>
    </StyledHeader>
  )
}

export default Header
