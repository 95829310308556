import { TrackHighlights } from '../types'

export const hasOverlap = (duration: number, offset: number, highlights: TrackHighlights[]): boolean => highlights.reduce<boolean>(
  (carry, highlight) => {
    const start = highlight.offset
    const end = start + highlight.duration
    return carry || ((highlight.duration !== duration || highlight.offset !== offset) && end >= offset && start < offset + duration)
  },
  false
)
