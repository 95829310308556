export const SET_CURRENT_LOAD_ATTEMPT = 'PLAYLIST_PLUGGING_SET_CURRENT_LOAD_ATTEMPT'
export const SET_CURRENT_PRELOAD_ATTEMPT = 'PLAYLIST_PLUGGING_SET_CURRENT_PRELOAD_ATTEMPT'
export const SET_FILTERS = 'PLAYLIST_PLUGGING_SET_FILTERS'
export const SET_LAST_SEARCH_TARGET = 'PLAYLIST_PLUGGING_SET_LAST_SEARCH_TARGET'
export const SET_LOAD_IN_PROGRESS = 'PLAYLIST_PLUGGING_SET_LOAD_IN_PROGRESS'
export const SET_LOAD_MORE_IN_PROGRESS = 'PLAYLIST_PLUGGING_SET_LOAD_MORE_IN_PROGRESS'
export const SET_PAGINATION = 'PLAYLIST_PLUGGING_SET_SET_PAGINATION'
export const SET_PLAYLISTS = 'PLAYLIST_PLUGGING_SET_PLAYLISTS'
export const SET_PLAYLISTS_HASH = 'PLAYLIST_PLUGGING_SET_PLAYLISTS_HASH'
export const SET_PRELOADED_PLAYLISTS = 'PLAYLIST_PLUGGING_SET_PRELOADED_PLAYLISTS'
export const SET_PRELOAD_IN_PROGRESS = 'PLAYLIST_PLUGGING_SET_PRELOAD_IN_PROGRESS'
