import {
  StyledAuthHeader,
  StyledAuthHeaderIllustration,
  StyledHeadline,
  StyledLogoHeader
} from '../styled'

import { Grid } from '@material-ui/core'
import React from 'react'

const AuthHeader = (): JSX.Element =>
  <StyledAuthHeader>
    <StyledAuthHeaderIllustration className={'left'} src={'/img/header/illustration-left.svg'} />
    <StyledAuthHeaderIllustration className={'right'} src={'/img/header/illustration-right.svg'} />
    <Grid container justifyContent={'center'}>
      <Grid item>
        <StyledLogoHeader alt="AIMS API" src={'/img/header/logo.svg'} />
      </Grid>
      <Grid item>
        <StyledHeadline variant={'h4'}>App</StyledHeadline>
      </Grid>
    </Grid>
  </StyledAuthHeader>

export default AuthHeader
