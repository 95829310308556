import { AspectRatioBoxProps } from '../types'
import React from 'react'
import { StyledAspectRatioBox } from '../styled'

const AspectRatioBox = ({ ratio, children }: AspectRatioBoxProps): JSX.Element =>
  <StyledAspectRatioBox ratio={ratio}>
    <div className={'aspect-ratio-box'}>
      { children }
    </div>
  </StyledAspectRatioBox>

export default AspectRatioBox
