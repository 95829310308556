export const uppercaseFirst = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1)

export const getPseudoUniqueHash = (): string => {
  let hash = ''

  for (let i = 0; i < 16; i++) {
    const nextVal = Math.floor((Math.random() * 10)).toString()
    hash = hash + nextVal
  }

  return hash
}
