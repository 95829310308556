import { ApiFilter, ApiFilterCondition } from '@aims-search/types'

const consolidateFilterConfiguration = (filterConfiguration: Record<string, unknown>): ApiFilter|undefined => {
  const consolidated: ApiFilter | ApiFilterCondition = ({} as unknown as ApiFilter | ApiFilterCondition)
  Object.keys(filterConfiguration).forEach(key => {
    if (key === 'similarity-search') {
      return
    }
    if (key !== 'conditions') {
      (consolidated as ApiFilterCondition)[(key as 'field' | 'operator' | 'value')] = filterConfiguration[key]
      return
    }
    const filterConfigurationItem = (
      Array.isArray(filterConfiguration[key])
        ? filterConfiguration[key]
        : Object.values(filterConfiguration[key] as Record<string, unknown>)
    ) as ApiFilter[] | ApiFilterCondition[]
    (consolidated as ApiFilter)[key] = [] as ApiFilter[] | ApiFilterCondition[]
    filterConfigurationItem.forEach((conditionData: ApiFilter | ApiFilterCondition) => {
      if (typeof (conditionData as ApiFilterCondition).value !== 'undefined' && (conditionData as ApiFilterCondition).value !== '') {
        ((consolidated as ApiFilter).conditions as ApiFilterCondition[]).push(conditionData as ApiFilterCondition)
        return
      }
      if (typeof (conditionData as ApiFilter).conditions !== 'undefined') {
        const consolidatedChildren = consolidateFilterConfiguration(conditionData as unknown as Record<string, unknown>)
        if (typeof consolidatedChildren !== 'undefined' && typeof consolidatedChildren.conditions !== 'undefined' && consolidatedChildren.conditions.length > 0) {
          ((consolidated as ApiFilter).conditions as ApiFilter[]).push(consolidatedChildren)
        }
      }
    })
  })
  const conditions = (consolidated as ApiFilter).conditions
  return typeof conditions !== 'undefined' && conditions.length > 0 ? consolidated as ApiFilter : undefined
}

const camelCaseToSnakeCase = (string: string): string => {
  return string.replace(/[A-Z]/g, char => `_${char.toLowerCase()}`)
}

const reformatFilters = (filters: ApiFilter): ApiFilter => ({
  logic: filters.logic,
  conditions: (filters.conditions as ApiFilterCondition[])
    .map(condition => Object.assign({}, condition, { field: camelCaseToSnakeCase(condition.field) }))
})

export {
  consolidateFilterConfiguration,
  reformatFilters
}
