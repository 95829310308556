import { CueSheetHook, CueSheetItem } from '@aims-search/types'
import { DetailedTrack, TrackField } from '@aims-track/types'

import { decorateTime } from '@aims-lib'
import { useState } from 'react'

const trackFieldKeyMap = new Map([
  ['albumCode', 'Catalog ID'],
  ['albumDescription', 'Album description'],
  ['albumKeywords', 'Album keywords'],
  ['albumName', 'Album'],
  ['albumNumber', 'Album number'],
  ['artists', 'Artists'],
  ['bpm', 'BPM'],
  ['composers', 'Composer(s)'],
  ['cuesheetInfo', 'Additional information'],
  ['decades', 'Decades'],
  ['duration', 'Duration'],
  ['genres', 'Genre(s)'],
  ['hit', 'Iconic/global hit song'],
  ['filepath', 'Filepath'],
  ['instruments', 'Instrument(s)'],
  ['isrc', 'ISRC'],
  ['jamSync', 'Sync jam'],
  ['key', 'Music key'],
  ['labelCode', 'Label code'],
  ['labelLcName', 'LC code'],
  ['labelName', 'Label'],
  ['lyrics', 'Lyrics'],
  ['lyricsLanguage', 'Lyrics language'],
  ['masterTrackNumber', 'Master track number'],
  ['moods', 'Mood(s)'],
  ['musicFor', 'Music for'],
  ['musicKey', 'Music key'],
  ['musicMeter', 'Music meter'],
  ['owner', 'Repertoire owner'],
  ['publisher', 'Publisher(s)'],
  ['publishingPartner', 'Publishing partner [ROW]'],
  ['publishingPartnerUs', 'Publishing partner [USA]'],
  ['recognisable', 'Recognizable'],
  ['restricted', 'Restricted'],
  ['releaseYear', 'Release year'],
  ['share', 'Hipgnosis share'],
  ['syncHistory', 'With sync history'],
  ['syncJam', 'Sync jam'],
  ['tags', 'Tags'],
  ['tempo', 'Tempo'],
  ['top', 'Top song'],
  ['trackCode', 'Track code'],
  ['trackDescription', 'Track description'],
  ['trackName', 'Track name'],
  ['trackNumber', 'Track number'],
  ['usages', 'Usages'],
  ['version', 'Version'],
  ['versionName', 'Version name'],
  ['versionTag', 'Version tag'],
  ['vocals', 'Vocals']
])

const forcefullyHiddenTrackFields = [
  'trackName',
  'trackCode',
  'duration',
  'albumCode',
  'trackFieldKey'
]

const getItemValue = (key: string, value: string|number|boolean|string[]|null): string => {
  if (value === null || typeof value === 'undefined' || (Array.isArray(value) && value.length === 0)) {
    return 'N/A'
  }
  switch (key) {
    case 'albumKeywords':
    case 'artists':
    case 'composers':
    case 'decades':
    case 'genres':
    case 'instruments':
    case 'key':
    case 'moods':
    case 'musicFor':
    case 'tags':
    case 'usages':
    case 'vocals':
      return Array.isArray(value) ? value.join(', ') : (value as string)
    case 'duration':
      return decorateTime((value as number))
    case 'version':
    case 'syncHistory':
    case 'recognisable':
    case 'restricted':
    case 'jamSync':
    case 'hit':
    case 'top':
      return value === true ? 'Yes' : 'No'
    case 'share':
      return `${Math.round((value as number) * 100)}%`
    default:
      return value.toString()
  }
}

const useCueSheetInfo = (track: DetailedTrack|null, metadataArray: TrackField[]): CueSheetHook => {
  let metadata = metadataArray.filter((key: TrackField): boolean => !forcefullyHiddenTrackFields.includes(key))

  let separatorIndex = metadata.findIndex((item: TrackField): boolean => item === '-')

  if (separatorIndex === -1) {
    separatorIndex = 3
    metadata = [
      ...metadata.slice(0, separatorIndex),
      '-',
      ...metadata.slice(separatorIndex)
    ]
  }

  const exposedMetadata = metadata.slice(0, separatorIndex)

  const createItems = (): Map<TrackField, CueSheetItem> => {
    const newItems = new Map<TrackField, CueSheetItem>()

    metadata.forEach((trackFieldKey: TrackField): void => {
      const label = trackFieldKeyMap.get(trackFieldKey) ?? ''
      if (trackFieldKey !== '-' && track !== null && track[trackFieldKey] !== undefined) {
        const detailedTrackFieldValue = track[trackFieldKey]
        if (detailedTrackFieldValue !== undefined) {
          const newSheetItem = {
            label,
            value: getItemValue(trackFieldKey, detailedTrackFieldValue)
          }
          newItems.set(trackFieldKey, newSheetItem)
        }
      }
    })

    return newItems
  }

  const [sheetInfoItems] = useState<Map<TrackField, CueSheetItem>>(createItems())

  const getItem = (key: TrackField): CueSheetItem|undefined => {
    if (key === '-') {
      return undefined
    }

    const item = sheetInfoItems.get(key)

    if (item === undefined) {
      throw new Error(`item [${key}] was not found in cue sheet items`)
    }

    return item
  }

  const printCSV = (): string => {
    if (track === null) {
      throw new Error('track cannot be null')
    }

    return metadata.reduce((carry, key) => {
      const item = sheetInfoItems.get(key)
      carry[0] += `${carry[0].length > 0 ? ',' : ''}"${item?.label ?? ''}"`
      carry[1] += `${carry[1].length > 0 ? ',' : ''}"${item?.value ?? ''}"`
      return carry
    }, ['', '']).join('\n')
  }

  return {
    getItem,
    printCSV,
    metadata,
    exposedMetadata
  }
}

export { trackFieldKeyMap }
export default useCueSheetInfo
