import { SearchState } from './types'
import { SearchType } from '@aims-search/types'
import { State } from '@aims-app-store'
import { UserIdentity } from '@aims-auth/types'
import { isPublic } from '@aims-store/auth'
import { isSupportedStreamingServiceLink } from 'lib/linkValidator'

export const didSeedChange = (state: State): boolean => state.data.search.fields.text !== state.data.search.lastSearchState?.fields.text
export const getExtraFilterIndex = (state: State): SearchState['extraFilterIndex'] => state.data.search.extraFilterIndex
export const getFiltersKey = (state: State): SearchState['filtersKey'] => state.data.search.filtersKey
export const getLastSearchState = (state: State): SearchState['lastSearchState'] => state.data.search.lastSearchState
export const getPreloadedResults = (state: State): SearchState['preloadedResults'] => state.data.search.preloadedResults
export const getPreloadedPromptResults = (state: State): SearchState['preloadedPromptResults'] => state.data.search.preloadedPromptResults
export const getResultsTotalPromptResults = (state: State): SearchState['promptResults']['totalResults'] => state.data.search.promptResults.totalResults
export const getResultsTotalResults = (state: State): SearchState['results']['totalResults'] => state.data.search.results.totalResults
export const getResultsTracks = (state: State): SearchState['results']['tracks'] => state.data.search.results.tracks
export const getResultsPromptsTracks = (state: State): SearchState['promptResults']['tracks'] => state.data.search.promptResults.tracks
export const getSearchError = (state: State): SearchState['error'] => state.data.search.error
export const getSearchFieldsFile = (state: State): SearchState['fields']['file'] => state.data.search.fields.file
export const getSearchFieldsId = (state: State): SearchState['fields']['id'] => state.data.search.fields.id
export const getSearchFieldsText = (state: State): SearchState['fields']['text'] => state.data.search.fields.text
export const getSearchHash = (state: State): SearchState['searchHash'] => state.data.search.searchHash
export const getSearchPaginationPage = (state: State): SearchState['pagination']['page'] => state.data.search.pagination.page
export const getSearchPaginationSize = (state: State): SearchState['pagination']['pageSize'] => state.data.search.pagination.pageSize
export const getSearchSegmentAudio = (state: State): SearchState['segment']['audio'] => state.data.search.segment.audio
export const getSearchSegmentChanged = (state: State): SearchState['segment']['changed'] => state.data.search.segment.changed
export const getSearchSegmentLimit = (state: State): SearchState['segment']['limit'] => state.data.search.segment.limit
export const getSearchSegmentOffset = (state: State): SearchState['segment']['offset'] => state.data.search.segment.offset
export const getSearchSegmentTrack = (state: State): SearchState['segment']['track'] => state.data.search.segment.track
export const isLoading = (state: State): SearchState['loading'] => state.data.search.loading
export const isSearchModifierIgnoreVocalsActive = (state: State): SearchState['modifiers']['ignoreVocals'] => state.data.search.modifiers.ignoreVocals
export const isSearchModifierPrioritiseBpmActive = (state: State): SearchState['modifiers']['prioritiseBpm'] => state.data.search.modifiers.prioritiseBpm

export const resolveCurrentSearchType = (state: State): SearchType => {
  if (state.data.search.fields.file !== null) {
    return SearchType.Upload
  }
  if (state.data.search.fields.id !== null) {
    return SearchType.Internal
  }
  if (state.data.search.fields.text.match(/^https?:\/\/(.+)\.(.+)/i) === null) {
    const user = state.data.auth.user as UserIdentity
    return user.textSearch ? SearchType.TextPrompt : SearchType.Text
  }
  if (isSupportedStreamingServiceLink(state.data.search.fields.text)) {
    return isPublic(state) ? SearchType.Text : SearchType.Link
  }
  return SearchType.FileLink
}
