import { Button, Tooltip } from '@aims-controls'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { TrackFileType, TrackProps } from '@aims-track/types'
import { feedback, feedbackDownload } from '@aims-store/feedback'
import { getDownloadFilenameTemplate, getMultipleDownloadVersions, getStreamingSecret, isCdnEnabled } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import { Check } from '@material-ui/icons'
import CustomDownloadIcon from '../../../../icons/component/CustomDownloadIcon'
import { downloadTrackNext } from '@aims-track/api'
import { enqueue } from '@aims-layout'
import { isFirefox } from '@aims-lib'
import { useStreaming } from '@aims-player/hooks'

const useDialogStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    paddingBottom: '1.1rem'
  },
  downloadButtonContainer: {
    position: 'relative',
    minWidth: '12rem'
  },
  circularProgressBox: {
    position: 'absolute',
    transform: 'translateX(-50%) translateY(-50%)',
    top: '50%',
    left: '15%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'center'
  }
})

const TrackDowloadButton = ({ track }: TrackProps): JSX.Element => {
  const dispatch = useDispatch()
  const template = useSelector(getDownloadFilenameTemplate)
  const apiSecret = useSelector(getStreamingSecret)
  const multipleDownloadVersions = useSelector(getMultipleDownloadVersions)
  const useCdn = useSelector(isCdnEnabled)
  const { trackDownloadUrl } = useStreaming(apiSecret, useCdn)
  const [fileTypeSelectOpen, setFileTypeSelectOpen] = useState(false)
  const [fileExtentionsList, setFileExtentionsList] = useState<Record<TrackFileType, boolean|undefined>>({
    [TrackFileType.mp3]: true,
    [TrackFileType.wav]: undefined,
    [TrackFileType.NONE]: undefined
  })
  const [downloadingType, setDownloadingType] = useState(TrackFileType.NONE)
  const dialogStyles = useDialogStyles()

  const download = (fileName: string, text: string): void => {
    const tempAnchor = document.createElement('a')

    tempAnchor.setAttribute('href', fileName)
    tempAnchor.setAttribute('download', text)
    tempAnchor.setAttribute('target', isFirefox() ? '_blank' : '_self')
    tempAnchor.setAttribute('rel', 'noopener noreferrer')
    tempAnchor.click()
  }

  const handleFileTypeClick = (fileType: TrackFileType): void => {
    setDownloadingType(fileType)

    const url = trackDownloadUrl(track, template, fileType)
    dispatch(enqueue({ message: `"${track.trackName}.${fileType}" is being downloaded, please wait...` }))

    downloadTrackNext(url)
      .then(() => {
        download(url, `${track.trackName}.${fileType}`)
        if (typeof (track.queryId) === 'string') {
          dispatch(feedback(feedbackDownload(track.idClient), track.queryId))
        }
        dispatch(enqueue({ message: `"${track.trackName}.${fileType}" was downloaded successfully.` }))
        setDownloadingType(TrackFileType.NONE)
      })
      .catch((err) => {
        setDownloadingType(TrackFileType.NONE)
        dispatch(enqueue({ message: (err as Error).message }))
      })
  }

  const renderSimpleDownload = (): JSX.Element => (
    <Tooltip title={'Download'}>
      <IconButton
        className={'action-download'}
        onClick={() => handleFileTypeClick(TrackFileType.mp3)}
        disabled={downloadingType !== TrackFileType.NONE}
      >
        {downloadingType !== TrackFileType.NONE ? <Check /> : <CustomDownloadIcon />}
      </IconButton>
    </Tooltip>
  )

  const handleOpenDialog = (): void => {
    const checkForAvailableExtentions = async (): Promise<void> => {
      if (fileExtentionsList[TrackFileType.wav] === undefined) {
        const wavLoaded = await fetch(trackDownloadUrl(track, template, TrackFileType.wav), { method: 'HEAD' })
        setFileExtentionsList(Object.assign({}, {
          ...fileExtentionsList,
          wav: wavLoaded.ok
        }))
      }
      return await Promise.resolve()
    }

    if (!fileTypeSelectOpen) {
      void checkForAvailableExtentions() // eslint-disable-line no-void
    }

    setFileTypeSelectOpen(!fileTypeSelectOpen)
  }

  const renderFileTypeButton = (fileType: TrackFileType): JSX.Element => {
    const isFileUndefined = fileExtentionsList[fileType] === undefined
    const isFileLoaded = fileExtentionsList[fileType] === true
    const tooltipTitle = isFileUndefined ? 'Checking file extention availability.' : 'Not available'
    const isBeingDownloaded = downloadingType === fileType

    return (
      <Tooltip title={isFileLoaded ? '' : tooltipTitle}>
        <span className={dialogStyles.downloadButtonContainer}>
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={() => handleFileTypeClick(fileType)}
            disabled={downloadingType !== TrackFileType.NONE || !isFileLoaded}
            fullWidth={true}
          >
            {fileType.toString()}
            {isBeingDownloaded && (
              <span className={dialogStyles.circularProgressBox}><CircularProgress size={20} /></span>
            )}
          </Button>
          {isFileUndefined && (
            <span className={dialogStyles.circularProgressBox}><CircularProgress size={20} /></span>
          )}
        </span>
      </Tooltip>
    )
  }

  const renderMultipleDownload = (): JSX.Element => {
    return (
      <>
        <IconButton
          className={'action-download'}
          onClick={handleOpenDialog}
          disabled={downloadingType !== TrackFileType.NONE}
        >
          <CustomDownloadIcon />
        </IconButton>
        <Dialog
          onClose={() => setFileTypeSelectOpen(!fileTypeSelectOpen)}
          aria-labelledby={'file-type-dialog'}
          open={fileTypeSelectOpen}
        >
          <DialogTitle id={'file-type-dialog-title'}>Download options</DialogTitle>
          <DialogContent classes={{ root: dialogStyles.content }}>
            {renderFileTypeButton(TrackFileType.mp3)}
            {renderFileTypeButton(TrackFileType.wav)}
          </DialogContent>
          <DialogActions classes={{ root: dialogStyles.actions }}>
            <Button
              color={'secondary'}
              variant={'text'}
              onClick={() => setFileTypeSelectOpen(false)}
            >Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return multipleDownloadVersions ? renderMultipleDownload() : renderSimpleDownload()
}

export default TrackDowloadButton
