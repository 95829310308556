import { Radio as MuiRadio, RadioProps } from '@material-ui/core'

import React from 'react'

const Radio = (props: RadioProps): JSX.Element => {
  return (
    <MuiRadio {...props} />
  )
}

export default Radio
