import { DetailedTrack, TrackField, TrackFileType } from '@aims-track/types'

import { StreamingFunctions } from '@aims-player/types'

const useStreaming = (apiSecret: string, useCdn: boolean): StreamingFunctions => {
  const host: string = useCdn && process.env.NODE_ENV !== 'development'
    ? process.env.NEXT_PUBLIC_CDN_URL as string
    : process.env.NEXT_PUBLIC_STREAM_API_URL as string

  const enhanceTemplate = (template: string, track: DetailedTrack): string => {
    const properties: string[] = [...(template.matchAll(/{[^{]+}/g) as unknown as RegExpMatchArray)]
    properties.forEach(property => {
      const field = property[0].replace(/[{}]/g, '') as TrackField
      if (field === '-') {
        return
      }
      const value = `${track[field] as string|number|null ?? 'na'}`
      template = template.replace(property[0], value)
    })
    return template
  }

  const trackStreamUrl = (track: DetailedTrack): string =>
    `${host}/${encodeURIComponent(track.streamingHash ?? apiSecret)}/${track.streamingId ?? track.id}${useCdn ? '.mp3' : ''}`

  const trackDownloadUrl = (track: DetailedTrack, template: string|null, fileType: TrackFileType): string =>
    `/api/download/${encodeURIComponent(track.streamingHash ?? apiSecret)}/${track.streamingId ?? track.id}/${encodeURIComponent(template !== null && template.length > 0 ? enhanceTemplate(template, track) : track.trackName)}/${fileType}?idClient=${encodeURIComponent(track.idClient)}`

  return {
    trackStreamUrl,
    trackDownloadUrl
  }
}

export default useStreaming
