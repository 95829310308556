import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../../colors'

const switchOverride: Overrides['MuiSwitch'] = {
  sizeSmall: {
    width: '2rem',
    height: '1.1rem',
    borderRadius: '0.55rem',

    '& .MuiSwitch-switchBase': {
      padding: '0 0 0 0.2rem',
      height: '100%',

      '&.Mui-checked': {
        transform: 'translate(0.8rem) !important',

        '& .MuiSwitch-thumb': {
          backgroundColor: colors.white
        }
      },
      '& .MuiIconButton-label': {
        width: '0.8rem'
      }
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: colors.grey.mediumLight,
      width: '0.8rem',
      height: '0.8rem'
    },
    '& .Mui-checked + .MuiSwitch-track': {
      borderWidth: 0
    }
  },
  track: {
    backgroundColor: colors.white,
    borderRadius: '0.6rem',
    border: `0.1rem solid ${colors.grey.light}`
  },
  thumb: {
    width: undefined,
    height: undefined,
    boxShadow: undefined
  }
}

export default switchOverride
