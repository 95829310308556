import { Grid } from '@material-ui/core'
import React from 'react'
import { SearchFilterWidgetProps } from '@aims-search/types'
import { StyledSelect } from '@aims-controls'
import { numericRange } from '@aims-lib'
import { trackFieldKeyMap } from '@aims-search/hooks'

const PercentileWidget = ({ index, field }: SearchFilterWidgetProps): JSX.Element => {
  const label = trackFieldKeyMap.get(field)

  return (
    <Grid container>
      <Grid item xs={12} className={'filtering-label'}>
        {label}
      </Grid>
      <Grid item xs={12} className={'filter-from'}>
        <StyledSelect
          variant={'outlined'}
          name={`conditions[${index}][value]`}
          id={`conditions-${index}-value`}
          fullWidth
          label={label}
          size={'small'}
        >
          <option key={'-'} value={''} />
          {numericRange(10, 0).map((num) => {
            const tempPercent = num * 10
            return (
              <option key={tempPercent} value={num / 10}>
                {`${num < 10 ? 'More than ' : ''}${tempPercent}%`}
              </option>
            )
          })}
        </StyledSelect>
        <input type="hidden" name={`conditions[${index}][field]`} value={field} />
        <input type="hidden" name={`conditions[${index}][operator]`} value="gte" />
      </Grid>
    </Grid>
  )
}

export default PercentileWidget
