import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomHelpIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 19.5 19.5'}>
    <g id="Elements_information_marker" data-name="Elements / information_marker" transform="translate(0.75 0.75)">
      <path className="fill" id="_" data-name="?" d="M1.492,9.769a1.176,1.176,0,0,1,1.153-1.23A1.176,1.176,0,0,1,3.8,9.769,1.176,1.176,0,0,1,2.645,11,1.177,1.177,0,0,1,1.492,9.769Zm.237-2.21V4.467a2.264,2.264,0,0,0,.577.063,1.892,1.892,0,0,0,1.389-.537,1.621,1.621,0,0,0,.384-1.057,1.3,1.3,0,0,0-.311-.915A.994.994,0,0,0,3,1.7a1.008,1.008,0,0,0-.753.283,1.393,1.393,0,0,0-.325,1.026H0A3.438,3.438,0,0,1,.739.884,2.957,2.957,0,0,1,3.074,0,2.809,2.809,0,0,1,5.232.8,3.014,3.014,0,0,1,6,2.983a2.806,2.806,0,0,1-.754,2.036A2.859,2.859,0,0,1,3.561,5.9V7.559Z" transform="translate(6 3)" fill="#00b8be"/>
      <circle className="stroke" id="Oval" cx="9" cy="9" r="9" fill="none" stroke="#00b8be" strokeMiterlimit="10" strokeWidth="1.5"/>
    </g>
  </SvgIcon>

export default CustomHelpIcon
