import { Button, Tooltip } from '@aims-controls'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, Typography } from '@material-ui/core'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { feedback, feedbackCueSheetDisplay, feedbackCueSheetExport } from '@aims-store/feedback'
import { getLicensingInfo, getMetadata } from '@aims-store/auth'
import { useDispatch, useSelector } from 'react-redux'

import CustomInfoIcon from '../../../../icons/component/CustomInfoIcon'
import HypertextLinkEmail from '../HypertextLinkEmail'
import { StyledTrackDialog } from '@aims-track/styled'
import { TrackProps } from '@aims-track/types'
import { useCueSheetInfo } from '@aims-search/hooks'

const TrackCueSheetInfo = ({ track }: TrackProps): JSX.Element => {
  const dispatch = useDispatch()
  const licensingInfo = useSelector(getLicensingInfo)
  const metadataStructure = useSelector(getMetadata)
  const [modalOpen, setModalOpen]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(Boolean(false))
  const [copyToClipboardText, setCopyToClipboardText]: [string, Dispatch<SetStateAction<string>>] =
    useState('Copy to clipboard')

  const { getItem, printCSV, metadata } = useCueSheetInfo(track, metadataStructure)

  const toggleModal = (): void => {
    if (typeof (track.queryId) === 'string' && !modalOpen) {
      dispatch(feedback(feedbackCueSheetDisplay(track.idClient), track.queryId))
    }
    setModalOpen(!modalOpen)
  }

  const copyToClipboard = (): void => {
    void navigator.clipboard.writeText(printCSV()) // eslint-disable-line no-void
    setCopyToClipboardText('Copied!')
    if (typeof (track.queryId) === 'string') {
      dispatch(feedback(feedbackCueSheetExport(track.idClient), track.queryId))
    }
    setTimeout(() => setCopyToClipboardText('Copy to clipboard'), 2000)
  }

  const makeItemValue = (value: string): JSX.Element|string => {
    const urlRegex = new RegExp(/(https?:\/\/[^\s]+)/ig)
    const matches = urlRegex.exec(value)

    if (matches === null || matches.length === 0) {
      if (value === 'N/A') {
        return <span className={'not-available'}>{value}</span>
      }
      return value
    } else {
      const beforeUrl = value.substring(0, matches.index)
      const url = value.substring(matches.index, matches.index + matches[0].length)
      const afterUrl = value.substring(matches.index + url.length)

      return (
        <>
          {beforeUrl}
          <Link href={url} target="_blank">
            {url}
          </Link>
          {afterUrl}
        </>
      )
    }
  }

  return (
    <>
      <Tooltip title={'Cue sheet info'}>
        <IconButton onClick={toggleModal} className={'action-cue-sheet-info'}>
          <CustomInfoIcon />
        </IconButton>
      </Tooltip>
      <StyledTrackDialog
        open={modalOpen}
        maxWidth={'sm'}
        fullWidth
        onClose={toggleModal}
      >
        <DialogTitle>
          <Typography variant={'h4'} component={'p'}>
            <CustomInfoIcon fontSize={'large'} />
            Cue sheet information
          </Typography>
        </DialogTitle>
        <DialogContent>
          {metadata.map((key) => {
            const item = getItem(key)
            return (item != null) && (
              <Grid container key={`${key}-${track.id}`}>
                <Grid item xs={6}>
                  {item.label}
                </Grid>
                <Grid item xs={6}>
                  {makeItemValue(item.value)}
                </Grid>
              </Grid>
            )
          }
          )}
          {licensingInfo.length > 0 && (
            <Grid container key={'licensingInfo'}>
              <Grid item xs={6}>
                Licensing info
              </Grid>
              <Grid item xs={6}>
                <HypertextLinkEmail text={licensingInfo} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button size={'medium'} color={'secondary'} variant={'contained'} onClick={copyToClipboard}>
            {copyToClipboardText}
          </Button>
          <Button size={'medium'} variant={'text'} onClick={toggleModal}>
            Close
          </Button>
        </DialogActions>
      </StyledTrackDialog>
    </>
  )
}

export default TrackCueSheetInfo
