import { parse, toSeconds } from 'iso8601-duration'

import leftPad from 'left-pad'

export const IGNORE_HOURS = true

export const decorateTime = (seconds: number): string => {
  seconds = Math.round(seconds)
  return `${Math.floor(seconds / 60)}:${leftPad(seconds % 60, 2, '0')}`
}

export const timeToSeconds = (date: Date|null, ignoreHours = false): number|null => {
  if (date === null) {
    return null
  }
  const seconds = (ignoreHours ? 0 : date.getHours() * 3600) + date.getMinutes() * 60 + date.getSeconds()
  return isNaN(seconds) ? null : seconds
}

export const iso8601ToSeconds = (duration: string): number => toSeconds(parse(duration))

export const today = (): Date => {
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export const thisYear = (): number => (new Date()).getFullYear()
