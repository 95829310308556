import AutocompleteWidget from './Widgets/AutocompleteWidget'
import BooleanWidget from './Widgets/BooleanWidget'
import DurationWidget from './Widgets/DurationWidget'
import { Grid } from '@material-ui/core'
import IntegerWidget from './Widgets/IntegerWidget'
import PercentileWidget from './Widgets/PercentileWidget'
import React from 'react'
import { SearchFilterWidgetProps } from '@aims-search/types'
import SwitchWidget from './Widgets/SwitchWidget'
import TextWidget from './Widgets/TextWidget'
import TextWithModifiersWidget from './Widgets/TextWithModifiersWidget'
import YearWidget from './Widgets/YearWidget'
import { trackFieldKeyMap } from '@aims-search/hooks'

const NO_AUTOCOMPLETE_FIELDS = [
  'active', 'bpm', 'commercial', 'duration', 'explicit', 'hit', 'jamSync', 'licensable', 'lyrics', 'profane',
  'recognisable', 'releaseYear', 'restricted', 'share', 'syncHistory', 'top', 'version'
]

const SearchFilterWidget = ({ index, field, fieldData }: SearchFilterWidgetProps): JSX.Element => {
  const isAutocomplete = fieldData !== undefined && !NO_AUTOCOMPLETE_FIELDS.includes(field)

  const getFilterWidget = (field: string, index: number): JSX.Element => {
    switch (field) {
      // integer
      case 'bpm':
        return (
          <IntegerWidget
            index={index}
            field={field}
            inputProps={{
              min: 0,
              max: 200
            }}
          />
        )
      case 'releaseYear':
        return <YearWidget index={index} field={field} />
      // duration
      case 'duration':
        return <DurationWidget index={index} field={field} />
      // string/text
      case 'albumCode':
      case 'albumDescription':
      case 'albumKeywords':
      case 'albumName':
      case 'albumNumber':
      case 'artists':
      case 'composers':
      case 'cuesheetInfo':
      case 'decades':
      case 'genres':
      case 'instruments':
      case 'isrc':
      case 'labelCode':
      case 'labelLcName':
      case 'labelName':
      case 'lyricsLanguage':
      case 'masterTrackNumber':
      case 'moods':
      case 'musicFor':
      case 'musicKey':
      case 'musicMeter':
      case 'owner':
      case 'publisher':
      case 'publishingPartner':
      case 'publishingPartnerUs':
      case 'tags':
      case 'tempo':
      case 'territories':
      case 'trackCode':
      case 'trackDescription':
      case 'trackName':
      case 'trackNumber':
      case 'versionName':
      case 'versionTag':
      case 'vocals':
        return <TextWidget index={index} field={field} />
      // string/text with modifiers
      case 'lyrics':
        return <TextWithModifiersWidget index={index} field={field} />
      // boolean
      case 'active':
      case 'commercial':
      case 'hit':
      case 'jamSync':
      case 'licensable':
      case 'profane':
      case 'recognisable':
      case 'restricted':
      case 'syncHistory':
      case 'top':
      case 'version':
        return <BooleanWidget index={index} field={field} />
      case 'share':
        return <PercentileWidget index={index} field={field} />
      case 'explicit':
        return <SwitchWidget index={index} field={field} label={'Exclude explicit lyrics'} filterValue={'false'} />
      // typeahead
      // TODO: precached option list is needed for typeahead widget
      default:
        throw new Error(`Unsupported filtering widget requested! [field: ${field}]`)
    }
  }

  return (
    <Grid item xs={12} sm={3}>
      {isAutocomplete ? (
        <Grid>
          <Grid item xs={12} className={'filtering-label'}>
            {trackFieldKeyMap.get(field)}
          </Grid>
          <AutocompleteWidget field={field} fieldData={fieldData} index={index} multiple={true} />
        </Grid>
      ) : getFilterWidget(field, index)}
    </Grid>
  )
}

export default SearchFilterWidget
