import { FeedbackOptions } from '@aims-feedback/types'

export const submitFeedback = async (options: FeedbackOptions): Promise<void> => {
  const { user, queryId, idClient, type } = options
  const headers: HeadersInit = {
    authorization: user.apiSecret,
    'Content-Type': 'application/json',
    'X-User-Id': user.id
  }
  const method = 'POST'
  const body = JSON.stringify({
    query_id: queryId,
    id_client: idClient,
    type
  })
  const response = await fetch(`${user.apiHost}/v1/feedback/conversion`, { headers, method, body })
  if (!response.ok) {
    const error = await response.json()
    throw new Error(error.message)
  }
}
