enum SearchType {
  Internal = 'id',
  Link = 'url',
  FileLink = 'file-url',
  Upload = 'file',
  Text = 'text',
  TextPrompt = 'text-prompt'
}

export default SearchType
