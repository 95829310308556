import { AddCircleOutlineRounded, RemoveCircleOutlineRounded } from '@material-ui/icons'
import { AutocompleteWidgetProps, CustomAutocompleteAvatarIconProps } from '@aims-search/types'
import { Chip, TextField } from '@aims-controls'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Tooltip, Typography } from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'
import colors from 'styled/colors'
import { matchSorter } from 'match-sorter'
import styled from 'styled-components'
import { trackFieldKeyMap } from '@aims-search/hooks'

const StyledIcon = styled.span<{isIncluded: CustomAutocompleteAvatarIconProps['isIncluded']}>`
  color: ${props => props.isIncluded ? colors.green : colors.crimson};
  cursor: pointer;
  display: flex;
  align-items: center;
`

const CustomIcon = ({ index, isIncluded, onClick }: CustomAutocompleteAvatarIconProps): JSX.Element => {
  const Icon = isIncluded ? AddCircleOutlineRounded : RemoveCircleOutlineRounded

  return (
    <Tooltip title={<Typography>Switch to {isIncluded ? '\'Exclude\'' : '\'Include\''}</Typography>}>
      <StyledIcon isIncluded={isIncluded} key={index}>
        <Icon onClick={() => onClick(index)} />
      </StyledIcon>
    </Tooltip>
  )
}

const AutocompleteWidget = ({ index, fieldData, field, multiple = false }: AutocompleteWidgetProps): JSX.Element => {
  const [includedOptions] = useState<boolean[]>(Array(fieldData.values.length).fill(true))

  const getMinCharacters = (options: string[]): number =>
    Math.min(Math.floor(options.length / 5000), 4)

  const [value, setValue] = useState<string[]>([])
  const [renderInputValue, setRenderInputValue] = useState('')
  const [focused, setFocused] = useState(false)
  const minCharacters = getMinCharacters(fieldData.values)
  const [refreshEnabledFilter, setRefreshEnabledFilter] = useState(false)

  useEffect(() => {
    if (refreshEnabledFilter) {
      setRefreshEnabledFilter(false)
    }
  }, [refreshEnabledFilter])

  const handleOnChange = (_event: ChangeEvent<Record<string, unknown>>, inputValue: string|string[]|null): void => {
    if (inputValue === null) {
      setValue([])
      return
    }

    const newValue = typeof inputValue === 'string' ? [inputValue] : inputValue

    setValue(newValue)
  }

  const filterOptions = (options: string[], { inputValue }: { inputValue: string }): string[] =>
    inputValue.length >= minCharacters
      ? matchSorter(options, inputValue).slice(0, minCharacters > 0 ? 100 : undefined)
      : []

  const getLabel = (): string|undefined =>
    value.length > 0 || focused ? undefined : trackFieldKeyMap.get(field)

  const getNoOptionsText = (): string => {
    if (minCharacters > 0 && renderInputValue.length < minCharacters) {
      return `Type at least ${minCharacters} character${minCharacters > 1 ? 's' : ''}`
    }
    return 'No results'
  }

  const handleRenderInputOnChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setRenderInputValue(e.target.value)
  }

  const handleToggleItemInSelection = (index: number): void => {
    includedOptions[index] = !includedOptions[index]
    setRefreshEnabledFilter(true)
  }

  const getAvatar = (tagIndex: number): JSX.Element => {
    return <CustomIcon isIncluded={includedOptions[tagIndex]} index={tagIndex} onClick={handleToggleItemInSelection} />
  }

  const getValueForIncluded = (): string[] => value.filter((filterItem: string, index: number): boolean => {
    return includedOptions[index]
  })

  const getValueForExcluded = (): string[] => value.filter((filterItem: string, index: number): boolean => {
    return !includedOptions[index]
  })

  return (
    <>
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            variant={'outlined'}
            color={'secondary'}
            fullWidth
            value={renderInputValue}
            size={'small'}
            label={getLabel()}
            onChange={handleRenderInputOnChange}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, tagIndex) => (
            <Chip key={`autocomplete-tag-${index}`} style={{ margin: 0 }} avatar={getAvatar(tagIndex)} {...getTagProps({ index: tagIndex })} label={option} />
          ))
        }}
        options={fieldData.values}
        value={value}
        onChange={handleOnChange}
        defaultValue={['']}
        multiple={multiple}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        filterOptions={filterOptions}
        limitTags={3}
        autoComplete
        autoHighlight
        noOptionsText={getNoOptionsText()}
      />
      <input type="hidden" name={`conditions[${index}][field]`} value={field} />
      <input type="hidden" name={`conditions[${index}][operator]`} value={'in'} />
      <input type="hidden" name={`conditions[${index}][value]`} value={getValueForIncluded()} />

      <input type="hidden" name={`conditions[${index + 1}][field]`} value={field} />
      <input type="hidden" name={`conditions[${index + 1}][operator]`} value={'neq'} />
      <input type="hidden" name={`conditions[${index + 1}][value]`} value={getValueForExcluded()} />
    </>
  )
}

export default AutocompleteWidget
