import styled, { keyframes } from 'styled-components'

import { Chip } from '@aims-controls'
import { hexToRGBA } from '@aims-lib'

const caretAnimation = keyframes`
  0%  {opacity: 1;}
  49%  {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 0;}
`

const StyledTrackTag = styled(Chip)`&& {
  background-color: ${props => props.theme.colors.white};
  border: 0.1rem solid ${props => props.theme.colors.primary};
  border-radius: 0.25rem;
  color: ${props => props.theme.colors.primary};
  font-size: 1rem;
  font-weight: ${props => props.theme.font.medium};
  margin-right: 0.3rem;

  &.MuiChip-clickable {
    &.is-bpm {
      cursor: default;
    }
    &:not(.add-tag) {
      &:not(.is-bpm):hover, &:not(.is-bpm):focus {
        color: ${props => props.theme.colors.crimson};
        background: ${props => `${hexToRGBA(props.theme.colors.crimson, 0.07)}`};
        border-color: ${props => props.theme.colors.crimson};
        text-decoration: line-through;
      }
    }
  }

  &.add-tag {
    cursor: text;

    &:hover {
      background-color: ${props => props.theme.colors.primaryLightest};
    }

    & .tag-input {
      cursor: text;
      padding: 0 0.2rem;
      position: relative;
      outline: none;

      &:empty {
        min-width: 4.5rem;

        &:before {
          content: attr(placeholder);
          color: ${props => props.theme.colors.grey.medium};
        }

        &:focus:after {
          content: '';
          left: 4px;
          height: 90%;
          position: absolute;
          border-left: 1px solid ${props => props.theme.colors.primary};
          animation: ${caretAnimation} 1s infinite;
        }
      }
    }

    & .tag-input br {
      display: none;
    }
  }
}`

export default StyledTrackTag
