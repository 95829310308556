import { RemainingSearchesRequest } from '@aims-playlist-plugging/types'
import { UserIdentity } from '@aims-auth/types'

const getRemainingMonthlyRequests = async (user: UserIdentity): Promise<RemainingSearchesRequest> => {
  const response = await fetch('/api/playlist-plugging/remaining-monthly-requests', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ apiSecret: user.apiSecret, id: user.id, apiHost: user.apiHost })
  })
  if (!response.ok) {
    const errorMessage = await response.text()
    throw new Error(errorMessage)
  }
  const responseJson = await response.json()

  return await Promise.resolve(responseJson)
}

const getRemainingMonthlyRequestsNext = async ({ apiSecret, id, apiHost = '' }: Partial<UserIdentity>): Promise<RemainingSearchesRequest> => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    authorization: apiSecret ?? '',
    'X-User-Id': id ?? ''
  }
  const response = await fetch(`${apiHost}/v1/accounts/remaining-monthly-credits`, { headers, method: 'GET' })
  if (!response.ok) {
    return await Promise.reject(new Error('Can not fetch remaining monthly requests!'))
  }
  const data = await response.json()
  return await Promise.resolve({ remainingRequests: data.remaining_monthly_credits, total: data.total_monthly_credits })
}

export {
  getRemainingMonthlyRequests,
  getRemainingMonthlyRequestsNext
}
