import { CircularProgress, Grid, Typography } from '@material-ui/core'

import { Collection } from '@aims-collection/types'
import Link from 'next/link'
import ProjectActions from '../ProjectActions'
import ProjectStyled from './Project.styled'
import React from 'react'
import { Tooltip } from '@aims-controls'
import { getIsRefreshingProject } from '@aims-store/project'
import { useSelector } from 'react-redux'

const Project = ({ project, featured = false }: {project: Collection, featured?: boolean}): JSX.Element => {
  const refreshingProject = useSelector(getIsRefreshingProject)

  const renderInfoIcons = (): JSX.Element => {
    const items: JSX.Element[] = []

    if (refreshingProject) {
      items.push(<Tooltip key={'refreshing-project'} title={'Refreshing project . . .'}><CircularProgress size={'100%'} /></Tooltip>)
    }

    return <div className={'info-icons-list'}>{items}</div>
  }

  return (
    <ProjectStyled className={`project ${featured ? 'featured' : 'full-width'}`}>
      <Grid container wrap={'nowrap'}>
        <Grid item xs={12} sm={9}>
          {
            featured
              ? <Typography variant={'h6'} className={'link disabled'}>{project.title}</Typography>
              : <Link href={`/my-projects/${encodeURIComponent(project.key)}`}>
                <Typography variant={'h6'} className={'link'}>{project.title}</Typography>
              </Link>
          }
          <div className={'metadata'}>
            <span className={'last-updated-title'}>Last updated</span> {renderInfoIcons()}
            <p>
              {project.updatedAt !== null ? (new Date(project.updatedAt)).toLocaleString() : 'never (add some tracks first)'}
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ProjectActions project={project} />
        </Grid>
      </Grid>
    </ProjectStyled>
  )
}

export default Project
