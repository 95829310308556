import { SvgIcon, SvgIconProps } from '@material-ui/core'

import React from 'react'

const CustomTrashIcon = (props: SvgIconProps): JSX.Element =>
  <SvgIcon {...props} viewBox={'0 0 22 22'}>
    <g id="Group" transform="translate(3 1)">
      <path id="Stroke_1" data-name="Stroke 1" d="M11.632,14.074H.922L0,0H12.554Z" transform="translate(1.489 5.946)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_3" data-name="Stroke 3" d="M0,3.075H15.533V0H0Z" transform="translate(0 2.87)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_5" data-name="Stroke 5" d="M4.889,0H.813A.813.813,0,0,0,0,.813V2.057a.813.813,0,0,0,.813.813H4.889A.813.813,0,0,0,5.7,2.057V.813A.813.813,0,0,0,4.889,0Z" transform="translate(4.916 0)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_6" data-name="Stroke 6" d="M.057,8.44V0" transform="translate(7.716 8.83)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_7" data-name="Stroke 7" d="M0,8.44.387,0" transform="translate(10.43 8.83)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
      <path id="Stroke_8" data-name="Stroke 8" d="M.387,8.44,0,0" transform="translate(4.73 8.83)" fill="none" stroke="silver" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.4"/>
    </g>
  </SvgIcon>

export default CustomTrashIcon
