import { Overrides } from '@material-ui/core/styles/overrides'
import colors from '../../colors'
import theme from '../../theme'

const tooltipOverride: Overrides['MuiTooltip'] = {
  tooltip: {
    borderRadius: '0.3rem',
    color: colors.white,
    fontSize: '0.85rem',
    fontWeight: theme.font.regular,
    maxWidth: '12rem',
    padding: '0.75rem 1.25rem',
    textAlign: 'center'
  }
}

export default tooltipOverride
