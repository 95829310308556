import { Check, StopRounded } from '@material-ui/icons'
import { CheckboxProps, Checkbox as MuiCheckbox } from '@material-ui/core'

import React from 'react'

const Checkbox = (props: CheckboxProps): JSX.Element => {
  const viewBox = props.disabled === true ? '5 5 14 14' : undefined

  return (
    <MuiCheckbox
      checkedIcon={<Check fontSize={'small'}/>}
      icon={<StopRounded fontSize={'small'} viewBox={viewBox}/>}
      {...props}
    />
  )
}

export default Checkbox
