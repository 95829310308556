import {
  AuthState,
  DecrementAutoTaggingRequestsRemainingAction,
  LoginSuccessAction,
  SetApiConfigurationAction,
  SetAutoTaggingTokenAction,
  SetIsLoadingAction,
  SetPublicUserAction
} from './types'
import {
  DECREMENT_AUTO_TAGGING_REQUESTS_REMAINING,
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  MAGIC_LOGIN_BEGIN,
  MAGIC_LOGIN_ERROR,
  REQUEST_RESET_BEGIN,
  REQUEST_RESET_ERROR,
  REQUEST_RESET_SUCCESS,
  RESET_BEGIN,
  RESET_ERROR,
  RESET_SUCCESS,
  SET_API_CONFIGURATION,
  SET_AUTO_TAGGING_TOKEN,
  SET_IS_LOADING,
  SET_PUBLIC_USER,
  SET_SEARCH_FILTER_FIELDS
} from './action-types'

import { Action } from 'redux'
import { SetSearchFilterFieldsAction } from '@aims-store/search'
import initialState from './initialState'

const reducer = (state = initialState, action: Action = { type: undefined }): AuthState => {
  switch (action.type) {
    case LOGIN_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        logout: false,
        resetRequested: false,
        resetComplete: false,
        error: false
      })
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        user: (action as LoginSuccessAction).payload.user
      })
    case LOGIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })
    case LOGOUT:
      return Object.assign({}, state, {
        loading: false,
        user: null,
        logout: true,
        error: false
      })
    case SET_API_CONFIGURATION:
      return {
        ...state,
        apiConfiguration: (action as SetApiConfigurationAction).apiConfiguration,
        autoTaggingConfiguration: (action as SetApiConfigurationAction).autoTaggingConfiguration
      }
    case SET_AUTO_TAGGING_TOKEN:
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          autoTaggingToken: (action as SetAutoTaggingTokenAction).token,
          remainingMonthlyRequests: (action as SetAutoTaggingTokenAction).remainingMonthlyRequests,
          monthlyRequestLimit: (action as SetAutoTaggingTokenAction).monthlyRequestLimit
        })
      })
    case SET_PUBLIC_USER:
      return Object.assign({}, state, {
        user: (action as SetPublicUserAction).payload.user
      })
    case DECREMENT_AUTO_TAGGING_REQUESTS_REMAINING:
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, {
          remainingMonthlyRequests: (action as DecrementAutoTaggingRequestsRemainingAction).remainingMonthlyRequests
        })
      })
    case REQUEST_RESET_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        resetRequested: false,
        error: false
      })
    case REQUEST_RESET_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        resetRequested: true
      })
    case REQUEST_RESET_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })
    case RESET_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        resetCompleted: false,
        error: false
      })
    case RESET_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        resetCompleted: true
      })
    case RESET_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })
    case MAGIC_LOGIN_BEGIN:
      return Object.assign({}, state, {
        loading: true,
        logout: false,
        resetRequested: false,
        resetComplete: false,
        error: false
      })
    case MAGIC_LOGIN_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: true
      })
    case SET_IS_LOADING:
      return Object.assign({}, state, {
        loading: (action as SetIsLoadingAction).payload.on
      })
    case SET_SEARCH_FILTER_FIELDS:
      return Object.assign({}, state, {
        filterFields: (action as SetSearchFilterFieldsAction).payload.searchFilterFields
      })
    default:
      return state
  }
}

export default reducer
