import styled from 'styled-components'

const StyledTrackCode = styled.span`
  border: 0.1rem solid ${props => props.theme.colors.grey.light};
  border-radius: 0.3rem;
  color: ${props => props.theme.colors.grey.medium};
  margin-bottom: 0.5rem;
  padding: 0.1rem 0.2rem;
`

export default StyledTrackCode
