import { SearchFilterField } from '@aims-search/types'
import { UserIdentity } from '@aims-auth/types'

const getSearchFilterFields = async (user: UserIdentity): Promise<SearchFilterField[]> => {
  const responses = await Promise.allSettled(user.filters.map(async (key: string): Promise<SearchFilterField> => {
    const field = getFieldUnderscored(key)
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: user.apiSecret
    }

    const response = await fetch(
        `${user.apiHost}/v1/tracks/values/${field}`,
        { headers, method: 'GET' }
    )

    if (!response.ok) {
      return await Promise.reject(new Error(`Filter field [${field}] failed to load.`))
    }

    const responseJson = await response.json()

    return await Promise.resolve({
      key,
      values: responseJson.values.filter(({ value }: { value: string}): boolean => value !== null)
        .map(({ value }: { value: string }): string => value.toString())
    })
  }))

  const result = responses
    .filter((settledResponse: PromiseSettledResult<SearchFilterField>): boolean => settledResponse.status === 'fulfilled')
    .map((settledResponse: PromiseSettledResult<SearchFilterField>): SearchFilterField => {
      return (settledResponse as PromiseFulfilledResult<SearchFilterField>).value
    })

  return await Promise.resolve(result)
}

const getFieldUnderscored = (field: string): string => {
  let underscored = ''
  for (let i = 0; i < field.length; i++) {
    const letter = field[i]
    const add = letter.charCodeAt(0) < 97 ? `_${letter.toLowerCase()}` : letter
    underscored = underscored + add
  }
  return underscored
}

export {
  getSearchFilterFields
}
