import { DetailedTrack } from '@aims-track/types'
import { Results } from '@aims-search/types'
import { isObject } from '@aims-lib'

const snakeCaseToCamelCase = (string: string): string => {
  return string
    .replace(/_+/, '_')
    .split('_')
    .reduce((carry: string, entry: string): string => {
      return `${carry}${carry.length > 0 ? `${entry[0].toUpperCase()}${entry.substr(1)}` : entry}`
    }, '')
}

export const convertKeysToCamelCase = <T>(object: Record<string, unknown>): T => {
  const results: Record<string, unknown> = {}
  Object.keys(object).forEach(key => {
    const value = object[key]
    const newKey = snakeCaseToCamelCase(key)
    if (isObject(value)) {
      results[newKey] = convertKeysToCamelCase(value as Record<string, unknown>)
      return
    }
    if (Array.isArray(value)) {
      results[newKey] = value.map(item => isObject(item) ? convertKeysToCamelCase(item) : item)
      return
    }
    results[newKey] = value
  })
  return results as T
}

export const reformatResults = <T extends Results>(rawResults: Record<string, unknown>): T => {
  const results = convertKeysToCamelCase<T>(rawResults)
  results.tracks = results.tracks.map((track: DetailedTrack): DetailedTrack => ({ ...track, queryId: results.queryId ?? null }))
  return results
}
