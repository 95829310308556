import { Switch, SwitchProps } from '@material-ui/core'

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import theme from '../../../../styled/theme'

const useStyles = makeStyles({
  root: {
    height: '1.1rem',
    width: '2rem',
    padding: 0
  },
  switchBase: {
    padding: '0.15rem',
    '&$checked': {
      transform: 'translateX(0.9rem)',
      color: theme.colors.white,
      '& + $track': {
        backgroundColor: theme.colors.secondary,
        borderColor: theme.colors.secondary,
        opacity: 1
      },
      '& $thumb': {
        backgroundColor: theme.colors.white
      }
    },
    '&$focusVisible $thumb': {
      backgroundColor: theme.colors.white
    }
  },
  thumb: {
    backgroundColor: theme.colors.grey.mediumLight,
    boxShadow: 'none',
    height: '0.8rem',
    width: '0.8rem'
  },
  track: {
    backgroundColor: theme.colors.white,
    border: `0.1rem solid ${theme.colors.grey.light}`,
    borderRadius: '0.6rem',
    opacity: 1
  },
  checked: {},
  focusVisible: {}
})

const StyledSwitch = (props: SwitchProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
}

export default StyledSwitch
